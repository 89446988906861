/* eslint-disable max-lines */
// import { ExcalidrawSidebar } from '@/components/mappings/excalidraw-sidebar';
import { ACCESS_TOKEN_KEY } from '@/constants';
import { useParams } from 'react-router-dom';
export function WorldPosition() {
  const params = useParams();
  return (
    <>
      <iframe
        src={``}
        id="worldPosition"
        style={{ border: 0, width: '100%', height: '65vh' }}
      ></iframe>
    </>
  );
}
