import { all } from 'redux-saga/effects';
import amenitySaga from './amenity';
import categorySaga from './category';
import connectionSaga from './connection';
import customerSaga from './customer';
import eventSaga from './event';
import floorSaga from './floor';
import locationSaga from './location';
import loginSaga from './login';
import qrcodeSaga from './qrcode';
import snapShotSaga from './snapshot';
import venueSaga from './venue';

function* rootSaga() {
  yield all([
    loginSaga(),
    customerSaga(),
    venueSaga(),
    floorSaga(),
    connectionSaga(),
    locationSaga(),
    eventSaga(),
    amenitySaga(),
    categorySaga(),
    qrcodeSaga(),
    snapShotSaga(),
    // add other saga watcher
  ]);
}
export default rootSaga;
