export const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.6667 2.66665H12V1.33331H10.6667V2.66665H5.33333V1.33331H4V2.66665H3.33333C2.59333 2.66665 2.00667 3.26665 2.00667 3.99998L2 13.3333C2 13.6869 2.14048 14.0261 2.39052 14.2761C2.64057 14.5262 2.97971 14.6666 3.33333 14.6666H12.6667C13.4 14.6666 14 14.0666 14 13.3333V3.99998C14 3.26665 13.4 2.66665 12.6667 2.66665ZM12.6667 13.3333H3.33333V6.66665H12.6667V13.3333ZM6 9.33331H4.66667V7.99998H6V9.33331ZM8.66667 9.33331H7.33333V7.99998H8.66667V9.33331ZM11.3333 9.33331H10V7.99998H11.3333V9.33331ZM6 12H4.66667V10.6666H6V12ZM8.66667 12H7.33333V10.6666H8.66667V12ZM11.3333 12H10V10.6666H11.3333V12Z"
      fill="#40495C"
    />
  </svg>
);
