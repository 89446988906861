/* eslint-disable max-lines */
import { Box, CardMedia, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomButton, { CustomButtonType } from '../common/Button';
import { CameraIcon, CloseIcon, UploadImgIcon } from '../icons';
import ReactImageUploading from './imageUploader';
import { ErrorMessage, Label } from './input';
import { InputType } from './customSelect';
import { UploadButton } from './styled';
import { useTranslation } from 'react-i18next';

export interface ImageInputProps {
  title?: string;
  label?: string;
  width: number;
  height: number;
  multiple?: boolean;
  inputType?: InputType;
  isSetting?: boolean;
  onUpload?: (value: any) => void;
  value?: any[];
  errorMessage?: string;
  isRequired?: boolean;
}

export const ImageInput = ({
  title,
  label,
  width,
  height,
  multiple = false,
  isSetting = false,
  inputType = InputType.POP_UP,
  onUpload,
  value,
  errorMessage,
  isRequired = false,
}: ImageInputProps) => {
  const [images, setImages] = useState(value ?? []);
  const maxNumber = 69;
  const { t } = useTranslation();

  const onChange = (imageList: any) => {
    // data for submit
    setImages(imageList);
    onUpload && onUpload(imageList);
  };

  const onError = (errors: any, files: any) => {
    // TODO
  };

  return (
    <Box component="div">
      {label && (
        <Label $fromType={inputType}>
          {label} {isRequired && <span>*</span>}
        </Label>
      )}
      <ReactImageUploading
        multiple={multiple}
        value={images}
        onChange={onChange}
        onError={onError}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          // write your own UI
          <Box component="div" className="img_input">
            <Box
              component="div"
              sx={{
                margin: isSetting ? '0 auto' : 0,
                textAlign: isSetting ? 'center' : 'left',
              }}
            >
              <Box component="div" sx={{ textAlign: 'center' }}>
                {images.length === 0 ? (
                  !isSetting ? (
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: width + 'px',
                        minHeight: height + 'px',
                        opacity: isDragging ? '0.3' : '1',
                        background: '#fff',
                        borderRadius: '2px',
                        padding: '24px',
                      }}
                      {...dragProps}
                    >
                      <Box
                        component="div"
                        sx={{ textAlign: 'center', width: '100%' }}
                      >
                        <UploadImgIcon />
                        <Note>{title}</Note>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '120px',
                        minHeight: '120px',
                        opacity: isDragging ? '0.3' : '1',
                        background: '#fff',
                        borderRadius: '50%',
                        padding: '24px',
                        margin: '20px auto 0',
                      }}
                      {...dragProps}
                    >
                      <Box
                        component="div"
                        sx={{ textAlign: 'center', width: '100%' }}
                      >
                        <CameraIcon />
                      </Box>
                    </Box>
                  )
                ) : (
                  <Box
                    component="div"
                    sx={{
                      display: isSetting ? 'block' : 'grid',
                      gap: '10px',

                      margin: isSetting ? '20px auto' : '0',
                      gridTemplateColumns: isSetting
                        ? '1fr'
                        : 'repeat(3, 160px)',
                    }}
                  >
                    {imageList.map((image, index) => {
                      return (
                        <Box
                          component="div"
                          key={`image-${index}`}
                          sx={{
                            position: 'relative',
                            borderRadius: isSetting ? '50%' : 'none',
                          }}
                        >
                          <CardMedia
                            image={
                              typeof image === 'string'
                                ? image
                                : image?.data_url
                            }
                            title={`image`}
                            sx={{
                              width: 120,
                              height: 120,
                              borderRadius: isSetting ? '50%' : 'none',
                              margin: isSetting ? '0 auto' : 0,
                            }}
                          />

                          {!isSetting && (
                            <Box
                              component="div"
                              sx={{
                                position: 'absolute',
                                top: '-10px',
                                right: '5px',
                              }}
                              onClick={() => onImageRemove(index)}
                            >
                              <CloseIcon />
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
              {isSetting ? (
                <>
                  <UploadButton onClick={onImageUpload}>
                    <CameraIcon />
                    {t('Change Photo')}
                  </UploadButton>
                  <Note mt={2} mb={2} $inputType={inputType} className="big">
                    {t(
                      'Support for a single upload, size limit: 10MB. Allowed file types: PNG, JPG',
                    )}
                  </Note>
                </>
              ) : (
                <>
                  <Note mt={1} mb={2} $inputType={inputType}>
                    {t(
                      'Please upload .jpg, .png, .svg formats only. For best results use square image (1:1 aspect ratio)',
                    )}
                  </Note>
                  <CustomButton
                    title={t(`Upload`)}
                    buttonType={CustomButtonType.UPLOAD}
                    onClick={onImageUpload}
                  />
                </>
              )}
            </Box>

            {/* <button type="button" onClick={onImageRemoveAll}>
              Remove all images
            </button> */}

            {errors && (
              <Box component="div">
                {errors.maxNumber && (
                  <Note mb={1} color={`red`}>
                    {t('Number of selected images exceed maxNumber')}
                  </Note>
                )}
                {errors.acceptType && (
                  <Note mb={1} color={`red`}>
                    {t('Your selected file type is not allowed')}
                  </Note>
                )}
                {errors.maxFileSize && (
                  <Note mb={1} color={`red`}>
                    {t('Selected file size exceed maxFileSize')}
                  </Note>
                )}
                {errors.resolution && (
                  <Note mb={1} color={`red`}>
                    {t('Selected file is not match your desired resolution')}
                  </Note>
                )}
              </Box>
            )}
          </Box>
        )}
      </ReactImageUploading>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Box>
  );
};

const Note = styled(Typography)<{ $inputType?: InputType }>`
  && {
    color: ${props =>
      props.$inputType === InputType.MAPPING ? '#fff' : '#5c677e '};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    &.big {
      font-size: 14px;
      padding: 10px 100px;
      text-align-center;
      display: flex;
    }
  }
`;
