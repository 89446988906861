import { RequestStatus } from '@/constants/API';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ICommonModel, LocationDetail } from './type';
import { IPageDataResponse } from '@/types/common';

export interface LocationState {
  getLocationListStatus: RequestStatus;
  getLocationToVenueStatus: RequestStatus;
  createLocationInfoStatus: RequestStatus;
  updateLocationInfoStatus: RequestStatus;
  deleteLocationInfoStatus: RequestStatus;
  getLocationDetailsStatus: RequestStatus;
  getTagListStatus: RequestStatus;
  locations: IPageDataResponse<LocationDetail> | null;
  locationDetails: LocationDetail | null;
  tags: ICommonModel[];
  locationsToVenue: ICommonModel[];
}

const initialState: LocationState = {
  getLocationListStatus: RequestStatus.IDLE,
  getLocationToVenueStatus: RequestStatus.IDLE,
  getTagListStatus: RequestStatus.IDLE,
  createLocationInfoStatus: RequestStatus.IDLE,
  updateLocationInfoStatus: RequestStatus.IDLE,
  deleteLocationInfoStatus: RequestStatus.IDLE,
  getLocationDetailsStatus: RequestStatus.IDLE,
  locations: null,
  locationDetails: null,
  tags: [],
  locationsToVenue: [],
};

export const locationSlice: any = createSlice({
  name: 'location',
  initialState,
  reducers: {
    getLocationListRequest: (state, action: PayloadAction<any>) => {
      state.getLocationListStatus = RequestStatus.REQUESTING;
    },
    getLocationListSuccess: (state, action: PayloadAction<any>) => {
      state.getLocationListStatus = RequestStatus.SUCCESS;
      state.locations = action.payload;
    },
    getLocationListFailure: state => {
      state.getLocationListStatus = RequestStatus.ERROR;
    },
    getLocationsToVenueRequest: (state, action: PayloadAction<any>) => {
      state.getLocationToVenueStatus = RequestStatus.REQUESTING;
    },
    getLocationsToVenueSuccess: (state, action: PayloadAction<any>) => {
      state.getLocationToVenueStatus = RequestStatus.SUCCESS;
      state.locationsToVenue = action.payload;
    },
    getLocationsToVenueFailure: state => {
      state.getLocationToVenueStatus = RequestStatus.ERROR;
    },
    getTagListRequest: (state, action: PayloadAction<any>) => {
      state.getTagListStatus = RequestStatus.REQUESTING;
    },
    getTagListSuccess: (state, action: PayloadAction<any>) => {
      state.getTagListStatus = RequestStatus.SUCCESS;
      state.tags = action.payload;
    },
    getTagListFailure: state => {
      state.getTagListStatus = RequestStatus.ERROR;
    },

    getLocationDetailsRequest: (state, action: PayloadAction<any>) => {
      state.getLocationDetailsStatus = RequestStatus.REQUESTING;
    },
    getLocationDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.getLocationDetailsStatus = RequestStatus.SUCCESS;
      state.locationDetails = action.payload;
    },
    getLocationDetailsFailure: state => {
      state.getLocationDetailsStatus = RequestStatus.ERROR;
    },
    createLocationInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createLocationInfoStatus = RequestStatus.REQUESTING;
    },
    createLocationInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createLocationInfoStatus = RequestStatus.SUCCESS;
    },
    createLocationInfoFailure: (state, action: PayloadAction<any>) => {
      state.createLocationInfoStatus = RequestStatus.ERROR;
    },
    updateLocationInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateLocationInfoStatus = RequestStatus.REQUESTING;
    },
    updateLocationInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateLocationInfoStatus = RequestStatus.SUCCESS;
    },
    updateLocationInfoFailure: (state, action: PayloadAction<any>) => {
      state.updateLocationInfoStatus = RequestStatus.ERROR;
    },
    deleteLocationInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteLocationInfoStatus = RequestStatus.REQUESTING;
    },
    deleteLocationInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteLocationInfoStatus = RequestStatus.SUCCESS;
    },
    deleteLocationInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteLocationInfoStatus = RequestStatus.ERROR;
    },
    resetLocationInfoRequestStatus: state => {
      state.getLocationListStatus = RequestStatus.IDLE;
      state.createLocationInfoStatus = RequestStatus.IDLE;
      state.updateLocationInfoStatus = RequestStatus.IDLE;
      state.deleteLocationInfoStatus = RequestStatus.IDLE;
      state.getLocationDetailsStatus = RequestStatus.IDLE;
      state.getTagListStatus = RequestStatus.IDLE;
      state.getLocationToVenueStatus = RequestStatus.IDLE;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getLocationListRequest,
  getLocationListSuccess,
  getLocationListFailure,
  createLocationInfoRequest,
  createLocationInfoSuccess,
  createLocationInfoFailure,
  updateLocationInfoRequest,
  updateLocationInfoSuccess,
  updateLocationInfoFailure,
  deleteLocationInfoRequest,
  deleteLocationInfoSuccess,
  deleteLocationInfoFailure,
  getLocationDetailsRequest,
  getLocationDetailsSuccess,
  getLocationDetailsFailure,
  resetLocationInfoRequestStatus,
  getTagListRequest,
  getTagListSuccess,
  getTagListFailure,
  getLocationsToVenueRequest,
  getLocationsToVenueSuccess,
  getLocationsToVenueFailure,
} = locationSlice.actions;

export default locationSlice.reducer;
