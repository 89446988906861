import { createSelector } from '@reduxjs/toolkit';
import { connectionSlice } from './connection';
import { RootState } from '../..';

const selectSlice = (state: RootState) =>
  state.connection || connectionSlice.getInitialState;

export const selectConnection = createSelector(
  [selectSlice],
  connectionState => ({
    ...connectionState,
  }),
);
