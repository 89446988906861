import { useAppSelector, useAppDispatch } from '@/hooks';

import { selectConnection } from '@/stores/slices/connection/selector';
import {
  getConnectionDetailsRequest,
  getConnectionListRequest,
  updateConnectionInfoRequest,
  deleteConnectionInfoRequest,
  createConnectionInfoRequest,
  resetConnectionInfoRequestStatus,
} from '@/stores/slices/connection/connection';

const useConnectionHook = () => {
  const {
    getConnectionDetailsStatus,
    getConnectionListStatus,
    updateConnectionInfoStatus,
    createConnectionInfoStatus,
    deleteConnectionInfoStatus,
    connectionDetails,
    connections,
  } = useAppSelector(selectConnection);

  const dispatch = useAppDispatch();

  const requestConnectionList = (params: any) =>
    dispatch(getConnectionListRequest(params));

  const requestConnectionDetails = (params: any) =>
    dispatch(getConnectionDetailsRequest(params));

  const requestUpdateConnectionInfo = (params: any) =>
    dispatch(updateConnectionInfoRequest(params));

  const requestCreateConnection = (params: any) =>
    dispatch(createConnectionInfoRequest(params));

  const requestDeleteConnection = (params: any) =>
    dispatch(deleteConnectionInfoRequest(params));

  const requestResetConnection = () =>
    dispatch(resetConnectionInfoRequestStatus());

  return {
    requestConnectionList,
    requestConnectionDetails,
    requestUpdateConnectionInfo,
    requestCreateConnection,
    requestDeleteConnection,
    requestResetConnection,
    getConnectionDetailsStatus,
    getConnectionListStatus,
    updateConnectionInfoStatus,
    createConnectionInfoStatus,
    deleteConnectionInfoStatus,
    connectionDetails,
    connections,
  };
};

export default useConnectionHook;
