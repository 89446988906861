import { call, put, takeLatest } from 'redux-saga/effects';

import { setUserSessionData } from '@/stores/slices/global/global';
import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  getProfileFailure,
  getProfileRequest,
  getProfileSuccess,
  updatePasswordFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
  updateProfileFailure,
  updateProfileRequest,
  updateProfileSuccess,
} from '../slices/auth/auth';

import { removeToken, setRefreshToken, setToken } from '@/utils/localStorage';

type IPayload = Record<string, any>;

function* loginFlow({ payload }: IPayload): any {
  let errorMess = ['Ops..Login failed!'];

  try {
    const response = yield call(SERVICES.login, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: loginSuccess.type,
        payload: response.data,
      });
      yield setToken(response.data?.token);
      yield setRefreshToken(response.data?.refresh);
      yield put({
        type: setUserSessionData.type,
        payload: response.data,
      });
    } else {
      errorMess = response.data?.non_field_errors;
      yield put({ type: loginFailure.type, payload: { errorMess } });
    }
  } catch (error) {
    yield put({ type: loginFailure.type, payload: { errorMess } });
  }
}

function* logoutFlow({ payload }: IPayload): any {
  yield removeToken();
}

function* getProfileFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getProfile, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getProfileSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getProfileFailure.type });
    }
  } catch (error) {
    yield put({ type: getProfileFailure.type });
  }
}

function* updateProfileFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateProfile, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateProfileSuccess.type,
        payload: response.data,
      });
      yield put({
        type: setUserSessionData.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateProfileFailure.type });
    }
  } catch (error) {
    yield put({ type: updateProfileFailure.type });
  }
}

function* changePasswordFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.changePassword, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updatePasswordSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updatePasswordFailure.type });
    }
  } catch (error) {
    yield put({ type: updatePasswordFailure.type });
  }
}

function* loginSaga() {
  yield takeLatest(loginRequest, loginFlow);
  yield takeLatest(logoutRequest, logoutFlow);
  yield takeLatest(getProfileRequest, getProfileFlow);
  yield takeLatest(updateProfileRequest, updateProfileFlow);
  yield takeLatest(updatePasswordRequest, changePasswordFlow);
}

export default loginSaga;
