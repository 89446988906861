import { Container } from '@/components/venue/components';
import { Loader } from '@/components/common/loader';
import { NoData } from '@/components/common/NoData';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import { checkIsRequesting } from '@/utils/helper';
import _ from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGlobalHook from '@/hooks/useGlobalHook';
import { RequestStatus } from '@/constants/API';
import useSnapshotHook from '@/hooks/useSnapshotHook';

import RevertConfirmPopup from '@/components/snapshot/popup';

export const SnapshotPage = () => {
  const { t } = useTranslation();
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | number>('');

  const location = useLocation();
  const queries = useParams();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();

  const {
    getSnapshotListStatus,
    deleteSnapshotStatus,
    requestSnapshotList,
    requestDeleteSnapshot,
    requestResetSnapshot,
    snapshots,
  } = useSnapshotHook();

  const loading = useMemo(
    () => checkIsRequesting([getSnapshotListStatus, deleteSnapshotStatus]),
    [getSnapshotListStatus, deleteSnapshotStatus],
  );

  const showNoData = useMemo(
    () =>
      getSnapshotListStatus !== RequestStatus.IDLE &&
      (snapshots?.data?.length === 0 || !snapshots),
    [snapshots, getSnapshotListStatus],
  );

  const navigate = useNavigateHook()?.navigate;
  const redirect = useCallback(
    (qr: any) => {
      navigate(
        `${location.pathname}?${queryString.stringify(
          _.omitBy(
            qr,
            v => !v || (Array.isArray(v) && _.isEmpty(v)) || _.isNil(v),
          ),
        )}`,
      );
    },
    [location.pathname, navigate],
  );

  const onDelete = (id: number | string) => {
    setOpenDelete(true);
    setSelectedId(id);
  };

  const submitDelete = () => {
    requestDeleteSnapshot(selectedId);
  };

  const onChangePage = (id: number) => {
    let params = queryString.parse(window.location.search);
    params.page = id.toString();
    redirect(params);
  };

  const pagination = {
    currentPage: snapshots?.current_page ?? 1,
    totalPages: snapshots?.total_page ?? 1,
    onPageChange: (id: number) => onChangePage(id),
  };

  const reloadData = () => {
    requestSnapshotList({ venueId: queries?.venueId });
  };

  useEffect(() => {
    return () => requestResetSnapshot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deleteSnapshotStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to revert snapshot')]);
    }
    if (deleteSnapshotStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Snapshot reverted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSnapshotStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <Container sx={{ mt: '20px' }}>
      {showNoData ? (
        <NoData />
      ) : (
        <CommonTable
          type={TableType.SNAPSHOT}
          data={snapshots?.data}
          pagination={pagination}
          onDelete={onDelete}
        />
      )}
      <RevertConfirmPopup
        title={t('Alert')}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onSubmit={submitDelete}
      />
      <Loader show={loading} />
    </Container>
  );
};
