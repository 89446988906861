export const AssetIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M8.9714 1.19526C8.71106 0.934913 8.28895 0.934913 8.0286 1.19526L5.69526 3.5286C5.43491 3.78895 5.43491 4.21106 5.69526 4.4714L8.0286 6.80474C8.28895 7.06509 8.71106 7.06509 8.9714 6.80474L11.3047 4.4714C11.5651 4.21106 11.5651 3.78895 11.3047 3.5286L8.9714 1.19526Z"
      fill="white"
    />
    <path
      d="M4.9714 5.19526C4.71106 4.93491 4.28895 4.93491 4.0286 5.19526L1.69526 7.5286C1.43491 7.78895 1.43491 8.21106 1.69526 8.4714L4.0286 10.8047C4.28895 11.0651 4.71106 11.0651 4.9714 10.8047L7.30474 8.4714C7.56509 8.21106 7.56509 7.78895 7.30474 7.5286L4.9714 5.19526Z"
      fill="white"
    />
    <path
      d="M12.9714 5.19526C12.7111 4.93491 12.2889 4.93491 12.0286 5.19526L9.69526 7.5286C9.43491 7.78895 9.43491 8.21106 9.69526 8.4714L12.0286 10.8047C12.2889 11.0651 12.7111 11.0651 12.9714 10.8047L15.3047 8.4714C15.5651 8.21106 15.5651 7.78895 15.3047 7.5286L12.9714 5.19526Z"
      fill="white"
    />
    <path
      d="M8.9714 9.19526C8.71106 8.93491 8.28895 8.93491 8.0286 9.19526L5.69526 11.5286C5.43491 11.7889 5.43491 12.2111 5.69526 12.4714L8.0286 14.8047C8.28895 15.0651 8.71106 15.0651 8.9714 14.8047L11.3047 12.4714C11.5651 12.2111 11.5651 11.7889 11.3047 11.5286L8.9714 9.19526Z"
      fill="white"
    />
  </svg>
);
