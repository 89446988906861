import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MenuItem, SelectProps } from '@mui/material';
import { CheckIcon, SelectIcon } from '../icons';
import { InputType } from './customSelect';
import { Label } from './input';
import {
  CustomCheckbox,
  ErrorMessage,
  InputContainer,
  InputWrapper,
  OptionText,
  SelectField,
  Span,
} from './styled';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface MultiSelectInputProps {
  label?: string;
  errorMess?: string;
  value?: string[] | number[];
  isRequired?: boolean;
  width?: number;
  options: { value: string | number; label: string | number }[];
  inputType?: InputType;
  onChange: (e: any) => void;
}

const MultiSelectItem: React.FC<MultiSelectInputProps & SelectProps> = ({
  label,
  value,
  errorMess,
  isRequired = false,
  options = [],
  placeholder,
  width = 0,
  inputType = InputType.POP_UP,
  onChange,
  ...inputProps
}) => {
  const { t } = useTranslation();

  const [checked, setCheck] = useState<(string | number)[]>(
    Array.isArray(value) ? value : typeof value === 'string' ? [value] : [],
  );

  const handleChange = (event: any) => {
    const value = event.target.value;

    if (value[value.length - 1] === 'all') {
      const newVal =
        checked.length === options.length ? [] : _.map(options, it => it.value);
      setCheck(newVal);
      return;
    }
  };

  const handleCheck = useCallback(
    (val: string | number) => {
      if (checked.find(it => it.toString() === val.toString())) {
        const newVal = checked.filter(it => it.toString() !== val.toString());
        setCheck(newVal);
        onChange(newVal);
      } else {
        setCheck([...checked, val]);
        onChange([...checked, val]);
      }
    },
    [checked, onChange],
  );

  const handleCheckAll = useCallback(() => {
    if (checked.length === options.length) {
      setCheck([]);
    } else {
      setCheck(_.map(options, it => it.value));
    }
  }, [checked.length, options]);

  const isCheckedAll = useMemo(() => {
    return !!options.length && checked.length === options.length;
  }, [checked.length, options.length]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setCheck(value);
    } else if (typeof value === 'string') {
      setCheck([value]);
    } else {
      setCheck([]);
    }
  }, [value]);

  return (
    <InputContainer>
      {label && (
        <Label $fromType={inputType}>
          {label}
          {isRequired && <span>*</span>}
        </Label>
      )}
      <InputWrapper>
        <SelectField
          {...inputProps}
          $inputType={inputType}
          value={value}
          $hasError={!!errorMess}
          IconComponent={props => <SelectIcon />}
          displayEmpty
          MenuProps={{
            disablePortal: true,
          }}
          multiple
          disabled={!(options && options?.length)}
          $width={width}
          renderValue={() => {
            const selectedOption: any = options.filter(
              it =>
                value?.find(item => item.toString() === it.value?.toString()),
            );
            if (isCheckedAll) {
              return (
                <OptionText>
                  {/* {options?.map(it => it.label).join(',')} */}
                  All selected
                </OptionText>
              );
            }
            if (value && selectedOption?.length) {
              return (
                <OptionText>
                  {Array.isArray(selectedOption)
                    ? selectedOption.map(it => it.label).join(',')
                    : !Array.isArray(selectedOption)
                    ? selectedOption
                    : ''}
                </OptionText>
              );
            }

            return (
              <OptionText $inputType={inputType}>{placeholder}</OptionText>
            );
          }}
          onChange={handleChange}
        >
          <MenuItem value={'all'} onClick={handleCheckAll}>
            <Span>
              <CustomCheckbox
                disableRipple
                checked={isCheckedAll}
                checkedIcon={<CheckIcon />}
              />
            </Span>

            <OptionText
              $inputType={inputType}
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              className="form_text"
            >
              {t('Select All')}
            </OptionText>
          </MenuItem>
          {(options || []).map(
            (
              v: { value: string | number; label: string | number },
              k: number,
            ) => {
              return (
                <MenuItem
                  value={v.value}
                  key={k}
                  selected={
                    isCheckedAll ||
                    !!value?.find(
                      item => item.toString() === v.value.toString(),
                    )
                  }
                  onClick={() => handleCheck(v.value)}
                >
                  <Span>
                    <CustomCheckbox
                      disableRipple
                      checked={
                        checked.indexOf(v.value || v.value.toString()) > -1
                      }
                      onChange={() => handleCheck(v.value)}
                      checkedIcon={<CheckIcon />}
                    />
                  </Span>

                  <OptionText
                    $inputType={inputType}
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    className="form_text"
                  >
                    {v.label}
                  </OptionText>
                </MenuItem>
              );
            },
          )}
        </SelectField>
      </InputWrapper>
      {errorMess && <ErrorMessage>{errorMess}</ErrorMessage>}
    </InputContainer>
  );
};

export default MultiSelectItem;
