/* eslint-disable max-lines */
import { Box } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const TableWrapper = styled.div<{ $collaspe: boolean }>`
  width: ${props => (props.$collaspe ? 'calc(100vw - 100px)' : 'calc(100vw - 220px)')};
  height: 100%;
  margin: 0 20px 20px;
  &.top {
    width: 100%;
    margin: 0;
  }
`;

export const PaginationContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
`;

export const TableContainer = styled.div`
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: auto;
  &.top {
    position: static;
    box-sizing: border-box;
    width: auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  tr > td:nth-child(2) {
    font-weight: 700;
  }
  tr.selected {
    background: #e4f0ff !important;
  }
  tr:nth-child(2n) {
    background: #f8f9fc;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 4px #d9d9d9;
  }
`;

export const TopTable = styled(Box)`
  display: grid;
  grid-template-columns: 12fr 48fr 20fr 20fr;
  gap: 0;
  align-items: start;
`;

export const TopTableHeader = styled(Box)`
  padding: 16px;
  gap: 10px;
  color: #121212;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  background: #dedede;
  line-height: 22px;
  &.top {
    border-radius: 6px 0 0 6px;
  }

  &.bottom {
    border-radius: 0px 6px 6px 0px;
  }
`;

export const TopCell = styled(Box)`
  padding: 16px;
  gap: 10px;
  text-align: left;
  font-size: 16px;
  margin: 6px 0;
  background: #f8f9fc;
  &.top {
    border-radius: 6px 0 0 6px;
  }

  &.bottom {
    border-radius: 0px 6px 6px 0px;
  }
`;
export const PublishContainer = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
`;

export const TableHeader = styled.th`
  height: 44px;
  padding: 0px 16px;
  gap: 10px;
  align-self: stretch;
  background-color: #40495c;
  border: 1px solid #babec9;
  color: #fff;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;

  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.top {
    background: #3080ec;
  }
`;

export const TableCell = styled.td`
  padding: 16px;
  text-align: left;
  gap: 10px;
  align-self: stretch;
  border: 1px solid #e9ecf6;
  overflow: hidden;
  color: #202634;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > img {
    max-width: 60px;
  }
  ul {
    list-style-type: none;
  }
  li {
    display: block;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ClippedText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const ActionCell = styled.td`
  border: 1px solid #e9ecf6;
  padding: 10px;
  text-align: center;
  * {
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
`;

export const Text = styled.span`
  padding-left: 10px;
  white-space: nowrap;
  &.danger {
    color: #eb5757;
  }
`;

export const IconText = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StatusTxt = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #202634;
  &.blue {
    color: #03ba5e;
  }
`;

export const LinkText = styled(Link)`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #202634;
  text-decoration: none;
  /* &:hover {
    text-decoration: underline;
  } */
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  svg path {
    stroke: #fff;
  }
`;

export const CustomLink = styled.a`
  font-size: 16px;
  color: #3080ec;
  line-height: 20px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const AntSwitch = styled(Switch)`
  && {
    width: 28px;
    height: 16px;
    padding: 0;
    &:active {
      .MuiSwitch-thumb {
        width: 15px;
      }
      .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(9px);
      }
    }
    .MuiSwitch-switchBase {
      padding: 2px;
    }
    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(12px);
      color: #fff;
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: #177ddc;
      }
    }
    .MuiSwitch-thumb {
      box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
      width: 12px;
      height: 12px;
      border-radius: 6px;
      transition: 1s ease out all;
    }
    .MuiSwitch-track {
      border-radius: 8px;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
    }
  }
`;
