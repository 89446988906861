import { Box } from '@mui/material';
import styled from 'styled-components';

export const TopContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: calc(100vw - 230px);
  margin: 0 30px;
`;

export const ChartContainer = styled(Box)`
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 20px;
`;
