import { RequestStatus } from '@/constants/API';
import { IPageDataResponse } from '@/types/common';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SnapshotDetail } from './type';

export interface SnapshotState {
  getSnapshotListStatus: RequestStatus;
  deleteSnapshotStatus: RequestStatus;
  snapshots: IPageDataResponse<SnapshotDetail> | null;
}

const initialState: SnapshotState = {
  getSnapshotListStatus: RequestStatus.IDLE,
  deleteSnapshotStatus: RequestStatus.IDLE,
  snapshots: null,
};

export const snapshotSlice: any = createSlice({
  name: 'snapshot',
  initialState,
  reducers: {
    getSnapshotListRequest: (state, action: PayloadAction<any>) => {
      state.getSnapshotListStatus = RequestStatus.REQUESTING;
    },
    getSnapshotListSuccess: (state, action: PayloadAction<any>) => {
      state.getSnapshotListStatus = RequestStatus.SUCCESS;
      state.snapshots = action.payload;
    },
    getSnapshotListFailure: (state, action: PayloadAction<any>) => {
      state.getSnapshotListStatus = RequestStatus.ERROR;
    },
    deleteSnapshotRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteSnapshotStatus = RequestStatus.REQUESTING;
    },
    deleteSnapshotSuccess: (state, action: PayloadAction<any>) => {
      state.deleteSnapshotStatus = RequestStatus.SUCCESS;
    },
    deleteSnapshotFailure: (state, action: PayloadAction<any>) => {
      state.deleteSnapshotStatus = RequestStatus.ERROR;
    },
    resetSnapshotRequestStatus: state => {
      state.getSnapshotListStatus = RequestStatus.IDLE;
      state.deleteSnapshotStatus = RequestStatus.IDLE;
    },
  },
});

export const {
  getSnapshotListRequest,
  getSnapshotListSuccess,
  getSnapshotListFailure,
  deleteSnapshotRequest,
  deleteSnapshotSuccess,
  deleteSnapshotFailure,
  resetSnapshotRequestStatus,
} = snapshotSlice.actions;

export default snapshotSlice.reducer;
