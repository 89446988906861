export const UploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.3077 13.8182C12.3077 13.6516 12.2468 13.5073 12.125 13.3856C12.0032 13.2638 11.859 13.2029 11.6923 13.2029C11.5256 13.2029 11.3814 13.2638 11.2596 13.3856C11.1378 13.5073 11.0769 13.6516 11.0769 13.8182C11.0769 13.9849 11.1378 14.1291 11.2596 14.2509C11.3814 14.3727 11.5256 14.4336 11.6923 14.4336C11.859 14.4336 12.0032 14.3727 12.125 14.2509C12.2468 14.1291 12.3077 13.9849 12.3077 13.8182ZM14.7692 13.8182C14.7692 13.6516 14.7083 13.5073 14.5865 13.3856C14.4647 13.2638 14.3205 13.2029 14.1538 13.2029C13.9872 13.2029 13.8429 13.2638 13.7212 13.3856C13.5994 13.5073 13.5385 13.6516 13.5385 13.8182C13.5385 13.9849 13.5994 14.1291 13.7212 14.2509C13.8429 14.3727 13.9872 14.4336 14.1538 14.4336C14.3205 14.4336 14.4647 14.3727 14.5865 14.2509C14.7083 14.1291 14.7692 13.9849 14.7692 13.8182ZM16 11.6644V14.7413C16 14.9977 15.9103 15.2157 15.7308 15.3952C15.5513 15.5747 15.3333 15.6644 15.0769 15.6644H0.923077C0.666667 15.6644 0.448718 15.5747 0.269231 15.3952C0.0897436 15.2157 0 14.9977 0 14.7413V11.6644C0 11.408 0.0897436 11.19 0.269231 11.0106C0.448718 10.8311 0.666667 10.7413 0.923077 10.7413H5.02885C5.16346 11.1003 5.38942 11.3952 5.70673 11.6259C6.02404 11.8567 6.37821 11.9721 6.76923 11.9721H9.23077C9.6218 11.9721 9.97596 11.8567 10.2933 11.6259C10.6106 11.3952 10.8365 11.1003 10.9712 10.7413H15.0769C15.3333 10.7413 15.5513 10.8311 15.7308 11.0106C15.9103 11.19 16 11.408 16 11.6644ZM12.875 5.43363C12.766 5.69004 12.5769 5.81825 12.3077 5.81825H9.84615V10.1259C9.84615 10.2926 9.78526 10.4368 9.66346 10.5586C9.54167 10.6804 9.39744 10.7413 9.23077 10.7413H6.76923C6.60256 10.7413 6.45833 10.6804 6.33654 10.5586C6.21474 10.4368 6.15385 10.2926 6.15385 10.1259V5.81825H3.69231C3.42308 5.81825 3.23397 5.69004 3.125 5.43363C3.01603 5.18363 3.0609 4.96248 3.25962 4.77017L7.56731 0.462477C7.68269 0.340683 7.82692 0.279785 8 0.279785C8.17308 0.279785 8.31731 0.340683 8.43269 0.462477L12.7404 4.77017C12.9391 4.96248 12.984 5.18363 12.875 5.43363Z"
      fill="white"
    />
  </svg>
);
