export enum FormType {
  INPUT = 'INPUT',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  IMAGE_UPLOADER = 'IMAGE_UPLOADER',
  CHECKBOX = 'CHECKBOX',
  TEXTAREA = 'TEXTAREA',
  DATEPICKER = 'DATEPICKER',
  TIMEPICKER = 'TIMEPICKER',
  COLORPICKER = 'COLORPICKER',
}
