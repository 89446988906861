import styled from 'styled-components';
import { CloseIcon } from '../icons';

export interface TagProps {
  tagName: string;
  onClick: () => void;
}
const TagItem = styled.span`
  display: flex;
  height: 28px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  background: #e34a66;
  color: #fff;
  box-sizing: border-box;
`;

const IconWrapper = styled.span``;

export const Tag = ({ tagName, onClick }: TagProps) => {
  return (
    <TagItem>
      {tagName}
      <IconWrapper onClick={onClick}>
        <CloseIcon />
      </IconWrapper>
    </TagItem>
  );
};
