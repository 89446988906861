import { RequestStatus } from '@/constants/API';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { VenueDetail } from './type';
import { IPageDataResponse } from '@/types/common';
import { VenueForm } from '@/components/venue/components/popup';

export interface VenueState {
  getVenueOptionsStatus: RequestStatus;
  getVenueListStatus: RequestStatus;
  createVenueInfoStatus: RequestStatus;
  updateVenueInfoStatus: RequestStatus;
  updateVenuePublishStatus: RequestStatus;
  deleteVenueInfoStatus: RequestStatus;
  deleteMultiVenueInfoStatus: RequestStatus;
  getVenueDetailsStatus: RequestStatus;
  getProvincesStatus: RequestStatus;
  duplicateVenueStatus: RequestStatus;
  venues: IPageDataResponse<VenueDetail> | null;
  venueOptions: any[];
  venueDetails: VenueDetail | null;
  provinces: any[];
}

const initialState: VenueState = {
  getVenueOptionsStatus: RequestStatus.IDLE,
  getVenueListStatus: RequestStatus.IDLE,
  updateVenuePublishStatus: RequestStatus.IDLE,
  createVenueInfoStatus: RequestStatus.IDLE,
  updateVenueInfoStatus: RequestStatus.IDLE,
  deleteVenueInfoStatus: RequestStatus.IDLE,
  deleteMultiVenueInfoStatus: RequestStatus.IDLE,
  getVenueDetailsStatus: RequestStatus.IDLE,
  getProvincesStatus: RequestStatus.IDLE,
  duplicateVenueStatus: RequestStatus.IDLE,
  venues: null,
  venueOptions: [],
  venueDetails: null,
  provinces: [],
};

export const venueSlice: any = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    getVenueOptionsRequest: (state, action: PayloadAction<any>) => {
      state.getVenueOptionsStatus = RequestStatus.REQUESTING;
    },
    getVenueOptionsSuccess: (state, action: PayloadAction<any>) => {
      state.getVenueOptionsStatus = RequestStatus.SUCCESS;
      state.venueOptions = action.payload;
    },
    getVenueOptionsFailure: state => {
      state.getVenueOptionsStatus = RequestStatus.ERROR;
    },

    getVenueListRequest: (state, action: PayloadAction<any>) => {
      state.getVenueListStatus = RequestStatus.REQUESTING;
    },
    getVenueListSuccess: (state, action: PayloadAction<any>) => {
      state.getVenueListStatus = RequestStatus.SUCCESS;
      state.venues = action.payload;
    },
    getVenueListFailure: state => {
      state.getVenueListStatus = RequestStatus.ERROR;
    },
    getProvincesRequest: (state, action: PayloadAction<any>) => {
      state.getProvincesStatus = RequestStatus.REQUESTING;
    },
    getProvincesSuccess: (state, action: PayloadAction<any>) => {
      state.getProvincesStatus = RequestStatus.SUCCESS;
      state.provinces = action.payload;
    },
    getProvincesFailure: state => {
      state.getProvincesStatus = RequestStatus.ERROR;
    },
    getVenueDetailsRequest: (state, action: PayloadAction<VenueForm>) => {
      state.getVenueDetailsStatus = RequestStatus.REQUESTING;
    },
    getVenueDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.getVenueDetailsStatus = RequestStatus.SUCCESS;
      state.venueDetails = action.payload;
    },
    getVenueDetailsFailure: state => {
      state.getVenueDetailsStatus = RequestStatus.ERROR;
    },
    createVenueInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createVenueInfoStatus = RequestStatus.REQUESTING;
    },
    createVenueInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createVenueInfoStatus = RequestStatus.SUCCESS;
    },
    createVenueInfoFailure: (state, action: PayloadAction<any>) => {
      state.createVenueInfoStatus = RequestStatus.ERROR;
    },
    updateVenueInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateVenueInfoStatus = RequestStatus.REQUESTING;
    },
    updateVenueInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateVenueInfoStatus = RequestStatus.SUCCESS;
    },
    updateVenueInfoFailure: (state, action: PayloadAction<any>) => {
      state.updateVenueInfoStatus = RequestStatus.ERROR;
    },
    updateVenuePublishRequest: (state, action: PayloadAction<any>) => {
      state.updateVenuePublishStatus = RequestStatus.REQUESTING;
    },
    updateVenuePublishSuccess: (state, action: PayloadAction<any>) => {
      state.updateVenuePublishStatus = RequestStatus.SUCCESS;
    },
    updateVenuePublishFailure: (state, action: PayloadAction<any>) => {
      state.updateVenuePublishStatus = RequestStatus.ERROR;
    },
    deleteVenueInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteVenueInfoStatus = RequestStatus.REQUESTING;
    },
    deleteVenueInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteVenueInfoStatus = RequestStatus.SUCCESS;
    },
    deleteVenueInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteVenueInfoStatus = RequestStatus.ERROR;
    },
    deleteMultiVenueInfoRequest: (state, action: PayloadAction<any>) => {
      state.deleteMultiVenueInfoStatus = RequestStatus.REQUESTING;
    },
    deleteMultiVenueInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteMultiVenueInfoStatus = RequestStatus.SUCCESS;
    },
    deleteMultiVenueInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteMultiVenueInfoStatus = RequestStatus.ERROR;
    },

    duplicateVenueRequest: (state, action: PayloadAction<any>) => {
      if (action) state.duplicateVenueStatus = RequestStatus.REQUESTING;
    },
    duplicateVenueSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.duplicateVenueStatus = RequestStatus.SUCCESS;
    },
    duplicateVenueFailure: (state, action: PayloadAction<any>) => {
      state.duplicateVenueStatus = RequestStatus.ERROR;
    },

    resetVenueInfoRequestStatus: state => {
      state.getVenueListStatus = RequestStatus.IDLE;
      state.createVenueInfoStatus = RequestStatus.IDLE;
      state.updateVenueInfoStatus = RequestStatus.IDLE;
      state.updateVenuePublishStatus = RequestStatus.IDLE;
      state.deleteVenueInfoStatus = RequestStatus.IDLE;
      state.deleteMultiVenueInfoStatus = RequestStatus.IDLE;
      state.getVenueDetailsStatus = RequestStatus.IDLE;
      state.duplicateVenueStatus = RequestStatus.IDLE;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getVenueOptionsRequest,
  getVenueOptionsSuccess,
  getVenueOptionsFailure,
  getVenueListRequest,
  getVenueListSuccess,
  getVenueListFailure,
  createVenueInfoRequest,
  createVenueInfoSuccess,
  createVenueInfoFailure,
  updateVenueInfoRequest,
  updateVenueInfoSuccess,
  updateVenueInfoFailure,
  deleteVenueInfoRequest,
  deleteVenueInfoSuccess,
  deleteVenueInfoFailure,
  deleteMultiVenueInfoRequest,
  deleteMultiVenueInfoSuccess,
  deleteMultiVenueInfoFailure,
  updateVenuePublishRequest,
  updateVenuePublishFailure,
  updateVenuePublishSuccess,
  getVenueDetailsRequest,
  getVenueDetailsSuccess,
  getVenueDetailsFailure,
  getProvincesRequest,
  getProvincesSuccess,
  getProvincesFailure,
  duplicateVenueRequest,
  duplicateVenueSuccess,
  duplicateVenueFailure,
  resetVenueInfoRequestStatus,
} = venueSlice.actions;

export default venueSlice.reducer;
