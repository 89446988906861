export const EditIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="edit">
      <path
        id="Vector"
        d="M11.61 3.59L14.41 6.39L5.8 15H3V12.2L11.61 3.59ZM12.59 2.61L14.2 1L17 3.8L15.39 5.41L12.59 2.61Z"
        fill="#555C6A"
      />
      <rect
        id="Rectangle 11884"
        x="2"
        y="17"
        width="16"
        height="2"
        fill="#555C6A"
      />
    </g>
  </svg>
);
