/* eslint-disable max-lines */

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { InputType } from './customSelect';
import Sketch from '@uiw/react-color-sketch';
import { Box, ClickAwayListener } from '@mui/material';

interface ColorPickerInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  isRequired?: boolean;
  width?: number;
  value?: string;
  fromType?: InputType;
}

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
`;

export const Label = styled.label<{ $fromType?: InputType }>`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  color: ${props =>
    props.$fromType === InputType.MAPPING ? '#fff' : '#5c677e'};
  span {
    color: #e34a66;
  }
`;

const ErrorMessage = styled.span`
  color: #e34a66;
`;

const BackgroundBox = styled.span<{ $color: string; $fromType?: InputType }>`
  display: flex;
  width: 40px;
  height: 40px;
  border: 1px solid #dee1eb;
  background: ${props => props.$color};
  cursor: pointer;
`;

const InputField = styled.input<{
  $hasError?: boolean;
  $width?: number;
  $fromType?: InputType;
}>`
  box-sizing: border-box;
  width: ${props => (props.$width ? props.$width + 'px' : '100%')};
  padding: 8px 12px;
  border: 1px solid
    ${props =>
      props.$hasError
        ? 'red'
        : props.$fromType === InputType.POP_UP
        ? '#dee1eb'
        : 'transparent'};
  border-radius: 2px;
  background: ${props =>
    props.$fromType === InputType.MAPPING ? '#40495C' : '#fff'};
  color: ${props =>
    props.$fromType === InputType.MAPPING ? '#fff' : '#222222 '};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  &:focus {
    outline: none;
  }
`;

const SketchPicker = styled(Box)`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 16;
  .w-color-sketch {
    > div {
      padding: 8px !important;
      margin: 0 !important;
      input {
        display: flex !important;
        padding: 2px 5px !important;
        align-items: flex-start !important;
        border-radius: 4px !important;
        border: 1px solid #e0e1e4 !important;
        font-size: 10px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        & + span {
          padding: 10px 0 0 !important;
        }
      }
    }
  }
  .w-color-sketch {
    .w-color-alpha .w-color-interactive {
      > div {
        width: 8px !important;
        height: 8px !important;
        border: 2px solid #fff !important;
        border-radius: 50% !important;
        top: -1px !important;
      }
      &:first-child > div {
        background: transparent !important;
      }
      &:nth-child(2) > div {
        background: transparent !important;
      }
    }
    > div > div > .w-color-alpha.w-color-alpha-horizontal {
      margin-left: 12px !important;
    }
    .w-color-hue {
      > div {
        border-radius: 8px !important;
      }
      + div > div {
        border-radius: 8px !important;
      }
    }
  }

  .w-color-swatch {
    border: none !important;
    & > div {
      width: 26px !important;
      height: 26px !important;
      border-radius: 16px !important;
      margin-right: 6px !important;
      margin-bottom: 6px !important;
    }
  }

  .w-color-saturation-fill {
    width: 12px !important;
    height: 12px !important;
  }
`;

const ColorPickerInput: React.FC<ColorPickerInputProps> = ({
  label,
  error,
  isRequired = false,
  width = 0,
  value,
  fromType = InputType.POP_UP,
  onChange,
  ...colorPickerInputProps
}) => {
  const [showSketch, setShowSketch] = useState<boolean>(false);
  const [hex, setHex] = useState<string>(value ?? '#fff');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      const event = {
        target: {
          value: hex,
        },
      } as React.ChangeEvent<HTMLInputElement>;

      onChange && onChange(event);
    }
  }, [hex, onChange]);

  return (
    <InputContainer>
      {label && (
        <Label $fromType={fromType}>
          {label}
          {isRequired && <span>*</span>}
        </Label>
      )}
      <InputWrapper>
        <BackgroundBox
          $fromType={fromType}
          $color={hex ?? '#fff'}
          onClick={() => setShowSketch(!showSketch)}
        />
        <InputField
          ref={inputRef}
          $fromType={fromType}
          {...colorPickerInputProps}
          $hasError={!!error}
          $width={width}
          value={hex}
        />
        {showSketch && (
          <ClickAwayListener onClickAway={() => setShowSketch(false)}>
            <SketchPicker>
              <Sketch
                color={hex}
                onChange={color => {
                  setHex(color.hex);
                }}
              />
            </SketchPicker>
          </ClickAwayListener>
        )}
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputContainer>
  );
};

export default ColorPickerInput;
