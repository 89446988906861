/* eslint-disable max-lines */
import { ReactComponent as PublishIc } from '@/assets/icons/publish_ic.svg';
import { ReactComponent as UnPublishIc } from '@/assets/icons/unpublish_ic.svg';
import { ReactComponent as Duplicate } from '@/assets/icons/duplicate.svg';
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { Loader } from '@/components/common/loader';
import { Label } from '@/components/form/input';

import {
  CustomerIcon,
  FloorIcon,
  LocationIcon,
  PhoneIcon,
  VenueIcon,
  SettingsIcon,
  TimeIcon,
} from '@/components/icons';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import {
  Header,
  PrimaryColumn,
  Tag,
  TopContainer,
  HeaderContainer,
  SettingBtn,
  Text,
  Grid,
  BoxSwitch,
  Section,
} from '@/components/top';
import { Container } from '@/components/venue/components';
import { VenuePopup } from '@/components/venue/components/popup';

import { ACTION_OPTIONS, PUBLISH_STATES } from '@/constants';
import { RequestStatus } from '@/constants/API';
import useFloorHook from '@/hooks/useFloorHook';
import useGlobalHook from '@/hooks/useGlobalHook';
import useVenueHook from '@/hooks/useVenueHook';
import { VenueDetailsForm } from '@/stores/slices/venue/type';
import { checkIsRequesting, transformWokHours } from '@/utils/helper';
import { Box } from '@mui/material';
import { ClockIcon } from '@mui/x-date-pickers';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCustomerHook from '@/hooks/useCustomerHook';
import ActionMenu from '@/components/common/ActionMenu';
import Path from '@/constants/path';
import { useTranslation } from 'react-i18next';
import MapComponent from '@/components/venue/components/map';
import { DuplicatePopup } from '@/components/venue/components/DuplicatePopup';

export const TopPage = () => {
  const { t } = useTranslation();

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openSwitch, setOpenSwitch] = useState<boolean>(false);
  const [openPopupDuplicate, setOpenPopupDuplicate] = useState<boolean>(false);

  const queries = useParams();
  const navigate = useNavigate();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    createVenueInfoStatus,
    requestUpdateVenueInfo,
    deleteVenueInfoStatus,
    updateVenueInfoStatus,
    requestDeleteVenue,
    requestResetVenue,
    venueDetails,
    getVenueDetailsStatus,
    requestVenueDetails,
    requestUpdateMultiVenueInfo,
    updateVenuePublishStatus,
    requestDuplicateVenue,
    duplicateVenueStatus,
  } = useVenueHook();
  const { getCustomerListStatus } = useCustomerHook();
  const [publish, setPublish] = useState<boolean>(
    venueDetails?.publish ?? false,
  );
  const { floors, getFloorListStatus, requestFloorList } = useFloorHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getVenueDetailsStatus,
        createVenueInfoStatus,
        deleteVenueInfoStatus,
        updateVenueInfoStatus,
        getCustomerListStatus,
        getFloorListStatus,
        updateVenuePublishStatus,
      ]),
    [
      getVenueDetailsStatus,
      createVenueInfoStatus,
      deleteVenueInfoStatus,
      updateVenueInfoStatus,
      getCustomerListStatus,
      getFloorListStatus,
      updateVenuePublishStatus,
    ],
  );
  const submitAddOrEdit = (formData: VenueDetailsForm) => {
    requestUpdateVenueInfo({ ...formData, id: queries.venueId });

    setOpenPopup(false);
  };

  const onEdit = () => {
    setOpenPopup(true);
  };

  const onDelete = () => {
    setOpenPopup(false);
    setOpenDelete(true);
  };

  const submitDelete = () => {
    requestDeleteVenue(queries.venueId);
  };

  const onSelectAction = (actionId: number | string) => {
    if (actionId === ACTION_OPTIONS[0].id) {
      setOpenSwitch(true);
      setPublish(false);
    } else if (actionId === ACTION_OPTIONS[1].id) {
      setOpenSwitch(true);
      setPublish(true);
    } else {
      setOpenSwitch(false);
      setOpenDelete(true);
    }
  };

  const changeState = () => {
    setOpenSwitch(false);

    requestUpdateMultiVenueInfo({
      venue_ids: [queries.venueId],
      publish_state: publish,
    });
  };

  const duplicateData = async (data: any) => {
    const formData = { ...data, id: queries.venueId };
    await requestDuplicateVenue(formData);
    setOpenPopupDuplicate(false);
  };

  useEffect(() => {
    requestVenueDetails({ id: queries.venueId });
    requestFloorList({ venue: queries?.venueId });

    return () => requestResetVenue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries.venueId]);

  useEffect(() => {
    if (
      updateVenuePublishStatus === RequestStatus.SUCCESS ||
      updateVenueInfoStatus === RequestStatus.SUCCESS
    ) {
      setSuccessMsg([t('Venue updated!')]);
      requestVenueDetails({ id: queries.venueId });
    }
    if (
      updateVenuePublishStatus === RequestStatus.ERROR ||
      updateVenueInfoStatus === RequestStatus.ERROR
    ) {
      setErrorMsg([t('Something went wrong. Unable to update venue')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateVenuePublishStatus, updateVenueInfoStatus]);

  useEffect(() => {
    if (deleteVenueInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to delete venue')]);
    }
    if (deleteVenueInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Venue deleted!')]);
      navigate(`${Path.CUSTOMERS}${queries.customerId}${Path.VENUE}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteVenueInfoStatus]);

  useEffect(() => {
    if (duplicateVenueStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to duplicate venue')]);
    }
    if (duplicateVenueStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Venue duplicated!')]);
    }

    console.log(duplicateVenueStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateVenueStatus]);

  return (
    <Container>
      <HeaderContainer>
        <Box>
          <Header>
            <Text className="main">{venueDetails?.name}</Text>
            {venueDetails?.publish ? (
              <Tag>
                {t(PUBLISH_STATES[1].title)}
                <PublishIc />
              </Tag>
            ) : (
              <Tag>
                {t(PUBLISH_STATES[0].title)}
                <UnPublishIc />
              </Tag>
            )}
          </Header>
          <Header>
            <LocationIcon />
            <Text className="small" mt={1}>
              {venueDetails?.address}
            </Text>
          </Header>
        </Box>
        <BoxSwitch>
          {/* <AntSwitch onChange={changePublishingState} checked={publish} />
          {publish ? PUBLISH_STATES[0].label : PUBLISH_STATES[1].label} */}
          <SettingBtn onClick={() => setOpenPopupDuplicate(true)}>
            <Duplicate />
            {t('Duplicate')}
          </SettingBtn>
          <ActionMenu
            onSelect={onSelectAction}
            options={ACTION_OPTIONS.filter(
              it => it.id !== (venueDetails?.publish ? 2 : 1),
            )}
          />
          <SettingBtn onClick={onEdit}>
            <SettingsIcon />
            {t('Settings')}
          </SettingBtn>
        </BoxSwitch>
      </HeaderContainer>
      <TopContainer ml={`24px`} mr={`24px`} pb={3}>
        <Box>
          <Box mb={4}>
            <Section title={t(`Floor`)}>
              <CommonTable type={TableType.TOP} data={floors?.data ?? []} />
            </Section>
          </Box>
          <Box>
            <Section title={t(`Venue Map`)}>
              <Box height={400}>
                <MapComponent
                  latitude={venueDetails?.latitude ?? undefined}
                  longitude={venueDetails?.longitude ?? undefined}
                />
              </Box>
            </Section>
          </Box>
        </Box>
        <Section title={t(`Venue Information`)}>
          <PrimaryColumn>
            <VenueIcon />
            <Text className="normal">{t('Name')}</Text>
            <Text>{venueDetails?.name}</Text>
          </PrimaryColumn>
          <PrimaryColumn>
            <LocationIcon />
            <Text className="normal">{t('Address')}</Text>
            <Text>{venueDetails?.address}</Text>
          </PrimaryColumn>
          <PrimaryColumn>
            <PhoneIcon />
            <Text className="normal">{t('Phone Number')}</Text>
            <Text>{venueDetails?.telephone}</Text>
          </PrimaryColumn>
          <PrimaryColumn>
            <FloorIcon />
            <Text className="normal">{t('Floors')}</Text>
            <Text>{floors?.total_records}</Text>
          </PrimaryColumn>
          <PrimaryColumn>
            <CustomerIcon />
            <Text className="normal">{t('Customer')}</Text>
            <Text>{venueDetails?.customer?.name}</Text>
          </PrimaryColumn>
          <PrimaryColumn className="no_border">
            <ClockIcon />
            <Text className="normal">{t('Work hours')}</Text>
            <Grid>
              {venueDetails
                ? transformWokHours(venueDetails, true, true, t).map(
                    (it, index) => (
                      <div key={index}>
                        <Text>{it?.day} :</Text>
                        <Text className="normal" sx={{ whiteSpace: 'nowrap' }}>
                          {it?.open} &nbsp;&nbsp;
                          <TimeIcon /> &nbsp;&nbsp;{it?.close}
                        </Text>
                      </div>
                    ),
                  )
                : ''}
            </Grid>
          </PrimaryColumn>
        </Section>
      </TopContainer>
      <DuplicatePopup
        open={openPopupDuplicate}
        onClose={() => setOpenPopupDuplicate(false)}
        onSubmit={duplicateData}
      />
      <VenuePopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={submitAddOrEdit}
        data={venueDetails}
        onDelete={onDelete}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onSubmit={submitDelete}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenSwitch(false)}
        open={openSwitch}
        onSubmit={changeState}
        actionTitle={
          publish ? t(PUBLISH_STATES[1].label) : t(PUBLISH_STATES[0].label)
        }
      >
        <Label>
          {publish
            ? t('Are you sure to publish this venue?')
            : t('Are you sure to unpublish this venue?')}
        </Label>
      </ConfirmPopUp>
      <Loader show={loading} />
    </Container>
  );
};
