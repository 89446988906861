import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50%;
  background-color: #fff;
`;

export const LoginForm = styled.form``;

export const LoginContainer = styled.div`
  width: 360px;
  border-radius: 8px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 22px 64px 0px rgba(0, 0, 0, 0.22);
  margin: 80px 20px 20px;
`;
export const LoginWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`;

export const Title = styled.h3`
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 10px 0;
`;

export const FormGroup = styled.div`
  margin-top: 25px;
`;

export const Label = styled.label`
  color: #5c677e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
`;

export const Input = styled.input<{ error: string | undefined }>`
  all: unset;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;
  width: 100%;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid #dee1eb;
  background: #fff;
  ${props => props.error && 'border-color: red;'}
`;

export const PasswordInputContainer = styled.div`
  position: relative; /* Ensure proper positioning of the icon */
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

export const SubmitButton = styled.button`
  border: none;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: #3080ec;
  width: 100%;
  max-width: 320px;
  outline: none;
  appearance: none;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  margin-top: 40px;
  text-transform: capitalize;
  &:hover {
    background-color: #0056b3;
  }
`;

export const PasswordToggleIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  height: 24px;
  transform: translateY(-50%);
`;
