import PopUp from '@/components/common/Popup';
import SelectItem from '@/components/form/select';
import Input from '@/components/form/input';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import { TwoInputContainer } from '.';
import { FloorDetail } from '@/stores/slices/floor/type';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WorldPosition } from '../world-position';
import { useParams } from 'react-router-dom';

export interface FloorPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formdata: FloorForm) => void;
  data?: FloorDetail;
  floorLevelOptions: any[];
}

export interface FloorForm {
  name: string;
  type: number | string | null;
}

export function WorldPopup({
  open,
  onClose,
  onSubmit,
  data,
  floorLevelOptions,
}: FloorPopupProps) {
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FloorForm>({
    defaultValues: {
      name: '',
      type: null,
    },
  });

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        type: data.type.id,
      });
    } else {
      reset({
        name: '',
        type: null,
      });
    }
  }, [data, reset, open]);

  const submitForm = async (data: FloorForm) => {
    const levelId = crypto.randomUUID();

    const dataSend = {
      ...data,
      id: levelId,
    };

    var iframe = document.getElementById('worldPosition') as HTMLIFrameElement;
    // Check if iframe and iframe.contentWindow are not null before calling postMessage
    if (iframe && iframe.contentWindow) {
      // Add a flag or identifier to the data being sent
      const messageData = { type: 'formSubmission', dataSend };

      await iframe.contentWindow.postMessage(messageData, '*');
    }
    onSubmit(dataSend);
    reset();
  };

  const params = useParams();
  const venueId = useMemo(() => {
    return params.venueId || '';
  }, [params.venueId]);

  const levelId = useMemo(() => {
    return params.floorId || '';
  }, [params.floorId]);

  const fetch = async () => {
    var iframe = document.getElementById('mapEditor') as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      const data = {
        type: 'reloadAPI',
      };
      await iframe.contentWindow.postMessage(data, '*');
    }
    onClose();
  };

  window.onmessage = async function (e) {
    if (e.data && e.data.type === 'doneAPI') {
      fetch();
    }
  };

  return (
    <PopUp
      title={data ? t('Edit Floor') : t('Add Floor')}
      onClose={onClose}
      open={open}
      selected={true}
    >
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <TwoInputContainer>
          <Controller
            control={control}
            name="name"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('Name')}
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={errors?.name?.message}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="type"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <SelectItem
                  isRequired
                  value={value ?? ''}
                  label={t('Level')}
                  options={floorLevelOptions}
                  onChange={onChange}
                  placeholder={t('Level')}
                  errorMess={errors?.type?.message}
                />
              );
            }}
          />
        </TwoInputContainer>

        <WorldPosition key={`${venueId}-${levelId}`} />

        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
            display: 'grid',
            gridTemplateColumns:
              i18n.resolvedLanguage === 'en' ? '70px 70px' : '120px 70px',
            justifyContent: 'flex-end',
            gridGap: '20px',
            position: 'absolute',
            bottom: '50px',
            right: '50px',
          }}
        >
          <CustomButton
            buttonType={CustomButtonType.WHITE_BG}
            title={t(`Cancel`)}
            onClick={onClose}
          />
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={t(`Submit`)}
          />
        </Box>
      </Box>
    </PopUp>
  );
}
