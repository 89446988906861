/* eslint-disable max-lines */
import { FilterBar, FilterType } from '@/components/common/FilterBar';
import { CustomerForm, CustomerPopup } from '@/components/customer/components';
import Input from '@/components/form/input';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { Container, FormWrapper } from '@/components/venue/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import Path from '@/constants/path';
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import useCustomerHook from '@/hooks/useCustomerHook';
import { Loader } from '@/components/common/loader';
import { checkIsRequesting } from '@/utils/helper';
import { RequestStatus } from '@/constants/API';
import { NoData } from '@/components/common/NoData';
import useGlobalHook from '@/hooks/useGlobalHook';
import { CustomerDetail } from '@/stores/slices/customer/type';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export interface FilterFormData {
  keyword: string;
}
export const CustomerPage = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | number>('');
  const [editingCustomer, setEditingCustomer] = useState<
    CustomerDetail | undefined
  >(undefined);

  const { t } = useTranslation();

  const location = useLocation();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    getCustomerListStatus,
    requestCustomerList,
    createCustomerInfoStatus,
    requestCreateCustomer,
    requestUpdateCustomerInfo,
    customers,
    deleteCustomerInfoStatus,
    updateCustomerInfoStatus,
    requestDeleteCustomer,
    requestResetCustomer,
  } = useCustomerHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getCustomerListStatus,
        createCustomerInfoStatus,
        deleteCustomerInfoStatus,
        updateCustomerInfoStatus,
      ]),
    [
      getCustomerListStatus,
      createCustomerInfoStatus,
      deleteCustomerInfoStatus,
      updateCustomerInfoStatus,
    ],
  );

  const showNoData = useMemo(
    () =>
      getCustomerListStatus !== RequestStatus.IDLE &&
      (customers?.data?.length === 0 || !customers),
    [customers, getCustomerListStatus],
  );
  const navigate = useNavigateHook()?.navigate;

  const onChangePage = (id: number) => {
    let params = queryString.parse(window.location.search);
    params.page = id.toString();
    const qs = queryString.stringify(params);
    navigate(`${location.pathname}?${qs}`);
  };

  // const onExport = () => {};

  const onAdd = () => {
    setEditingCustomer(undefined);
    setOpenPopup(true);
  };

  const submitAddOrEdit = (formData: CustomerForm) => {
    if (editingCustomer) {
      requestUpdateCustomerInfo({ ...formData, id: editingCustomer.id });
    } else {
      requestCreateCustomer(formData);
    }
    setOpenPopup(false);
  };

  const onView = (id: number | string) => {
    navigate(`${Path.CUSTOMERS}/${id}${Path.VENUE}`);
  };

  const onEdit = (id: number | string) => {
    setEditingCustomer(
      customers?.data.find((it: CustomerDetail) => it.id === id) ?? undefined,
    );
    setOpenPopup(true);
  };

  const onDelete = (id: number | string) => {
    setOpenDelete(true);
    setSelectedId(id);
  };

  const submitDelete = () => {
    requestDeleteCustomer(selectedId);
  };

  const handleDebounceFn = (inputValue: string) => {
    navigate(`${location.pathname}?keyword=${inputValue}`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  const onKeywordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget?.value;
    debounceFn(keyword);
  };

  const { control } = useForm<FilterFormData>({
    defaultValues: {
      keyword: '',
    },
  });

  const pagination = {
    currentPage: customers?.current_page ?? 1,
    totalPages: customers?.total_page ?? 1,
    onPageChange: (id: number) => onChangePage(id),
  };

  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    requestCustomerList({ ...params });
  };

  useEffect(() => {
    return () => requestResetCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateCustomerInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Customer updated!')]);
      reloadData();
    }
    if (updateCustomerInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update customer')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCustomerInfoStatus]);

  useEffect(() => {
    if (createCustomerInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Customer created!')]);
      reloadData();
    }

    if (createCustomerInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to create customer')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCustomerInfoStatus]);

  useEffect(() => {
    if (deleteCustomerInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to delete customer')]);
    }
    if (deleteCustomerInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Customer deleted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCustomerInfoStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <Container>
      <FilterBar
        type={FilterType.GENERIC}
        onAdd={onAdd}
        // onExport={onExport}
        hasButton
        buttonText={t('Add new customer')}
      >
        <FormWrapper>
          <Controller
            control={control}
            name="keyword"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <Input
                  inputType={1}
                  onChange={e => {
                    onChange(e);
                    onKeywordChange(e);
                  }}
                  value={value}
                  placeholder={t('Search')}
                />
              );
            }}
          />
        </FormWrapper>
      </FilterBar>

      {showNoData ? (
        <NoData />
      ) : (
        <CommonTable
          type={TableType.CUSTOMER}
          data={customers?.data ?? []}
          pagination={pagination}
          onEdit={onEdit}
          onDelete={onDelete}
          onView={onView}
        />
      )}
      <CustomerPopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={submitAddOrEdit}
        data={editingCustomer}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onSubmit={submitDelete}
      />
      <Loader show={loading} />
    </Container>
  );
};
