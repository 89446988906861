/* eslint-disable max-lines */
import { CustomController } from '@/components/form/controller';
import { Box, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CustomButton, { CustomButtonType } from 'src/components/common/Button';
import {
  OneInputContainer,
  TwoInputContainer,
} from 'src/components/floor/components';
import {
  transformSelectOptions,
  transformSelectOptionsWithTranslation,
  getDefaultEventForm,
  transformEventDetails,
} from '@/utils/helper';
import { FormType } from '@/components/form/types';
import { EventDetail, EventDetailsForm } from '@/stores/slices/event/type';
import useEventHook from '@/hooks/useEventHook';
import useLocationHook from '@/hooks/useLocationHook';
import { useEffect, useRef } from 'react';
import _ from 'lodash';
import { EVENT_TYPES } from '@/constants';
import { useTranslation } from 'react-i18next';
import { ImageInput } from '@/components/form/imageUploading';
import { InputType } from '@/components/form/customSelect';
import CustomDateTimePicker from '@/components/form/datetimepicker';
import MultiSelectItem from '@/components/form/multiselect';
import TextArea from '@/components/form/textarea';

export interface EventProps {
  onSubmit: (formdata: EventDetailsForm) => void;
  data?: EventDetail;
}

export function Event({ onSubmit, data }: EventProps) {
  const eventForm = useForm<EventDetailsForm>({
    defaultValues: data ? transformEventDetails(data) : getDefaultEventForm(),
  });

  const { locationsToVenue } = useLocationHook();
  const { eventTags } = useEventHook();
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = eventForm;
  const nameRef = useRef<any>(null);
  const assetRef = useRef<any>(null);
  const { t } = useTranslation();

  const submitForm = async (formDt: any) => {
    onSubmit({
      ...formDt,
      bannerImage: Array.isArray(formDt?.bannerImage)
        ? formDt?.bannerImage[0]?.data_url ?? null
        : formDt?.bannerImage ?? null,
      iconImage: Array.isArray(formDt?.iconImage)
        ? formDt?.iconImage[0]?.data_url ?? null
        : formDt?.iconImage ?? null,
      tags: _.map(formDt?.tags ?? [], it => ({ id: it.value ?? it })),
      locations: _.map(formDt?.locations ?? [], it => ({ id: it.value ?? it })),
      startTime: formDt?.startTime ?? null,
      endTime: formDt?.endTime ?? null,
      showEndTime: formDt?.showEndTime ?? null,
      showStartTime: formDt?.showStartTime ?? null,
      images: _.map(formDt?.images, it => ({
        image: typeof it === 'string' ? it : it.data_url,
      })),
    });
  };

  const onError = async () => {
    const ref = _.get(errors, [Object.keys(errors)[0]])?.ref;
    if (ref.name === 'title' && nameRef.current) {
      nameRef?.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      assetRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (data) {
      reset(transformEventDetails(data));
    } else {
      reset(getDefaultEventForm());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box component="form" onSubmit={handleSubmit(submitForm, onError)}>
      <Typography
        component="h3"
        sx={{
          fontSize: '24px',
          lineHeight: '36px',
          textDecoration: 'uppercase',
          margin: '30px 0 30px 20px',
        }}
      >
        {data ? t('Edit Event') : t('Add Event')}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '20px',
          margin: '20px',
          '& .bottom_20': {
            marginBottom: '20px',
          },
        }}
      >
        <Box>
          <OneInputContainer className="bottom_20" ref={nameRef}>
            <CustomController
              controller={control}
              name="title"
              label={t('Name')}
              isRequired
              error={errors?.title?.message}
              formType={FormType.INPUT}
            />
          </OneInputContainer>
          <OneInputContainer className="bottom_20" ref={assetRef}>
            <Controller
              control={control}
              name="contentDetail"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;

                return (
                  <TextArea
                    inputType={1}
                    onChange={onChange}
                    value={value}
                    label={t('Content Details')}
                    error={errors?.contentDetail?.message}
                    rows={30}
                  />
                );
              }}
            />
          </OneInputContainer>
        </Box>
        <Box
          sx={{
            '& .img_input': {
              borderRadius: '10px',
              border: '1px #ccc dashed',
              padding: '20px',
            },
            '& .MuiFormControl-root': {
              width: '100%',
            },
          }}
        >
          <TwoInputContainer className="bottom_20">
            <Controller
              control={control}
              name="startTime"
              rules={{
                validate: value => {
                  const val = getValues('endTime');
                  return (
                    !value ||
                    (value && val && value < val) ||
                    t('Ending time should greater than starting time')
                  );
                },
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <CustomDateTimePicker
                    label={t('Start at')}
                    value={value}
                    onChange={onChange}
                    errorMsg={errors?.startTime?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="endTime"
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <CustomDateTimePicker
                    label={t('End at')}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </TwoInputContainer>
          <TwoInputContainer className="bottom_20">
            <Controller
              control={control}
              name="showStartTime"
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <CustomDateTimePicker
                    label={t('Display start at')}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="showEndTime"
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <CustomDateTimePicker
                    label={t('Display end at')}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </TwoInputContainer>
          <OneInputContainer className="bottom_20">
            <Controller
              control={control}
              name="locations"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;

                return (
                  <MultiSelectItem
                    isRequired
                    options={transformSelectOptions(locationsToVenue)}
                    value={value}
                    onChange={onChange}
                    placeholder={t('Location')}
                    label={t('Location')}
                    errorMess={errors?.locations?.message ?? ''}
                  />
                );
              }}
            />
          </OneInputContainer>
          <OneInputContainer className="bottom_20">
            <CustomController
              controller={control}
              name="type"
              label={t('Type')}
              isRequired
              formType={FormType.SELECT}
              error={errors?.type?.message}
              options={transformSelectOptionsWithTranslation(EVENT_TYPES, t)}
            />
          </OneInputContainer>
          <OneInputContainer className="bottom_20">
            <CustomController
              controller={control}
              name="tags"
              label={t('Tag')}
              formType={FormType.MULTI_SELECT}
              options={transformSelectOptions(eventTags)}
              error={errors.tags?.message}
            />
          </OneInputContainer>
          <OneInputContainer className="bottom_20">
            <CustomController
              controller={control}
              name="contentUrl"
              label={t('Content url')}
              formType={FormType.INPUT}
              error={errors?.contentUrl?.message}
            />
          </OneInputContainer>
          <Box component="div" className="bottom_20">
            <Controller
              control={control}
              name="bannerImage"
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <ImageInput
                    label={t('Banner')}
                    title={t('Drag an image here or click “Upload” below')}
                    width={600}
                    height={200}
                    inputType={InputType.POP_UP}
                    onUpload={onChange}
                    value={value ? [value] : []}
                  />
                );
              }}
            />
          </Box>
          <Box component="div" className="bottom_20">
            <Controller
              control={control}
              name="iconImage"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <ImageInput
                    label={t('Icon')}
                    title={t('Drag an image here or click “Upload” below')}
                    width={600}
                    height={100}
                    inputType={InputType.POP_UP}
                    onUpload={onChange}
                    value={value ? [value] : []}
                    isRequired
                    errorMessage={errors?.iconImage?.message}
                  />
                );
              }}
            />
          </Box>
          <Box component="div" className="bottom_20">
            <Controller
              control={control}
              name="images"
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <ImageInput
                    label={t('Event picture')}
                    title={t('Drag an image here or click “Upload” below')}
                    width={600}
                    height={200}
                    multiple
                    inputType={InputType.POP_UP}
                    onUpload={onChange}
                    value={value ?? []}
                  />
                );
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          margin: '40px 0',
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CustomButton
          buttonType={CustomButtonType.WHITE_BG}
          title={t(`Cancel`)}
          onClick={() => reset()}
        />
        <CustomButton
          type="submit"
          buttonType={CustomButtonType.NO_ICON}
          title={t(`Submit`)}
        />
      </Box>
    </Box>
  );
}
