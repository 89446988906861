import { Box } from '@mui/material';
import CustomButton, { CustomButtonType } from 'src/components/common/Button';
import PopUp from 'src/components/common/Popup';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Input, { Label } from '../form/input';

export interface IPopUp {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  sx?: any;
  children?: any;
  className?: string;
  title?: string;
  actionTitle?: string;
}

const RevertConfirmPopup = ({
  className,
  children,
  title,
  sx,
  open,
  onClose,
  onSubmit,
  actionTitle = 'Revert',
  ...props
}: IPopUp) => {
  const { t, i18n } = useTranslation();

  const [venueId, setVenueId] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [hasError, setError] = useState<boolean>(false);

  const queries = useParams();

  useEffect(() => {
    if (queries?.venueId) {
      setVenueId(queries?.venueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) {
      setValue('');
      setError(false);
    }
  }, [open]);

  const onAgree = () => {
    if (value !== venueId) {
      setError(true);
      return;
    } else {
      setError(false);
    }
    onSubmit();
    onClose();
  };

  const onChange = (e: any) => {
    setValue(e.target?.value);
    if (e.target?.value === venueId) setError(false);
  };

  return (
    <PopUp
      open={open}
      className={`confirm_modal`}
      title={title}
      onClose={onClose}
    >
      {children}
      <Box
        mb={2}
        sx={{
          '& .error': {
            color: '#eb5757',
            textAlign: 'left',
          },
        }}
      >
        <Input
          label={t(`Enter venue ID "${venueId}" to confirm.`)}
          value={value}
          onChange={onChange}
        />
        {hasError && <Label className="error">{t('Incorrect venue ID')}</Label>}
      </Box>
      <Box
        component="div"
        sx={{
          margin: '20px 0 0',
          display: 'grid',
          gridTemplateColumns:
            i18n.resolvedLanguage === 'ja' ? '120px 70px' : '70px 70px',
          justifyContent: 'flex-end',
          gridGap: '20px',
          '& button:nth-child(2)': {
            background: '#eb5757',
          },
        }}
      >
        <CustomButton
          buttonType={CustomButtonType.WHITE_BG}
          title={t('Cancel')}
          onClick={onClose}
        />
        <CustomButton
          buttonType={CustomButtonType.NO_ICON}
          title={t('Revert')}
          onClick={onAgree}
        />
      </Box>
    </PopUp>
  );
};

export default RevertConfirmPopup;
