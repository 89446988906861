/* eslint-disable max-lines */
import { FilterBar, FilterType } from '@/components/common/FilterBar';
import Input, { Label } from '@/components/form/input';
import { CommonTable } from '@/components/layouts/table';
import { Container } from '@/components/venue/components';
import { VenuePopup } from '@/components/venue/components/popup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TableType } from '@/components/layouts/table/table';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import Path from '@/constants/path';
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { RequestStatus } from '@/constants/API';
import { useLocation, useParams } from 'react-router-dom';
import useVenueHook from '@/hooks/useVenueHook';
import {
  checkIsRequesting,
  getCountryFromVenueOptions,
  transformSelectOptionsWithTranslation,
} from '@/utils/helper';
import queryString from 'query-string';
import _ from 'lodash';
import useGlobalHook from '@/hooks/useGlobalHook';
import { VenueDetail, VenueDetailsForm } from '@/stores/slices/venue/type';
import { Loader } from '@/components/common/loader';
import { NoData } from '@/components/common/NoData';
import useCustomerHook from '@/hooks/useCustomerHook';
import { TableHeaderAction } from '@/components/layouts/table/rowSelected';
import { Box, Collapse, SelectChangeEvent } from '@mui/material';
import {
  ACTION_OPTIONS,
  PUBLISH_STATES,
  PUBLISH_STATES_SELECT,
  VENUE_TYPES,
} from '@/constants';
import SelectItem from '@/components/form/select';
import MultiSelectItem from '@/components/form/multiselect';
import { useTranslation } from 'react-i18next';

interface FormData {
  keyword: string;
  country?: string[] | number[];
  type?: string[] | number[];
  publish: string | number;
}
export interface DeleteForm {
  input: string;
}
export const VenuePage = () => {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [selectedId, setSelectedId] = useState<string | number>('');
  const [editingVenue, setEditingVenue] = useState<VenueDetail | undefined>(
    undefined,
  );

  const [publish, setPublish] = useState<boolean>(false);

  const [selecteds, setSelecteds] = useState<(string | number)[]>([]);
  const [openSwitch, setOpenSwitch] = useState<boolean>(false);

  const location = useLocation();
  const queries = useParams();

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    getVenueListStatus,
    requestVenueList,
    createVenueInfoStatus,
    requestCreateVenue,
    requestUpdateVenueInfo,
    venues,
    deleteVenueInfoStatus,
    updateVenueInfoStatus,
    requestDeleteVenue,
    requestResetVenue,
    requestDeleteMultiVenue,
    requestUpdateMultiVenueInfo,
    deleteMultiVenueInfoStatus,
    updateVenuePublishStatus,
    venueOptions,
  } = useVenueHook();

  const { getAllCustomerListStatus } = useCustomerHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getVenueListStatus,
        createVenueInfoStatus,
        deleteVenueInfoStatus,
        updateVenueInfoStatus,
        getAllCustomerListStatus,
        deleteMultiVenueInfoStatus,
        updateVenuePublishStatus,
      ]),
    [
      getVenueListStatus,
      createVenueInfoStatus,
      deleteVenueInfoStatus,
      updateVenueInfoStatus,
      getAllCustomerListStatus,
      updateVenuePublishStatus,
      deleteMultiVenueInfoStatus,
    ],
  );

  const showNoData = useMemo(
    () =>
      getVenueListStatus !== RequestStatus.IDLE &&
      (venues?.data?.length === 0 || !venues),
    [venues, getVenueListStatus],
  );
  const navigate = useNavigateHook()?.navigate;

  const { control, getValues, reset } = useForm<FormData>({
    defaultValues: {
      keyword: '',
    },
  });

  const redirect = useCallback(
    (qr: any) => {
      navigate(
        `${location.pathname}?${queryString.stringify(
          _.omitBy(qr, v => _.isEmpty(v) || _.isNil(v)),
        )}`,
      );
    },
    [location.pathname, navigate],
  );

  const onChangePage = (id: number) => {
    let params = queryString.parse(window.location.search);
    params.page = id.toString();
    redirect(params);
  };

  // const onExport = () => {};

  const onAdd = () => {
    setEditingVenue(undefined);
    setOpenPopup(true);
  };

  const submitAddOrEdit = (formData: VenueDetailsForm) => {
    if (editingVenue) {
      requestUpdateVenueInfo({ ...formData, id: editingVenue.id });
    } else {
      requestCreateVenue(formData);
    }
    setOpenPopup(false);
  };

  const onView = (id: number | string) => {
    navigate(
      `${Path.CUSTOMERS}/${queries?.customerId}${Path.VENUE}/${id}${Path.FLOOR_PLAN}`,
    );
  };

  const onEdit = (id: number | string) => {
    setEditingVenue(
      venues?.data.find((it: VenueDetail) => it.id === id) ?? undefined,
    );
    setOpenPopup(true);
  };

  const onDelete = (id: number | string) => {
    setOpenDelete(true);
    setSelectedId(id);
  };

  const onSelect = (id: number | string) => {
    if (selecteds.includes(id)) {
      setSelecteds(selecteds.filter(it => it !== id));
    } else {
      setSelecteds([...selecteds, id]);
    }
  };

  const onChangeSelected = (e: any) => {
    if (e.target.checked) {
      setSelecteds([...venues?.data?.map((it: VenueDetail) => it.id)]);
    } else {
      setSelecteds([]);
    }
  };

  const submitDelete = () => {
    if (selectedId) {
      requestDeleteVenue(selectedId);
    } else if (selecteds.length) {
      requestDeleteMultiVenue({ venue_ids: selecteds });
    }
  };

  const onSelectAction = (actionId: number | number) => {
    setSelectedId('');
    if (!selecteds.length) return;
    if (actionId === ACTION_OPTIONS[0].id) {
      setOpenSwitch(true);
      setPublish(false);
    } else if (actionId === ACTION_OPTIONS[1].id) {
      setOpenSwitch(true);
      setPublish(true);
    } else {
      setOpenSwitch(false);
      setOpenDelete(true);
    }
  };

  const changeState = () => {
    setOpenSwitch(false);

    requestUpdateMultiVenueInfo({
      venue_ids: selecteds,
      publish_state: publish,
    });
  };

  const handleDebounceFn = (inputValue: string) => {
    let params = queryString.parse(window.location.search);
    params.keyword = inputValue;
    delete params.page;
    redirect(params);
    navigate(`${location.pathname}?keyword=${inputValue}`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  const onKeywordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget?.value;
    debounceFn(keyword);
  };

  const pagination = {
    currentPage: venues?.current_page ?? 1,
    totalPages: venues?.total_page ?? 1,
    onPageChange: (id: number) => onChangePage(id),
  };

  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    requestVenueList(
      queries?.customerId
        ? { ...params, customerId: queries?.customerId }
        : { ...params },
    );
  };

  const onCountryChange = (e: SelectChangeEvent<any>) => {
    let params = queryString.parse(window.location.search);
    params.countries = getValues('country')?.join(',') ?? '';
    redirect(params);
  };

  const onTypeChange = (e: SelectChangeEvent<any>) => {
    let params = queryString.parse(window.location.search);
    params.type = getValues('type')?.join(',') ?? '';
    redirect(params);
  };

  const onPublishChange = (e: SelectChangeEvent<any>) => {
    let params = queryString.parse(window.location.search);

    if (+getValues('publish')) {
      params.publish = +getValues('publish') === 1 ? '0' : '1';
    } else {
      delete params.publish;
    }

    redirect(params);
  };

  useEffect(() => {
    return () => requestResetVenue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateVenueInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Venue updated!')]);
      reloadData();
    }
    if (updateVenueInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update venue')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateVenueInfoStatus]);

  useEffect(() => {
    if (updateVenuePublishStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Venue updated!')]);
      reloadData();
    }
    if (updateVenuePublishStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update venue')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateVenuePublishStatus]);

  useEffect(() => {
    if (deleteMultiVenueInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Deleted venue(s)')]);
      reloadData();
    }
    if (deleteMultiVenueInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([
        t('Something went wrong. Unable to deleted selected venue(s)'),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMultiVenueInfoStatus]);

  useEffect(() => {
    if (createVenueInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Venue created!')]);
      reloadData();
    }

    if (createVenueInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to create venue')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createVenueInfoStatus]);

  useEffect(() => {
    if (deleteVenueInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to delete venue')]);
    }
    if (deleteVenueInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Venue deleted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteVenueInfoStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    reloadData();
    let params = queryString.parse(window.location.search);
    const keyword = params?.keyword ? String(params?.keyword) : '';
    const state = params?.publish ?? '';
    const country = params?.countries?.toString() ?? '';
    const type = params?.type?.toString() ?? '';
    reset({
      keyword,
      country: country?.split(','),
      type: type?.split(','),
      publish: Array.isArray(state) ? _.toInteger(state[0]) : +state ?? 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <Container>
      <FilterBar
        type={FilterType.GENERIC}
        onAdd={onAdd}
        // onExport={onExport}
        buttonText={t('Add new Venue')}
        onSelectAction={onSelectAction}
        hasAction
        hasButton
        disabled={!!(selecteds.length === 0)}
      >
        <Box className="gap">
          <Controller
            control={control}
            name="keyword"
            render={({ field }) => {
              return (
                <Input
                  inputType={1}
                  onChange={e => onKeywordChange(e)}
                  placeholder={t('Search')}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="country"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <MultiSelectItem
                  options={getCountryFromVenueOptions(venueOptions)}
                  value={value ?? []}
                  onChange={e => {
                    onChange(e);
                    onCountryChange(e);
                  }}
                  placeholder={t('Country')}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="publish"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <SelectItem
                  options={transformSelectOptionsWithTranslation(
                    PUBLISH_STATES_SELECT,
                    t,
                  )}
                  value={value}
                  onChange={e => {
                    onChange(e);
                    onPublishChange(e);
                  }}
                  placeholder={t('Status')}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="type"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <MultiSelectItem
                  options={transformSelectOptionsWithTranslation(
                    VENUE_TYPES,
                    t,
                  )}
                  value={value ?? []}
                  onChange={e => {
                    onChange(e);
                    onTypeChange(e);
                  }}
                  placeholder={t('Type')}
                />
              );
            }}
          />
        </Box>
      </FilterBar>
      {showNoData ? (
        <NoData />
      ) : (
        <>
          <Collapse in={!!selecteds.length} timeout="auto" unmountOnExit>
            <TableHeaderAction
              selected={selecteds}
              total={venues?.total_records}
            />
          </Collapse>

          <CommonTable
            type={TableType.VENUE}
            data={venues?.data}
            pagination={pagination}
            onEdit={onEdit}
            onView={onView}
            onSelect={onSelect}
            indeterminate={
              selecteds.length > 0 && selecteds.length < venues?.page_size
            }
            onChangeSelect={onChangeSelected}
            selected={selecteds}
          />
        </>
      )}
      <VenuePopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={submitAddOrEdit}
        onDelete={() => onDelete(editingVenue?.id ?? 0)}
        data={editingVenue}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onSubmit={submitDelete}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenSwitch(false)}
        open={openSwitch}
        onSubmit={changeState}
        actionTitle={
          publish ? PUBLISH_STATES[1].label : PUBLISH_STATES[0].label
        }
      >
        <Label>
          {publish
            ? t('Are you sure to publish selected venue(s)?')
            : t('Are you sure to unpublish selected venue(s)?')}
        </Label>
      </ConfirmPopUp>
      <Loader show={loading} />
    </Container>
  );
};
