import { createSelector } from '@reduxjs/toolkit';
import { venueSlice } from './venue';
import { RootState } from '../..';

const selectSlice = (state: RootState) =>
  state.venue || venueSlice.getInitialState;

export const selectVenue = createSelector([selectSlice], venueState => ({
  ...venueState,
}));
