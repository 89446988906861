import { RequestStatus } from '@/constants/API';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CommonType } from './type';

export interface AmenityState {
  getAmenityListStatus: RequestStatus;
  getLocationAmenityListStatus: RequestStatus;
  setVenueAmenitiesStatus: RequestStatus;
  createAmenityInfoStatus: RequestStatus;
  updateAmenityInfoStatus: RequestStatus;
  deleteAmenityInfoStatus: RequestStatus;
  amenities: CommonType[];
  locationAmenities: CommonType[];
}

const initialState: AmenityState = {
  getAmenityListStatus: RequestStatus.IDLE,
  getLocationAmenityListStatus: RequestStatus.IDLE,
  setVenueAmenitiesStatus: RequestStatus.IDLE,
  createAmenityInfoStatus: RequestStatus.IDLE,
  updateAmenityInfoStatus: RequestStatus.IDLE,
  deleteAmenityInfoStatus: RequestStatus.IDLE,
  amenities: [],
  locationAmenities: [],
};

export const amenitySlice: any = createSlice({
  name: 'amenity',
  initialState,
  reducers: {
    getAmenityListRequest: (state, action: PayloadAction<any>) => {
      state.getAmenityListStatus = RequestStatus.REQUESTING;
    },
    getAmenityListSuccess: (state, action: PayloadAction<any>) => {
      state.getAmenityListStatus = RequestStatus.SUCCESS;
      state.amenities = action.payload;
    },
    getAmenityListFailure: state => {
      state.getAmenityListStatus = RequestStatus.ERROR;
    },
    getLocationAmenityListRequest: (state, action: PayloadAction<any>) => {
      state.getLocationAmenityListStatus = RequestStatus.REQUESTING;
    },
    getLocationAmenityListSuccess: (state, action: PayloadAction<any>) => {
      state.getLocationAmenityListStatus = RequestStatus.SUCCESS;
      state.locationAmenities = action.payload;
    },
    getLocationAmenityListFailure: state => {
      state.getLocationAmenityListStatus = RequestStatus.ERROR;
    },
    setVenueAmenitiesRequest: (state, action: PayloadAction<any>) => {
      if (action) state.setVenueAmenitiesStatus = RequestStatus.REQUESTING;
    },
    setVenueAmenitiesSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.setVenueAmenitiesStatus = RequestStatus.SUCCESS;
    },
    setVenueAmenitiesFailure: (state, action: PayloadAction<any>) => {
      state.setVenueAmenitiesStatus = RequestStatus.ERROR;
    },
    createAmenityInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createAmenityInfoStatus = RequestStatus.REQUESTING;
    },
    createAmenityInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createAmenityInfoStatus = RequestStatus.SUCCESS;
    },
    createAmenityInfoFailure: (state, action: PayloadAction<any>) => {
      state.createAmenityInfoStatus = RequestStatus.ERROR;
    },
    updateAmenityInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateAmenityInfoStatus = RequestStatus.REQUESTING;
    },
    updateAmenityInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateAmenityInfoStatus = RequestStatus.SUCCESS;
    },
    updateAmenityInfoFailure: (state, action: PayloadAction<any>) => {
      state.updateAmenityInfoStatus = RequestStatus.ERROR;
    },
    deleteAmenityInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteAmenityInfoStatus = RequestStatus.REQUESTING;
    },
    deleteAmenityInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.deleteAmenityInfoStatus = RequestStatus.SUCCESS;
    },
    deleteAmenityInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteAmenityInfoStatus = RequestStatus.ERROR;
    },

    resetAmenityInfoRequestStatus: state => {
      state.getAmenityListStatus = RequestStatus.IDLE;
      state.getLocationAmenityListStatus = RequestStatus.IDLE;
      state.setVenueAmenitiesStatus = RequestStatus.IDLE;
      state.createAmenityInfoStatus = RequestStatus.IDLE;
      state.updateAmenityInfoStatus = RequestStatus.IDLE;
      state.deleteAmenityInfoStatus = RequestStatus.IDLE;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAmenityListRequest,
  getAmenityListSuccess,
  getAmenityListFailure,
  getLocationAmenityListRequest,
  getLocationAmenityListSuccess,
  getLocationAmenityListFailure,
  setVenueAmenitiesRequest,
  setVenueAmenitiesSuccess,
  setVenueAmenitiesFailure,
  createAmenityInfoRequest,
  createAmenityInfoSuccess,
  createAmenityInfoFailure,
  updateAmenityInfoRequest,
  updateAmenityInfoSuccess,
  updateAmenityInfoFailure,
  deleteAmenityInfoRequest,
  deleteAmenityInfoSuccess,
  deleteAmenityInfoFailure,
  resetAmenityInfoRequestStatus,
} = amenitySlice.actions;

export default amenitySlice.reducer;
