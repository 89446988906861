export const ACCESS_TOKEN_KEY = 'asi7yBUvjhKJ9ub';
export const REFRESH_TOKEN_KEY = '3gH89QNBYuN2R9';
export const DEFAULT_NULL_INDEX = -1;
export const INIT_MAX_NUMBER = 1000;
export const DEFAULT_DATA_URL_KEY = 'dataURL';
export const POPOVER_ID = 'popover_id';
export const DIGIMAP_COLLASPE_SIDEBAR = 'digimap_collaspe_sidebar';

export const USER_CACHED_DATA = 'USER_CACHED_DATA';
export const MAP_STATE_CACHED_DATA = 'MAP_STATE_CACHED_DATA';
export const MAP_ELEMENT_CACHED_DATA = 'MAP_ELEMENT_CACHED_DATA';
export const API_KEY = 'AIzaSyDUiBIdQhABpvEBXjDo1N4XSKehrjvT6Ug';
export * from './modes';
export * from './projectActions';
export * from './units';

export const KEYBOARD_BUTTON_CODE = {
  DELETE: 46,
  BACKSPACE: 8,
  ESC: 27,
  Z: 90,
  ALT: 18,
  C: 67,
  V: 86,
  CTRL: 17,
  ENTER: 13,
  TAB: 9,
};

export const CONNECTION_TYPES = [
  {
    value: 1,
    label: 'Lift',
  },
  {
    value: 2,
    label: 'Escalator',
  },
  {
    value: 3,
    label: 'Stair',
  },
];

export const PUBLISH_STATES = [
  { value: 1, label: 'Unpublish', title: 'Unpublished' },
  { value: 2, label: 'Publish', title: 'Published' },
];

export const PUBLISH_STATES_SELECT = [
  { value: 0, label: 'Reset', title: 'Reset' },
  { value: 1, label: 'Unpublish', title: 'Unpublished' },
  { value: 2, label: 'Publish', title: 'Published' },
];

export const ACTION_OPTIONS = [
  { id: 1, label: 'Unpublish', value: 1 },
  { id: 2, label: 'Publish', value: 2 },
  { id: 3, label: 'Delete', value: 3 },
];

export const FLOOR_PATH_REGEX = new RegExp(
  /^\/venues\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/i,
);

export const VENUE_PATH_REGEX = new RegExp(
  /^\/customers\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/i,
);

export const CUSTOMER_VENUE_PATH_REGEX = new RegExp(
  /^\/customers\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/venues\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/i,
);

export const CUSTOMER_VENUE_ONLY_PATH_REGEX = new RegExp(
  /^\/customers\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/venues$/i,
);

export const CUSTOMER_VENUE_FLOOR_PATH_REGEX = new RegExp(
  /^\/customers\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/venues\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/floor-plans$/i,
);

export const CUSTOMER_VENUE_CONNECTION_PATH_REGEX = new RegExp(
  /^\/customers\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/venues\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/connections$/i,
);

export const CUSTOMER_VENUE_LOCATION_PATH_REGEX = new RegExp(
  /^\/customers\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/venues\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/locations$/i,
);

export const CUSTOMER_ONLY_PATH_REGEX = new RegExp(
  /^\/customers\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i,
);

export const VENUE_ONLY_PATH_REGEX = new RegExp(
  /^\/venues\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i,
);

export const MAPPING_PATH_REGEX = new RegExp(
  /^\/venues\/[0-9a-fA-F]{8}[-][0-9a-fA-F]{4}[-][0-9a-fA-F]{4}[-][0-9a-fA-F]{4}[-][0-9a-fA-F]{12}\/floor-plans/i,
);

export const EVENT_PATH_REGEX = new RegExp(
  /^\/customers\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/venues\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/events/i,
);
export const CLOSE_BTN = 'close';

export const LANG_LIST = [
  {
    label: 'English',
    title: 'English',
    value: 'en',
  },
  {
    label: 'Japanese',
    title: 'Japanese',
    value: 'ja',
  },
];

export const VENUE_TYPES = [
  {
    label: 'Shopping Mall',
    value: 1,
  },
  {
    label: 'University',
    value: 2,
  },
  {
    label: 'Office',
    value: 3,
  },
  {
    label: 'Hospital',
    value: 4,
  },
  {
    label: 'Expo / Exhibition',
    value: 5,
  },
  {
    label: 'Stadium / Sports Arena',
    value: 6,
  },
  {
    label: 'Airport',
    value: 7,
  },
  {
    label: 'Amusement Park',
    value: 8,
  },
  {
    label: 'Real Estate',
    value: 9,
  },
  {
    label: 'Inventory / Warehouse',
    value: 10,
  },
  {
    label: 'Others',
    value: 11,
  },
];

export const LOCATION_STATES = [
  {
    label: 'Open',
    value: 1,
  },
  {
    label: 'Close',
    value: 2,
  },
  {
    label: 'Under construction',
    value: 3,
  },
];

export const LOCATION_STATUS = [
  {
    label: 'Available',
    value: 1,
  },
  {
    label: 'In use',
    value: 2,
  },
  {
    label: 'Reserved',
    value: 3,
  },
];

export const EVENT_TYPES = [
  {
    label: 'SALE',
    value: 1,
  },
  {
    label: 'GRAND OPENING',
    value: 2,
  },
  {
    label: 'PROMOTION',
    value: 3,
  },
];
