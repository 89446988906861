export const VenueIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Venue">
      <path
        id="Vector"
        d="M16.6667 5.00016H3.33333V3.3335H16.6667V5.00016ZM10.8333 12.9168C10.8333 13.8668 11.1917 14.8835 11.6667 15.8335V16.6668H3.33333V11.6668H2.5V10.0002L3.33333 5.8335H16.6667L17.25 8.75016C16.6667 8.4835 16.0667 8.3335 15.4167 8.3335C12.9167 8.3335 10.8333 10.4168 10.8333 12.9168ZM10 11.6668H5V15.0002H10V11.6668ZM18.3333 12.9168C18.3333 15.0835 15.4167 18.3335 15.4167 18.3335C15.4167 18.3335 12.5 15.0835 12.5 12.9168C12.5 11.3335 13.8333 10.0002 15.4167 10.0002C17 10.0002 18.3333 11.3335 18.3333 12.9168ZM16.4167 13.0002C16.4167 12.5002 15.9167 12.0002 15.4167 12.0002C14.9167 12.0002 14.4167 12.4168 14.4167 13.0002C14.4167 13.5002 14.8333 14.0002 15.4167 14.0002C16 14.0002 16.5 13.5002 16.4167 13.0002Z"
        fill="#555C6A"
      />
    </g>
  </svg>
);
