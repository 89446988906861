// FIXME: Update this configuration file based on your project information

export const AppConfig = {
  site_name: 'Starter',
  title: 'Digimap CMS',
  description: 'Digimap CMS',
  locale: 'en',
  apiBase: `${process.env.REACT_APP_PUBLIC_API_BASE || ''}/api/v1/`,
  apiKey: process.env.REACT_APP_API_SECRET_KEY || '',
  coreEditorBaseUrl: process.env.REACT_APP_CORE_EDITOR_BASE || '',
};
