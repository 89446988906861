/* eslint-disable max-lines */
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import CheckBoxItem from './checkbox';
import MultipleSelectCustomize, {
  DirectionType,
  InputType,
} from './customSelect';
import CustomDatePicker from './datepicker';
import Input from './input';
import SelectItem from './select';
import TextArea from './textarea';
import CustomTimePicker from './timepicker';
import ColorPickerInput from './colorPicker';
import { FormType } from './types';
import { RadioInput } from '@/components/form/radio';
import { useTranslation } from 'react-i18next';

export interface FormController {
  controller: any;
  formType: FormType;
  options?: { label: string; value: string | number }[] | any[];
  label?: string;
  isRequired?: boolean;
  error?: string;
  placeholder?: string;
  name: string;
  width?: number;
  disabled?: boolean;
  inputType?: InputType;
  direction?: DirectionType;
  isMulti?: boolean;
}
export const CustomController = ({
  formType,
  controller,
  options,
  label,
  isRequired = false,
  error,
  placeholder,
  name,
  width,
  disabled = false,
  inputType = InputType.POP_UP,
  direction = DirectionType.COLUMN,
  isMulti = true,
}: FormController) => {
  const { t } = useTranslation();

  const renderInput = useCallback(
    (onChange: (event: any) => void, value: any) => {
      switch (formType) {
        case FormType.CHECKBOX:
          return (
            <CheckBoxItem
              onChange={onChange}
              label={label ?? ''}
              checked={!!value}
              inputType={inputType}
              disabled={disabled}
              value={value}
            />
          );
        case FormType.RADIO:
          return (
            <RadioInput
              value={value ?? ''}
              label={label}
              options={options ?? []}
              onChange={onChange}
              placeholder={placeholder}
              isRequired={isRequired}
            />
          );
        case FormType.INPUT:
          return (
            <Input
              label={label}
              isRequired={isRequired}
              error={error}
              value={value}
              onChange={onChange}
              width={width}
              fromType={inputType}
              direction={direction}
              disabled={disabled}
            />
          );
        case FormType.TEXTAREA:
          return (
            <TextArea
              label={label}
              isRequired={isRequired}
              error={error}
              value={value}
              onChange={onChange}
              width={width}
              inputType={inputType}
              disabled={disabled}
            />
          );

        case FormType.SELECT:
          return (
            <SelectItem
              value={value ?? ''}
              label={label}
              options={options ?? []}
              onChange={onChange}
              placeholder={placeholder}
              width={width}
              inputType={inputType}
              disabled={disabled}
              isRequired={isRequired}
              errorMess={error}
            />
          );

        case FormType.MULTI_SELECT:
          return (
            <MultipleSelectCustomize
              inputType={inputType}
              options={options ?? []}
              label={label}
              onChange={onChange}
              value={isMulti ? value ?? [] : value ?? ''}
              error={!!error}
              helperText={error}
              sx={{
                height: 'unset !important',
                borderRadius: '4px',
                minWidth: width ? width + 'px' : '190px',
              }}
              disabled={disabled}
              isMulti={isMulti}
              isRequired={isRequired}
            />
          );

        case FormType.DATEPICKER:
          return (
            <CustomDatePicker
              label={label}
              value={value}
              onChange={onChange}
              inputType={inputType}
              disabled={disabled}
              isRequired={isRequired}
            />
          );

        case FormType.TIMEPICKER:
          return (
            <CustomTimePicker
              label={label}
              onChange={onChange}
              value={value}
              inputType={inputType}
              disabled={disabled}
              isRequired={isRequired}
            />
          );

        case FormType.COLORPICKER:
          return (
            <ColorPickerInput
              label={label}
              onChange={onChange}
              value={value ?? '#fff'}
              width={width}
              fromType={inputType}
            />
          );
        default:
          return (
            <Input
              label={label}
              isRequired={isRequired}
              error={error}
              value={value}
              onChange={onChange}
              fromType={inputType}
              disabled={disabled}
            />
          );
      }
    },
    [
      direction,
      error,
      formType,
      inputType,
      isRequired,
      label,
      options,
      placeholder,
      width,
      disabled,
      isMulti,
    ],
  );

  return (
    <Controller
      control={controller}
      name={name}
      rules={
        isRequired
          ? {
              required: t('This field is required'),
            }
          : {}
      }
      render={({ field }) => {
        const { value, onChange } = field;
        return renderInput(onChange, value);
      }}
    />
  );
};
