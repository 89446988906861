import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import React from 'react';
import { RadioProps } from '@mui/material';
import { Label } from '@/components/form/input';
import { InputType } from '@/components/form/customSelect';
import { InputContainer } from '@/components/form/styled';

interface RadioInputProps {
  label?: string;
  error?: string;
  value?: string | number;
  isRequired?: boolean;
  width?: number;
  options: { value: string | number; label: string | number }[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const RadioInput: React.FC<RadioInputProps & RadioProps> = ({
  label,
  value,
  options,
  onChange,
}: RadioInputProps) => {
  return (
    <InputContainer>
      {label && <Label $fromType={InputType.MAPPING}>{label}</Label>}
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={onChange}
        >
          {(options || []).map((v, k) => (
            <FormControlLabel
              value={v.value}
              key={k}
              control={<Radio />}
              label={v.label}
              sx={{ color: '#FFF' }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </InputContainer>
  );
};
