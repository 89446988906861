import { Container } from '@/components/venue/components';
import { Box, Typography } from '@mui/material';

export const SettingsPage = () => {
  return (
    <Container>
      <Box sx={{ width: 'calc(100vw - 220px)' }}>
        <Typography sx={{ textAlign: 'center', fontSize: '36px' }}>
          Settings
        </Typography>
      </Box>
    </Container>
  );
};
