import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';

import amenityReducer from '@/stores/slices/amenity/amenity';
import authReducer from '@/stores/slices/auth/auth';
import categoryReducer from '@/stores/slices/category/category';
import connectionReducer from '@/stores/slices/connection/connection';
import customerReducer from '@/stores/slices/customer/customer';
import eventReducer from '@/stores/slices/event/event';
import floorReducer from '@/stores/slices/floor/floor';
import globalReducer from '@/stores/slices/global/global';
import locationReducer from '@/stores/slices/location/location';
import qrcodeReducer from '@/stores/slices/qrcode/qrcode';
import snapshotReducer from '@/stores/slices/snapshot/snapshot';
import venueReducer from '@/stores/slices/venue/venue';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: customerReducer,
    global: globalReducer,
    venue: venueReducer,
    floor: floorReducer,
    connection: connectionReducer,
    location: locationReducer,
    event: eventReducer,
    amenity: amenityReducer,
    category: categoryReducer,
    qrcode: qrcodeReducer,
    snapshot: snapshotReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
