export const TOOLBAR_WIDTH = 50;
export const SIDEBAR_WIDTH = 300;
export const FOOTER_HEIGHT = 20;

export enum SIDEBAR_ITEMS {
  'customer' = 'Customers',
  'top' = 'Dashboard',
  'mapping' = 'Mapping',
  'venue' = 'Venues',
  'floor' = 'Editor',
  'location' = 'Locations',
  'connection' = 'Connections',
  'event' = 'Events',
  'amenity' = 'Amenities',
  'category' = 'Categories',
  'analytics' = 'Analytics',
  'viewer' = 'Viewer',
  'snapshots' = 'Snapshots',
}

export const MAPPING_TOOL_BAR_ITEMS = {
  file: 'File',
  edit: 'Edit',
  view: 'View',
  image: 'Image',
};
