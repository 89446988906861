import { Box, Dialog } from '@mui/material';
import styled from 'styled-components';
import { CloseIcon } from '../icons';

const Container = styled(Box)`
  overflow: auto;
  margin-top: 80px;
  padding: 24px;
`;
const CustomDialog = styled(Dialog)<{ selected: boolean }>`
  &.confirm_modal {
    .MuiDialog-paper {
      width: 350px;
    }
    .modal_content {
      margin-top: 50px;
      padding: 20px;
      text-align: center;
    }
  }
  &.no_padding {
    .modal_content {
      margin-top: 40px;
    }
  }
  .MuiDialog-paper {
    border-radius: 0;
    width: ${props => (props.selected ? '100%' : '640px')};
    height: ${props => (props.selected ? '100%' : 'auto')};
    margin: ${props => (props.selected ? '0px' : '32px')};
    background: #f5f5f5;
    overflow: hidden;
    max-width: ${props => (props.selected ? '100%' : '600px')};
    max-height: ${props => (props.selected ? '100%' : 'calc(100% - 64px)')};
    .common_modal_title {
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      text-align: center;
      color: #202634;
      padding: 24px;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: ${props => (props.selected ? '100%' : '600px')};
    max-height: ${props => (props.selected ? '100%' : 'calc(100% - 64px)')};
  }
`;

const CloseArea = styled(Box)`
  gap: 16px;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
  position: absolute;
  top: 24px;
  z-index: 1;
  right: 20px;
`;

export interface IPopUp {
  open: boolean;
  onClose: () => void;
  sx?: any;
  children: any;
  className?: string;
  title?: string;
  selected?: boolean;
}

const PopUp = ({
  className,
  children,
  title,
  sx,
  open,
  onClose,
  selected,
  ...props
}: IPopUp) => {
  return (
    <CustomDialog
      open={open}
      className={`common_modal ${className}`}
      selected={selected || false}
      {...props}
    >
      <CloseArea className={'close_btn'} onClick={onClose}>
        <CloseIcon />
      </CloseArea>
      {!!title && (
        <Box className="common_modal_title" component="div">
          {title}
        </Box>
      )}
      <Container className="modal_content">{children}</Container>
    </CustomDialog>
  );
};

export default PopUp;
