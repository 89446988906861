import { useAppDispatch, useAppSelector } from '@/hooks';
import { UserData } from '@/stores/slices/auth/auth';

import {
  setErrorMessages,
  setInfoMessages,
  setSuccessMessages,
  setWarningMessages,
  setUserSessionData,
  openChangePwPopup,
  openSettingPopup,
  setCollaspe
} from '@/stores/slices/global/global';
import { selectGlobal } from '@/stores/slices/global/selector';

const useGlobalHook = () => {
  const {
    errorMessages,
    warningMessages,
    successMessages,
    infoMessages,
    userData,
    openSettingPopUp,
    openChangePwPopUp,
    collaspe
  } = useAppSelector(selectGlobal);
  const dispatch = useAppDispatch();

  const setErrorMsg = (params: (string | undefined)[]) =>
    dispatch(setErrorMessages(params));

  const setWarningMsg = (params: (string | undefined)[]) =>
    dispatch(setWarningMessages(params));

  const setSuccessMsg = (params: (string | undefined)[]) =>
    dispatch(setSuccessMessages(params));

  const setInfoMsg = (params: (string | undefined)[]) =>
    dispatch(setInfoMessages(params));

  const setUserData = (params: UserData) => {
    dispatch(setUserSessionData(params));
  };

  const setSettingsPopUp = (state: boolean) => {
    dispatch(openSettingPopup(state));
  };

  const setChangePwPopUp = (state: boolean) => {
    dispatch(openChangePwPopup(state));
  };

  const setChangeCollaspe = (state: boolean) => {
    dispatch(setCollaspe(state));
  };
  return {
    setErrorMsg,
    setWarningMsg,
    setSuccessMsg,
    setInfoMsg,
    errorMessages,
    warningMessages,
    successMessages,
    infoMessages,
    setUserData,
    userData,
    setSettingsPopUp,
    setChangePwPopUp,
    openSettingPopUp,
    openChangePwPopUp,
    collaspe,
    setChangeCollaspe
  };
};

export default useGlobalHook;
