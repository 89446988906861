import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import AutocompleteComponent from '@/components/form/autocomplete';
import { Box } from '@mui/material';

interface MapProps {
  latitude?: number;
  longitude?: number;
  onMarkerChange?: (latitude: number, longitude: number) => void;
}

const containerStyle = {
  width: '100%',
  height: '400px',
};

const MapComponent: React.FC<MapProps> = ({
  latitude,
  longitude,
  onMarkerChange,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey:
      process.env.REACT_APP_GMAP_API_SECRET_KEY ??
      'AIzaSyBehtYIHuws7LFe_xoiYGCaybdm5P_0fgg',
    libraries: ['places'],
  });

  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<any>(null);

  const onLoad = (map: any) => {
    setMap(map);
  };

  useEffect(() => {
    if (map && latitude !== undefined && longitude !== undefined) {
      map.setCenter({ lat: latitude, lng: longitude });
      marker?.setPosition({ lat: latitude, lng: longitude });
    } else if (!latitude || !longitude) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude: lat, longitude: lng } = position.coords;
          marker?.setPosition({ lat, lng });
          map?.setCenter({ lat, lng });
        },
        error => {
          console.error('Error getting geolocation:', error.message);
        },
      );
    }
  }, [map, latitude, longitude, marker, onMarkerChange]);

  const onMarkerDragEnd = (event: any) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    if (onMarkerChange) {
      onMarkerChange(lat, lng);
    }
  };

  const selectPlace = (address: string, lat: number, lng: number) => {
    onMarkerChange && onMarkerChange(lat, lng);
    marker?.setPosition({ lat, lng });
    map?.setCenter({ lat, lng });
  };

  return isLoaded ? (
    <>
      {onMarkerChange && (
        <Box mt={2} mb={2}>
          <AutocompleteComponent onSelect={selectPlace} />
        </Box>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: latitude || 0, lng: longitude || 0 }}
        zoom={16}
        onLoad={onLoad}
      >
        <Marker
          position={{ lat: latitude || 0, lng: longitude || 0 }}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          onLoad={marker => setMarker(marker)}
        />
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

export default MapComponent;
