import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UserData } from '../auth/auth';
import {
  getCollaspe,
  getUserCachedDataFromLocalStorage,
  setUserCachedDataFromLocalStorage,
} from '@/utils/localStorage';

export interface GlobalState {
  showMessage: boolean;
  successMessages: Array<string | undefined>;
  infoMessages: Array<string | undefined>;
  warningMessages: Array<string | undefined>;
  errorMessages: Array<string | undefined>;
  userData: UserData;
  openSettingPopUp: boolean;
  openChangePwPopUp: boolean;
  collaspe: boolean;
}

const defaultUserInfo = {
  id: 0,
  username: '',
  email: '',
  avatar: '',
  token: '',
};

export const retrieveUserCachedDataFromStorage = () => {
  let userInfo = getUserCachedDataFromLocalStorage();
  if (!userInfo) {
    userInfo = { ...defaultUserInfo };
  }
  return userInfo;
};
const initialState: GlobalState = {
  showMessage: false,
  successMessages: [],
  infoMessages: [],
  warningMessages: [],
  errorMessages: [],
  userData: retrieveUserCachedDataFromStorage(),
  openSettingPopUp: false,
  openChangePwPopUp: false,
  collaspe: getCollaspe() ?? false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setSuccessMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.successMessages = action.payload;
    },
    setInfoMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.infoMessages = action.payload;
    },
    setWarningMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.warningMessages = action.payload;
    },
    setErrorMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.errorMessages = action.payload;
    },
    setUserSessionData: (state, action: PayloadAction<UserData>) => {
      state.userData = {
        ...getUserCachedDataFromLocalStorage(),
        ...action.payload,
      };
      setUserCachedDataFromLocalStorage(state.userData);
    },
    openSettingPopup: (state, action: PayloadAction<boolean>) => {
      state.openSettingPopUp = action.payload;
    },
    openChangePwPopup: (state, action: PayloadAction<boolean>) => {
      state.openChangePwPopUp = action.payload;
    },
    setCollaspe: (state, action: PayloadAction<boolean>) => {
      state.collaspe = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSuccessMessages,
  setInfoMessages,
  setWarningMessages,
  setErrorMessages,
  setUserSessionData,
  openSettingPopup,
  openChangePwPopup,
  setCollaspe,
} = globalSlice.actions;

export default globalSlice.reducer;
