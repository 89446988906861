import { RequestStatus } from '@/constants/API';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ConnectionDetail } from './type';
import { IPageDataResponse } from '@/types/common';

export interface ConnectionState {
  getConnectionListStatus: RequestStatus;
  createConnectionInfoStatus: RequestStatus;
  updateConnectionInfoStatus: RequestStatus;
  deleteConnectionInfoStatus: RequestStatus;
  getConnectionDetailsStatus: RequestStatus;
  connections: IPageDataResponse<ConnectionDetail> | null;
  connectionDetails: ConnectionDetail | null;
}

const initialState: ConnectionState = {
  getConnectionListStatus: RequestStatus.IDLE,
  createConnectionInfoStatus: RequestStatus.IDLE,
  updateConnectionInfoStatus: RequestStatus.IDLE,
  deleteConnectionInfoStatus: RequestStatus.IDLE,
  getConnectionDetailsStatus: RequestStatus.IDLE,
  connections: null,
  connectionDetails: null,
};

export const connectionSlice: any = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    getConnectionListRequest: (state, action: PayloadAction<any>) => {
      state.getConnectionListStatus = RequestStatus.REQUESTING;
    },
    getConnectionListSuccess: (state, action: PayloadAction<any>) => {
      state.getConnectionListStatus = RequestStatus.SUCCESS;
      state.connections = action.payload;
    },
    getConnectionListFailure: state => {
      state.getConnectionListStatus = RequestStatus.ERROR;
    },

    getConnectionDetailsRequest: (state, action: PayloadAction<any>) => {
      state.getConnectionDetailsStatus = RequestStatus.REQUESTING;
    },
    getConnectionDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.getConnectionDetailsStatus = RequestStatus.SUCCESS;
      state.connectionDetails = action.payload;
    },
    getConnectionDetailsFailure: state => {
      state.getConnectionDetailsStatus = RequestStatus.ERROR;
    },
    createConnectionInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createConnectionInfoStatus = RequestStatus.REQUESTING;
    },
    createConnectionInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createConnectionInfoStatus = RequestStatus.SUCCESS;
    },
    createConnectionInfoFailure: (state, action: PayloadAction<any>) => {
      state.createConnectionInfoStatus = RequestStatus.ERROR;
    },
    updateConnectionInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateConnectionInfoStatus = RequestStatus.REQUESTING;
    },
    updateConnectionInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateConnectionInfoStatus = RequestStatus.SUCCESS;
    },
    updateConnectionInfoFailure: (state, action: PayloadAction<any>) => {
      state.updateConnectionInfoStatus = RequestStatus.ERROR;
    },
    deleteConnectionInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteConnectionInfoStatus = RequestStatus.REQUESTING;
    },
    deleteConnectionInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteConnectionInfoStatus = RequestStatus.SUCCESS;
    },
    deleteConnectionInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteConnectionInfoStatus = RequestStatus.ERROR;
    },
    resetConnectionInfoRequestStatus: state => {
      state.getConnectionListStatus = RequestStatus.IDLE;
      state.createConnectionInfoStatus = RequestStatus.IDLE;
      state.updateConnectionInfoStatus = RequestStatus.IDLE;
      state.deleteConnectionInfoStatus = RequestStatus.IDLE;
      state.getConnectionDetailsStatus = RequestStatus.IDLE;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getConnectionListRequest,
  getConnectionListSuccess,
  getConnectionListFailure,
  createConnectionInfoRequest,
  createConnectionInfoSuccess,
  createConnectionInfoFailure,
  updateConnectionInfoRequest,
  updateConnectionInfoSuccess,
  updateConnectionInfoFailure,
  deleteConnectionInfoRequest,
  deleteConnectionInfoSuccess,
  deleteConnectionInfoFailure,
  getConnectionDetailsRequest,
  getConnectionDetailsSuccess,
  getConnectionDetailsFailure,
  resetConnectionInfoRequestStatus,
} = connectionSlice.actions;

export default connectionSlice.reducer;
