/* eslint-disable max-lines */
import { ACTION_OPTIONS } from '@/constants';
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useMemo } from 'react';
import { DownIcon } from '../icons';
import useCustomerHook from '@/hooks/useCustomerHook';
import useVenueHook from '@/hooks/useVenueHook';
import { VenueDetail } from '@/stores/slices/venue/type';
import Path from '@/constants/path';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaListUl } from 'react-icons/fa';
import useGlobalHook from '@/hooks/useGlobalHook';

export interface ISelectOptionType {
  label: string;
  value: number | string;
  id: number;
}

export enum ActionType {
  GENERIC_ACTION = 1,
  CUSTOM = 2,
}
export interface IActionMenu {
  options?: ISelectOptionType[];
  onSelect?: (val: number) => void;
  className?: string;
  type?: ActionType;
  disabled?: boolean;
}

const ActionMenu = ({
  options = ACTION_OPTIONS,
  onSelect,
  className,
  type = ActionType.GENERIC_ACTION,
  disabled = false,
}: IActionMenu) => {
  const params = useParams();
  const { t } = useTranslation();
  const { collaspe } = useGlobalHook();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const { allCustomers }: any = useCustomerHook();
  const { venueOptions } = useVenueHook();

  const renderVenueList = useMemo(() => {
    const customerList: any[] = [];
    allCustomers?.forEach((item: any) => {
      customerList.push({
        ...item,
        venues: venueOptions.filter(
          (venue: any) => venue?.customer === item.id,
        ),
      });
    });

    return customerList
      .filter(it => it.venues?.length > 0)
      ?.map(it => (
        <MenuItemWrapper key={it.id}>
          <Text>{it.name}</Text>
          <Box>
            {it.venues?.map((ve: VenueDetail) => (
              <CustomLink
                key={ve.id}
                to={`${Path.CUSTOMERS}/${it.id}${Path.VENUE}/${ve.id}${Path.TOP}`}
                onClick={handleClose}
              >
                {ve.name}
              </CustomLink>
            ))}
          </Box>
        </MenuItemWrapper>
      ));
  }, [allCustomers, venueOptions]);

  const renderGenericMenu = useMemo(() => {
    return options?.map((it, idx) => (
      <CustomMenuItem
        key={idx}
        onClick={event => {
          handleClose(event);
          onSelect && onSelect(it.id);
        }}
      >
        {t(it.label)}
      </CustomMenuItem>
    ));
  }, [onSelect, options, t]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box className={`${className}`} sx={{ zIndex: 10 }}>
      {collaspe ? (
        <MiniButtonOption
          onClick={handleToggle}
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
        >
          <FaListUl />
        </MiniButtonOption>
      ) : (
        <ButtonOption
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={
            type === ActionType.CUSTOM ? 'custom' : disabled ? ' disable' : ''
          }
        >
          {type === ActionType.GENERIC_ACTION ? (
            t('Action')
          ) : params?.venueId ? (
            <ClipText>
              {venueOptions.find((it: any) => it.id === params?.venueId)?.name}
            </ClipText>
          ) : (
            ''
          )}
          <DownIcon />
        </ButtonOption>
      )}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ width: type === ActionType.GENERIC_ACTION ? '100px' : '248px' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ padding: 0 }}
                >
                  {type === ActionType.GENERIC_ACTION
                    ? renderGenericMenu
                    : renderVenueList}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

const ButtonOption = styled(Button)`
  && {
    display: flex;
    width: 100px;
    padding: 8px 10px;
    justify-content: flex-start;
    color: #d0d0d0;
    border: 1px solid #3080ec;
    align-items: center;
    gap: 10px;
    text-transform: none;
    color: #111111;
    position: relative;
    svg {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    &.custom {
      width: 163px;
      padding: 10px 16px;
      svg {
        top: 16px;
      }
    }
    &.disable {
      opacity: 0.2;
      pointer-events: none;
    }
  }
`;
export const CustomLink = styled(Link)`
  && {
    display: block;
    font-size: 14px;
    padding: 8px 16px;
    text-align: left;
    color: #3080ec;
    line-height: 18px;
    text-decoration: none;
    word-break: break-word;

    &:hover {
      text-decoration: underline;
      background: #fafafa;
    }
  }
`;

export const Text = styled(Typography)`
  && {
    font-size: 14px;
    color: #111111;
    line-height: 18px;
    padding: 10px 16px;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  && {
    &.disable {
      opacity: 0.3;
      cursor: disabled;
    }
  }
`;

export const MenuItemWrapper = styled(MenuItem)`
  display: block;
  border-bottom: 1px solid #eaeded;
  background: #fff;
  white-space: normal;
  padding: 6px 0;
  &:hover {
    background: #fff;
  }
  p.MuiTypography-root {
    padding: 0 16px;
    font-size: 12px;
    font-weight: 600;
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const ClipText = styled(Box)`
  display: inline-block;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
`;
export default ActionMenu;

const MiniButtonOption = styled(Button)`
  font-size: 20px;
  height: 46px;
  border-bottom: 1px solid #3080ec;
  border-radius: 0;
  min-width: auto;
  width: 100%;
`;
