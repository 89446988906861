import React from 'react';
import styled from 'styled-components';
import { InputType } from './customSelect';
import { Label } from './input';

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  isRequired?: boolean;
  width?: number;
  inputType?: InputType;
}

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
`;

const ErrorMessage = styled.span`
  color: #e34a66;
  font-size: 10px;
  line-height: 14px;
`;

const AreaField = styled.textarea<{
  $hasError?: boolean;
  $width?: number;
  $inputType?: InputType;
}>`
  font-family: 'Poppins';
  width: ${props => (props.$width ? props.$width - 24 + 'px' : '100%')};
  padding: 8px 12px;
  border: 1px solid ${props => (props.$hasError ? 'red' : '#ccc')};
  border-radius: 2px;
  border: ${props =>
    props.$inputType === InputType.MAPPING ? 'none' : '1px solid #dee1eb'};
  background: ${props =>
    props.$inputType === InputType.MAPPING ? '#40495C' : '#fff'};
  color: ${props =>
    props.$inputType === InputType.MAPPING ? '#fff' : '#222222 '};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  &:focus {
    outline: none;
  }
`;

const TextArea: React.FC<TextAreaProps> = ({
  label,
  error,
  isRequired = false,
  width = 0,
  rows = 3,
  inputType = InputType.POP_UP,
  ...textAreaProps
}) => {
  return (
    <InputContainer>
      {label && (
        <Label $fromType={inputType}>
          {label}
          {isRequired && <span>*</span>}
        </Label>
      )}
      <InputWrapper>
        <AreaField
          {...textAreaProps}
          $hasError={!!error}
          $width={width}
          rows={rows}
          $inputType={inputType}
        />
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputContainer>
  );
};

export default TextArea;
