export const FloorPlanIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="floor-plan">
      <path
        id="Subtract"
        d="M8.00003 8V3H17V7H10V13H11V8H17V17H11V15H10V17H3.00003V12H7.00003V15H8.00003V10H7.00003V11H3.00003V3H7.00003V8H8.00003Z"
        fill="#555C6A"
      />
    </g>
  </svg>
);
