import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  getFloorLevelsRequest,
  getFloorLevelsSuccess,
  getFloorLevelsFailure,
  getFloorListRequest,
  getFloorListSuccess,
  getFloorListFailure,
  getFloorListOptionRequest,
  getFloorListOptionSuccess,
  getFloorListOptionFailure,
  getFloorDetailsRequest,
  getFloorDetailsSuccess,
  getFloorDetailsFailure,
  createFloorInfoRequest,
  createFloorInfoSuccess,
  createFloorInfoFailure,
  updateFloorInfoRequest,
  updateFloorInfoSuccess,
  updateFloorInfoFailure,
  deleteFloorInfoRequest,
  deleteFloorInfoSuccess,
  deleteFloorInfoFailure,
  updateFloorPublishFailure,
  updateFloorPublishRequest,
  updateFloorPublishSuccess,
  deleteMultiFloorInfoFailure,
  deleteMultiFloorInfoRequest,
  deleteMultiFloorInfoSuccess,
} from '../slices/floor/floor';

type IPayload = Record<string, any>;

function* getFloorListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getFloors, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getFloorListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getFloorListFailure.type });
    }
  } catch (error) {
    yield put({ type: getFloorListFailure.type });
  }
}

function* getFloorListOptionFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getFloorsOption, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getFloorListOptionSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getFloorListOptionFailure.type });
    }
  } catch (error) {
    yield put({ type: getFloorListOptionFailure.type });
  }
}

function* getFloorLevelsFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getFloorLevels, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getFloorLevelsSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getFloorLevelsFailure.type });
    }
  } catch (error) {
    yield put({ type: getFloorLevelsFailure.type });
  }
}

function* getFloorDetailsFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getFloorDetails, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getFloorDetailsSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getFloorDetailsFailure.type });
    }
  } catch (error) {
    yield put({ type: getFloorDetailsFailure.type });
  }
}

function* createFloorFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createFloor, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createFloorInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createFloorInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createFloorInfoFailure.type });
  }
}

function* updateFloorFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateFloor, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateFloorInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateFloorInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: updateFloorInfoFailure.type });
  }
}

function* updateMultiFloorFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updatePublishStatusFloor, payload);

    if (
      response.status === StatusCode.SUCCESS_COMMON ||
      response.status === StatusCode.SUCCESS_CREATED
    ) {
      yield put({
        type: updateFloorPublishSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateFloorPublishFailure.type });
    }
  } catch (error) {
    yield put({ type: updateFloorPublishFailure.type });
  }
}

function* deleteFloorFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteFloor, payload);

    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteFloorInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteFloorInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteFloorInfoFailure.type });
  }
}

function* deleteMultiFloorFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteFloors, payload);

    if (
      response.status === StatusCode.SUCCESS_COMMON ||
      response.status === StatusCode.SUCCESS_CREATED
    ) {
      yield put({
        type: deleteMultiFloorInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteMultiFloorInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteMultiFloorInfoFailure.type });
  }
}

function* floorSaga() {
  yield takeLatest(getFloorLevelsRequest, getFloorLevelsFlow);
  yield takeLatest(getFloorListRequest, getFloorListFlow);
  yield takeLatest(getFloorListOptionRequest, getFloorListOptionFlow);
  yield takeLatest(createFloorInfoRequest, createFloorFlow);
  yield takeLatest(updateFloorInfoRequest, updateFloorFlow);
  yield takeLatest(updateFloorPublishRequest, updateMultiFloorFlow);
  yield takeLatest(deleteFloorInfoRequest, deleteFloorFlow);
  yield takeLatest(deleteMultiFloorInfoRequest, deleteMultiFloorFlow);
  yield takeLatest(getFloorDetailsRequest, getFloorDetailsFlow);
}

export default floorSaga;
