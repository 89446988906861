import { Layout } from '@/components/layouts';
import Path from '@/constants/path';
import useGlobalHook from '@/hooks/useGlobalHook';
import { getToken } from '@/utils/localStorage';
import { Navigate } from 'react-router-dom';
import { ChangePw } from '../layouts/header/changepw';
import { Settings } from '../layouts/header/settings';

export interface ProtectedRouteProps {
  children: any;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const token = getToken();
  const {
    setSettingsPopUp,
    openSettingPopUp,
    openChangePwPopUp,
    setChangePwPopUp,
  } = useGlobalHook();

  if (!token) {
    return <Navigate to={Path.LOGIN} replace />;
  }

  return (
    <Layout>
      {children}
      <Settings
        open={openSettingPopUp}
        onClose={() => setSettingsPopUp(false)}
      />
      <ChangePw
        open={openChangePwPopUp}
        onClose={() => setChangePwPopUp(false)}
      />
    </Layout>
  );
};

export default ProtectedRoute;
