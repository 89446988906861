enum Path {
  LOGIN = '/login',
  HOME = '/',
  MAPPING = '/venues/:venueId/floor-plans/:floorId/mappings',
  FLOOR_PLAN = '/floor-plans',
  VENUE = '/venues',
  TOP = '/top',
  ANALYTICS = '/analytics',
  VIEWER = '/viewer',
  LOCATION = '/locations',
  EVENT = '/events',
  AMENITY = '/amenities',
  CATEGORY = '/categories',
  SNAPSHOT = '/snapshots',
  CUSTOMER_VENUE_LOCATION = '/customers/:customerId/venues/:venueId/locations',
  CUSTOMER_VENUE_EVENT = '/customers/:customerId/venues/:venueId/events',
  CUSTOMER_VENUE_ADD_EVENT = '/customers/:customerId/venues/:venueId/events/add',
  CUSTOMER_VENUE_EDIT_EVENT = '/customers/:customerId/venues/:venueId/events/edit/:eventId',
  CUSTOMER_VENUE_AMENITY = '/customers/:customerId/venues/:venueId/amenities',
  CUSTOMER_VENUE_CATEGORY = '/customers/:customerId/venues/:venueId/categories',
  CUSTOMER_VENUE_TOP = '/customers/:customerId/venues/:venueId/top',
  CUSTOMER_VENUE_ANALYTICS = '/customers/:customerId/venues/:venueId/analytics',
  CUSTOMER_VENUE_VIEWER = '/customers/:customerId/venues/:venueId/viewer',
  CUSTOMER_VENUE_SNAPSHOT = '/customers/:customerId/venues/:venueId/snapshots',
  CUSTOMERS = '/customers',
  CUSTOMERS_VENUE = '/customers/:customerId/venues',
  CONNECTIONS = '/connections',
  CUSTOMER_VENUE_CONNECTIONS = '/customers/:customerId/venues/:venueId/connections',
  MAP = '/mappings',
  SETTINGS = '/settings',
  VENUE_FLOOR = '/venues/:venueId/floor-plans',
  CUSTOMER_VENUE_FLOOR = '/customers/:customerId/venues/:venueId/floor-plans',
}

export default Path;
