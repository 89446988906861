import styled from 'styled-components';
import CustomButton, { CustomButtonType } from './Button';
import ActionMenu from './ActionMenu';

export enum FilterType {
  GENERIC = 'GENERIC',
}

export interface FilterBarType {
  type: FilterType;
  buttonText: string;
  // onExport: () => void;
  onAdd: () => void;
  onSelectAction?: (id: number) => void;
  children: any;
  hasAction?: boolean;
  hasButton?: boolean;
  disabled?: boolean;
}

const Bar = styled.div`
  margin: 30px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  min-width: calc(100vw - 220px);
  > div {
    margin-bottom: 0;
  }
  .MuiInputBase-root {
    min-width: 200px;
  }
  .gap {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const FilterBar = ({
  type,
  buttonText,
  onAdd,
  // onExport,
  hasAction = false,
  disabled = false,
  hasButton = false,
  onSelectAction,
  children,
}: FilterBarType) => {
  return (
    <Bar>
      {children}
      <ButtonWrapper>
        {/* <CustomButton
          buttonType={CustomButtonType.EXPORT_ICON}
          title={`Export`}
          onClick={onExport}
        ></CustomButton> */}
        {hasAction && (
          <ActionMenu
            onSelect={(id: number) => onSelectAction && onSelectAction(id)}
            disabled={disabled}
          />
        )}

        {hasButton && (
          <CustomButton
            buttonType={CustomButtonType.ADD_ICON}
            title={buttonText}
            onClick={onAdd}
          ></CustomButton>
        )}
      </ButtonWrapper>
    </Bar>
  );
};
