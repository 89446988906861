import { RequestStatus } from '@/constants/API';
import { CustomerForm } from '@/components/customer/components';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CustomerDetail } from './type';
import { IPageDataResponse } from '@/types/common';

export interface CustomerState {
  getCustomerListStatus: RequestStatus;
  getAllCustomerListStatus: RequestStatus;
  createCustomerInfoStatus: RequestStatus;
  updateCustomerInfoStatus: RequestStatus;
  deleteCustomerInfoStatus: RequestStatus;
  getCustomerDetailsStatus: RequestStatus;
  customers: IPageDataResponse<CustomerDetail> | null;
  customerDetails: CustomerDetail | null;
  allCustomers: { id: number | string; name: string }[];
}

export interface UserData {
  id: number;
  fullname: string;
  avatar: string;
  token: string;
}

const initialState: CustomerState = {
  getCustomerListStatus: RequestStatus.IDLE,
  getAllCustomerListStatus: RequestStatus.IDLE,
  createCustomerInfoStatus: RequestStatus.IDLE,
  updateCustomerInfoStatus: RequestStatus.IDLE,
  deleteCustomerInfoStatus: RequestStatus.IDLE,
  getCustomerDetailsStatus: RequestStatus.IDLE,
  customers: null,
  customerDetails: null,
  allCustomers: [],
};

export const customerSlice: any = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomerListRequest: (state, action: PayloadAction<CustomerForm>) => {
      state.getCustomerListStatus = RequestStatus.REQUESTING;
    },
    getCustomerListSuccess: (state, action: PayloadAction<any>) => {
      state.getCustomerListStatus = RequestStatus.SUCCESS;
      state.customers = action.payload;
    },
    getCustomerListFailure: state => {
      state.getCustomerListStatus = RequestStatus.ERROR;
    },
    getAllCustomerListRequest: (state, action: PayloadAction<CustomerForm>) => {
      state.getAllCustomerListStatus = RequestStatus.REQUESTING;
    },
    getAllCustomerListSuccess: (state, action: PayloadAction<any>) => {
      state.getAllCustomerListStatus = RequestStatus.SUCCESS;
      state.allCustomers = action.payload;
    },
    getAllCustomerListFailure: state => {
      state.getAllCustomerListStatus = RequestStatus.ERROR;
    },
    getCustomerDetailsRequest: (state, action: PayloadAction<CustomerForm>) => {
      state.getCustomerDetailsStatus = RequestStatus.REQUESTING;
    },
    getCustomerDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.getCustomerDetailsStatus = RequestStatus.SUCCESS;
      state.customerDetails = action.payload;
    },
    getCustomerDetailsFailure: state => {
      state.getCustomerDetailsStatus = RequestStatus.ERROR;
    },
    createCustomerInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createCustomerInfoStatus = RequestStatus.REQUESTING;
    },
    createCustomerInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createCustomerInfoStatus = RequestStatus.SUCCESS;
    },
    createCustomerInfoFailure: (state, action: PayloadAction<any>) => {
      state.createCustomerInfoStatus = RequestStatus.ERROR;
    },
    updateCustomerInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateCustomerInfoStatus = RequestStatus.REQUESTING;
    },
    updateCustomerInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateCustomerInfoStatus = RequestStatus.SUCCESS;
    },
    updateCustomerInfoFailure: (state, action: PayloadAction<any>) => {
      state.updateCustomerInfoStatus = RequestStatus.ERROR;
    },
    deleteCustomerInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteCustomerInfoStatus = RequestStatus.REQUESTING;
    },
    deleteCustomerInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteCustomerInfoStatus = RequestStatus.SUCCESS;
    },
    deleteCustomerInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteCustomerInfoStatus = RequestStatus.ERROR;
    },
    resetCustomerInfoRequestStatus: state => {
      state.getCustomerListStatus = RequestStatus.IDLE;
      state.getAllCustomerListStatus = RequestStatus.IDLE;
      state.createCustomerInfoStatus = RequestStatus.IDLE;
      state.updateCustomerInfoStatus = RequestStatus.IDLE;
      state.deleteCustomerInfoStatus = RequestStatus.IDLE;
      state.getCustomerDetailsStatus = RequestStatus.IDLE;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getCustomerListRequest,
  getCustomerListSuccess,
  getCustomerListFailure,
  createCustomerInfoRequest,
  createCustomerInfoSuccess,
  createCustomerInfoFailure,
  updateCustomerInfoRequest,
  updateCustomerInfoSuccess,
  updateCustomerInfoFailure,
  deleteCustomerInfoRequest,
  deleteCustomerInfoSuccess,
  deleteCustomerInfoFailure,
  getCustomerDetailsRequest,
  getCustomerDetailsSuccess,
  getCustomerDetailsFailure,
  getAllCustomerListRequest,
  getAllCustomerListFailure,
  getAllCustomerListSuccess,
  resetCustomerInfoRequestStatus,
} = customerSlice.actions;

export default customerSlice.reducer;
