import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const TwoInputContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  gap: 20px;
  &.mt_10 {
    margin-top: 10px;
  }
  > div {
    width: calc(50% - 10px);
  }
`;

export const OneInputContainer = styled.div`
  width: 100%;
  &.mt_10 {
    margin-top: 10px;
  }
`;
