import { DEFAULT_NULL_INDEX } from 'src/constants';

import { CommonType } from '@/stores/slices/amenity/type';
import { EventDetail } from '@/stores/slices/event/type';
import { LocationDetail } from '@/stores/slices/location/type';
import { QRCodeDetail } from '@/stores/slices/qrcode/types';
import { VenueDetail } from '@/stores/slices/venue/type';
import dayjs from 'dayjs';
import _ from 'lodash';
import { RequestStatus } from 'src/constants/API';
import {
  ErrorsType,
  IImageValidation,
  ImageListType,
  ResolutionType,
} from 'src/types';

export const isValidEmail = (email: string) => {
  // Basic email format validation using a regular expression
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const checkIsRequesting = (statuses: string[]) => {
  let isRequesting = false;
  statuses.forEach((status: string) => {
    if (status === RequestStatus.REQUESTING) {
      isRequesting = true;
      return;
    }
  });
  return isRequesting;
};

export const isImage = (src = ''): boolean => {
  const dotIndex = src.lastIndexOf('.');
  const fileExtension = src.substring(dotIndex);
  const regex = RegExp(/(gif|jpg|jpeg|tiff|png|heic|heif)$/, 'gim');
  return !!regex.exec(fileExtension);
};

export const isResolutionValid = (
  image: HTMLImageElement,
  resolutionType: ResolutionType,
  resolutionWidth: number = 0,
  resolutionHeight: number = 1,
): boolean => {
  if (!resolutionWidth || !resolutionHeight || !image.width || !image.height)
    return true;
  switch (resolutionType) {
    case 'absolute': {
      if (image.width === resolutionWidth && image.height === resolutionHeight)
        return true;
      break;
    }
    case 'ratio': {
      const ratio = resolutionWidth / resolutionHeight;
      if (image.width / image.height === ratio) return true;
      break;
    }
    case 'less': {
      if (image.width <= resolutionWidth && image.height <= resolutionHeight)
        return true;
      break;
    }
    case 'more': {
      if (image.width >= resolutionWidth && image.height >= resolutionHeight)
        return true;
      break;
    }
    default:
      break;
  }
  return false;
};

export const isImageValid = (fileType: string) => {
  if (fileType.includes('image')) {
    return true;
  }
  return false;
};

export const isMaxFileSizeValid = (fileSize: number, maxFileSize?: number) => {
  return maxFileSize ? fileSize <= maxFileSize : true;
};

export const isAcceptTypeValid = (
  acceptType: string[] | undefined,
  fileName: string,
) => {
  if (acceptType && acceptType.length > 0) {
    const type: string = fileName.split('.').pop() || '';
    if (
      acceptType.findIndex(item => item.toLowerCase() === type.toLowerCase()) <
      0
    )
      return false;
  }
  return true;
};

export const isMaxNumberValid = (
  totalNumber: number,
  maxNumber: number,
  keyUpdate: number,
) => {
  if (maxNumber !== 0 && !maxNumber) return true;
  if (keyUpdate === DEFAULT_NULL_INDEX) {
    if (totalNumber <= maxNumber) return true;
  } else if (totalNumber <= maxNumber + 1) return true;
  return false;
};

export const openFileDialog = (inputRef: any): void => {
  if (inputRef.current) inputRef.current.click();
};

export const getAcceptTypeString = (
  acceptType?: Array<string>,
  allowNonImageType?: boolean,
) => {
  if (acceptType?.length) return acceptType.map(item => `.${item}`).join(', ');
  if (allowNonImageType) return '';
  return 'image/*';
};

export const getBase64 = (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise(resolve => {
    reader.addEventListener('load', () => resolve(String(reader.result)));
    reader.readAsDataURL(file);
  });
};

export const getImage = (file: File): Promise<HTMLImageElement> => {
  const image = new Image();
  return new Promise(resolve => {
    image.addEventListener('load', () => resolve(image));
    image.src = URL.createObjectURL(file);
  });
};

export const getListFiles = (
  files: FileList,
  dataURLKey: string,
): Promise<ImageListType> => {
  const promiseFiles: Array<Promise<string>> = [];
  for (let i = 0; i < files.length; i += 1) {
    promiseFiles.push(getBase64(files[i]));
  }
  return Promise.all(promiseFiles).then((fileListBase64: Array<string>) => {
    const fileList: ImageListType = fileListBase64.map((base64, index) => ({
      [dataURLKey]: base64,
      file: files[index],
    }));
    return fileList;
  });
};
export const getErrorValidation = async ({
  fileList,
  value,
  maxNumber,
  keyUpdate,
  acceptType,
  maxFileSize,
  resolutionType,
  resolutionWidth,
  resolutionHeight,
  allowNonImageType,
}: IImageValidation): Promise<ErrorsType> => {
  const newErrors: ErrorsType = {};
  if (!isMaxNumberValid(fileList.length + value.length, maxNumber, keyUpdate)) {
    newErrors.maxNumber = true;
  } else {
    for (let i = 0; i < fileList.length; i += 1) {
      const { file } = fileList[i];
      if (!file) continue;
      if (!allowNonImageType && !isImageValid(file.type)) {
        newErrors.acceptType = true;
        break;
      }
      if (!isAcceptTypeValid(acceptType, file.name)) {
        newErrors.acceptType = true;
        break;
      }
      if (!isMaxFileSizeValid(file.size, maxFileSize)) {
        newErrors.maxFileSize = true;
        break;
      }
      if (resolutionType) {
        const image = await getImage(file);
        const checkRes = isResolutionValid(
          image,
          resolutionType,
          resolutionWidth,
          resolutionHeight,
        );
        if (!checkRes) {
          newErrors.resolution = true;
          break;
        }
      }
    }
  }
  if (Object.values(newErrors).find(Boolean)) return newErrors;
  return null;
};

export const getDefaultWorkHours = () => {
  const currentDay = dayjs(new Date()).format('YYYY-MM-DD');
  const startTime = dayjs(new Date(currentDay + ' 09:00:00')).format();
  const endTime = dayjs(new Date(currentDay + ' 21:00:00')).format();

  return [
    {
      day: 'Monday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Tuesday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Wednesday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Thursday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Friday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Saturday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Sunday',
      open: startTime,
      close: endTime,
      selected: true,
    },
  ];
};

export const getDefaultLocationForm = () => {
  return {
    category: '',
    map: '',
    name: '',
    state: 1,
    status: 1,
    twitter: '',
    amenity: '',
    facebook: '',
    website: '',
    categories: [],
    tags: [],
    phone: '',
    workHours: getDefaultWorkHours(),
    originalLogo: '',
    images: [],
    template: '',
  };
};

export const getDefaultQRCodeForm = () => {
  return {
    level: '',
    location: '',
    lat: 0,
    lng: 0,
    angle: 0,
  };
};

export const getDefaultEventForm = () => {
  return {
    title: '',
    description: '',
    contentDetail: '',
    bannerImage: '',
    iconImage: '',
    contentUrl: '',
    startTime: '',
    endTime: '',
    showStartTime: '',
    showEndTime: '',
    type: 1,
    tags: [],
    images: [],
    locations: [],
  };
};

export const defaultPropertyFormValue = () => ({
  latitude: '',
  longitude: '',
  name: '',
  text: '',
  id: '1',
  external_id: 1,
  asset: 1,
  location: '',
  location_name: '',
  fontFamily: 1,
  font_style: 'normal',
  fontSize: '24',
  height: 100,
  altitude: 100,
  belvel_size: 3,
  belvel_thickness: 0.3,
  belvel_segment: 1,
  belvel_offset: 20,
  color: '#fff',
  strokeColor: '#fff',
  opacity: 0.2,
  images: [],
  locked: false,
  isDeleted: false,
  edge_offset: 0,
  main_path: false,
  connection_group: null,
});

export const defaultCameraFormValue = () => ({
  hemisphone: '',
  directional: '',
  perspective: '',
  position_x: '',
  position_y: '',
  position_z: '',
  rotation_x: '',
  rotation_y: '',
  rotation_z: '',
  width: '',
  height: '',
  extrusion_scale: '',
  render_sampling: '',
});

export const options = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
];

export function deepEqual(obj1: Object | any, obj2: Object | any) {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 == 'object' &&
    obj1 != null &&
    typeof obj2 == 'object' &&
    obj2 != null
  ) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!obj2.hasOwnProperty(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  return false;
}

export const isHTMLSVGElement = (node: Node | null): node is SVGElement => {
  // lower-casing due to XML/HTML convention differences
  // https://johnresig.com/blog/nodename-case-sensitivity
  return node?.nodeName.toLowerCase() === 'svg';
};

export const normalizeSVG = async (SVGString: string) => {
  const SVG_NS = 'http://www.w3.org/2000/svg';

  const doc = new DOMParser().parseFromString(SVGString, 'image/svg+xml');
  const svg = doc.querySelector('svg');
  const errorNode = doc.querySelector('parsererror');
  if (errorNode || !isHTMLSVGElement(svg)) {
    throw new Error('invalid svg');
  } else {
    if (!svg.hasAttribute('xmlns')) {
      svg.setAttribute('xmlns', SVG_NS);
    }

    if (!svg.hasAttribute('width') || !svg.hasAttribute('height')) {
      const viewBox = svg.getAttribute('viewBox');
      let width = svg.getAttribute('width') || '50';
      let height = svg.getAttribute('height') || '50';
      if (viewBox) {
        const match = viewBox.match(/\d+ +\d+ +(\d+) +(\d+)/);
        if (match) {
          [, width, height] = match;
        }
      }
      svg.setAttribute('width', width);
      svg.setAttribute('height', height);
    }

    return svg.outerHTML;
  }
};

export const transformSelectOptions = (
  data: Record<string, any>[],
  isCountry = false,
) => {
  return data?.map(it => ({
    value: isCountry ? it.name : it.id,
    label: it.name,
    title: it.name,
  }));
};

export const transformLocationSelectOptions = (
  data: Record<string, any>[],
  isCountry = false,
) => {
  return data?.map(it => ({
    value: isCountry ? it.name : it.id,
    label: it.common_name ? it.common_name : it.id,
    title: it.common_name ? it.common_name : it.id,
  }));
};

export const transformSelectOptionsWithTranslation = (
  data: Record<string, any>[],
  cb: (str: string) => string,
) => {
  return data?.map(it => ({
    value: it.value,
    label: cb(it.label),
    title: cb(it.title),
  }));
};

export const getCountryFromVenueOptions = (data: Record<string, any>[]) => {
  const countries: string[] = [];

  data.forEach((it: any) => {
    if (!countries.includes(it.country) && it.country) {
      countries.push(it.country);
    }
  });

  return countries.map(it => ({
    value: it,
    label: it,
    title: it,
  }));
};

export const formatWorkHours = (dates: any[]) => {
  return dates
    ?.filter(it => it.selected)
    ?.map(it => ({
      day: it.day,
      open: dayjs(new Date(it.open)).format('HH:mm'),
      close: dayjs(new Date(it.close)).format('HH:mm'),
    }));
};

export const formatWorkingTime = (date: string) => {
  return dayjs(new Date(date)).format('HH:mm');
};

export const transformWokHours = (
  data: any,
  format: boolean = false,
  notShownFull: boolean = false,
  cb: any = null,
) => {
  const currentDay = dayjs(new Date()).format('YYYY-MM-DD');
  const startTime = format
    ? '09:00'
    : dayjs(new Date(currentDay + ' 09:00:00')).format();
  const endTime = format
    ? '21:00'
    : dayjs(new Date(currentDay + ' 21:00:00')).format();
  const weekDays = format
    ? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    : [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];
  if (notShownFull) {
    return weekDays
      .filter(it => _.find(data?.workHours, item => item.day?.includes(it)))
      ?.map(day => {
        const found = _.find(data?.workHours, item => item.day?.includes(day));
        return {
          day: cb ? cb(day) : day,
          open: `${found?.open}`,
          close: `${found?.close}`,
        };
      });
  }

  return weekDays.map(it => {
    const found = _.find(data?.workHours, item => item.day === it);
    if (found) {
      return {
        day: cb ? cb(it) : it,
        open: format
          ? `${found.open}`
          : dayjs(new Date(`${currentDay} ${found.open}:00`)).format(),
        close: format
          ? `${found.close}`
          : dayjs(new Date(`${currentDay} ${found.close}:00`)).format(),
        selected: true,
      };
    } else {
      return {
        day: cb ? cb(it) : it,
        open: startTime,
        close: endTime,
        selected: false,
      };
    }
  });
};

export const transformVenueDetails = (data: VenueDetail) => {
  return {
    ..._.omit(data, ['logo']),
    publish: data?.publish,
    originalLogo: data?.logo?.original ?? null,
    customer: data?.customer?.id,
    workHours: transformWokHours(data),
  };
};

export const transformLocationDetails = (data: LocationDetail) => {
  return {
    ..._.omit(data, ['logo']),
    category: data.category?.id,
    workHours: transformWokHours(data),
    images: data.images?.map(it => it.original) ?? [],
    originalLogo: data?.logo?.original,
    categories: data.categories?.map(it => it.id) ?? [],
    tags: data.tags?.map(it => it.id) ?? [],
    level: data?.level?.id,
    template: data?.template?.id,
  };
};

export const transformQRCodeDetail = (data: QRCodeDetail) => {
  return {
    ...data,
    location: data?.location.id,
    level: data?.level.id,
    lat: data?.lat,
    lng: data?.lng,
    angle: data?.angle,
  };
};

export const transformEventDetails = (data: EventDetail) => {
  return {
    ...data,
    startTime: data.startTime ? formatDateTime(data.startTime) : undefined,
    endTime: data.endTime ? formatDateTime(data.endTime) : undefined,
    showStartTime: data.showStartTime
      ? formatDateTime(data.showStartTime)
      : undefined,
    showEndTime: data.showEndTime
      ? formatDateTime(data.showEndTime)
      : undefined,
    images: data.images?.map(it => it.image) ?? [],
    tags: data.tags?.map(it => it.id) ?? [],
    locations: data.locations?.map(it => it.id) ?? [],
  };
};

export const getBase64FromUrl = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const formatDateTime = (
  dateTime: string,
  format: string = 'YYYY-MM-DD HH:mm',
) => {
  return dayjs(new Date(dateTime)).format(format);
};

export const formatAmenity = (
  amenities: CommonType[],
  response: CommonType[],
): any[] => {
  return amenities?.map((it: any) => ({
    id: it.id,
    name: it.name,
    icon: it.icon,
    selected: !!response?.find((res: any) => res.id === it.id),
  }));
};
