import { Box } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CalendarIcon } from '../icons';
import { InputType } from './customSelect';
import { ErrorMessage, Label } from './input';

export interface CustomDateTimePickerProps {
  value?: any;
  inputFormat?: string;
  label?: string;
  max?: number;
  min?: number;
  onChange: (value: any) => void;
  onAccept?: (value: any) => void;
  inputType?: InputType;
  disabled?: boolean;
  isRequired?: boolean;
  format?: string;
  errorMsg?: string;
}

const CustomDateTime = styled(DateTimePicker)<{ $inputType?: InputType }>`
  .MuiOutlinedInput-input {
    border: none;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px;
    background: ${props =>
      props.$inputType === InputType.MAPPING ? '#40495C' : '#fff'};
    color: ${props =>
      props.$inputType === InputType.MAPPING ? '#fff' : '#222222'};
    border: ${props =>
      props.$inputType === InputType.MAPPING ? 'none' : '1px solid #dee1eb'};
  }
  fieldset {
    border: none !important;
  }
  .MuiButtonBase-root svg {
    width: 24px;
    height: 24px;
  }
`;

export default function CustomDateTimePicker({
  value,
  inputFormat = 'MM/dd/yyyy hh-mm',
  label = '',
  onChange,
  max = 22,
  min = 9,
  inputType = InputType.POP_UP,
  disabled,
  isRequired,
  onAccept,
  errorMsg,
  ...rest
}: CustomDateTimePickerProps) {
  const [val, setVal] = useState<any>(value ? dayjs(value) : null);
  useEffect(() => {
    setVal(value ? dayjs(value) : null);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="div">
        {label && (
          <Label $fromType={inputType}>
            {label} {isRequired && <span>*</span>}
          </Label>
        )}
        <CustomDateTime
          $inputType={inputType}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          slotProps={{ textField: { size: 'small', placeholder: '' } }}
          value={val}
          onChange={va => {
            setVal(va);
            onChange(va);
          }}
          onAccept={onAccept}
          ampmInClock={false}
          ampm={false}
          disabled={disabled}
          format={`YYYY-MM-DD HH:mm`}
        />
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
      </Box>
    </LocalizationProvider>
  );
}
