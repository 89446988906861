import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface NoDataProps {
  message?: string;
}
export const NoData = ({ message = 'There is no item' }: NoDataProps) => {
  const { t } = useTranslation();

  return (
    <Typography fontSize={18} color={'#202634'} textAlign={`center`}>
      {/* {message} */}
      {t('There is no item')}
    </Typography>
  );
};
