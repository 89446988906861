import { GridSample } from '@/components/mappings/grid';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const MappingComponent = () => {
  const params = useParams();
  const venueId = useMemo(() => {
    return params.venueId || '';
  }, [params.venueId]);

  const levelId = useMemo(() => {
    return params.floorId || '';
  }, [params.floorId]);

  return <GridSample key={`${venueId}-${levelId}`} />;
};
