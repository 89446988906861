export const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M9.60807 9.60799L13.3333 13.3333"
      stroke="#40495C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 10.6667C8.87581 10.6667 10.6667 8.87589 10.6667 6.66675C10.6667 4.45761 8.87581 2.66675 6.66667 2.66675C4.45753 2.66675 2.66667 4.45761 2.66667 6.66675C2.66667 8.87589 4.45753 10.6667 6.66667 10.6667Z"
      stroke="#40495C"
      strokeWidth="1.5"
    />
  </svg>
);
