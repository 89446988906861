import CustomButton, { CustomButtonType } from '@/components/common/Button';
import PopUp from '@/components/common/Popup';
import { OneInputContainer } from '@/components/floor/components';
import Input from '@/components/form/input';
import TextArea from '@/components/form/textarea';
import { CustomerDetail } from '@/stores/slices/customer/type';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface CustomerPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formdata: CustomerForm) => void;
  data?: CustomerDetail;
}

export interface CustomerForm {
  name: string;
  phone: string;
  url: string;
  description: string;
}

export function CustomerPopup({
  open,
  onClose,
  onSubmit,
  data,
}: CustomerPopupProps) {
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CustomerForm>({
    defaultValues: {
      name: '',
      phone: '',
      url: '',
      description: '',
    },
  });

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        phone: data.phone,
        url: data.url,
        description: data.description,
      });
    } else {
      reset({
        name: '',
        phone: '',
        url: '',
        description: '',
      });
    }
  }, [data, reset, open]);

  const submitForm = (data: CustomerForm) => {
    onSubmit(data);
    reset();
  };

  return (
    <PopUp
      title={data ? t('Edit Customer') : t('Add Customer')}
      onClose={onClose}
      open={open}
    >
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <OneInputContainer>
          <Controller
            control={control}
            name="name"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('Customer Name')}
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={errors?.name?.message}
                />
              );
            }}
          />
        </OneInputContainer>

        <OneInputContainer>
          <Controller
            control={control}
            name="phone"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('Phone Number')}
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={errors?.phone?.message}
                />
              );
            }}
          />
        </OneInputContainer>
        <OneInputContainer>
          <Controller
            control={control}
            name="url"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('Link')}
                  value={value}
                  onChange={onChange}
                  error={errors?.url?.message}
                />
              );
            }}
          />
        </OneInputContainer>
        <OneInputContainer>
          <Controller
            control={control}
            name="description"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <TextArea
                  label={t('Description')}
                  error={errors?.description?.message}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </OneInputContainer>
        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
            display: 'grid',
            gridTemplateColumns:
              i18n.resolvedLanguage === 'en' ? '70px 70px' : '120px 70px',
            justifyContent: 'flex-end',
            gridGap: '20px',
          }}
        >
          <CustomButton
            buttonType={CustomButtonType.WHITE_BG}
            title={t(`Cancel`)}
            onClick={onClose}
          />
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={t(`Submit`)}
          />
        </Box>
      </Box>
    </PopUp>
  );
}
