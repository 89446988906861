/* eslint-disable max-lines */
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { Loader } from '@/components/common/loader';
import { NoData } from '@/components/common/NoData';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { LocationPopup } from '@/components/location/components/popup';
import { Filter } from '@/components/location/filter';
import { Container } from '@/components/venue/components';
import { RequestStatus } from '@/constants/API';
import useCustomerHook from '@/hooks/useCustomerHook';
import useFloorHook from '@/hooks/useFloorHook';
import useGlobalHook from '@/hooks/useGlobalHook';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import useLocationHook from '@/hooks/useLocationHook';
import {
  LocationDetail,
  LocationDetailsForm,
} from '@/stores/slices/location/type';
import { checkIsRequesting } from '@/utils/helper';
import _ from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCategoryHook from '@/hooks/useCategoryHook';

export const LocationPage = () => {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [selectedId, setSelectedId] = useState<string | number>('');
  const [editingLocation, setEditingLocation] = useState<
    LocationDetail | undefined
  >(undefined);

  const location = useLocation();
  const queries = useParams();

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const { getFloorListOptionStatus, requestFloorListOption } = useFloorHook();
  const { getAllCustomerListStatus } = useCustomerHook();
  const {
    getLocationListStatus,
    requestLocationList,
    createLocationInfoStatus,
    requestCreateLocation,
    requestUpdateLocationInfo,
    locations,
    getTagListStatus,
    requestTagList,
    deleteLocationInfoStatus,
    updateLocationInfoStatus,
    requestDeleteLocation,
    requestResetLocation,
  } = useLocationHook();
  const { getCategoryListStatus, requestCategoryList } = useCategoryHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getLocationListStatus,
        createLocationInfoStatus,
        deleteLocationInfoStatus,
        updateLocationInfoStatus,
        getCategoryListStatus,
        getTagListStatus,
        getFloorListOptionStatus,
        getAllCustomerListStatus,
      ]),
    [
      getLocationListStatus,
      createLocationInfoStatus,
      deleteLocationInfoStatus,
      updateLocationInfoStatus,
      getCategoryListStatus,
      getTagListStatus,
      getFloorListOptionStatus,
      getAllCustomerListStatus,
    ],
  );

  const showNoData = useMemo(
    () =>
      getLocationListStatus !== RequestStatus.IDLE &&
      (locations?.data?.length === 0 || !locations),
    [locations, getLocationListStatus],
  );
  const navigate = useNavigateHook()?.navigate;
  const redirect = useCallback(
    (qr: any) => {
      navigate(
        `${location.pathname}?${queryString.stringify(
          _.omitBy(
            qr,
            v => !v || (Array.isArray(v) && _.isEmpty(v)) || _.isNil(v),
          ),
        )}`,
      );
    },
    [location.pathname, navigate],
  );
  const onChangePage = (id: number) => {
    let params = queryString.parse(window.location.search);
    params.page = id.toString();
    redirect(params);
  };

  const onAdd = () => {
    setEditingLocation(undefined);
    setOpenPopup(true);
  };

  const submitAddOrEdit = (formData: LocationDetailsForm) => {
    if (editingLocation) {
      requestUpdateLocationInfo({
        ...formData,
        id: editingLocation.id,
        venue: queries?.venueId,
      });
    } else {
      requestCreateLocation({ ...formData, venue: queries?.venueId });
    }
    setOpenPopup(false);
  };

  const onEdit = (id: number | string) => {
    setEditingLocation(
      locations?.data.find((it: LocationDetail) => it.id === id) ?? undefined,
    );
    setOpenPopup(true);
  };

  const onDelete = (id: number | string) => {
    setOpenDelete(true);
    setSelectedId(id);
  };

  const submitDelete = () => {
    requestDeleteLocation(selectedId);
  };

  const handleDebounceFn = (inputValue: string) => {
    let params = queryString.parse(window.location.search);
    params.keyword = inputValue;
    delete params.page;
    redirect(params);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  const onKeywordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget?.value;
    debounceFn(keyword);
  };

  const onLevelChange = (e: any) => {
    let params = queryString.parse(window.location.search);
    params.level = e.target?.value;
    delete params.page;
    redirect(params);
  };

  const onCategoryChange = (e: any) => {
    let params = queryString.parse(window.location.search);
    params.category = e.target?.value;
    delete params.page;
    redirect(params);
  };

  const onTagChange = (e: any) => {
    let params = queryString.parse(window.location.search);
    params.tags = e?.map((it: any) => it.value);
    delete params.page;
    redirect(params);
  };

  const pagination = {
    currentPage: locations?.current_page ?? 1,
    totalPages: locations?.total_page ?? 1,
    onPageChange: (id: number) => onChangePage(id),
  };

  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    requestLocationList(
      queries?.venueId ? { ...params, venue: queries?.venueId } : { ...params },
    );
  };

  useEffect(() => {
    requestCategoryList({ venue: queries?.venueId });
    requestTagList();
    requestFloorListOption({ venue: queries?.venueId });
    return () => requestResetLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateLocationInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Location updated!')]);
      reloadData();
    }
    if (updateLocationInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update location')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLocationInfoStatus]);

  useEffect(() => {
    if (createLocationInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Location created!')]);
      reloadData();
    }

    if (createLocationInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to create location')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLocationInfoStatus]);

  useEffect(() => {
    if (deleteLocationInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to delete location')]);
    }
    if (deleteLocationInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Location deleted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLocationInfoStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <Container>
      <Filter
        onAdd={onAdd}
        // onExport={onExport}
        onKeywordChange={onKeywordChange}
        onLevelChange={onLevelChange}
        onCategoryChange={onCategoryChange}
        onTagChange={onTagChange}
      />
      {showNoData ? (
        <NoData />
      ) : (
        <CommonTable
          type={TableType.LOCATION}
          data={locations?.data}
          pagination={pagination}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )}

      <LocationPopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={submitAddOrEdit}
        data={editingLocation}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onSubmit={submitDelete}
      />
      <Loader show={loading} />
    </Container>
  );
};
