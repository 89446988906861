import { RequestStatus } from '@/constants/API';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FloorDetail, FloorLevels } from './type';
import { IPageDataResponse } from '@/types/common';
import { FloorForm } from '@/components/floor/components/popup';

export interface FloorState {
  getFloorLevelsStatus: RequestStatus;
  getFloorListStatus: RequestStatus;
  createFloorInfoStatus: RequestStatus;
  updateFloorInfoStatus: RequestStatus;
  updateFloorPublishStatus: RequestStatus;
  deleteFloorInfoStatus: RequestStatus;
  deleteMultiFloorInfoStatus: RequestStatus;
  getFloorDetailsStatus: RequestStatus;
  getFloorListOptionStatus: RequestStatus;
  floors: IPageDataResponse<FloorDetail> | null;
  floorDetails: FloorDetail | null;
  floorLevels: FloorLevels | null;
  floorOptions: FloorDetail[];
}

const initialState: FloorState = {
  getFloorLevelsStatus: RequestStatus.IDLE,
  getFloorListOptionStatus: RequestStatus.IDLE,
  getFloorListStatus: RequestStatus.IDLE,
  createFloorInfoStatus: RequestStatus.IDLE,
  updateFloorInfoStatus: RequestStatus.IDLE,
  updateFloorPublishStatus: RequestStatus.IDLE,
  deleteFloorInfoStatus: RequestStatus.IDLE,
  deleteMultiFloorInfoStatus: RequestStatus.IDLE,
  getFloorDetailsStatus: RequestStatus.IDLE,
  floors: null,
  floorLevels: null,
  floorDetails: null,
  floorOptions: [],
};

export const floorSlice: any = createSlice({
  name: 'floor',
  initialState,
  reducers: {
    getFloorListRequest: (state, action: PayloadAction<FloorForm>) => {
      state.getFloorListStatus = RequestStatus.REQUESTING;
    },
    getFloorListSuccess: (state, action: PayloadAction<any>) => {
      state.getFloorListStatus = RequestStatus.SUCCESS;
      state.floors = action.payload;
    },
    getFloorListFailure: state => {
      state.getFloorListStatus = RequestStatus.ERROR;
    },

    getFloorListOptionRequest: (state, action: PayloadAction<FloorForm>) => {
      state.getFloorListOptionStatus = RequestStatus.REQUESTING;
    },
    getFloorListOptionSuccess: (state, action: PayloadAction<any>) => {
      state.getFloorListOptionStatus = RequestStatus.SUCCESS;
      state.floorOptions = action.payload;
    },
    getFloorListOptionFailure: state => {
      state.getFloorListOptionStatus = RequestStatus.ERROR;
    },

    getFloorLevelsRequest: (state, action: PayloadAction<FloorForm>) => {
      state.getFloorLevelsStatus = RequestStatus.REQUESTING;
    },
    getFloorLevelsSuccess: (state, action: PayloadAction<any>) => {
      state.getFloorLevelsStatus = RequestStatus.SUCCESS;
      state.floorLevels = action.payload;
    },
    getFloorLevelsFailure: state => {
      state.getFloorLevelsStatus = RequestStatus.ERROR;
    },

    getFloorDetailsRequest: (state, action: PayloadAction<FloorForm>) => {
      state.getFloorDetailsStatus = RequestStatus.REQUESTING;
    },
    getFloorDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.getFloorDetailsStatus = RequestStatus.SUCCESS;
      state.floorDetails = action.payload;
    },
    getFloorDetailsFailure: state => {
      state.getFloorDetailsStatus = RequestStatus.ERROR;
    },

    createFloorInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createFloorInfoStatus = RequestStatus.REQUESTING;
    },
    createFloorInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createFloorInfoStatus = RequestStatus.SUCCESS;
    },
    createFloorInfoFailure: (state, action: PayloadAction<any>) => {
      state.createFloorInfoStatus = RequestStatus.ERROR;
    },

    updateFloorInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateFloorInfoStatus = RequestStatus.REQUESTING;
    },
    updateFloorInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateFloorInfoStatus = RequestStatus.SUCCESS;
    },
    updateFloorInfoFailure: (state, action: PayloadAction<any>) => {
      state.updateFloorInfoStatus = RequestStatus.ERROR;
    },

    updateFloorPublishRequest: (state, action: PayloadAction<any>) => {
      state.updateFloorPublishStatus = RequestStatus.REQUESTING;
    },
    updateFloorPublishSuccess: (state, action: PayloadAction<any>) => {
      state.updateFloorPublishStatus = RequestStatus.SUCCESS;
    },
    updateFloorPublishFailure: (state, action: PayloadAction<any>) => {
      state.updateFloorPublishStatus = RequestStatus.ERROR;
    },

    deleteFloorInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteFloorInfoStatus = RequestStatus.REQUESTING;
    },
    deleteFloorInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteFloorInfoStatus = RequestStatus.SUCCESS;
    },
    deleteFloorInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteFloorInfoStatus = RequestStatus.ERROR;
    },

    deleteMultiFloorInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteMultiFloorInfoStatus = RequestStatus.REQUESTING;
    },
    deleteMultiFloorInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteMultiFloorInfoStatus = RequestStatus.SUCCESS;
    },
    deleteMultiFloorInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteMultiFloorInfoStatus = RequestStatus.ERROR;
    },

    resetFloorInfoRequestStatus: state => {
      state.getFloorListStatus = RequestStatus.IDLE;
      state.createFloorInfoStatus = RequestStatus.IDLE;
      state.updateFloorInfoStatus = RequestStatus.IDLE;
      state.deleteFloorInfoStatus = RequestStatus.IDLE;
      state.deleteMultiFloorInfoStatus = RequestStatus.IDLE;
      state.getFloorDetailsStatus = RequestStatus.IDLE;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getFloorLevelsRequest,
  getFloorLevelsSuccess,
  getFloorLevelsFailure,
  getFloorListRequest,
  getFloorListSuccess,
  getFloorListFailure,
  createFloorInfoRequest,
  createFloorInfoSuccess,
  createFloorInfoFailure,
  updateFloorInfoRequest,
  updateFloorInfoSuccess,
  updateFloorInfoFailure,
  updateFloorPublishRequest,
  updateFloorPublishSuccess,
  updateFloorPublishFailure,
  deleteFloorInfoRequest,
  deleteFloorInfoSuccess,
  deleteFloorInfoFailure,
  getFloorDetailsRequest,
  getFloorDetailsSuccess,
  getFloorDetailsFailure,
  resetFloorInfoRequestStatus,
  getFloorListOptionRequest,
  getFloorListOptionFailure,
  getFloorListOptionSuccess,
  deleteMultiFloorInfoRequest,
  deleteMultiFloorInfoSuccess,
  deleteMultiFloorInfoFailure,
} = floorSlice.actions;

export default floorSlice.reducer;
