import { RequestStatus } from '@/constants/API';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { EventDetail } from './type';
import { IPageDataResponse } from '@/types/common';
import { ICommonModel } from '../location/type';

export interface EventState {
  getEventListStatus: RequestStatus;
  createEventInfoStatus: RequestStatus;
  updateEventInfoStatus: RequestStatus;
  deleteEventInfoStatus: RequestStatus;
  getEventDetailsStatus: RequestStatus;
  getEventTagListStatus: RequestStatus;
  getCategoryListStatus: RequestStatus;
  events: IPageDataResponse<EventDetail> | null;
  eventDetails: EventDetail | null;
  eventTags: ICommonModel[];
}

const initialState: EventState = {
  getEventListStatus: RequestStatus.IDLE,
  getEventTagListStatus: RequestStatus.IDLE,
  getCategoryListStatus: RequestStatus.IDLE,
  createEventInfoStatus: RequestStatus.IDLE,
  updateEventInfoStatus: RequestStatus.IDLE,
  deleteEventInfoStatus: RequestStatus.IDLE,
  getEventDetailsStatus: RequestStatus.IDLE,
  events: null,
  eventDetails: null,
  eventTags: [],
};

export const eventSlice: any = createSlice({
  name: 'event',
  initialState,
  reducers: {
    getEventListRequest: (state, action: PayloadAction<any>) => {
      state.getEventListStatus = RequestStatus.REQUESTING;
    },
    getEventListSuccess: (state, action: PayloadAction<any>) => {
      state.getEventListStatus = RequestStatus.SUCCESS;
      state.events = action.payload;
    },
    getEventListFailure: state => {
      state.getEventListStatus = RequestStatus.ERROR;
    },
    getEventTagListRequest: (state, action: PayloadAction<any>) => {
      state.getEventTagListStatus = RequestStatus.REQUESTING;
    },
    getEventTagListSuccess: (state, action: PayloadAction<any>) => {
      state.getEventTagListStatus = RequestStatus.SUCCESS;
      state.eventTags = action.payload;
    },
    getEventTagListFailure: state => {
      state.getEventTagListStatus = RequestStatus.ERROR;
    },
    getEventDetailsRequest: (state, action: PayloadAction<any>) => {
      state.getEventDetailsStatus = RequestStatus.REQUESTING;
    },
    getEventDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.getEventDetailsStatus = RequestStatus.SUCCESS;
      state.eventDetails = action.payload;
    },
    getEventDetailsFailure: state => {
      state.getEventDetailsStatus = RequestStatus.ERROR;
    },
    createEventInfoRequest: (state, action: PayloadAction<any>) => {
      state.createEventInfoStatus = RequestStatus.REQUESTING;
    },
    createEventInfoSuccess: (state, action: PayloadAction<any>) => {
      state.createEventInfoStatus = RequestStatus.SUCCESS;
    },
    createEventInfoFailure: (state, action: PayloadAction<any>) => {
      state.createEventInfoStatus = RequestStatus.ERROR;
    },
    updateEventInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateEventInfoStatus = RequestStatus.REQUESTING;
    },
    updateEventInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateEventInfoStatus = RequestStatus.SUCCESS;
    },
    updateEventInfoFailure: (state, action: PayloadAction<any>) => {
      state.updateEventInfoStatus = RequestStatus.ERROR;
    },
    deleteEventInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteEventInfoStatus = RequestStatus.REQUESTING;
    },
    deleteEventInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteEventInfoStatus = RequestStatus.SUCCESS;
    },
    deleteEventInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteEventInfoStatus = RequestStatus.ERROR;
    },
    resetEventInfoRequestStatus: state => {
      state.getEventListStatus = RequestStatus.IDLE;
      state.createEventInfoStatus = RequestStatus.IDLE;
      state.updateEventInfoStatus = RequestStatus.IDLE;
      state.deleteEventInfoStatus = RequestStatus.IDLE;
      state.getEventDetailsStatus = RequestStatus.IDLE;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getEventListRequest,
  getEventListSuccess,
  getEventListFailure,
  createEventInfoRequest,
  createEventInfoSuccess,
  createEventInfoFailure,
  updateEventInfoRequest,
  updateEventInfoSuccess,
  updateEventInfoFailure,
  deleteEventInfoRequest,
  deleteEventInfoSuccess,
  deleteEventInfoFailure,
  getEventDetailsRequest,
  getEventDetailsSuccess,
  getEventDetailsFailure,
  getEventTagListRequest,
  getEventTagListSuccess,
  getEventTagListFailure,
  resetEventInfoRequestStatus,
} = eventSlice.actions;

export default eventSlice.reducer;
