import { createSelector } from '@reduxjs/toolkit';
import { floorSlice } from './floor';
import { RootState } from '../..';

const selectSlice = (state: RootState) =>
  state.floor || floorSlice.getInitialState;

export const selectFloor = createSelector([selectSlice], floorState => ({
  ...floorState,
}));
