import { useAppSelector, useAppDispatch } from '@/hooks';

import { selectEvent } from '@/stores/slices/event/selector';
import {
  getEventDetailsRequest,
  getEventListRequest,
  updateEventInfoRequest,
  deleteEventInfoRequest,
  createEventInfoRequest,
  resetEventInfoRequestStatus,
  getEventTagListRequest,
} from '@/stores/slices/event/event';

const useEventHook = () => {
  const {
    getEventDetailsStatus,
    getEventListStatus,
    updateEventInfoStatus,
    createEventInfoStatus,
    deleteEventInfoStatus,
    getEventTagListStatus,
    eventDetails,
    events,
    eventTags,
  } = useAppSelector(selectEvent);

  const dispatch = useAppDispatch();

  const requestEventList = (params: any) =>
    dispatch(getEventListRequest(params));

  const requestEventDetails = (params: any) =>
    dispatch(getEventDetailsRequest(params));

  const requestUpdateEventInfo = (params: any) =>
    dispatch(updateEventInfoRequest(params));

  const requestCreateEvent = (params: any) =>
    dispatch(createEventInfoRequest(params));

  const requestDeleteEvent = (params: any) =>
    dispatch(deleteEventInfoRequest(params));

  const requestEventTagList = () => dispatch(getEventTagListRequest());

  const requestResetEvent = () => dispatch(resetEventInfoRequestStatus());

  return {
    requestEventList,
    requestEventDetails,
    requestUpdateEventInfo,
    requestCreateEvent,
    requestDeleteEvent,
    requestResetEvent,
    requestEventTagList,
    getEventDetailsStatus,
    getEventListStatus,
    updateEventInfoStatus,
    createEventInfoStatus,
    deleteEventInfoStatus,
    getEventTagListStatus,
    eventDetails,
    events,
    eventTags,
  };
};

export default useEventHook;
