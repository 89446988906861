import { RequestStatus } from '@/constants/API';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { QRCodeDetail } from './types';

export interface QRCodeState {
  getQRCodeListStatus: RequestStatus;
  getQRCodeDetailStatus: RequestStatus;
  createQRCodeInfoStatus: RequestStatus;
  updateQRCodeInfoStatus: RequestStatus;
  deleteQRCodeInfoStatus: RequestStatus;
  qrcodes: QRCodeDetail[];
  qrcodeDetail: QRCodeDetail | null;
}

const initialState: QRCodeState = {
  getQRCodeListStatus: RequestStatus.IDLE,
  getQRCodeDetailStatus: RequestStatus.IDLE,
  createQRCodeInfoStatus: RequestStatus.IDLE,
  updateQRCodeInfoStatus: RequestStatus.IDLE,
  deleteQRCodeInfoStatus: RequestStatus.IDLE,
  qrcodes: [],
  qrcodeDetail: null,
};

export const qrcodeSlice: any = createSlice({
  name: 'qrcode',
  initialState,
  reducers: {
    getQRCodeListRequest: (state, action: PayloadAction<any>) => {
      state.getQRCodeListStatus = RequestStatus.REQUESTING;
    },
    getQRCodeListSuccess: (state, action: PayloadAction<any>) => {
      state.getQRCodeListStatus = RequestStatus.SUCCESS;
      state.qrcodes = action.payload;
    },
    getQRCodeListFailure: state => {
      state.getQRCodeListStatus = RequestStatus.ERROR;
    },
    getQRCodeDetailRequest: (state, action: PayloadAction<any>) => {
      state.getQRCodeDetailStatus = RequestStatus.REQUESTING;
    },
    getQRCodeDetailSuccess: (state, action: PayloadAction<any>) => {
      state.getQRCodeDetailStatus = RequestStatus.SUCCESS;
      state.qrcodeDetail = action.payload;
    },
    getQRCodeDetailFailure: state => {
      state.getQRCodeDetailStatus = RequestStatus.ERROR;
    },
    createQRCodeInfoRequest: (state, action: PayloadAction<any>) => {
      state.createQRCodeInfoStatus = RequestStatus.REQUESTING;
    },
    createQRCodeInfoSuccess: (state, action: PayloadAction<any>) => {
      state.createQRCodeInfoStatus = RequestStatus.SUCCESS;
    },
    createQRCodeInfoFailure: (state, action: PayloadAction<any>) => {
      state.createQRCodeInfoStatus = RequestStatus.ERROR;
    },
    updateQRCodeInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateQRCodeInfoStatus = RequestStatus.REQUESTING;
    },
    updateQRCodeInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateQRCodeInfoStatus = RequestStatus.SUCCESS;
    },
    updateQRCodeInfoFailure: (state, action: PayloadAction<any>) => {
      state.updateQRCodeInfoStatus = RequestStatus.ERROR;
    },
    deleteQRCodeInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteQRCodeInfoStatus = RequestStatus.REQUESTING;
    },
    deleteQRCodeInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteQRCodeInfoStatus = RequestStatus.SUCCESS;
    },
    deleteQRCodeInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteQRCodeInfoStatus = RequestStatus.ERROR;
    },
    resetQRCodeInfoRequestStatus: state => {
      state.getQRCodeListStatus = RequestStatus.IDLE;
      state.getQRCodeDetailStatus = RequestStatus.IDLE;
      state.createQRCodeInfoStatus = RequestStatus.IDLE;
      state.updateQRCodeInfoStatus = RequestStatus.IDLE;
      state.deleteQRCodeInfoStatus = RequestStatus.IDLE;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getQRCodeListRequest,
  getQRCodeListSuccess,
  getQRCodeListFailure,
  getQRCodeDetailRequest,
  getQRCodeDetailSuccess,
  getQRCodeDetailFailure,
  createQRCodeInfoRequest,
  createQRCodeInfoSuccess,
  createQRCodeInfoFailure,
  updateQRCodeInfoRequest,
  updateQRCodeInfoSuccess,
  updateQRCodeInfoFailure,
  deleteQRCodeInfoRequest,
  deleteQRCodeInfoSuccess,
  deleteQRCodeInfoFailure,
  resetQRCodeInfoRequestStatus,
} = qrcodeSlice.actions;

export default qrcodeSlice.reducer;
