import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  getCategoryListRequest,
  getCategoryListSuccess,
  getCategoryListFailure,
  createCategoryInfoRequest,
  createCategoryInfoSuccess,
  createCategoryInfoFailure,
  updateCategoryInfoRequest,
  updateCategoryInfoSuccess,
  updateCategoryInfoFailure,
  deleteCategoryInfoRequest,
  deleteCategoryInfoSuccess,
  deleteCategoryInfoFailure,
} from '../slices/category/category';

type IPayload = Record<string, any>;

function* getCategoryListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getCategoriesToVenue, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getCategoryListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getCategoryListFailure.type });
    }
  } catch (error) {
    yield put({ type: getCategoryListFailure.type });
  }
}

function* createCategoryFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createCategory, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: createCategoryInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createCategoryInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createCategoryInfoFailure.type });
  }
}

function* updateCategoryFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateCategory, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateCategoryInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateCategoryInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: updateCategoryInfoFailure.type });
  }
}

function* deleteCategoryFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteCategory, payload);

    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteCategoryInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteCategoryInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteCategoryInfoFailure.type });
  }
}
function* categorySaga() {
  yield takeLatest(getCategoryListRequest, getCategoryListFlow);
  yield takeLatest(createCategoryInfoRequest, createCategoryFlow);
  yield takeLatest(updateCategoryInfoRequest, updateCategoryFlow);
  yield takeLatest(deleteCategoryInfoRequest, deleteCategoryFlow);
}

export default categorySaga;
