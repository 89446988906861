/* eslint-disable max-lines */
import { CustomController } from '@/components/form/controller';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import CustomButton, { CustomButtonType } from 'src/components/common/Button';
import PopUp from 'src/components/common/Popup';
import {
  OneInputContainer,
  TwoInputContainer,
} from 'src/components/floor/components';
import { StateSection } from './stateSection';
import {
  getDefaultLocationForm,
  transformSelectOptions,
  transformLocationDetails,
  formatWorkHours,
  transformSelectOptionsWithTranslation,
} from '@/utils/helper';
import { FormType } from '@/components/form/types';
import {
  LocationDetail,
  LocationDetailsForm,
} from '@/stores/slices/location/type';
import useLocationHook from '@/hooks/useLocationHook';
import { useEffect, useRef } from 'react';
import _ from 'lodash';
import { LOCATION_STATES, LOCATION_STATUS } from '@/constants';
import { useTranslation } from 'react-i18next';
import useCategoryHook from '@/hooks/useCategoryHook';

export interface LocationPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formdata: LocationDetailsForm) => void;
  data?: LocationDetail;
}

export function LocationPopup({
  open,
  onClose,
  onSubmit,
  data,
}: LocationPopupProps) {
  const locationForm = useForm<LocationDetailsForm>({
    defaultValues: getDefaultLocationForm(),
  });

  const { tags } = useLocationHook();
  const { categories } = useCategoryHook();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = locationForm;
  const nameRef = useRef<any>(null);
  const assetRef = useRef<any>(null);
  const stateRef = useRef<any>(null);
  const { t, i18n } = useTranslation();

  const submitForm = async (formDt: any) => {
    onSubmit({
      ...formDt,
      originalLogo: Array.isArray(formDt?.originalLogo)
        ? formDt?.originalLogo[0]?.data_url ?? null
        : formDt?.originalLogo ?? null,
      categories: _.map(formDt.categories, it =>
        typeof it === 'string' ? it : it.value,
      ),
      tags: _.map(formDt?.tags ?? [], it => ({ id: it.value ?? it })),
      workHours: formatWorkHours(formDt.workHours),
      images: _.map(formDt?.images, it => ({
        original: typeof it === 'string' ? it : it.data_url,
      })),
    });
    reset();
  };

  const onError = async () => {
    const ref = _.get(errors, [Object.keys(errors)[0]])?.ref;
    if (ref.name === 'name' && nameRef.current) {
      nameRef?.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      assetRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (data) {
      reset(transformLocationDetails(data));
    } else {
      reset(getDefaultLocationForm());
    }
  }, [data, reset, open]);

  return (
    <PopUp
      title={data ? t('Edit Location') : t('Add Location')}
      onClose={onClose}
      open={open}
    >
      <Box component="form" onSubmit={handleSubmit(submitForm, onError)}>
        <OneInputContainer ref={nameRef}>
          <CustomController
            controller={control}
            name="name"
            label={t('Name')}
            isRequired
            error={errors?.name?.message}
            formType={FormType.INPUT}
          />
        </OneInputContainer>
        <TwoInputContainer ref={stateRef}>
          <CustomController
            controller={control}
            name="status"
            label={t('Status')}
            isRequired
            formType={FormType.SELECT}
            error={errors?.status?.message}
            options={transformSelectOptionsWithTranslation(LOCATION_STATUS, t)}
          />
          <CustomController
            controller={control}
            name="state"
            label={t('State')}
            isRequired
            formType={FormType.SELECT}
            error={errors?.state?.message}
            options={transformSelectOptionsWithTranslation(LOCATION_STATES, t)}
          />
        </TwoInputContainer>

        <TwoInputContainer ref={assetRef}>
          <CustomController
            controller={control}
            name="phone"
            label={t('Phone Number')}
            formType={FormType.INPUT}
            error={errors?.phone?.message}
          />
        </TwoInputContainer>
        <OneInputContainer>
          <CustomController
            controller={control}
            name="categories"
            label={t('Categories')}
            formType={FormType.MULTI_SELECT}
            options={transformSelectOptions(categories)}
            error={errors.categories?.message}
          />
        </OneInputContainer>
        <OneInputContainer className="mt_10">
          <CustomController
            controller={control}
            name="tags"
            label={t('Tags')}
            formType={FormType.MULTI_SELECT}
            options={transformSelectOptions(tags)}
            error={errors.tags?.message}
          />
        </OneInputContainer>
        <OneInputContainer className="mt_10">
          <CustomController
            controller={control}
            name="website"
            label={t('Link')}
            formType={FormType.INPUT}
          />
        </OneInputContainer>
        <OneInputContainer>
          <CustomController
            controller={control}
            name="description"
            label={t('Description')}
            formType={FormType.TEXTAREA}
          />
        </OneInputContainer>
        <StateSection form={locationForm} />
        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
            display: 'grid',
            gridTemplateColumns:
              i18n.resolvedLanguage === 'en' ? '70px 70px' : '120px 70px',
            justifyContent: 'flex-end',
            gridGap: '20px',
          }}
        >
          <CustomButton
            buttonType={CustomButtonType.WHITE_BG}
            title={t(`Cancel`)}
            onClick={onClose}
          />
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={t(`Submit`)}
          />
        </Box>
      </Box>
    </PopUp>
  );
}
