import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';

import {
  deleteSnapshotFailure,
  deleteSnapshotRequest,
  deleteSnapshotSuccess,
  getSnapshotListFailure,
  getSnapshotListRequest,
  getSnapshotListSuccess,
} from '../slices/snapshot/snapshot';

type IPayload = Record<string, any>;

function* getSnapshotListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getSnapshots, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getSnapshotListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getSnapshotListFailure.type });
    }
  } catch (error) {
    yield put({ type: getSnapshotListFailure.type });
  }
}

function* deleteSnapshotFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteSnapshot, payload);

    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteSnapshotSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteSnapshotFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteSnapshotFailure.type });
  }
}

function* snapshotSaga() {
  yield takeLatest(getSnapshotListRequest, getSnapshotListFlow);
  yield takeLatest(deleteSnapshotRequest, deleteSnapshotFlow);
}

export default snapshotSaga;
