import { Box, Typography } from '@mui/material';
import { TableAction } from './types';
import { ReactComponent as RecIcon } from '@/assets/icons/selecteds_ic.svg';
import { useTranslation } from 'react-i18next';

export const TableHeaderAction = ({
  selected = [],
  total = 0,
}: TableAction) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '8px 0px 8px 17px',
        alignItems: 'center',
        gap: '16px',
        margin: '0 20px',
        background: '#E4F0FF',
        '& > div': {
          marginBottom: 0,
        },
      }}
    >
      <RecIcon />
      <Typography>
        {t('Selected')}: {selected.length}/{total}
      </Typography>
    </Box>
  );
};
