import { createSelector } from '@reduxjs/toolkit';
import { amenitySlice } from './amenity';
import { RootState } from '../..';

const selectSlice = (state: RootState) =>
  state.amenity || amenitySlice.getInitialState;

export const selectAmenity = createSelector([selectSlice], amenityState => ({
  ...amenityState,
}));
