import { createSelector } from '@reduxjs/toolkit';
import { customerSlice } from './customer';
import { RootState } from '../..';

const selectSlice = (state: RootState) =>
  state.customer || customerSlice.getInitialState;

export const selectCustomer = createSelector([selectSlice], customerState => ({
  ...customerState,
}));
