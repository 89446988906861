import React from 'react';
import styled from 'styled-components';
import { SearchIcon } from '@/components/icons';
import { DirectionType, InputType } from './customSelect';
import { Box } from '@mui/material';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  isRequired?: boolean;
  inputType?: number;
  width?: number;
  value?: string | number;
  fromType?: InputType;
  direction?: DirectionType;
}

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
`;

export const Label = styled.label<{ $fromType?: InputType }>`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  color: ${props =>
    props.$fromType === InputType.MAPPING ? '#fff' : '#5c677e'};
  span {
    color: #e34a66;
    padding-left: 5px;
    vertical-align: middle;
  }
`;

export const ErrorMessage = styled.span`
  color: #e34a66;
  font-size: 10px;
  line-height: 14px;
`;

export const InputField = styled.input<{
  $hasError?: boolean;
  $inputType?: number;
  $width?: number;
  $fromType?: InputType;
}>`
  box-sizing: border-box;
  width: ${props => (props.$width ? props.$width + 'px' : '100%')};
  padding: ${props =>
    props.$inputType === 1 ? '8px 12px 8px 30px' : '8px 12px'};
  border: 1px solid
    ${props =>
      props.$hasError
        ? '#e34a66'
        : props.$fromType === InputType.POP_UP
        ? '#dee1eb'
        : 'transparent'};
  border-radius: 2px;
  background: ${props =>
    props.$fromType === InputType.MAPPING ? '#40495C' : '#fff'};
  color: ${props =>
    props.$fromType === InputType.MAPPING ? '#fff' : '#222222 '};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #ccc;
  }
`;

const Input: React.FC<InputProps> = ({
  label,
  error,
  isRequired = false,
  inputType = 0,
  width = 0,
  value,
  fromType = InputType.POP_UP,
  direction = DirectionType.COLUMN,
  ...inputProps
}) => {
  return (
    <InputContainer>
      <Box
        sx={{
          display: direction === DirectionType.COLUMN ? 'block' : 'flex',
          '& > label': {
            marginBottom: direction === DirectionType.COLUMN ? '0.5rem' : '',
            paddingTop: direction === DirectionType.COLUMN ? '0' : '8px',
            paddingRight: direction === DirectionType.COLUMN ? '0' : '5px',
          },
        }}
      >
        {label && (
          <Label $fromType={fromType}>
            {label}
            {isRequired && <span>*</span>}
          </Label>
        )}
        <InputWrapper>
          {inputType === 1 && <SearchIcon />}

          <InputField
            $inputType={inputType}
            $fromType={fromType}
            {...inputProps}
            $hasError={!!error}
            $width={width}
            value={value}
          />
        </InputWrapper>
      </Box>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputContainer>
  );
};

export default Input;
