import { FilterBar, FilterType } from '@/components/common/FilterBar';
import { FormWrapper } from '@/components/venue/components';
import { Controller, useForm } from 'react-hook-form';
import SelectItem from '@/components/form/select';
import Input from '@/components/form/input';
import { FilterFormData } from '@/types';
import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import useLocationHook from '@/hooks/useLocationHook';
import { transformSelectOptions } from '@/utils/helper';
import useFloorHook from '@/hooks/useFloorHook';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

type FilterProps = {
  onAdd: () => void;
  onLevelChange: (e: SelectChangeEvent<any>) => void;
  onLocationChange: (e: SelectChangeEvent<any>) => void;
  onKeywordChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const Filter = ({
  onAdd,
  onLevelChange,
  onLocationChange,
  onKeywordChange,
}: FilterProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  let params = queryString.parse(window.location.search);
  const { control, reset } = useForm<FilterFormData>({
    defaultValues: {
      keyword: '',
      level: undefined,
      location: undefined,
    },
  });

  useEffect(() => {
    reset({
      keyword: Array.isArray(params?.keyword)
        ? params?.keyword[0] ?? ''
        : params?.keyword ?? undefined,
      level:
        typeof params?.level === 'string'
          ? params?.level ?? undefined
          : Array.isArray(params?.level)
          ? params?.level[0] ?? undefined
          : undefined,
      location:
        typeof params?.location === 'string'
          ? params?.location ?? undefined
          : Array.isArray(params?.location)
          ? params?.location[0] ?? undefined
          : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const { floorOptions }: any = useFloorHook();
  const { locationsToVenue }: any = useLocationHook();

  return (
    <FilterBar
      type={FilterType.GENERIC}
      onAdd={onAdd}
      // onExport={onExport}
      buttonText={t('Add QR Code')}
    >
      <FormWrapper>
        <Controller
          control={control}
          name="level"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <SelectItem
                options={transformSelectOptions(floorOptions)}
                value={value}
                onChange={e => {
                  onChange(e);
                  onLevelChange(e);
                }}
                placeholder={t('Floor')}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="location"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <SelectItem
                options={transformSelectOptions(locationsToVenue)}
                value={value}
                onChange={e => {
                  onChange(e);
                  onLocationChange(e);
                }}
                placeholder={t('Location')}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="keyword"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <Input
                inputType={1}
                onChange={e => {
                  onChange(e);
                  onKeywordChange(e);
                }}
                placeholder={t('Search')}
                value={value}
              />
            );
          }}
        />
      </FormWrapper>
    </FilterBar>
  );
};
