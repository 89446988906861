import { Box, CheckboxProps } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
import styled from 'styled-components';
import { InputType } from './customSelect';

export interface ICheckBoxItem {
  label?: string | React.ReactNode;
  className?: string;
  checked: boolean;
  inputType?: InputType;
}

const CheckBoxItem = ({
  className,
  label,
  checked,
  inputType = InputType.POP_UP,
  ...props
}: CheckboxProps & ICheckBoxItem) => {
  return (
    <Container className={className} $inputType={inputType}>
      <FormControlLabel
        control={
          <Checkbox
            disableRipple
            sx={{
              color: '#DEE1EB',
              '&.Mui-checked': {
                color: '#E34A66',
              },
            }}
            checked={checked}
            {...props}
          />
        }
        label={label}
      />
    </Container>
  );
};

export default CheckBoxItem;

const Container = styled(Box)<{ $inputType?: InputType }>`
  .MuiCheckbox-root {
    padding: 0;
    width: 17px;
    height: 17px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .MuiFormControlLabel-root {
    margin-left: -2px;
    margin-right: 0px;
  }
  .MuiFormControlLabel-label {
    margin-left: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${props =>
      props.$inputType === InputType.MAPPING ? '#fff' : '#222222 '};
  }
`;
