import {
  ACCESS_TOKEN_KEY,
  DIGIMAP_COLLASPE_SIDEBAR,
  MAP_ELEMENT_CACHED_DATA,
  MAP_STATE_CACHED_DATA,
  REFRESH_TOKEN_KEY,
  USER_CACHED_DATA,
} from 'src/constants';
import { AppState } from '@excalidraw/excalidraw/types/types';
import { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';
import { MappingElement } from '@/types/draw';

// <Access Token>
export const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const setToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);

export const removeToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const setRefreshToken = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const removeRefreshToken = (): void => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const isHavingToken = () => !!getToken();
// </Access Token>

// <User Profile>
export const getUserCachedDataFromLocalStorage = (): any | null => {
  const data = localStorage.getItem(USER_CACHED_DATA);
  try {
    return data ? JSON.parse(data) : null;
  } catch (e) {
    return null;
  }
};
export const setUserCachedDataFromLocalStorage = (userSessionData: any) =>
  localStorage.setItem(USER_CACHED_DATA, JSON.stringify(userSessionData));

export const removeUserCachedDataFromLocalStorage = () => {
  localStorage.removeItem(USER_CACHED_DATA);
};

export const setMapStateToLocalStorage = (mapData: AppState) =>
  localStorage.setItem(MAP_STATE_CACHED_DATA, JSON.stringify(mapData));

export const getMapStateFromStorage = (): AppState | null => {
  const data = localStorage.getItem(MAP_STATE_CACHED_DATA);
  try {
    const returnedData = data ? JSON.parse(data) : null;
    if (returnedData) {
      returnedData.collaborators = new Map(returnedData.collaborators);
    }
    return returnedData;
  } catch (e) {
    return null;
  }
};

export const setMapElementToLocalStorage = (
  mapData: Array<ExcalidrawElement>,
) => localStorage.setItem(MAP_ELEMENT_CACHED_DATA, JSON.stringify(mapData));

export const getMapElementFromStorage = (): Array<MappingElement> | null => {
  const data = localStorage.getItem(MAP_ELEMENT_CACHED_DATA);
  try {
    return data ? JSON.parse(data) : null;
  } catch (e) {
    return null;
  }
};

export const getCollaspe = (): boolean => {
  const value = localStorage.getItem(DIGIMAP_COLLASPE_SIDEBAR);
  return value === 'true';
};

export const setCollaspe = (collaspe: boolean): void => {
  localStorage.setItem(DIGIMAP_COLLASPE_SIDEBAR, collaspe.toString());
};
