import * as React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Label } from './input';
import { CalendarIcon } from '../icons';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { InputType } from './customSelect';

export interface DatePickerProps {
  value?: any;
  inputFormat?: string;
  label?: string;
  onChange?: (value: any) => void;
  inputType?: InputType;
  disabled?: boolean;
  isRequired?: boolean;
}

const CustomDate = styled(DatePicker)<{ $inputType?: InputType }>`
  .MuiOutlinedInput-input {
    border: none;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px;
    background: ${props =>
      props.$inputType === InputType.MAPPING ? '#40495C' : '#fff'};
    color: ${props =>
      props.$inputType === InputType.MAPPING ? '#fff' : '#222222'};
    border: ${props =>
      props.$inputType === InputType.MAPPING ? 'none' : '1px solid #dee1eb'};
  }
  fieldset {
    border: none !important;
  }
`;

export default function CustomDatePicker({
  value,
  inputFormat = 'MM/dd/yyyy',
  label = '',
  onChange,
  disabled,
  isRequired,
  inputType = InputType.POP_UP,
}: DatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="div">
        {label && (
          <Label $fromType={inputType}>
            {label} {isRequired && <span>*</span>}
          </Label>
        )}
        <CustomDate
          $inputType={inputType}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          slotProps={{ textField: { size: 'small', placeholder: '' } }}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </Box>
    </LocalizationProvider>
  );
}
