export const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="none"
  >
    <path
      d="M3.5509 8.44126L3.5326 8.45957L0 4.92697L1.48862 3.43836L3.55096 5.5007L8.51138 0.540283L10 2.0289L3.56927 8.45963L3.5509 8.44126Z"
      fill="white"
    />
  </svg>
);
