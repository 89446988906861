import { useAppSelector, useAppDispatch } from '@/hooks';

import { selectFloor } from '@/stores/slices/floor/selector';
import {
  getFloorLevelsRequest,
  getFloorDetailsRequest,
  getFloorListRequest,
  getFloorListOptionRequest,
  updateFloorInfoRequest,
  deleteFloorInfoRequest,
  createFloorInfoRequest,
  updateFloorPublishRequest,
  deleteMultiFloorInfoRequest,
  resetFloorInfoRequestStatus,
} from '@/stores/slices/floor/floor';

const useFloorHook = () => {
  const {
    floorLevels,
    getFloorLevelsStatus,
    updateFloorPublishStatus,
    deleteMultiFloorInfoStatus,
    getFloorDetailsStatus,
    getFloorListStatus,
    updateFloorInfoStatus,
    createFloorInfoStatus,
    deleteFloorInfoStatus,
    getFloorListOptionStatus,
    floorDetails,
    floors,
    floorOptions,
  } = useAppSelector(selectFloor);

  const dispatch = useAppDispatch();

  const requestFloorLevels = (params: any) =>
    dispatch(getFloorLevelsRequest(params));

  const requestFloorList = (params: any) =>
    dispatch(getFloorListRequest(params));

  const requestFloorListOption = (params: any) =>
    dispatch(getFloorListOptionRequest(params));

  const requestFloorDetails = (params: any) =>
    dispatch(getFloorDetailsRequest(params));

  const requestUpdateFloorInfo = (params: any) =>
    dispatch(updateFloorInfoRequest(params));

  const requestUpdateMultiFloorInfo = (params: any) =>
    dispatch(updateFloorPublishRequest(params));

  const requestCreateFloor = (params: any) =>
    dispatch(createFloorInfoRequest(params));

  const requestDeleteFloor = (params: any) =>
    dispatch(deleteFloorInfoRequest(params));

  const requestMultiDeleteFloor = (params: any) =>
    dispatch(deleteMultiFloorInfoRequest(params));

  const requestResetFloor = () => dispatch(resetFloorInfoRequestStatus());

  return {
    requestFloorList,
    requestFloorDetails,
    requestUpdateFloorInfo,
    requestCreateFloor,
    requestDeleteFloor,
    requestResetFloor,
    requestUpdateMultiFloorInfo,
    requestMultiDeleteFloor,
    updateFloorPublishStatus,
    deleteMultiFloorInfoStatus,
    getFloorDetailsStatus,
    getFloorListStatus,
    updateFloorInfoStatus,
    createFloorInfoStatus,
    deleteFloorInfoStatus,
    floorDetails,
    floors,
    floorLevels,
    getFloorLevelsStatus,
    floorOptions,
    getFloorListOptionStatus,
    requestFloorLevels,
    requestFloorListOption,
  };
};

export default useFloorHook;
