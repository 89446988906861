/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { NoData } from '@/components/common/NoData';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
// import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { checkIsRequesting } from '@/utils/helper';
import { RequestStatus } from '@/constants/API';
import { Container } from '@/components/venue/components';
import { useLocation, useParams } from 'react-router-dom';
import useQRCodeHook from '@/hooks/useQRCodeHook';
// import qrcode from '@/stores/slices/qrcode/qrcode';
import queryString from 'query-string';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import _ from 'lodash';
import { Filter } from '@/components/qrcode/filter';
import useFloorHook from '@/hooks/useFloorHook';
import useLocationHook from '@/hooks/useLocationHook';

export const QRCodePage = () => {
  // const { t } = useTranslation();

  const location = useLocation();
  const queries = useParams();

  const { getFloorListOptionStatus, requestFloorListOption } = useFloorHook();
  const { getLocationToVenueStatus, requestLocationsToVenueList } =
    useLocationHook();

  const {
    getQRCodeListStatus,
    requestQRCodeList,
    getQRCodeDetailStatus,
    requestResetQRCode,
    qrcodes,
  } = useQRCodeHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getQRCodeListStatus,
        getQRCodeDetailStatus,
        getFloorListOptionStatus,
        getLocationToVenueStatus,
      ]),
    [
      getQRCodeListStatus,
      getQRCodeDetailStatus,
      getFloorListOptionStatus,
      getLocationToVenueStatus,
    ],
  );

  const showNoData = useMemo(
    () =>
      getQRCodeListStatus !== RequestStatus.IDLE &&
      (qrcodes?.data?.length === 0 || !qrcodes),
    [qrcodes, getQRCodeListStatus],
  );
  const navigate = useNavigateHook()?.navigate;
  const redirect = useCallback(
    (qr: any) => {
      navigate(
        `${location.pathname}?${queryString.stringify(
          _.omitBy(
            qr,
            v => !v || (Array.isArray(v) && _.isEmpty(v)) || _.isNil(v),
          ),
        )}`,
      );
    },
    [location.pathname, navigate],
  );

  const onChangePage = (id: number) => {
    let params = queryString.parse(window.location.search);
    params.page = id.toString();
    redirect(params);
  };

  const onAdd = () => {};
  const onEdit = () => {};
  const onDelete = () => {};

  const handleDebounceFn = (inputValue: string) => {
    let params = queryString.parse(window.location.search);
    params.keyword = inputValue;
    delete params.page;
    redirect(params);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  const onKeywordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget?.value;
    debounceFn(keyword);
  };

  const onLevelChange = (e: any) => {
    let params = queryString.parse(window.location.search);
    params.level = e.target?.value;
    delete params.page;
    redirect(params);
  };

  const onLocationChange = (e: any) => {
    let params = queryString.parse(window.location.search);
    params.location = e.target?.value;
    delete params.page;
    redirect(params);
  };

  const pagination = {
    currentPage: qrcodes?.current_page ?? 1,
    totalPages: qrcodes?.total_page ?? 1,
    onPageChange: (id: number) => onChangePage(id),
  };

  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    requestQRCodeList(
      queries?.venueId ? { ...params, venue: queries?.venueId } : { ...params },
    );
  };

  useEffect(() => {
    requestFloorListOption({ venue: queries?.venueId });
    requestLocationsToVenueList({ venue: queries?.venueId });
    return () => requestResetQRCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** fetch data based on query string */
  useEffect(() => {
    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <Container>
      <Filter
        onAdd={onAdd}
        onKeywordChange={onKeywordChange}
        onLevelChange={onLevelChange}
        onLocationChange={onLocationChange}
      />
      {showNoData ? (
        <NoData />
      ) : (
        <CommonTable
          type={TableType.QRCODE}
          data={qrcodes?.data}
          pagination={pagination}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )}
      <Loader show={loading} />
    </Container>
  );
};
