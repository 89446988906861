import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  getEventListRequest,
  getEventListSuccess,
  getEventListFailure,
  getEventDetailsRequest,
  getEventDetailsSuccess,
  getEventDetailsFailure,
  createEventInfoRequest,
  createEventInfoSuccess,
  createEventInfoFailure,
  updateEventInfoRequest,
  updateEventInfoSuccess,
  updateEventInfoFailure,
  deleteEventInfoRequest,
  deleteEventInfoSuccess,
  deleteEventInfoFailure,
  getEventTagListRequest,
  getEventTagListFailure,
  getEventTagListSuccess,
} from '../slices/event/event';

type IPayload = Record<string, any>;

function* getEventListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getEvents, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getEventListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getEventListFailure.type });
    }
  } catch (error) {
    yield put({ type: getEventListFailure.type });
  }
}

function* getEventTagListFlow(): any {
  try {
    const response = yield call(SERVICES.getEventTags);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getEventTagListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getEventTagListFailure.type });
    }
  } catch (error) {
    yield put({ type: getEventTagListFailure.type });
  }
}

function* getEventDetailsFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getEventDetails, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getEventDetailsSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getEventDetailsFailure.type });
    }
  } catch (error) {
    yield put({ type: getEventDetailsFailure.type });
  }
}

function* createEventFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createEvent, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: createEventInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createEventInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createEventInfoFailure.type });
  }
}

function* updateEventFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateEvent, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateEventInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateEventInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: updateEventInfoFailure.type });
  }
}

function* deleteEventFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteEvent, payload);

    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteEventInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteEventInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteEventInfoFailure.type });
  }
}
function* eventSaga() {
  yield takeLatest(getEventListRequest, getEventListFlow);
  yield takeLatest(getEventTagListRequest, getEventTagListFlow);
  yield takeLatest(createEventInfoRequest, createEventFlow);
  yield takeLatest(updateEventInfoRequest, updateEventFlow);
  yield takeLatest(deleteEventInfoRequest, deleteEventFlow);
  yield takeLatest(getEventDetailsRequest, getEventDetailsFlow);
}

export default eventSaga;
