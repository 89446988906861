/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { NoData } from '@/components/common/NoData';
import { Container } from '@/components/venue/components';
import { RequestStatus } from '@/constants/API';
import useAmenityHook from '@/hooks/useAmenityHook';
import useGlobalHook from '@/hooks/useGlobalHook';
import { checkIsRequesting } from '@/utils/helper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { CommonType } from '@/stores/slices/amenity/type';
import _ from 'lodash';
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { AmenityPopup } from '@/components/amenity/components/popup';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { Filter } from '@/components/amenity/filter';
import queryString from 'query-string';

export const AmenityPage = () => {
  const {
    getAmenityListStatus,
    requestAmenityList,
    createAmenityInfoStatus,
    requestCreateAmenityInfo,
    updateAmenityInfoStatus,
    requestUpdateAmenityInfo,
    deleteAmenityInfoStatus,
    requestDeleteAmenityInfo,
    amenities,
    requestResetAmenity,
    setVenueAmenitiesStatus,
  } = useAmenityHook();
  const { t } = useTranslation();

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [data, setData] = useState<CommonType[]>(amenities ?? []);

  const [selectedId, setSelectedId] = useState<string | number>('');
  const [editingAmenity, setEditingAmenity] = useState<CommonType | undefined>(
    undefined,
  );

  const location = useLocation();
  const queries = useParams();

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getAmenityListStatus,
        setVenueAmenitiesStatus,
        createAmenityInfoStatus,
        updateAmenityInfoStatus,
        deleteAmenityInfoStatus,
      ]),
    [
      getAmenityListStatus,
      setVenueAmenitiesStatus,
      createAmenityInfoStatus,
      updateAmenityInfoStatus,
      deleteAmenityInfoStatus,
    ],
  );

  const showNoData = useMemo(() => {
    return (
      getAmenityListStatus !== RequestStatus.IDLE &&
      (!data.length || !amenities)
    );
  }, [amenities, data.length, getAmenityListStatus]);

  const navigate = useNavigateHook()?.navigate;
  const redirect = useCallback(
    (qr: any) => {
      if (qr.keyword) {
        setData(
          amenities?.filter(
            (it: CommonType) =>
              it.name?.toLowerCase().includes(qr.keyword?.toLowerCase()),
          ),
        );
      } else {
        setData(amenities);
      }

      navigate(
        `${location.pathname}?${queryString.stringify(
          _.omitBy(
            qr,
            v => !v || (Array.isArray(v) && _.isEmpty(v)) || _.isNil(v),
          ),
        )}`,
      );
    },
    [amenities, location.pathname, navigate],
  );

  const reloadData = () => {
    requestAmenityList({ venue: queries?.venueId });
  };

  const onAdd = () => {
    setEditingAmenity(undefined);
    setOpenPopup(true);
  };

  const submitAddOrEdit = (formData: Omit<CommonType, 'id'>) => {
    if (editingAmenity) {
      requestUpdateAmenityInfo({
        ..._.omitBy(formData, it => !it),
        id: editingAmenity.id,
        venue: queries?.venueId,
      });
    } else {
      requestCreateAmenityInfo({
        ..._.omitBy(formData, it => !it),
        venue: queries?.venueId,
      });
    }
    setOpenPopup(false);
  };

  const onEdit = (id: number | string) => {
    setEditingAmenity(amenities?.find((it: CommonType) => it.id === id));
    setOpenPopup(true);
  };

  const onDelete = (id: number | string) => {
    setOpenDelete(true);
    setSelectedId(id);
  };

  const submitDelete = () => {
    requestDeleteAmenityInfo(selectedId);
  };

  const handleDebounceFn = (inputValue: string) => {
    let params = queryString.parse(window.location.search);
    params.keyword = inputValue;
    delete params.page;
    redirect(params);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  const onKeywordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget?.value;
    debounceFn(keyword);
  };

  useEffect(() => {
    return () => requestResetAmenity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateAmenityInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Amenity updated!')]);
      reloadData();
    }
    if (updateAmenityInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update amenity')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAmenityInfoStatus]);

  useEffect(() => {
    if (createAmenityInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Amenity created!')]);
      reloadData();
    }

    if (createAmenityInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to create amenity')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAmenityInfoStatus]);

  useEffect(() => {
    if (deleteAmenityInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to delete amenity')]);
    }
    if (deleteAmenityInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Amenity deleted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAmenityInfoStatus]);

  useEffect(() => {
    if (getAmenityListStatus === RequestStatus.SUCCESS) {
      setData(amenities);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAmenityListStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Container>
      <Filter
        onAdd={onAdd}
        // onExport={onExport}
        onKeywordChange={onKeywordChange}
      />
      {showNoData ? (
        <NoData />
      ) : (
        <CommonTable
          type={TableType.AMENITY}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )}
      <AmenityPopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={submitAddOrEdit}
        data={editingAmenity}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onSubmit={submitDelete}
      />
      <Loader show={loading} />
    </Container>
  );
};
