import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  getLocationListRequest,
  getLocationListSuccess,
  getLocationListFailure,
  getLocationDetailsRequest,
  getLocationDetailsSuccess,
  getLocationDetailsFailure,
  createLocationInfoRequest,
  createLocationInfoSuccess,
  createLocationInfoFailure,
  updateLocationInfoRequest,
  updateLocationInfoSuccess,
  updateLocationInfoFailure,
  deleteLocationInfoRequest,
  deleteLocationInfoSuccess,
  deleteLocationInfoFailure,
  getTagListRequest,
  getTagListSuccess,
  getTagListFailure,
  getLocationsToVenueFailure,
  getLocationsToVenueRequest,
  getLocationsToVenueSuccess,
} from '../slices/location/location';

type IPayload = Record<string, any>;

function* getLocationListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getLocations, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getLocationListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getLocationListFailure.type });
    }
  } catch (error) {
    yield put({ type: getLocationListFailure.type });
  }
}

function* getLocationsToVenueFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getAllLocations, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getLocationsToVenueSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getLocationsToVenueFailure.type });
    }
  } catch (error) {
    yield put({ type: getLocationsToVenueFailure.type });
  }
}

function* getTagListFlow(): any {
  try {
    const response = yield call(SERVICES.getTags);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getTagListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getTagListFailure.type });
    }
  } catch (error) {
    yield put({ type: getTagListFailure.type });
  }
}

function* getLocationDetailsFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getLocationDetails, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getLocationDetailsSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getLocationDetailsFailure.type });
    }
  } catch (error) {
    yield put({ type: getLocationDetailsFailure.type });
  }
}

function* createLocationFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createLocation, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createLocationInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createLocationInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createLocationInfoFailure.type });
  }
}

function* updateLocationFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateLocation, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateLocationInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateLocationInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: updateLocationInfoFailure.type });
  }
}

function* deleteLocationFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteLocation, payload);

    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteLocationInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteLocationInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteLocationInfoFailure.type });
  }
}
function* locationSaga() {
  yield takeLatest(getLocationListRequest, getLocationListFlow);
  yield takeLatest(getLocationsToVenueRequest, getLocationsToVenueFlow);
  yield takeLatest(getTagListRequest, getTagListFlow);
  yield takeLatest(createLocationInfoRequest, createLocationFlow);
  yield takeLatest(updateLocationInfoRequest, updateLocationFlow);
  yield takeLatest(deleteLocationInfoRequest, deleteLocationFlow);
  yield takeLatest(getLocationDetailsRequest, getLocationDetailsFlow);
}

export default locationSaga;
