import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { qrcodeSlice } from './qrcode';

const selectSlice: any = (state: RootState) =>
  state.qrcode || qrcodeSlice.getInitialState;

export const selectQRCode = createSelector([selectSlice], qrcodeState => ({
  ...qrcodeState,
}));
