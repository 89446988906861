import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { Autocomplete, TextField } from '@mui/material';
import SERVICES from '@/services';

interface AutocompleteComponentProps {
  onSelect: (address: string, lat: number, long: number) => void;
}

const AutocompleteComponent: React.FC<AutocompleteComponentProps> = ({
  onSelect,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetchGeoSearch = useRef(
    debounce(async value => {
      const { data, status } = await SERVICES.geoSearch({ address: value });
      const geoDatas = data.results.map((e: any) => ({
        label: e.formatted_address,
        location: e.geometry.location,
      }));
      console.log(geoDatas);
      setOptions(geoDatas);
    }, 500),
  ).current;

  useEffect(() => {
    if (inputValue) {
      fetchGeoSearch(inputValue);
      return () => {
        fetchGeoSearch.cancel();
      };
    } else {
      setOptions([]);
    }
  }, [inputValue]);
  return (
    <Autocomplete
      autoComplete
      filterOptions={x => x}
      includeInputInList
      filterSelectedOptions
      options={options}
      getOptionLabel={option => (option as any).label}
      noOptionsText="No locations"
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        const option = value as any;
        if (option) {
          console.log(option.label);
          console.log(option.location);
          onSelect(option.label, option.location.lat, option.location.lng);
        }
      }}
      renderInput={params => (
        <TextField {...params} label="Geo Search" variant="outlined" />
      )}
    />
  );
};

export default AutocompleteComponent;
