import { useAppSelector, useAppDispatch } from '@/hooks';

import { selectAuth } from '@/stores/slices/auth/selector';
import {
  loginRequest,
  logoutRequest,
  updatePasswordRequest,
  updateProfileRequest,
} from '@/stores/slices/auth/auth';
import { LoginFormState } from '@/pages/login';

const useAuthHook = () => {
  const {
    loginRequestStatus,
    userData,
    errorMess,
    updateProfileRequestStatus,
    updatePasswordRequestStatus,
  } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const requestLogin = (params: LoginFormState) =>
    dispatch(loginRequest(params));

  const requestUpdateProfile = (params: any) =>
    dispatch(updateProfileRequest(params));

  const requestChangePassword = (params: any) =>
    dispatch(updatePasswordRequest(params));

  const logout = () => dispatch(logoutRequest());

  return {
    loginRequestStatus,
    requestLogin,
    userData,
    logout,
    requestUpdateProfile,
    requestChangePassword,
    errorMess,
    updateProfileRequestStatus,
    updatePasswordRequestStatus,
  };
};

export default useAuthHook;
