import { ReactComponent as ClosingEyeIcon } from '@/assets/icons/close_eye.svg';
import { ReactComponent as EyeIcon } from '@/assets/icons/eye.svg';
import GradienBackground from '@/assets/bg.png';
import logo from '@/assets/big_logo.svg';

import { checkIsRequesting } from '@/utils/helper';
import React, { useEffect, useMemo, useState } from 'react';
import useAuthHook from '@/hooks/useAuthHook';
import useGlobalHook from '@/hooks/useGlobalHook';
import { Loader } from '@/components/common/loader';
import { RequestStatus } from '@/constants/API';
import { useNavigate } from 'react-router-dom';
import Path from '@/constants/path';
import { getToken } from '@/utils/localStorage';
import {
  Container,
  ErrorMessage,
  FormGroup,
  Input,
  Label,
  LoginContainer,
  LoginForm,
  LoginWrapper,
  PasswordInputContainer,
  PasswordToggleIcon,
  SubmitButton,
  Title,
} from '@/components/login';
import { Badge, LogoWrapper } from '@/components/layouts/sidebar/components';
import { useTranslation } from 'react-i18next';

export interface LoginFormState {
  username: string;
  password: string;
}

const initialFormState: LoginFormState = {
  username: '',
  password: '',
};

const Login: React.FC = () => {
  const { t } = useTranslation();

  const { loginRequestStatus, requestLogin, errorMess } = useAuthHook();
  const { setErrorMsg } = useGlobalHook();
  const [formData, setFormData] = useState<LoginFormState>(initialFormState);
  const [errors, setErrors] =
    useState<Partial<LoginFormState>>(initialFormState);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const loading = useMemo(
    () => checkIsRequesting([loginRequestStatus]),
    [loginRequestStatus],
  );

  const token = getToken();

  const navigate = useNavigate();

  useEffect(() => {
    if (loginRequestStatus === RequestStatus.SUCCESS || token) {
      navigate(Path.HOME);
    }
    if (loginRequestStatus === RequestStatus.ERROR) {
      setErrorMsg(errorMess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginRequestStatus, token]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const newErrors: Partial<LoginFormState> = {};

    if (!formData.username) {
      newErrors.username = t('Username is required');
    }

    if (!formData.password) {
      newErrors.password = t('Password is required');
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      requestLogin(formData);
    }
  };

  return (
    <Container
      style={{
        backgroundImage: `url(${GradienBackground})`,
      }}
    >
      <LogoWrapper className="big_logo" to={Path.HOME}>
        <img src={logo} className="App-logo" alt="logo" />
        <Badge>CMS</Badge>
      </LogoWrapper>

      <LoginContainer>
        <LoginWrapper>
          <Title>{t('Login')}</Title>

          <LoginForm onSubmit={handleSubmit}>
            <FormGroup>
              <Label>{t('Username')}:</Label>
              <PasswordInputContainer>
                <Input
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  error={errors.username}
                />
              </PasswordInputContainer>
              {errors.username && (
                <ErrorMessage>{errors.username}</ErrorMessage>
              )}
            </FormGroup>
            <FormGroup>
              <Label>{t('Password')}:</Label>
              <PasswordInputContainer>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={errors.password}
                />
                <PasswordToggleIcon onClick={handleTogglePasswordVisibility}>
                  {showPassword ? <ClosingEyeIcon /> : <EyeIcon />}
                </PasswordToggleIcon>
              </PasswordInputContainer>
              {errors.password && (
                <ErrorMessage>{errors.password}</ErrorMessage>
              )}
            </FormGroup>
            <SubmitButton type="submit">{t('Submit')}</SubmitButton>
          </LoginForm>
        </LoginWrapper>
      </LoginContainer>
      <Loader show={loading} />
    </Container>
  );
};

export default Login;
