import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as PrevIcon } from '@/assets/icons/chevron_left.svg';
import { ReactComponent as NextIcon } from '@/assets/icons/chevron_right.svg';

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
}

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button<{ buttonactive: string; disabled?: boolean }>`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 5px 9px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.buttonactive === 'true' ? '#3080EC' : 'rgba(255, 255, 255, 0.00)'};
  color: ${props => (props.buttonactive === 'true' ? '#fff' : '40495C')};
  border-radius: 2px;
  border: 1px solid #babec9;
  cursor: pointer;
  svg {
    opacity: ${props => (props.disabled ? '0.3' : '1')};
  }
`;

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const [activePage, setActivePage] = useState<number>(currentPage);

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage >= 1 && newPage <= totalPages) {
        onPageChange(newPage);
        setActivePage(newPage);
      }
    },
    [onPageChange, totalPages],
  );

  return (
    <PaginationContainer>
      <PageButton
        onClick={() => handlePageChange(activePage - 1)}
        disabled={activePage === 1}
        buttonactive={`false`}
      >
        <PrevIcon />
      </PageButton>
      {[...Array(totalPages)].map((_, index) => (
        <PageButton
          key={index}
          onClick={() => handlePageChange(index + 1)}
          buttonactive={activePage === index + 1 ? 'true' : 'false'}
        >
          {index + 1}
        </PageButton>
      ))}
      <PageButton
        onClick={() => handlePageChange(activePage + 1)}
        disabled={activePage === totalPages}
        buttonactive={`false`}
      >
        <NextIcon />
      </PageButton>
    </PaginationContainer>
  );
};

export default Pagination;
