import { FilterBar, FilterType } from '@/components/common/FilterBar';
import { FormWrapper } from '@/components/venue/components';
import { Controller, useForm } from 'react-hook-form';
import MultipleSelectCustomize from 'src/components/form/customSelect';
import Input from '@/components/form/input';
import { EventFilterFormData } from '@/types';
import { ChangeEvent, useCallback, useEffect } from 'react';
import {
  transformSelectOptions,
  transformSelectOptionsWithTranslation,
} from '@/utils/helper';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import useEventHook from '@/hooks/useEventHook';
import MultiSelectItem from '../form/multiselect';
import { EVENT_TYPES } from '@/constants';
import { SelectChangeEvent } from '@mui/material';

type FilterProps = {
  onAdd: () => void;
  onKeywordChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onTagChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onTypeChange: (e: SelectChangeEvent<any>) => void;
};

export const Filter = ({
  onAdd,
  // onExport,
  onKeywordChange,
  onTagChange,
  onTypeChange,
}: FilterProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  let params = queryString.parse(window.location.search);
  const { control, reset } = useForm<EventFilterFormData>({
    defaultValues: {
      keyword: '',
      tags: [],
      type: [],
    },
  });

  const convertTags = useCallback((tags: any) => {
    if (Array.isArray(tags)) {
      return tags.map(it => it);
    }
    return tags ? [tags] : [];
  }, []);

  useEffect(() => {
    reset({
      keyword: Array.isArray(params?.keyword)
        ? params?.keyword[0] ?? ''
        : params?.keyword ?? undefined,
      type: convertTags(params?.type),
      tags: convertTags(params?.tags),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const { eventTags } = useEventHook();
  return (
    <FilterBar
      type={FilterType.GENERIC}
      onAdd={onAdd}
      // onExport={onExport}
      hasButton
      buttonText={t('Add new event')}
    >
      <FormWrapper>
        <FormWrapper>
          <Controller
            control={control}
            name="type"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <MultiSelectItem
                  options={transformSelectOptionsWithTranslation(
                    EVENT_TYPES,
                    t,
                  )}
                  value={value ?? []}
                  onChange={e => {
                    onChange(e);
                    onTypeChange(e);
                  }}
                  placeholder={t('Type')}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="tags"
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <MultipleSelectCustomize
                  options={transformSelectOptions(eventTags)}
                  onChange={val => {
                    onChange(val);
                    onTagChange(val);
                  }}
                  value={value || []}
                  sx={{
                    '& .multiple-container': {
                      minWidth: '200px',
                    },
                  }}
                  placeholder={t('Tags')}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="keyword"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  inputType={1}
                  onChange={e => {
                    onChange(e);
                    onKeywordChange(e);
                  }}
                  placeholder={t('Search')}
                  value={value}
                />
              );
            }}
          />
        </FormWrapper>
      </FormWrapper>
    </FilterBar>
  );
};
