import { Box } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';
import { ClockIcon } from '../icons';
import { InputType } from './customSelect';
import { Label } from './input';

export interface CustomTimePickerProps {
  value?: any;
  inputFormat?: string;
  label?: string;
  max?: number;
  min?: number;
  onChange: (value: any) => void;
  onAccept?: (value: any) => void;
  inputType?: InputType;
  disabled?: boolean;
  isRequired?: boolean;
}

const CustomTime = styled(TimePicker)<{ $inputType?: InputType }>`
  .MuiOutlinedInput-input {
    border: none;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px;
    background: ${props =>
      props.$inputType === InputType.MAPPING ? '#40495C' : '#fff'};
    color: ${props =>
      props.$inputType === InputType.MAPPING ? '#fff' : '#222222'};
    border: ${props =>
      props.$inputType === InputType.MAPPING ? 'none' : '1px solid #dee1eb'};
  }
  fieldset {
    border: none !important;
  }
  .MuiButtonBase-root svg path {
    fill: ${props =>
      props.$inputType === InputType.MAPPING ? '#fff' : '#202634 '};
  }
`;

export default function CustomTimePicker({
  value,
  inputFormat = 'MM/dd/yyyy',
  label = '',
  onChange,
  max = 22,
  min = 9,
  inputType = InputType.POP_UP,
  disabled,
  isRequired,
  onAccept,
  ...rest
}: CustomTimePickerProps) {
  const [val, setVal] = useState<any>(dayjs(value));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="div">
        {label && (
          <Label $fromType={inputType}>
            {label} {isRequired && <span>*</span>}
          </Label>
        )}
        <CustomTime
          $inputType={inputType}
          slots={{
            openPickerIcon: ClockIcon,
          }}
          slotProps={{ textField: { size: 'small', placeholder: '' } }}
          views={['hours', 'minutes']}
          value={val}
          onChange={va => {
            setVal(va);
            onChange(va);
          }}
          onAccept={onAccept}
          ampmInClock={false}
          ampm={false}
          disabled={disabled}
        />
      </Box>
    </LocalizationProvider>
  );
}
