import { useAppSelector, useAppDispatch } from '@/hooks';

import { selectSnapshot } from '@/stores/slices/snapshot/selector';

import {
  getSnapshotListRequest,
  deleteSnapshotRequest,
  resetSnapshotRequestStatus,
} from '@/stores/slices/snapshot/snapshot';

const useSnapshotHook = () => {
  const { getSnapshotListStatus, deleteSnapshotStatus, snapshots } =
    useAppSelector(selectSnapshot);

  const dispatch: any = useAppDispatch();

  const requestSnapshotList = (params: any) =>
    dispatch(getSnapshotListRequest(params));

  const requestDeleteSnapshot = (params: any) =>
    dispatch(deleteSnapshotRequest(params));

  const requestResetSnapshot = () => dispatch(resetSnapshotRequestStatus());

  return {
    requestSnapshotList,
    requestDeleteSnapshot,
    requestResetSnapshot,
    getSnapshotListStatus,
    deleteSnapshotStatus,
    snapshots,
  };
};

export default useSnapshotHook;
