/* eslint-disable max-lines */
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { Loader } from '@/components/common/loader';
import { NoData } from '@/components/common/NoData';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { Filter } from '@/components/event/filter';
import { Container } from '@/components/venue/components';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import useEventHook from '@/hooks/useEventHook';
import { EventDetailsForm } from '@/stores/slices/event/type';
import { checkIsRequesting } from '@/utils/helper';
import _ from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLocationHook from '@/hooks/useLocationHook';
import { Event } from '@/components/event/components/event';

export const EventPage = () => {
  const { t } = useTranslation();
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [selectedId, setSelectedId] = useState<string | number>('');

  const location = useLocation();
  const queries = useParams();

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const { requestLocationsToVenueList, getLocationToVenueStatus } =
    useLocationHook();

  const isEditingEvent = useMemo(
    () => location.pathname.includes('edit'),
    [location.pathname],
  );
  const isAddingEvent = useMemo(
    () => location.pathname.includes('add'),
    [location.pathname],
  );

  const {
    getEventListStatus,
    requestEventList,
    createEventInfoStatus,
    requestCreateEvent,
    requestUpdateEventInfo,
    events,
    deleteEventInfoStatus,
    updateEventInfoStatus,
    requestDeleteEvent,
    requestResetEvent,
    requestEventTagList,
    getEventTagListStatus,
    getEventDetailsStatus,
    eventDetails,
    requestEventDetails,
  } = useEventHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getEventListStatus,
        createEventInfoStatus,
        deleteEventInfoStatus,
        updateEventInfoStatus,
        getLocationToVenueStatus,
        getEventTagListStatus,
        getEventDetailsStatus,
      ]),
    [
      getEventListStatus,
      createEventInfoStatus,
      deleteEventInfoStatus,
      updateEventInfoStatus,
      getLocationToVenueStatus,
      getEventTagListStatus,
      getEventDetailsStatus,
    ],
  );

  const showNoData = useMemo(() => {
    return (
      getEventListStatus !== RequestStatus.IDLE &&
      (events?.data?.length === 0 || !events)
    );
  }, [events, getEventListStatus]);

  const navigate = useNavigateHook()?.navigate;
  const redirect = useCallback(
    (qr: any) => {
      navigate(
        `${location.pathname}?${queryString.stringify(
          _.omitBy(
            qr,
            v => !v || (Array.isArray(v) && _.isEmpty(v)) || _.isNil(v),
          ),
        )}`,
      );
    },
    [location.pathname, navigate],
  );
  const onChangePage = (id: number) => {
    let params = queryString.parse(window.location.search);
    params.page = id.toString();
    redirect(params);
  };

  // const onExport = () => {};

  const onAdd = () => {
    navigate(`${location.pathname}/add/`);
  };

  const submitAddOrEdit = (formData: EventDetailsForm) => {
    if (isEditingEvent) {
      requestUpdateEventInfo({
        ..._.omitBy(formData, it => !it),
        id: queries?.eventId,
        venue: queries?.venueId,
      });
    } else {
      requestCreateEvent({
        ..._.omitBy(formData, it => !it),
        venue: queries?.venueId,
      });
    }
  };

  const onEdit = (id: number | string) => {
    navigate(`${location.pathname}/edit/${id}`);
  };

  const onDelete = (id: number | string) => {
    setOpenDelete(true);
    setSelectedId(id);
  };

  const submitDelete = () => {
    requestDeleteEvent(selectedId);
  };

  const handleDebounceFn = (inputValue: string) => {
    let params = queryString.parse(window.location.search);
    params.keyword = inputValue;
    delete params.page;
    redirect(params);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  const onKeywordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget?.value;
    debounceFn(keyword);
  };

  const onTagChange = (e: any) => {
    let params = queryString.parse(window.location.search);
    params.tags = e?.map((it: any) => it.value);
    delete params.page;
    redirect(params);
  };

  const onTypeChange = (e: any) => {
    let params = queryString.parse(window.location.search);
    params.type = e;
    delete params.page;
    redirect(params);
  };

  const pagination = {
    currentPage: events?.current_page ?? 1,
    totalPages: events?.total_page ?? 1,
    onPageChange: (id: number) => onChangePage(id),
  };

  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    requestEventList({ ...params, venue: queries?.venueId });
  };

  useEffect(() => {
    requestLocationsToVenueList({ venue: queries?.venueId });
    requestEventTagList();
    return () => requestResetEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateEventInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Event updated!')]);
      navigate(
        `/customers/${queries?.customerId}/venues/${queries?.venueId}/events`,
      );
    }
    if (updateEventInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update event')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEventInfoStatus]);

  useEffect(() => {
    if (createEventInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Event created!')]);
      navigate(
        `/customers/${queries?.customerId}/venues/${queries?.venueId}/events`,
      );
    }

    if (createEventInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to create event')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createEventInfoStatus]);

  useEffect(() => {
    if (deleteEventInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to delete event')]);
    }
    if (deleteEventInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Event deleted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEventInfoStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    if (
      !location.pathname.includes('edit') &&
      !location.pathname.includes('add')
    ) {
      reloadData();
    } else if (location.pathname.includes('edit')) {
      requestEventDetails({ id: queries?.eventId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <Container>
      {isEditingEvent || isAddingEvent ? (
        <Event
          onSubmit={submitAddOrEdit}
          data={isAddingEvent ? null : eventDetails}
        />
      ) : (
        <>
          <Filter
            onAdd={onAdd}
            // onExport={onExport}
            onKeywordChange={onKeywordChange}
            onTagChange={onTagChange}
            onTypeChange={onTypeChange}
          />
          {showNoData ? (
            <NoData />
          ) : (
            <CommonTable
              type={TableType.EVENT}
              data={events?.data}
              pagination={pagination}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
          <ConfirmPopUp
            title={t('Alert')}
            onClose={() => setOpenDelete(false)}
            open={openDelete}
            onSubmit={submitDelete}
          />
        </>
      )}

      <Loader show={loading} />
    </Container>
  );
};
