import { createSelector } from '@reduxjs/toolkit';
import { globalSlice } from './global';
import { RootState } from '../..';

const selectSlice = (state: RootState) =>
  state.global || globalSlice.getInitialState;

export const selectGlobal = createSelector([selectSlice], globalState => ({
  ...globalState,
}));
