import { createSelector } from '@reduxjs/toolkit';
import { categorySlice } from './category';
import { RootState } from '../..';

const selectSlice = (state: RootState) =>
  state.category || categorySlice.getInitialState;

export const selectCategory = createSelector([selectSlice], categoryState => ({
  ...categoryState,
}));
