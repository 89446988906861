import { Box, Button } from '@mui/material';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  > div {
    width: calc(50% - 10px);
  }
  &.single {
    > div {
      width: 100%;
    }
  }
  &.mt1 {
    margin-top: 20px;
  }
`;

export const Container = styled(Box)`
  width: 100%;
  height: auto;
  overflow: auto;
`;

export const DeleteButton = styled(Button)`
  && {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #eb5757;
    background: #fff;
    color: #eb5757;
    font-size: 14px;
    text-transform: none;
    white-space: nowrap;
    &:hover {
      opacity: 0.8;
      background: #fff;
    }
  }
`;
