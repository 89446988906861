import { useAppSelector, useAppDispatch } from '@/hooks';

import { selectVenue } from '@/stores/slices/venue/selector';
import {
  getVenueDetailsRequest,
  getVenueListRequest,
  getVenueOptionsRequest,
  updateVenueInfoRequest,
  deleteVenueInfoRequest,
  createVenueInfoRequest,
  resetVenueInfoRequestStatus,
  getProvincesRequest,
  updateVenuePublishRequest,
  deleteMultiVenueInfoRequest,
  duplicateVenueRequest,
} from '@/stores/slices/venue/venue';
import _ from 'lodash';
import React, { useEffect } from 'react';

const useVenueHook: any = () => {
  const {
    getVenueDetailsStatus,
    getVenueListStatus,
    updateVenueInfoStatus,
    createVenueInfoStatus,
    deleteVenueInfoStatus,
    getProvincesStatus,
    deleteMultiVenueInfoStatus,
    updateVenuePublishStatus,
    venueDetails,
    venues,
    venueOptions,
    getVenueOptionsStatus,
    provinces,
    duplicateVenueStatus,
  } = useAppSelector(selectVenue);

  const dispatch = useAppDispatch();
  const [venueOptionsList, setVenueOptionsList] = React.useState<any[]>([]);

  useEffect(() => {
    if (venueOptions?.length) {
      const newOptions = _.map(venueOptions, v => ({
        value: v.id,
        label: v.name,
      }));
      setVenueOptionsList(newOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueOptions]);

  const requestVenueOptions = (params: any) =>
    dispatch(getVenueOptionsRequest(params));

  const requestVenueList = (params: any) =>
    dispatch(getVenueListRequest(params));

  const requestVenueDetails = (params: any) =>
    dispatch(getVenueDetailsRequest(params));

  const requestUpdateVenueInfo = (params: any) =>
    dispatch(updateVenueInfoRequest(params));

  const requestUpdateMultiVenueInfo = (params: any) =>
    dispatch(updateVenuePublishRequest(params));

  const requestCreateVenue = (params: any) =>
    dispatch(createVenueInfoRequest(params));

  const requestDeleteVenue = (params: any) =>
    dispatch(deleteVenueInfoRequest(params));

  const requestDeleteMultiVenue = (params: any) =>
    dispatch(deleteMultiVenueInfoRequest(params));

  const requestProvinces = () => dispatch(getProvincesRequest());

  const requestResetVenue = () => dispatch(resetVenueInfoRequestStatus());

  const requestDuplicateVenue = (params: any) =>
    dispatch(duplicateVenueRequest(params));

  return {
    requestVenueOptions,
    requestVenueList,
    requestVenueDetails,
    requestUpdateVenueInfo,
    requestCreateVenue,
    requestDeleteVenue,
    requestResetVenue,
    requestProvinces,
    requestDeleteMultiVenue,
    requestUpdateMultiVenueInfo,
    requestDuplicateVenue,
    getVenueDetailsStatus,
    deleteMultiVenueInfoStatus,
    updateVenuePublishStatus,
    getVenueListStatus,
    venueOptions,
    venueOptionsList,
    getVenueOptionsStatus,
    updateVenueInfoStatus,
    createVenueInfoStatus,
    deleteVenueInfoStatus,
    getProvincesStatus,
    venueDetails,
    venues,
    provinces,
    duplicateVenueStatus,
  };
};

export default useVenueHook;
