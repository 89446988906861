import { FilterBar, FilterType } from '@/components/common/FilterBar';
import SelectItem from '@/components/form/select';
import { FormWrapper } from '@/components/venue/components';
import { FilterFormData } from '@/types';
import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Input from '../form/input';
import { CONNECTION_TYPES } from '@/constants';
import useFloorHook from '@/hooks/useFloorHook';
import { transformSelectOptions } from '@/utils/helper';

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

type FilterProps = {
  onAdd: () => void;
  // onExport: () => void;
  onDownwardFloorChange: (e: SelectChangeEvent<any>) => void;
  onUpwardFloorChange: (e: SelectChangeEvent<any>) => void;
  onKeywordChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onTypeChange: (e: SelectChangeEvent<any>) => void;
};

export const Filter = ({
  onAdd,
  // onExport,
  onDownwardFloorChange,
  onUpwardFloorChange,
  onKeywordChange,
  onTypeChange,
}: FilterProps) => {
  const location = useLocation();
  let params = queryString.parse(window.location.search);
  const { t } = useTranslation();

  const { control, reset } = useForm<FilterFormData>({
    defaultValues: {
      type: 0,
      upward_accessible_floor: 0,
      downward_accessible_floor: 0,
      keyword: '',
    },
  });

  const convertParams = useCallback((arg: any, key: string) => {
    return Array.isArray(arg[key])
      ? arg[key][0] ?? undefined
      : arg[key] ?? undefined;
  }, []);

  useEffect(() => {
    reset({
      keyword: convertParams(params, 'keyword'),
      type: convertParams(params, 'type'),
      upward_accessible_floor: convertParams(params, 'levels'),
      downward_accessible_floor: convertParams(params, 'levels1'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const { floorOptions } = useFloorHook();

  return (
    <FilterBar
      type={FilterType.GENERIC}
      onAdd={onAdd}
      // onExport={onExport}
      hasButton
      buttonText={t('Add Connector group')}
    >
      <FormWrapper>
        <Controller
          control={control}
          name="type"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <SelectItem
                options={CONNECTION_TYPES}
                value={value}
                onChange={e => {
                  onChange(e);
                  onTypeChange(e);
                }}
                placeholder={t('Type')}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="upward_accessible_floor"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <SelectItem
                options={transformSelectOptions(floorOptions)}
                value={value}
                onChange={e => {
                  onChange(e);
                  onUpwardFloorChange(e);
                }}
                placeholder={t('Upward-accessible floors')}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="downward_accessible_floor"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <SelectItem
                options={transformSelectOptions(floorOptions)}
                value={value}
                onChange={e => {
                  onChange(e);
                  onDownwardFloorChange(e);
                }}
                placeholder={t('Downward-accessible floors')}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="keyword"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <Input
                inputType={1}
                onChange={e => {
                  onChange(e);
                  onKeywordChange(e);
                }}
                placeholder={t('Search')}
                value={value}
              />
            );
          }}
        />
      </FormWrapper>
    </FilterBar>
  );
};
