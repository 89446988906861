import { createSelector } from '@reduxjs/toolkit';
import { eventSlice } from './event';
import { RootState } from '../..';

const selectSlice = (state: RootState) =>
  state.event || eventSlice.getInitialState;

export const selectEvent = createSelector([selectSlice], eventState => ({
  ...eventState,
}));
