/* eslint-disable max-lines */
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { FilterBar, FilterType } from '@/components/common/FilterBar';
import { NoData } from '@/components/common/NoData';
import { Loader } from '@/components/common/loader';
import { FloorForm, FloorPopup } from '@/components/floor/components/popup';
import Input, { Label } from '@/components/form/input';
import SelectItem from '@/components/form/select';
import { CommonTable } from '@/components/layouts/table';
import { TableHeaderAction } from '@/components/layouts/table/rowSelected';
import { TableType } from '@/components/layouts/table/table';
import { Container, FormWrapper } from '@/components/venue/components';
import {
  ACTION_OPTIONS,
  PUBLISH_STATES,
  PUBLISH_STATES_SELECT,
} from '@/constants';
import { RequestStatus } from '@/constants/API';
import Path from '@/constants/path';
import useCustomerHook from '@/hooks/useCustomerHook';
import useFloorHook from '@/hooks/useFloorHook';
import useGlobalHook from '@/hooks/useGlobalHook';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import useVenueHook from '@/hooks/useVenueHook';
import { FloorDetail } from '@/stores/slices/floor/type';
import {
  checkIsRequesting,
  transformSelectOptionsWithTranslation,
} from '@/utils/helper';
import { Collapse, SelectChangeEvent } from '@mui/material';
import _ from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface FormData {
  keyword: string;
  level: number | string;
  publish: number | number;
}

export const FloorPlanPage = () => {
  const { t } = useTranslation();

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [floorLevelOptions, setFloorLevelOptions] = useState<any[]>([]);
  const [floorLevelsForSearch, setFloorLevelsForSearch] = useState<any[]>([]);
  const [selecteds, setSelecteds] = useState<(string | number)[]>([]);
  const [openSwitch, setOpenSwitch] = useState<boolean>(false);

  const location = useLocation();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const [selectedId, setSelectedId] = useState<string | number>('');
  const urlParams = useParams();

  const [editingFloor, setEditingFloor] = useState<FloorDetail | undefined>(
    undefined,
  );
  const [publish, setPublish] = useState<boolean>(false);

  const { getVenueOptionsStatus } = useVenueHook();
  const { getAllCustomerListStatus } = useCustomerHook();

  const {
    getFloorListStatus,
    requestFloorList,
    createFloorInfoStatus,
    requestCreateFloor,
    requestUpdateFloorInfo,
    floors,
    deleteFloorInfoStatus,
    updateFloorInfoStatus,
    requestDeleteFloor,
    requestResetFloor,
    floorLevels,
    getFloorLevelsStatus,
    requestMultiDeleteFloor,
    requestUpdateMultiFloorInfo,
    deleteMultiFloorInfoStatus,
    updateFloorPublishStatus,
  } = useFloorHook();

  const showNoData = useMemo(
    () =>
      getFloorListStatus !== RequestStatus.IDLE &&
      (floors?.data?.length === 0 || !floors),
    [floors, getFloorListStatus],
  );

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getFloorListStatus,
        createFloorInfoStatus,
        deleteFloorInfoStatus,
        updateFloorInfoStatus,
        getVenueOptionsStatus,
        getFloorLevelsStatus,
        getAllCustomerListStatus,
        deleteMultiFloorInfoStatus,
        updateFloorPublishStatus,
      ]),
    [
      getFloorListStatus,
      createFloorInfoStatus,
      deleteFloorInfoStatus,
      updateFloorInfoStatus,
      getVenueOptionsStatus,
      getFloorLevelsStatus,
      getAllCustomerListStatus,
      deleteMultiFloorInfoStatus,
      updateFloorPublishStatus,
    ],
  );

  const reloadData = () => {
    let params = queryString.parse(window.location.search);

    if (params?.level) {
      params.type = params.level;
      params.level = null;
    }
    const id = urlParams.venueId;

    if (id) {
      params = { ...params, venue: id };
    }
    requestFloorList({ ...params });
  };

  /** fetch data based on query string */
  useEffect(() => {
    let params = queryString.parse(window.location.search);
    const keyword = params?.keyword ? String(params?.keyword) : '';
    const level = params?.level ? String(params?.level) : '';
    const state = params?.publish ?? '';

    reset({
      keyword,
      level,
      publish: Array.isArray(state) ? _.toInteger(state[0]) : +state ?? 0,
    });

    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (urlParams.venueId) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.venueId]);

  useEffect(() => {
    setFloorLevelsForSearch(
      _.map(floorLevels, v => ({
        value: v.value,
        label: v.name,
      })),
    );
    setFloorLevelOptions(
      _.map(floorLevels, v => ({
        value: v.id,
        label: v.name,
      })),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorLevels]);

  const navigate = useNavigateHook()?.navigate;

  const onChangePage = (id: number) => {
    let params = queryString.parse(window.location.search);
    params.page = id.toString();
    const qs = queryString.stringify(params);
    navigate(`${location.pathname}?${qs}`);
  };

  // const onExport = () => {};

  const onAdd = () => {
    setEditingFloor(undefined);
    setOpenPopup(true);
  };

  const onSelect = (id: number | string) => {
    if (selecteds.includes(id)) {
      setSelecteds(selecteds.filter(it => it !== id));
    } else {
      setSelecteds([...selecteds, id]);
    }
  };

  const submitAddOrEdit = (formData: FloorForm) => {
    if (editingFloor) {
      requestUpdateFloorInfo({
        ...formData,
        id: editingFloor.id,
        venue: urlParams?.venueId,
      });
    } else {
      requestCreateFloor({ ...formData, venue: urlParams?.venueId });
    }
    setOpenPopup(false);
  };

  const handleDebounceFn = (newParams: {
    level?: string;
    keyword?: string;
  }) => {
    const oldParams = queryString.parse(window.location.search);

    const updatedParams = {
      ...oldParams,
      ...newParams,
    };

    const newQueryString = queryString.stringify(updatedParams);

    navigate(`${location.pathname}?${newQueryString}`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  const onKeywordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget?.value;
    debounceFn({ keyword });
  };
  const onLevelChange = (e: any) => {
    const level = e.target?.value;
    debounceFn({ level });
  };

  const onView = (id: number | string) => {
    navigate(
      `${Path.CUSTOMERS}/${urlParams?.customerId}${Path.VENUE}/${urlParams?.venueId}${Path.LOCATION}`,
    );
  };

  const onChangeSelected = (e: any) => {
    if (e.target.checked) {
      setSelecteds([...floors?.data?.map((it: FloorDetail) => it.id)]);
    } else {
      setSelecteds([]);
    }
  };

  const changeState = () => {
    setOpenSwitch(false);
    requestUpdateMultiFloorInfo({
      level_ids: selecteds,
      publish_state: publish,
    });
  };

  const redirect = useCallback(
    (qr: any) => {
      navigate(
        `${location.pathname}?${queryString.stringify(
          _.omitBy(qr, v => _.isEmpty(v) || _.isNil(v)),
        )}`,
      );
    },
    [location.pathname, navigate],
  );

  useEffect(() => {
    if (updateFloorInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Floor updated!']);
      reloadData();
    }
    if (updateFloorInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update floor')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFloorInfoStatus]);

  useEffect(() => {
    if (updateFloorPublishStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Floors updated!')]);
      reloadData();
    }
    if (updateFloorPublishStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update floors')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFloorPublishStatus]);

  useEffect(() => {
    if (createFloorInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Floor created!')]);
      reloadData();
    }

    if (createFloorInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to create floor')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFloorInfoStatus]);

  useEffect(() => {
    if (deleteFloorInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to delete floor')]);
    }
    if (deleteFloorInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Floor deleted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFloorInfoStatus]);

  useEffect(() => {
    if (deleteMultiFloorInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([
        t('Something went wrong. Unable to delete selected floor(s)'),
      ]);
    }
    if (deleteMultiFloorInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Deleted floor(s)!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMultiFloorInfoStatus]);

  const submitDelete = () => {
    if (selectedId) {
      requestDeleteFloor(selectedId);
    } else if (selecteds.length) {
      requestMultiDeleteFloor({ level_ids: selecteds });
    }
  };

  const onEdit = (id: number | string) => {
    setEditingFloor(
      floors?.data.find((it: FloorDetail) => it.id === id) ?? undefined,
    );
    setOpenPopup(true);
  };

  const onDelete = (id: number | string) => {
    setOpenDelete(true);
    setSelectedId(id);
  };

  const { control, reset, getValues } = useForm<FormData>({
    defaultValues: {
      keyword: '',
      level: 0,
    },
  });

  const pagination = {
    currentPage: floors?.current_page ?? 1,
    totalPages: floors?.total_page ?? 1,
    onPageChange: (id: number) => onChangePage(id),
  };

  const onSelectAction = (actionId: number | number) => {
    setSelectedId('');

    if (!selecteds.length) return;
    if (actionId === ACTION_OPTIONS[0].id) {
      setOpenSwitch(true);
      setPublish(false);
    } else if (actionId === ACTION_OPTIONS[1].id) {
      setOpenSwitch(true);
      setPublish(true);
    } else {
      setOpenSwitch(false);
      setOpenDelete(true);
    }
  };

  const onPublishChange = (e: SelectChangeEvent<any>) => {
    let params = queryString.parse(window.location.search);

    if (+getValues('publish')) {
      params.publish = +getValues('publish') === 1 ? '0' : '1';
    } else {
      delete params.publish;
    }

    redirect(params);
  };

  useEffect(() => {
    return () => requestResetFloor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FilterBar
        type={FilterType.GENERIC}
        onAdd={onAdd}
        // onExport={onExport}
        buttonText={t('Add new floor')}
        onSelectAction={onSelectAction}
        hasAction
        hasButton
        disabled={!!(selecteds.length === 0)}
      >
        <FormWrapper>
          <Controller
            control={control}
            name="level"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <SelectItem
                  options={floorLevelsForSearch}
                  value={value}
                  onChange={e => {
                    onChange(e);
                    onLevelChange(e);
                  }}
                  placeholder={t('Level')}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="keyword"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <Input
                  inputType={1}
                  onChange={e => {
                    onChange(e);
                    onKeywordChange(e);
                  }}
                  value={value}
                  placeholder={t('Search')}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="publish"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <SelectItem
                  options={transformSelectOptionsWithTranslation(
                    PUBLISH_STATES_SELECT,
                    t,
                  )}
                  value={value}
                  onChange={e => {
                    onChange(e);
                    onPublishChange(e);
                  }}
                  placeholder={t('Status')}
                />
              );
            }}
          />
        </FormWrapper>
      </FilterBar>

      {showNoData ? (
        <NoData />
      ) : (
        <>
          <Collapse in={!!selecteds.length} timeout="auto" unmountOnExit>
            <TableHeaderAction
              selected={selecteds}
              total={floors?.total_records}
            />
          </Collapse>

          <CommonTable
            type={TableType.FLOOR}
            data={floors?.data ?? []}
            pagination={pagination}
            onDelete={onDelete}
            onEdit={onEdit}
            onView={onView}
            onSelect={onSelect}
            indeterminate={
              selecteds.length > 0 && selecteds.length < floors?.page_size
            }
            onChangeSelect={onChangeSelected}
            selected={selecteds}
          />
        </>
      )}

      <FloorPopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={submitAddOrEdit}
        data={editingFloor}
        floorLevelOptions={floorLevelOptions}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onSubmit={submitDelete}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenSwitch(false)}
        open={openSwitch}
        onSubmit={changeState}
        actionTitle={
          publish ? PUBLISH_STATES[1].label : PUBLISH_STATES[0].label
        }
      >
        <Label>
          {publish
            ? t('Are you sure to publish selected floor(s)?')
            : t('Are you sure to unpublish selected floor(s)?')}
        </Label>
      </ConfirmPopUp>
      <Loader show={loading} />
    </Container>
  );
};
