/* eslint-disable max-lines */
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { Loader } from '@/components/common/loader';
import { NoData } from '@/components/common/NoData';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { CategoryPopup } from '@/components/category/components/popup';
import { Filter } from '@/components/category/filter';
import { Container } from '@/components/venue/components';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import useCategoryHook from '@/hooks/useCategoryHook';
import { checkIsRequesting } from '@/utils/helper';
import _ from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CommonType } from '@/stores/slices/amenity/type';

export const CategoryPage = () => {
  const {
    getCategoryListStatus,
    requestCategoryList,
    createCategoryInfoStatus,
    requestCreateCategory,
    requestUpdateCategoryInfo,
    categories,
    deleteCategoryInfoStatus,
    updateCategoryInfoStatus,
    requestDeleteCategory,
    requestResetCategory,
  } = useCategoryHook();
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [data, setData] = useState<CommonType[]>(categories ?? []);

  const [selectedId, setSelectedId] = useState<string | number>('');
  const [editingCategory, setEditingCategory] = useState<
    CommonType | undefined
  >(undefined);

  const location = useLocation();
  const queries = useParams();

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getCategoryListStatus,
        createCategoryInfoStatus,
        deleteCategoryInfoStatus,
        updateCategoryInfoStatus,
      ]),
    [
      getCategoryListStatus,
      createCategoryInfoStatus,
      deleteCategoryInfoStatus,
      updateCategoryInfoStatus,
    ],
  );

  const showNoData = useMemo(() => {
    return (
      getCategoryListStatus !== RequestStatus.IDLE &&
      (!data.length || !categories)
    );
  }, [categories, data.length, getCategoryListStatus]);

  const navigate = useNavigateHook()?.navigate;
  const redirect = useCallback(
    (qr: any) => {
      if (qr.keyword) {
        setData(
          categories?.filter(
            (it: CommonType) =>
              it.name?.toLowerCase().includes(qr.keyword?.toLowerCase()),
          ),
        );
      } else {
        setData(categories);
      }

      navigate(
        `${location.pathname}?${queryString.stringify(
          _.omitBy(
            qr,
            v => !v || (Array.isArray(v) && _.isEmpty(v)) || _.isNil(v),
          ),
        )}`,
      );
    },
    [categories, location.pathname, navigate],
  );

  const reloadData = () => {
    requestCategoryList({ venue: queries?.venueId });
  };

  const onAdd = () => {
    setEditingCategory(undefined);
    setOpenPopup(true);
  };

  const submitAddOrEdit = (formData: Omit<CommonType, 'id'>) => {
    if (editingCategory) {
      requestUpdateCategoryInfo({
        ..._.omitBy(formData, it => !it),
        id: editingCategory.id,
        venue: queries?.venueId,
      });
    } else {
      requestCreateCategory({
        ..._.omitBy(formData, it => !it),
        venue: queries?.venueId,
      });
    }
    setOpenPopup(false);
  };

  const onEdit = (id: number | string) => {
    setEditingCategory(categories?.find((it: CommonType) => it.id === id));
    setOpenPopup(true);
  };

  const onDelete = (id: number | string) => {
    setOpenDelete(true);
    setSelectedId(id);
  };

  const submitDelete = () => {
    requestDeleteCategory(selectedId);
  };

  const handleDebounceFn = (inputValue: string) => {
    let params = queryString.parse(window.location.search);
    params.keyword = inputValue;
    delete params.page;
    redirect(params);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  const onKeywordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget?.value;
    debounceFn(keyword);
  };

  useEffect(() => {
    return () => requestResetCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateCategoryInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Category updated!')]);
      reloadData();
    }
    if (updateCategoryInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update category')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCategoryInfoStatus]);

  useEffect(() => {
    if (createCategoryInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Category created!')]);
      reloadData();
    }

    if (createCategoryInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to create category')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCategoryInfoStatus]);

  useEffect(() => {
    if (deleteCategoryInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to delete category')]);
    }
    if (deleteCategoryInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Category deleted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCategoryInfoStatus]);

  useEffect(() => {
    if (getCategoryListStatus === RequestStatus.SUCCESS) {
      setData(categories);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCategoryListStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Container>
      <Filter
        onAdd={onAdd}
        // onExport={onExport}
        onKeywordChange={onKeywordChange}
      />
      {showNoData ? (
        <NoData />
      ) : (
        <CommonTable
          type={TableType.CATEGORY}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )}

      <CategoryPopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={submitAddOrEdit}
        data={editingCategory}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onSubmit={submitDelete}
      />
      <Loader show={loading} />
    </Container>
  );
};
