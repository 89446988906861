/**
 *
 * GlobalContainer
 *
 */

import { memo } from 'react';
import useGlobalHook from '@/hooks/useGlobalHook';
import useDisplayMessagesFactory from '@/hooks/useDisplayMessages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ToastMessage from '@/components/ToastMessage';

interface Props {
  children: React.ReactNode;
}

export const GlobalContainer = memo(({ children }: Props) => {
  const {
    errorMessages,
    warningMessages,
    successMessages,
    infoMessages,
    setErrorMsg,
    setInfoMsg,
    setSuccessMsg,
    setWarningMsg,
  } = useGlobalHook();

  useDisplayMessagesFactory(errorMessages, ToastMessage.error, setErrorMsg);
  useDisplayMessagesFactory(warningMessages, ToastMessage.warn, setWarningMsg);
  useDisplayMessagesFactory(infoMessages, ToastMessage.inform, setInfoMsg);
  useDisplayMessagesFactory(
    successMessages,
    ToastMessage.success,
    setSuccessMsg,
  );
  return (
    <>
      {children}
      <ToastContainer />
    </>
  );
});
