import PopUp from '@/components/common/Popup';
import Input from '@/components/form/input';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import { useEffect, useMemo, useState } from 'react';
import { OneInputContainer } from '@/components/floor/components';
import { PasswordToggleIcon } from './styled';
import useGlobalHook from '@/hooks/useGlobalHook';
import { ReactComponent as ClosingEyeIcon } from '@/assets/icons/close_eye.svg';
import { ReactComponent as EyeIcon } from '@/assets/icons/eye.svg';
import { Loader } from '@/components/common/loader';
import useAuthHook from '@/hooks/useAuthHook';
import { checkIsRequesting } from '@/utils/helper';
import { RequestStatus } from '@/constants/API';
import { useTranslation } from 'react-i18next';

export interface PwProps {
  open: boolean;
  onClose: () => void;
}

export interface PwForm {
  new_password: string;
  old_password: string;
  confirm_password: string;
}

export function ChangePw({ open, onClose }: PwProps) {
  const { t, i18n } = useTranslation();

  const { setChangePwPopUp } = useGlobalHook();
  const [showPassword, setShowPassword] = useState<boolean[]>([
    false,
    false,
    false,
  ]);
  const { requestChangePassword, updatePasswordRequestStatus, logout } =
    useAuthHook();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();

  const handleTogglePasswordVisibility = (idx: number) => {
    const arr = [...showPassword];
    arr[idx] = !arr[idx];
    setShowPassword(arr);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PwForm>({
    defaultValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
  });

  useEffect(() => {
    if (updatePasswordRequestStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update password')]);
      setChangePwPopUp(false);
    }
    if (updatePasswordRequestStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Password is updated!')]);
      setChangePwPopUp(false);
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePasswordRequestStatus]);

  const submitForm = (data: PwForm) => {
    requestChangePassword(data);
  };

  const loading = useMemo(
    () => checkIsRequesting([updatePasswordRequestStatus]),
    [updatePasswordRequestStatus],
  );

  return (
    <PopUp title={t('Change password')} onClose={onClose} open={open}>
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <OneInputContainer>
          <Controller
            control={control}
            name="old_password"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Box position="relative">
                  <Input
                    label={t('Current Password')}
                    isRequired
                    type={showPassword[0] ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    error={errors?.old_password?.message}
                  />
                  <PasswordToggleIcon
                    onClick={() => handleTogglePasswordVisibility(0)}
                  >
                    {showPassword[0] ? <ClosingEyeIcon /> : <EyeIcon />}
                  </PasswordToggleIcon>
                </Box>
              );
            }}
          />
        </OneInputContainer>
        <OneInputContainer>
          <Controller
            control={control}
            name="new_password"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Box position="relative">
                  <Input
                    label={t('New Password')}
                    isRequired
                    type={showPassword[1] ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    error={errors?.new_password?.message}
                  />
                  <PasswordToggleIcon
                    onClick={() => handleTogglePasswordVisibility(1)}
                  >
                    {showPassword[1] ? <ClosingEyeIcon /> : <EyeIcon />}
                  </PasswordToggleIcon>
                </Box>
              );
            }}
          />
        </OneInputContainer>
        <OneInputContainer>
          <Controller
            control={control}
            name="confirm_password"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Box position="relative">
                  <Input
                    label={t('Confirm New Password')}
                    isRequired
                    type={showPassword[2] ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    error={errors?.confirm_password?.message}
                  />
                  <PasswordToggleIcon
                    onClick={() => handleTogglePasswordVisibility(2)}
                  >
                    {showPassword[2] ? <ClosingEyeIcon /> : <EyeIcon />}
                  </PasswordToggleIcon>
                </Box>
              );
            }}
          />
        </OneInputContainer>
        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
            display: 'grid',
            gridTemplateColumns:
              i18n.resolvedLanguage === 'ja' ? '120px 70px' : '70px 70px',
            justifyContent: 'flex-end',
            gridGap: '20px',
          }}
        >
          <CustomButton
            buttonType={CustomButtonType.WHITE_BG}
            title={`Cancel`}
            onClick={onClose}
          />
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={`Submit`}
          />
        </Box>
      </Box>
      <Loader show={loading} />
    </PopUp>
  );
}
