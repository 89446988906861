/* eslint-disable max-lines */
import Pagination from '@/components/common/pagination';
import { useMemo } from 'react';
import {
  PaginationContainer,
  Table,
  TableContainer,
  TableWrapper,
  TopTable,
} from './component';

import {
  CategoryHeader,
  CommonTableProps,
  ConnectionHeader,
  SnapshotHeader,
  CustomerHeader,
  EventHeader,
  FloorHeader,
  LocationHeader,
  TableType,
  TopHeader,
  VenueHeader,
  QRCodeHeader,
  AmenityHeader,
} from './table';
import {
  AmenityRow,
  CategoryRow,
  ConnectionRow,
  CustomerRow,
  EventRow,
  FloorRow,
  LocationRow,
  QRCodeRow,
  SnapshotRow,
  TopRow,
  VenueRow,
} from './tableRow';
import { Title } from '../header/styled';
import { useTranslation } from 'react-i18next';
import useGlobalHook from '@/hooks/useGlobalHook';

export const CommonTable = ({
  type,
  data,
  pagination,
  onView,
  onEdit,
  onDelete,
  onSelect,
  indeterminate,
  onChangeSelect,
  selected,
}: CommonTableProps) => {
  const { t } = useTranslation();

  const { collaspe } = useGlobalHook();
  const handleDeleteTag = () => {};

  const renderTable = useMemo(() => {
    switch (type) {
      case TableType.TOP:
        return (
          <>
            <TopTable>
              <TopHeader />

              {data?.map((row, index) => (
                <TopRow key={row.id} row={{ ...row, index }} />
              ))}
            </TopTable>
            {!data?.length ? (
              <Title sx={{ textAlign: 'center', margin: '20px 0' }}>
                {t('No data')}
              </Title>
            ) : (
              ''
            )}
          </>
        );
      case TableType.FLOOR:
        return (
          <Table>
            <FloorHeader
              indeterminate={indeterminate}
              onChange={onChangeSelect}
            />
            <tbody>
              {data?.map((row, index) => (
                <FloorRow
                  key={row.id}
                  row={{ ...row, index }}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onView={onView}
                  onSelect={onSelect}
                  selected={!!selected?.includes(row.id)}
                />
              ))}
            </tbody>
          </Table>
        );
      case TableType.VENUE:
        return (
          <Table>
            <VenueHeader
              indeterminate={indeterminate}
              onChange={onChangeSelect}
            />
            <tbody>
              {data?.map((row, index) => (
                <VenueRow
                  key={row.id}
                  row={{ ...row, index }}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onView={onView}
                  onSelect={onSelect}
                  selected={!!selected?.includes(row.id)}
                />
              ))}
            </tbody>
          </Table>
        );

      case TableType.LOCATION:
        return (
          <Table>
            <LocationHeader />
            <tbody>
              {data?.map((row, index) => (
                <LocationRow
                  key={row.id}
                  row={{ ...row, index }}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  handleDeleteTag={handleDeleteTag}
                />
              ))}
            </tbody>
          </Table>
        );
      case TableType.EVENT:
        return (
          <Table>
            <EventHeader />
            <tbody>
              {data?.map((row, index) => (
                <EventRow
                  key={row.id}
                  row={{ ...row, index }}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  handleDeleteTag={handleDeleteTag}
                />
              ))}
            </tbody>
          </Table>
        );
      case TableType.CATEGORY:
        return (
          <Table>
            <CategoryHeader />
            <tbody>
              {data?.map((row, index) => (
                <CategoryRow
                  key={row.id}
                  row={{ ...row, index }}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ))}
            </tbody>
          </Table>
        );

      case TableType.CUSTOMER:
        return (
          <Table>
            <CustomerHeader />
            <tbody>
              {data?.map((row, index) => (
                <CustomerRow
                  key={row.id}
                  row={{ ...row, index }}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onView={onView}
                />
              ))}
            </tbody>
          </Table>
        );

      case TableType.CONNECTION:
        return (
          <Table>
            <ConnectionHeader />
            <tbody>
              {data?.map((row, index) => (
                <ConnectionRow
                  key={row.id}
                  row={{ ...row, index }}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ))}
            </tbody>
          </Table>
        );
      case TableType.QRCODE:
        return (
          <Table>
            <QRCodeHeader />
            <tbody>
              {data?.map((row, index) => (
                <QRCodeRow
                  key={row.id}
                  row={{ ...row, index }}
                  onEdit={onEdit}
                />
              ))}
            </tbody>
          </Table>
        );
      case TableType.SNAPSHOT:
        let firstDraftRendered = false;
        let firstPublicRendered = false;

        return (
          <Table>
            <SnapshotHeader />
            <tbody>
              {data?.map((row, index) => {
                const isFirstDraft =
                  row.state === 'draft' && !firstDraftRendered;
                const isFirstPublic =
                  row.state === 'public' && !firstPublicRendered;

                if (isFirstDraft) {
                  firstDraftRendered = true;
                }

                if (isFirstPublic) {
                  firstPublicRendered = true;
                }

                return (
                  <SnapshotRow
                    key={row.id}
                    row={{ ...row, index }}
                    onDelete={onDelete}
                    firstDraftRendered={isFirstDraft}
                    firstPublicRendered={isFirstPublic}
                  />
                );
              })}
            </tbody>
          </Table>
        );

      case TableType.AMENITY:
        return (
          <Table>
            <AmenityHeader />
            <tbody>
              {data?.map((row, index) => (
                <AmenityRow
                  key={row.id}
                  row={{ ...row, index }}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ))}
            </tbody>
          </Table>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    type,
    data,
    indeterminate,
    onChangeSelect,
    onEdit,
    onDelete,
    onView,
    onSelect,
    selected,
  ]);

  return (
    <TableWrapper className={type === TableType.TOP ? 'top' : ''} $collaspe={collaspe}>
      <TableContainer className={type === TableType.TOP ? 'top' : ''}>
        {renderTable}
      </TableContainer>
      {pagination && (
        <PaginationContainer>
          <Pagination {...pagination} />
        </PaginationContainer>
      )}
    </TableWrapper>
  );
};
