export const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M21.9532 17.5956C21.7201 17.3474 20.8973 16.6135 19.3848 15.6425C17.8611 14.663 16.7374 14.0522 16.4151 13.9098C16.3867 13.8972 16.3554 13.8926 16.3245 13.8965C16.2937 13.9003 16.2645 13.9125 16.2401 13.9317C15.7209 14.3366 14.8467 15.0804 14.7999 15.1206C14.4972 15.38 14.4972 15.38 14.2494 15.2992C13.8137 15.1563 12.4601 14.4375 11.2806 13.2554C10.1011 12.0732 9.34525 10.6861 9.20239 10.2508C9.12069 10.0026 9.12069 10.0026 9.38097 9.6999C9.42115 9.65303 10.1654 8.7789 10.5703 8.26013C10.5895 8.2357 10.6017 8.2065 10.6055 8.17567C10.6094 8.14483 10.6048 8.11353 10.5922 8.08513C10.4497 7.76235 9.83901 6.63911 8.85952 5.11541C7.88718 3.60331 7.15412 2.78052 6.9059 2.54748C6.88312 2.52599 6.85497 2.51103 6.8244 2.5042C6.79384 2.49736 6.762 2.4989 6.73223 2.50864C5.86457 2.80681 5.0274 3.18727 4.23217 3.64483C3.46448 4.0911 2.73768 4.60427 2.06024 5.17835C2.03659 5.19846 2.01886 5.22462 2.00895 5.25404C1.99904 5.28345 1.99732 5.31501 2.00398 5.34532C2.09729 5.78016 2.54328 7.59538 3.92725 10.1097C5.33934 12.6759 6.31794 13.9906 8.39165 16.0572C10.4654 18.1238 11.8217 19.1613 14.3905 20.5734C16.9048 21.9574 18.7209 22.4038 19.1549 22.4962C19.1853 22.5028 19.2169 22.5011 19.2463 22.4912C19.2758 22.4813 19.3021 22.4636 19.3223 22.44C19.8963 21.7625 20.4093 21.0357 20.8554 20.268C21.3128 19.4728 21.6933 18.6356 21.9916 17.768C22.0011 17.7384 22.0026 17.7069 21.9958 17.6766C21.9891 17.6463 21.9744 17.6183 21.9532 17.5956Z"
      fill="#2B6CCD"
    />
  </svg>
);
