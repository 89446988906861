import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  createAmenityInfoFailure,
  createAmenityInfoRequest,
  createAmenityInfoSuccess,
  deleteAmenityInfoFailure,
  deleteAmenityInfoRequest,
  deleteAmenityInfoSuccess,
  getAmenityListFailure,
  getAmenityListRequest,
  getAmenityListSuccess,
  getLocationAmenityListFailure,
  getLocationAmenityListRequest,
  getLocationAmenityListSuccess,
  setVenueAmenitiesFailure,
  setVenueAmenitiesRequest,
  setVenueAmenitiesSuccess,
  updateAmenityInfoFailure,
  updateAmenityInfoRequest,
  updateAmenityInfoSuccess,
} from '../slices/amenity/amenity';

type IPayload = Record<string, any>;

function* getAmenityListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getAmenities, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getAmenityListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getAmenityListFailure.type });
    }
  } catch (error) {
    yield put({ type: getAmenityListFailure.type });
  }
}

function* getLocationAmenityListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getLocationAmenities, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getLocationAmenityListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getLocationAmenityListFailure.type });
    }
  } catch (error) {
    yield put({ type: getLocationAmenityListFailure.type });
  }
}

function* setVenueAmenitiesFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.setVenueAmenities, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: setVenueAmenitiesSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: setVenueAmenitiesFailure.type });
    }
  } catch (error) {
    yield put({ type: setVenueAmenitiesFailure.type });
  }
}

function* createAmenityFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createAmenity, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: createAmenityInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createAmenityInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createAmenityInfoFailure.type });
  }
}

function* updateAmenityFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateAmenity, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateAmenityInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateAmenityInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: updateAmenityInfoFailure.type });
  }
}

function* deleteAmenityFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteAmenity, payload);

    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteAmenityInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteAmenityInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteAmenityInfoFailure.type });
  }
}

function* amenitySaga() {
  yield takeLatest(getAmenityListRequest, getAmenityListFlow);
  yield takeLatest(getLocationAmenityListRequest, getLocationAmenityListFlow);
  yield takeLatest(setVenueAmenitiesRequest, setVenueAmenitiesFlow);
  yield takeLatest(createAmenityInfoRequest, createAmenityFlow);
  yield takeLatest(updateAmenityInfoRequest, updateAmenityFlow);
  yield takeLatest(deleteAmenityInfoRequest, deleteAmenityFlow);
}

export default amenitySaga;
