/* eslint-disable max-lines */
import {
  ConnectionIcon,
  CustomerIcon,
  EditIcon,
  FloorIcon,
  FloorPlanIcon,
  LocationIcon,
  GraphyIcon,
  VenueIcon,
  EventIcon,
  CategoryIcon,
  AssetIcon,
  QRCodeIcon,
  SnapshotIcon,
} from '@/components/icons';
import { Logo } from '@/components/layouts/logo';
import { SidebarItem } from '@/components/layouts/sidebar/item';
import {
  ACCESS_TOKEN_KEY,
  CUSTOMER_VENUE_PATH_REGEX,
  FLOOR_PATH_REGEX,
  VENUE_PATH_REGEX,
} from '@/constants';
import { SIDEBAR_ITEMS } from '@/constants/layout';
import Path from '@/constants/path';
import useFloorHook from '@/hooks/useFloorHook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { SubMenuItemProps } from '@/types/layout';
// import { FloorPopup } from '@/components/floor/components/popup';
import { transformSelectOptions } from '@/utils/helper';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useVenueHook from '@/hooks/useVenueHook';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { WorldPopup } from '@/components/floor/components/worldPopup';
import { Header } from '../header';
import { Box } from '@mui/material';
import { IoMenu } from 'react-icons/io5';
import { setCollaspe } from '@/utils/localStorage';

const SidebarContainer = styled.div<{ $collaspe?: boolean }>`
  width: ${props => (props.$collaspe ? '50px' : '172px')};
  align-self: stretch;
  background: white;
  border-right: 0.5px #dee1eb solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
`;

const BoxContainer = styled.div<{ $collaspe?: boolean }>`
  width: ${props => (props.$collaspe ? '50px' : '172px')};
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
`;

const LogoContainer = styled.div<{ $collaspe?: boolean }>`
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin-right: ${props => (props.$collaspe ? '0px' : '10px')};
  width: -webkit-fill-available;
  height: ${props => (props.$collaspe ? '48px' : 'auto')};
  font-size: 24px;
`;

export const Sidebar = () => {
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [createFloor, setCreateFloor] = useState<boolean>(true);

  const {
    requestCreateFloor,
    floors,
    floorLevels,
    createFloorInfoStatus,
    requestFloorList,
  } = useFloorHook();
  const { venueDetails } = useVenueHook();
  const { setSuccessMsg, collaspe, setChangeCollaspe } = useGlobalHook();

  const handleChangeCollaspe = () => {
    setChangeCollaspe(!collaspe);
    setCollaspe(!collaspe);
  };

  const addFloor = (formData: any) => {
    requestCreateFloor({ ...formData, venue: params?.venueId });
    setCreateFloor(false);
  };

  const renderFloorMenu = useCallback(() => {
    let customerId = params?.customerId ?? 0;
    if (!customerId) {
      customerId = _.get(_.get(venueDetails, 'customer'), 'id');
    }

    const items: SubMenuItemProps[] = [];
    for (let i = 0; i < floors?.data?.length; i++) {
      const row = floors?.data[i];
      items.push({
        id: row.id,
        name: row.name,
        icon: <FloorPlanIcon />,
        items: [
          {
            id: 1,
            name: t('Mapping'),
            icon: <EditIcon />,
            to: `${Path.VENUE}/${row.venue.id}${Path.FLOOR_PLAN}/${row.id}${
              Path.MAP
            }?token=${localStorage.getItem(ACCESS_TOKEN_KEY)}&venue_id=${
              row.venue.id
            }&floor_id=${row.id}`,
          },
        ],
      });
    }

    return (
      <SidebarItem
        name={t(SIDEBAR_ITEMS.floor)}
        icon={<FloorIcon />}
        to={`${Path.CUSTOMERS}/${customerId ?? 0}${Path.VENUE}/${
          params?.venueId ?? 0
        }${Path.FLOOR_PLAN}`}
        items={floors?.data}
        openAddFloor={setOpenPopup}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floors, params?.customerId, params?.venueId, venueDetails]);

  const renderMenu = useMemo(() => {
    let customerId = params?.customerId ?? 0;
    if (!customerId) {
      customerId = _.get(_.get(venueDetails, 'customer'), 'id');
    }
    const mappingSidebar = (
      <>
        <SidebarItem
          name={t(SIDEBAR_ITEMS.top)}
          icon={<VenueIcon />}
          to={`${Path.CUSTOMERS}/${customerId ?? 0}${Path.VENUE}/${
            params?.venueId ?? 0
          }${Path.TOP}`}
        />
        <SidebarItem
          name={t(SIDEBAR_ITEMS.floor)}
          icon={<FloorIcon />}
          to={`${Path.VENUE}/${params.venueId}${Path.FLOOR_PLAN}/editor${
            Path.MAP
          }?token=${localStorage.getItem(ACCESS_TOKEN_KEY)}&venue_id=${
            params.venueId
          }`}
          openAddFloor={setOpenPopup}
        />
        <SidebarItem
          name={t(SIDEBAR_ITEMS.connection)}
          icon={<ConnectionIcon />}
          to={`${Path.CUSTOMERS}/${customerId ?? 0}${Path.VENUE}/${
            params?.venueId ?? 0
          }${Path.CONNECTIONS}`}
        />
        <SidebarItem
          name={t(SIDEBAR_ITEMS.location)}
          icon={<LocationIcon />}
          to={`${Path.CUSTOMERS}/${customerId ?? 0}${Path.VENUE}/${
            params?.venueId ?? 0
          }${Path.LOCATION}`}
        />
        <SidebarItem
          name={t(SIDEBAR_ITEMS.event)}
          icon={<EventIcon />}
          to={`${Path.CUSTOMERS}/${customerId ?? 0}${Path.VENUE}/${
            params?.venueId ?? 0
          }${Path.EVENT}`}
        />
        <SidebarItem
          name={t(SIDEBAR_ITEMS.amenity)}
          icon={<AssetIcon />}
          to={`${Path.CUSTOMERS}/${customerId ?? 0}${Path.VENUE}/${
            params?.venueId ?? 0
          }${Path.AMENITY}`}
        />
        <SidebarItem
          className="category_icon"
          name={t(SIDEBAR_ITEMS.category)}
          icon={<CategoryIcon />}
          to={`${Path.CUSTOMERS}/${customerId ?? 0}${Path.VENUE}/${
            params?.venueId ?? 0
          }${Path.CATEGORY}`}
        />
        <SidebarItem
          name={t(SIDEBAR_ITEMS.viewer)}
          icon={<QRCodeIcon />}
          to={`${Path.CUSTOMERS}/${customerId ?? 0}${Path.VENUE}/${
            params?.venueId ?? 0
          }${Path.VIEWER}`}
        />
        <SidebarItem
          name={t(SIDEBAR_ITEMS.analytics)}
          icon={<GraphyIcon />}
          to={`${Path.CUSTOMERS}/${customerId ?? 0}${Path.VENUE}/${
            params?.venueId ?? 0
          }${Path.ANALYTICS}`}
        />
        <SidebarItem
          name={t(SIDEBAR_ITEMS.snapshots)}
          icon={<SnapshotIcon />}
          to={`${Path.CUSTOMERS}/${customerId ?? 0}${Path.VENUE}/${
            params?.venueId ?? 0
          }${Path.SNAPSHOT}`}
        />
      </>
    );
    let sidebar = (
      <>
        <SidebarItem
          name={t(SIDEBAR_ITEMS.customer)}
          icon={<CustomerIcon />}
          to={Path.CUSTOMERS}
        />
        <SidebarItem
          name={t(SIDEBAR_ITEMS.venue)}
          icon={<VenueIcon />}
          to={Path.VENUE}
        />
      </>
    );
    switch (location.pathname) {
      case Path.HOME:
      case Path.CUSTOMERS:
      case Path.VENUE:
        break;
      case Path.FLOOR_PLAN:
      case Path.LOCATION:
      case Path.CONNECTIONS:
      case Path.EVENT:
      case Path.MAPPING:
        sidebar = mappingSidebar;
        break;
      default:
        sidebar = (
          <>
            <SidebarItem
              name={t(SIDEBAR_ITEMS.customer)}
              icon={<CustomerIcon />}
              to={Path.CUSTOMERS}
            />
            <SidebarItem
              name={t(SIDEBAR_ITEMS.venue)}
              icon={<VenueIcon />}
              to={Path.VENUE}
            />
          </>
        );
    }
    if (
      location.pathname.match(CUSTOMER_VENUE_PATH_REGEX) ||
      location.pathname.match(FLOOR_PATH_REGEX)
    ) {
      sidebar = mappingSidebar;
    } else {
      if (location.pathname.match(VENUE_PATH_REGEX)) {
        sidebar = (
          <>
            <SidebarItem
              name={t(SIDEBAR_ITEMS.customer)}
              icon={<CustomerIcon />}
              to={Path.CUSTOMERS}
            />
            <SidebarItem
              name={t(SIDEBAR_ITEMS.venue)}
              icon={<VenueIcon />}
              to={Path.VENUE}
            />
          </>
        );
      }
    }
    return sidebar;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname,
    params?.customerId,
    params?.venueId,
    renderFloorMenu,
    venueDetails,
    t,
    collaspe,
  ]);

  useEffect(() => {
    if (createFloorInfoStatus === RequestStatus.SUCCESS) {
      if (createFloor) {
        setOpenPopup(false);
        setCreateFloor(true);
      }
      setSuccessMsg(['Floor created']);
      requestFloorList({ venue: params?.venueId });
    }

    if (createFloorInfoStatus === RequestStatus.ERROR) {
      setOpenPopup(false);
      setSuccessMsg(['Something went wrong. Unable to create floor!']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFloorInfoStatus]);

  useEffect(() => {
    if (
      location.pathname.match(CUSTOMER_VENUE_PATH_REGEX) ||
      location.pathname.match(FLOOR_PATH_REGEX)
    ) {
      requestFloorList({ venue: params?.venueId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SidebarContainer $collaspe={collaspe}>
        <BoxContainer $collaspe={collaspe}>
          <Box component="div" sx={{ width: '100%' }}>
            <LogoContainer $collaspe={collaspe}>
              {!collaspe && <Logo />}
              <div style={{ cursor: 'pointer' }} onClick={handleChangeCollaspe}>
                <IoMenu />
              </div>
            </LogoContainer>
            {renderMenu}
          </Box>
          <Header />
        </BoxContainer>
      </SidebarContainer>
      <WorldPopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={addFloor}
        floorLevelOptions={transformSelectOptions(floorLevels)}
      />
    </>
  );
};
