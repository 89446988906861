/* eslint-disable max-lines */
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { NoData } from '@/components/common/NoData';
import { Loader } from '@/components/common/loader';
import { ConnectionPopup, Filter } from '@/components/connection';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { Container } from '@/components/venue/components';
import { RequestStatus } from '@/constants/API';
import useConnectionHook from '@/hooks/useConnectionHook';
import useCustomerHook from '@/hooks/useCustomerHook';
import useFloorHook from '@/hooks/useFloorHook';
import useGlobalHook from '@/hooks/useGlobalHook';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import {
  ConnectionDetail,
  ConnectionDetailsForm,
} from '@/stores/slices/connection/type';
import { checkIsRequesting } from '@/utils/helper';
import _ from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ConnectionPage = () => {
  const { t } = useTranslation();

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [selectedId, setSelectedId] = useState<string | number>('');
  const [editingConnection, setEditingConnection] = useState<
    ConnectionDetail | undefined
  >(undefined);

  const location = useLocation();
  const queries = useParams();

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    getConnectionListStatus,
    requestConnectionList,
    createConnectionInfoStatus,
    requestCreateConnection,
    requestUpdateConnectionInfo,
    deleteConnectionInfoStatus,
    connections,
    updateConnectionInfoStatus,
    requestDeleteConnection,
    requestResetConnection,
  } = useConnectionHook();

  const { getAllCustomerListStatus } = useCustomerHook();
  const { requestFloorListOption, getFloorListOptionStatus } = useFloorHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getConnectionListStatus,
        createConnectionInfoStatus,
        deleteConnectionInfoStatus,
        updateConnectionInfoStatus,
        getAllCustomerListStatus,
        getFloorListOptionStatus,
      ]),
    [
      getConnectionListStatus,
      createConnectionInfoStatus,
      deleteConnectionInfoStatus,
      updateConnectionInfoStatus,
      getAllCustomerListStatus,
      getFloorListOptionStatus,
    ],
  );

  const showNoData = useMemo(
    () =>
      getConnectionListStatus !== RequestStatus.IDLE &&
      (connections?.data?.length === 0 || !connections),
    [connections, getConnectionListStatus],
  );
  const navigate = useNavigateHook()?.navigate;
  const redirect = useCallback(
    (qr: any) => {
      navigate(
        `${location.pathname}?${queryString.stringify(
          _.omitBy(
            qr,
            v => !v || (Array.isArray(v) && _.isEmpty(v)) || _.isNil(v),
          ),
        )}`,
      );
    },
    [location.pathname, navigate],
  );

  const onChangePage = (id: number) => {
    let qr = queryString.parse(window.location.search);
    qr.page = id.toString();
    redirect(qr);
  };

  // const onExport = () => {};

  const onAdd = () => {
    setEditingConnection(undefined);
    setOpenPopup(true);
  };

  const submitAddOrEdit = (formData: ConnectionDetailsForm) => {
    if (editingConnection) {
      requestUpdateConnectionInfo({
        ...formData,
        id: editingConnection.id,
        venue: queries?.venueId,
      });
    } else {
      requestCreateConnection({ ...formData, venue: queries?.venueId });
    }
    setOpenPopup(false);
  };

  const onEdit = (id: number | string) => {
    setEditingConnection(
      connections?.data.find((it: ConnectionDetail) => it.id === id) ??
        undefined,
    );
    setOpenPopup(true);
  };

  const onDelete = (id: number | string) => {
    setOpenDelete(true);
    setSelectedId(id);
  };

  const submitDelete = () => {
    requestDeleteConnection(selectedId);
  };

  const handleDebounceFn = (inputValue: string) => {
    let qr = queryString.parse(window.location.search);
    qr.keyword = inputValue;
    redirect(qr);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  const onKeywordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const keyword = e.currentTarget?.value;
    debounceFn(keyword);
  };

  const onDownwardFloorChange = (e: any) => {
    let qr = queryString.parse(window.location.search);
    qr.levels = e.target.value;
    redirect(qr);
  };

  const onUpwardFloorChange = (e: any) => {
    onDownwardFloorChange(e);
  };

  const onTypeChange = (e: any) => {
    let qr = queryString.parse(window.location.search);
    qr.type = e.target.value;
    redirect(qr);
  };

  const pagination = {
    currentPage: connections?.current_page ?? 1,
    totalPages: connections?.total_page ?? 1,
    onPageChange: (id: number) => onChangePage(id),
  };

  const reloadData = () => {
    let pr = queryString.parse(window.location.search);
    requestConnectionList(
      queries?.venueId ? { ...pr, venue: queries?.venueId } : { ...pr },
    );
  };

  useEffect(() => {
    requestFloorListOption({ venue: queries?.venueId });
    return () => requestResetConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateConnectionInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Connection updated!')]);
      reloadData();
    }
    if (updateConnectionInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update connection')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateConnectionInfoStatus]);

  useEffect(() => {
    if (createConnectionInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Connection created!')]);
      reloadData();
    }

    if (createConnectionInfoStatus === RequestStatus.ERROR) {
      setSuccessMsg([t('Something went wrong. Unable to create connection')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createConnectionInfoStatus]);

  useEffect(() => {
    if (deleteConnectionInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to delete connection')]);
    }
    if (deleteConnectionInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Connection deleted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteConnectionInfoStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <Container>
      <Filter
        onAdd={onAdd}
        // onExport={onExport}
        onKeywordChange={onKeywordChange}
        onDownwardFloorChange={onDownwardFloorChange}
        onUpwardFloorChange={onUpwardFloorChange}
        onTypeChange={onTypeChange}
      />
      {showNoData ? (
        <NoData />
      ) : (
        <CommonTable
          type={TableType.CONNECTION}
          data={connections?.data}
          pagination={pagination}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )}
      <ConnectionPopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={submitAddOrEdit}
        data={editingConnection}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onSubmit={submitDelete}
      />
      <Loader show={loading} />
    </Container>
  );
};
