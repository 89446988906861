import { ReactNode } from 'react';
import { Sidebar } from '@/components/layouts/sidebar';
import styled from 'styled-components';
import { Content } from '@/components/layouts/content';

const LayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  overflow: hidden;
`;

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <LayoutContainer>
      <Sidebar />
      <Content>{children}</Content>
    </LayoutContainer>
  );
};
