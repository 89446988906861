import { RequestStatus } from '@/constants/API';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CommonType } from '../amenity/type';

export interface CategoryState {
  getCategoryListStatus: RequestStatus;
  createCategoryInfoStatus: RequestStatus;
  updateCategoryInfoStatus: RequestStatus;
  deleteCategoryInfoStatus: RequestStatus;
  categories: CommonType[];
}

const initialState: CategoryState = {
  getCategoryListStatus: RequestStatus.IDLE,
  createCategoryInfoStatus: RequestStatus.IDLE,
  updateCategoryInfoStatus: RequestStatus.IDLE,
  deleteCategoryInfoStatus: RequestStatus.IDLE,
  categories: [],
};

export const categorySlice: any = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getCategoryListRequest: (state, action: PayloadAction<any>) => {
      state.getCategoryListStatus = RequestStatus.REQUESTING;
    },
    getCategoryListSuccess: (state, action: PayloadAction<any>) => {
      state.getCategoryListStatus = RequestStatus.SUCCESS;
      state.categories = action.payload;
    },
    getCategoryListFailure: state => {
      state.getCategoryListStatus = RequestStatus.ERROR;
    },

    createCategoryInfoRequest: (state, action: PayloadAction<any>) => {
      state.createCategoryInfoStatus = RequestStatus.REQUESTING;
    },
    createCategoryInfoSuccess: (state, action: PayloadAction<any>) => {
      state.createCategoryInfoStatus = RequestStatus.SUCCESS;
    },
    createCategoryInfoFailure: (state, action: PayloadAction<any>) => {
      state.createCategoryInfoStatus = RequestStatus.ERROR;
    },
    updateCategoryInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateCategoryInfoStatus = RequestStatus.REQUESTING;
    },
    updateCategoryInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateCategoryInfoStatus = RequestStatus.SUCCESS;
    },
    updateCategoryInfoFailure: (state, action: PayloadAction<any>) => {
      state.updateCategoryInfoStatus = RequestStatus.ERROR;
    },
    deleteCategoryInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteCategoryInfoStatus = RequestStatus.REQUESTING;
    },
    deleteCategoryInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteCategoryInfoStatus = RequestStatus.SUCCESS;
    },
    deleteCategoryInfoFailure: (state, action: PayloadAction<any>) => {
      state.deleteCategoryInfoStatus = RequestStatus.ERROR;
    },
    resetCategoryInfoRequestStatus: state => {
      state.getCategoryListStatus = RequestStatus.IDLE;
      state.createCategoryInfoStatus = RequestStatus.IDLE;
      state.updateCategoryInfoStatus = RequestStatus.IDLE;
      state.deleteCategoryInfoStatus = RequestStatus.IDLE;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getCategoryListRequest,
  getCategoryListSuccess,
  getCategoryListFailure,
  createCategoryInfoRequest,
  createCategoryInfoSuccess,
  createCategoryInfoFailure,
  updateCategoryInfoRequest,
  updateCategoryInfoSuccess,
  updateCategoryInfoFailure,
  deleteCategoryInfoRequest,
  deleteCategoryInfoSuccess,
  deleteCategoryInfoFailure,
  resetCategoryInfoRequestStatus,
} = categorySlice.actions;

export default categorySlice.reducer;
