import styled from 'styled-components';
import logo from '@/assets/logo.svg';
import React from 'react';
import { Badge, LogoWrapper } from '../sidebar/components';
import Path from '@/constants/path';

const LogoContainer = styled.div`
  width: 150px;
  padding: 8px 16px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
`;

const LogoImage = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
`;

export const Logo = () => (
  <LogoContainer>
    <LogoImage>
      <LogoWrapper to={Path.HOME}>
        <img src={logo} className="App-logo" alt="logo" />
        <Badge>CMS</Badge>
      </LogoWrapper>
    </LogoImage>
  </LogoContainer>
);
