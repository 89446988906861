/* eslint-disable max-lines */
import { CustomController } from '@/components/form/controller';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CustomButton, { CustomButtonType } from 'src/components/common/Button';
import PopUp from 'src/components/common/Popup';
import { OneInputContainer } from 'src/components/floor/components';
import { FormType } from '@/components/form/types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageInput } from '@/components/form/imageUploading';
import { InputType } from '@/components/form/customSelect';
import { CommonType } from '@/stores/slices/amenity/type';
import _ from 'lodash';

export interface AmenityPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formdata: Omit<CommonType, 'id'>) => void;
  data?: CommonType;
}

export function AmenityPopup({
  open,
  onClose,
  onSubmit,
  data,
}: AmenityPopupProps) {
  const amenityForm = useForm<Omit<CommonType, 'id'>>({
    defaultValues: _.omit(data, ['id']),
  });

  const {
    control,
    handleSubmit,
    reset,

    formState: { errors },
  } = amenityForm;
  const { t, i18n } = useTranslation();

  const submitForm = async (formDt: any) => {
    onSubmit({
      ...formDt,
      icon: Array.isArray(formDt?.icon)
        ? formDt?.icon[0]?.data_url ?? null
        : formDt?.icon ?? null,
    });
  };

  useEffect(() => {
    if (data) {
      reset(_.omit(data, ['id']));
    } else {
      reset({
        name: '',
        icon: '',
      });
    }
  }, [data, reset, open]);

  return (
    <PopUp
      title={data ? t('Edit Amenity') : t('Add Amenity')}
      onClose={onClose}
      open={open}
    >
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <OneInputContainer>
          <CustomController
            controller={control}
            name="name"
            label={t('Name')}
            isRequired
            error={errors?.name?.message}
            formType={FormType.INPUT}
          />
        </OneInputContainer>

        <Box component="div" mb={3}>
          <Controller
            control={control}
            name="icon"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <ImageInput
                  label={t('Icon')}
                  title={t('Drag an image here or click “Upload” below')}
                  width={120}
                  height={120}
                  inputType={InputType.POP_UP}
                  onUpload={onChange}
                  value={value ? [value] : []}
                  isRequired
                  errorMessage={errors?.icon?.message}
                />
              );
            }}
          />
        </Box>

        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
            display: 'grid',
            gridTemplateColumns:
              i18n.resolvedLanguage === 'en' ? '70px 70px' : '120px 70px',
            justifyContent: 'flex-end',
            gridGap: '20px',
          }}
        >
          <CustomButton
            buttonType={CustomButtonType.WHITE_BG}
            title={t(`Cancel`)}
            onClick={onClose}
          />
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={t(`Submit`)}
          />
        </Box>
      </Box>
    </PopUp>
  );
}
