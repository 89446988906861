import { Box, Button, Typography } from '@mui/material';
import styled from 'styled-components';

export const Tag = styled(Box)`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: #e4fff2;
  span {
    color: #03ba5e;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const Header = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Segment = styled(Box)`
  display: flex;
  margin: 20px 0;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #dee1eb;
  background: #fff;

  & img {
    max-width: 100%;
  }
`;

export const TopContainer = styled(Box)`
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 20px;
`;

export const BoxSwitch = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
`;

export const SettingBtn = styled(Button)`
  && {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #3080ec;
    color: #fff;
    svg {
      fill: #fff;
      stroke: #fff;
    }
    &:hover {
      opacity: 0.7;
      background: #3080ec;
    }
  }
`;

export const PrimaryColumn = styled(Box)`
  display: grid;
  grid-template-columns: 20px 140px 1fr;
  padding: 20px 16px;
  margin: 0 -16px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px dashed #d8dae0;
  &.no_border {
    border-bottom: none;
  }
  svg {
    fill: #3080ec;
    stroke: #3080ec;
    path {
      fill: #3080ec;
      stroke: #3080ec;
    }
  }
`;

export const Text = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    &.main {
      font-size: 24px;
      font-weight: normal;
    }
    &.normal {
      font-weight: normal;
    }
    &.small {
      font-weight: normal;
      font-size: 16px;
      color: #555c6a;
    }
    &.flex {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
      }
    }
  }
`;

export const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 50px 1fr;
`;

export interface CustomSegment {
  title: string;
  children: any;
}

export const Section = ({ title, children }: CustomSegment) => {
  return (
    <Segment
      padding={'0 16px 70px'}
      sx={{
        position: 'relative',
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      }}
    >
      <Box
        sx={{
          margin: '0 -16px',
          padding: '16px',
          background: '#fafafa',
          borderBottom: '#eaeded',
          width: '100%',
          borderRadius: '8px 8px 0 0',
        }}
      >
        <Text>{title}</Text>
      </Box>
      <Box width={`100%`}>{children}</Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '50px',
        }}
        borderTop={`1px solid #eaeded`}
      ></Box>
    </Segment>
  );
};
