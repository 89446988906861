import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  createQRCodeInfoFailure,
  createQRCodeInfoRequest,
  createQRCodeInfoSuccess,
  deleteQRCodeInfoFailure,
  deleteQRCodeInfoRequest,
  deleteQRCodeInfoSuccess,
  getQRCodeDetailFailure,
  getQRCodeDetailRequest,
  getQRCodeDetailSuccess,
  getQRCodeListFailure,
  getQRCodeListRequest,
  getQRCodeListSuccess,
  updateQRCodeInfoFailure,
  updateQRCodeInfoRequest,
  updateQRCodeInfoSuccess,
} from '../slices/qrcode/qrcode';

type IPayload = Record<string, any>;

function* getQRCodeListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getQRCodes, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getQRCodeListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getQRCodeListFailure.type });
    }
  } catch (error) {
    yield put({ type: getQRCodeListFailure.type });
  }
}

function* getQRCodeDetailFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getQRCodeDetail, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getQRCodeDetailSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getQRCodeDetailFailure.type });
    }
  } catch (error) {
    yield put({ type: getQRCodeDetailFailure.type });
  }
}

function* createQRCodeFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createQRCode, payload);

    if (
      response.status === StatusCode.SUCCESS_CREATED ||
      response.status === StatusCode.SUCCESS_COMMON
    ) {
      yield put({
        type: createQRCodeInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createQRCodeInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createQRCodeInfoFailure.type });
  }
}

function* updateQRCodeFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateQRCode, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateQRCodeInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateQRCodeInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: updateQRCodeInfoFailure.type });
  }
}

function* deleteQRCodeFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteQRCode, payload);

    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteQRCodeInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteQRCodeInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteQRCodeInfoFailure.type });
  }
}
function* qrcodeSaga() {
  yield takeLatest(getQRCodeListRequest, getQRCodeListFlow);
  yield takeLatest(getQRCodeDetailRequest, getQRCodeDetailFlow);
  yield takeLatest(createQRCodeInfoRequest, createQRCodeFlow);
  yield takeLatest(updateQRCodeInfoRequest, updateQRCodeFlow);
  yield takeLatest(deleteQRCodeInfoRequest, deleteQRCodeFlow);
}

export default qrcodeSaga;
