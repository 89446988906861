import { AutocompleteGetTagProps, Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { CloseIcon } from 'src/components/icons';
import * as React from 'react';
import { InputType } from '@/components/form/customSelect/index';

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
  disabled: boolean;
  $inputType?: InputType;
}

export const IconWrapper = styled.span``;

export function Tag(props: TagProps) {
  const { label, onDelete, disabled, ...other } = props;
  return (
    <div {...other}>
      <Typography
        sx={{
          fontSize: '12px',
          lineHeight: '18px',
          color: '#fff',
        }}
      >
        {label}
      </Typography>
      <IconWrapper onClick={disabled ? () => {} : onDelete}>
        <CloseIcon />
      </IconWrapper>
    </div>
  );
}

export const Root = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  position: relative;
`;

export const LabelPrimary = styled(Typography)<{ $inputType?: InputType }>`
  && {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.05em;
    color: ${props =>
      props.$inputType === InputType.MAPPING ? '#fff' : '#5c677e'};
  }
`;

export const InputWrapper = styled(Box)<{ $inputType?: InputType }>`
  background: ${props =>
    props.$inputType === InputType.MAPPING ? '#40495C' : '#fff'};
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  position: relative;
  &:hover {
    border-color: #dee1eb;
    box-shadow: none;
  }

  &.focused {
    border-color: #dee1eb;
    box-shadow: none;
  }

  & input {
    background: ${props =>
      props.$inputType === InputType.MAPPING ? '#40495C' : '#fff'};
    color: ${props =>
      props.$inputType === InputType.MAPPING ? '#fff' : '#222222 '};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

export const StyledTag = styled(Tag)<TagProps>`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  background: ${props =>
    props.$inputType === InputType.MAPPING ? '#353A47' : '#e34a66'};
  color: #fff;
  outline: 0;
  overflow: hidden;
  font-size: 12px;
  height: 26px;
  box-sizing: border-box;
  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 18px;
    cursor: pointer;
    padding: 4px;
  }
  svg path {
    stroke: #fff;
  }
`;

export const ListBox = styled.ul<{ $inputType?: InputType }>`
  z-index: 1000;
  width: 100%;
  margin: 2px 0 0;
  padding: 15px 8px;
  position: absolute;
  list-style: none;
  background: ${props =>
    props.$inputType === InputType.MAPPING ? '#40495C' : '#fff'};
  overflow: auto;
  max-height: 250px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;

  & li {
    padding: 5px 12px;
    display: flex;

    & p {
      flex-grow: 1;
      font-size: 14px;
      line-height: 20px;
      color: #222;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`;

export const IconContainer = styled(Box)<{ $inputType?: InputType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  svg path {
    stroke: ${props =>
      props.$inputType === InputType.MAPPING ? '#fff' : '#40495C'};
  }
`;
