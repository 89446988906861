/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import PopUp from '@/components/common/Popup';
import {
  OneInputContainer,
  TwoInputContainer,
} from '@/components/floor/components';
import Input, { Label } from '@/components/form/input';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import MultiSelectItem from '../form/multiselect';
import SelectItem from '../form/select';
import {
  ConnectionDetail,
  ConnectionDetailsForm,
} from '@/stores/slices/connection/type';
import {
  CONNECTION_TYPES,
  LOCATION_STATES,
  LOCATION_STATUS,
} from '@/constants';
import { AntSwitch } from '../layouts/table/component';
import { useEffect } from 'react';
import _ from 'lodash';
import {
  transformSelectOptions,
  transformSelectOptionsWithTranslation,
} from '@/utils/helper';
import useFloorHook from '@/hooks/useFloorHook';
import { useTranslation } from 'react-i18next';

export interface ConnectionPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formdata: ConnectionDetailsForm) => void;
  data?: ConnectionDetail;
}

export function ConnectionPopup({
  open,
  onClose,
  onSubmit,
  data,
}: ConnectionPopupProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ConnectionDetailsForm>({
    defaultValues: {
      name: '',
      levels: [],
      state: 1,
      status: 1,
      type: 1,
      accessible: false,
    },
  });
  const { t, i18n } = useTranslation();

  const { floorOptions } = useFloorHook();

  const submitForm = (data: any) => {
    onSubmit(data);
  };

  useEffect(() => {
    if (data) {
      reset({
        name: data?.name,
        levels: _.map(data?.levels, it => it.id),
        type: data?.type,
        accessible: data?.accessible,
        state: data?.state,
        status: data?.status,
      });
    } else {
      reset({
        name: '',
        levels: [],
        type: 1,
        status: 1,
        state: 1,
        accessible: false,
      });
    }
  }, [data, reset, open]);

  return (
    <PopUp
      title={data ? t('Edit Connection') : t('Add Connection')}
      onClose={onClose}
      open={open}
    >
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <OneInputContainer>
          <Controller
            control={control}
            name="name"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('Connection Name')}
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={errors?.name?.message}
                />
              );
            }}
          />
        </OneInputContainer>
        <TwoInputContainer>
          <Controller
            control={control}
            name="status"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <SelectItem
                  isRequired
                  value={value ?? ''}
                  options={transformSelectOptionsWithTranslation(
                    LOCATION_STATUS,
                    t,
                  )}
                  onChange={onChange}
                  label={t('Status')}
                  errorMess={errors?.status?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="state"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <SelectItem
                  isRequired
                  value={value ?? ''}
                  options={transformSelectOptionsWithTranslation(
                    LOCATION_STATES,
                    t,
                  )}
                  onChange={onChange}
                  label={t('State')}
                  placeholder={t('State')}
                  errorMess={errors?.state?.message}
                />
              );
            }}
          />
        </TwoInputContainer>
        <OneInputContainer>
          <Controller
            control={control}
            name="type"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <SelectItem
                  options={transformSelectOptionsWithTranslation(
                    CONNECTION_TYPES,
                    t,
                  )}
                  value={value}
                  onChange={onChange}
                  placeholder={t('Type')}
                  label={t('Type')}
                  isRequired
                  errorMess={errors?.type?.message}
                />
              );
            }}
          />
        </OneInputContainer>
        <OneInputContainer>
          <Controller
            control={control}
            name="levels"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <MultiSelectItem
                  options={transformSelectOptions(floorOptions)}
                  value={value}
                  onChange={onChange}
                  placeholder={t('Arrival Floor')}
                  label={t('Arrival Floor')}
                  isRequired
                  errorMess={errors?.levels?.message ?? ''}
                />
              );
            }}
          />
        </OneInputContainer>
        <OneInputContainer>
          <Controller
            control={control}
            name="accessible"
            render={({ field }) => {
              const { value, onChange } = field;

              return (
                <Box mt={2}>
                  <Label>{t('Barrier')}</Label>
                  <AntSwitch onChange={onChange} checked={value} />
                </Box>
              );
            }}
          />
        </OneInputContainer>
        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
            display: 'grid',
            gridTemplateColumns:
              i18n.resolvedLanguage === 'en' ? '70px 70px' : '120px 70px',
            justifyContent: 'flex-end',
            gridGap: '20px',
          }}
        >
          <CustomButton
            buttonType={CustomButtonType.WHITE_BG}
            title={`Cancel`}
            onClick={onClose}
          />
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={`Submit`}
          />
        </Box>
      </Box>
    </PopUp>
  );
}
