import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  getConnectionListRequest,
  getConnectionListSuccess,
  getConnectionListFailure,
  getConnectionDetailsRequest,
  getConnectionDetailsSuccess,
  getConnectionDetailsFailure,
  createConnectionInfoRequest,
  createConnectionInfoSuccess,
  createConnectionInfoFailure,
  updateConnectionInfoRequest,
  updateConnectionInfoSuccess,
  updateConnectionInfoFailure,
  deleteConnectionInfoRequest,
  deleteConnectionInfoSuccess,
  deleteConnectionInfoFailure,
} from '../slices/connection/connection';

type IPayload = Record<string, any>;

function* getConnectionListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getConnections, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getConnectionListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getConnectionListFailure.type });
    }
  } catch (error) {
    yield put({ type: getConnectionListFailure.type });
  }
}

function* getConnectionDetailsFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getConnectionDetails, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getConnectionDetailsSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getConnectionDetailsFailure.type });
    }
  } catch (error) {
    yield put({ type: getConnectionDetailsFailure.type });
  }
}

function* createConnectionFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createConnection, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createConnectionInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createConnectionInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createConnectionInfoFailure.type });
  }
}

function* updateConnectionFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateConnection, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateConnectionInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateConnectionInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: updateConnectionInfoFailure.type });
  }
}

function* deleteConnectionFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteConnection, payload);

    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteConnectionInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteConnectionInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteConnectionInfoFailure.type });
  }
}
function* connectionSaga() {
  yield takeLatest(getConnectionListRequest, getConnectionListFlow);
  yield takeLatest(createConnectionInfoRequest, createConnectionFlow);
  yield takeLatest(updateConnectionInfoRequest, updateConnectionFlow);
  yield takeLatest(deleteConnectionInfoRequest, deleteConnectionFlow);
  yield takeLatest(getConnectionDetailsRequest, getConnectionDetailsFlow);
}

export default connectionSaga;
