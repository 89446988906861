export const GraphyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.907 11.4671C16.907 11.3006 16.8408 11.1409 16.7231 11.0231C16.6053 10.9053 16.4456 10.8392 16.2791 10.8392H13.7674C13.6009 10.8392 13.4412 10.9053 13.3234 11.0231C13.2057 11.1409 13.1395 11.3006 13.1395 11.4671V17.1183H11.8837V3.5136C11.8837 2.90412 11.882 2.50895 11.8435 2.21928C11.8067 1.94719 11.7473 1.86179 11.6995 1.81407C11.6518 1.76635 11.5664 1.70691 11.2943 1.67007C11.0038 1.63156 10.6095 1.62988 10 1.62988C9.39051 1.62988 8.99535 1.63156 8.70567 1.67007C8.43358 1.70691 8.34819 1.76635 8.30047 1.81407C8.25274 1.86179 8.1933 1.94719 8.15647 2.21928C8.11795 2.50979 8.11628 2.90412 8.11628 3.5136V17.1183H6.86047V7.28105C6.86047 7.11451 6.79431 6.9548 6.67656 6.83705C6.5588 6.71929 6.39909 6.65314 6.23256 6.65314H3.72093C3.5544 6.65314 3.39469 6.71929 3.27693 6.83705C3.15918 6.9548 3.09302 7.11451 3.09302 7.28105V17.1183H1.62791C1.46138 17.1183 1.30167 17.1844 1.18391 17.3022C1.06615 17.4199 1 17.5796 1 17.7462C1 17.9127 1.06615 18.0724 1.18391 18.1902C1.30167 18.3079 1.46138 18.3741 1.62791 18.3741H18.3721C18.5386 18.3741 18.6983 18.3079 18.8161 18.1902C18.9338 18.0724 19 17.9127 19 17.7462C19 17.5796 18.9338 17.4199 18.8161 17.3022C18.6983 17.1844 18.5386 17.1183 18.3721 17.1183H16.907V11.4671Z"
      fill="#555C6A"
    />
  </svg>
);
