import { useAppSelector, useAppDispatch } from '@/hooks';

import {
  getQRCodeListRequest,
  getQRCodeDetailRequest,
  updateQRCodeInfoRequest,
  deleteQRCodeInfoRequest,
  createQRCodeInfoRequest,
  resetQRCodeInfoRequestStatus,
} from '@/stores/slices/qrcode/qrcode';
import { selectQRCode } from '@/stores/slices/qrcode/selector';

const useQRCodeHook = () => {
  const {
    getQRCodeListStatus,
    getQRCodeDetailStatus,
    updateQRCodeInfoStatus,
    createQRCodeInfoStatus,
    deleteQRCodeInfoStatus,
    qrcodes,
    qrcode,
  } = useAppSelector(selectQRCode);

  const dispatch = useAppDispatch();

  const requestQRCodeList = (params: any) =>
    dispatch(getQRCodeListRequest(params));

  const requestQRCodeDetail = (params: any) =>
    dispatch(getQRCodeDetailRequest(params));

  const requestCreateQRCode = (params: any) =>
    dispatch(createQRCodeInfoRequest(params));

  const requestUpdateQRCodeInfo = (params: any) =>
    dispatch(updateQRCodeInfoRequest(params));

  const requestDeleteQRCode = (params: any) =>
    dispatch(deleteQRCodeInfoRequest(params));

  const requestResetQRCode = () => dispatch(resetQRCodeInfoRequestStatus());

  return {
    requestQRCodeList,
    requestQRCodeDetail,
    requestCreateQRCode,
    requestUpdateQRCodeInfo,
    requestDeleteQRCode,
    requestResetQRCode,
    getQRCodeListStatus,
    getQRCodeDetailStatus,
    updateQRCodeInfoStatus,
    createQRCodeInfoStatus,
    deleteQRCodeInfoStatus,
    qrcodes,
    qrcode,
  };
};

export default useQRCodeHook;
