import { Box } from '@mui/material';
import CustomButton, { CustomButtonType } from './Button';
import PopUp from './Popup';
import Input, { Label } from '../form/input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
export interface IPopUp {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  sx?: any;
  children?: any;
  className?: string;
  title?: string;
  actionTitle?: string;
}

const ConfirmPopUp = ({
  className,
  children,
  title,
  sx,
  open,
  onClose,
  onSubmit,
  actionTitle = 'Delete',
  ...props
}: IPopUp) => {
  const { t, i18n } = useTranslation();

  const onAgree = () => {
    if (actionTitle === 'Delete' && value !== 'delete') {
      setError(true);
      return;
    } else {
      setError(false);
    }
    onSubmit();
    onClose();
  };
  const [value, setValue] = useState<string>('');
  const [hasError, setError] = useState<boolean>(false);
  const onChange = (e: any) => {
    setValue(e.target?.value);
    if (e.target?.value === 'delete') setError(false);
  };

  useEffect(() => {
    if (open) {
      setValue('');
      setError(false);
    }
  }, [open]);

  return (
    <PopUp
      open={open}
      className={`confirm_modal`}
      title={title}
      onClose={onClose}
    >
      {children}
      {actionTitle === 'Delete' ? (
        <Box
          mb={2}
          sx={{
            '& .error': {
              color: '#eb5757',
              textAlign: 'left',
            },
          }}
        >
          <Input
            label={t('Enter "delete" to this below input to confirm.')}
            value={value}
            onChange={onChange}
          />
          {hasError && (
            <Label className="error">{t('Incorrect required input')}</Label>
          )}
        </Box>
      ) : (
        ''
      )}
      <Box
        component="div"
        sx={{
          margin: '20px 0 0',
          display: 'grid',
          gridTemplateColumns:
            actionTitle === 'Delete'
              ? i18n.resolvedLanguage === 'ja'
                ? '120px 70px'
                : '70px 70px'
              : i18n.resolvedLanguage === 'ja'
              ? '120px 70px'
              : '70px 70px',
          justifyContent: 'flex-end',
          gridGap: '20px',
          '& button:nth-child(2)': {
            background: '#eb5757',
          },
        }}
      >
        <CustomButton
          buttonType={CustomButtonType.WHITE_BG}
          title={t(`Cancel`)}
          onClick={onClose}
        />
        <CustomButton
          buttonType={CustomButtonType.NO_ICON}
          title={actionTitle === 'Delete' ? t('Delete') : actionTitle}
          onClick={onAgree}
        />
      </Box>
    </PopUp>
  );
};

export default ConfirmPopUp;
