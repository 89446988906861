/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import PopUp from '@/components/common/Popup';
import Input from '@/components/form/input';
import { DuplicateForm } from '@/stores/slices/venue/type';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputContainer } from '.';
import { useTranslation } from 'react-i18next';

export interface DuplicateProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formdata: DuplicateForm) => void;
}

export function DuplicatePopup({ open, onClose, onSubmit }: DuplicateProps) {
  const nameRef = useRef<any>(null);
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<DuplicateForm>({
    defaultValues: {
      name: '',
      externalId: '',
    },
  });
  const dataWatch = watch();

  const submitForm = (formDt: any) => {
    onSubmit({ ...formDt, externalId: toSnakeCase(dataWatch.name) });
    reset();
  };

  const toSnakeCase = (text: string) => {
    return text
      .replace(/([a-z])([A-Z])/g, '$1_$2')
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2')
      .toLowerCase()
      .replace(/[\s-]+/g, '_');
  };

  return (
    <PopUp title={'Duplicate'} onClose={onClose} open={open}>
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <InputContainer className="single" ref={nameRef}>
          <Controller
            control={control}
            name="name"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('Name')}
                  isRequired
                  error={errors?.name?.message}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </InputContainer>

        <InputContainer className="single">
          <Controller
            control={control}
            name="externalId"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('ID')}
                  type="text"
                  value={toSnakeCase(dataWatch.name)}
                  onChange={onChange}
                  error={errors?.externalId?.message}
                  disabled
                />
              );
            }}
          />
        </InputContainer>

        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
            display: 'flex',
            gridTemplateColumns:
              i18n.resolvedLanguage === 'ja' ? '150px 210px' : '150px 160px',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns:
                i18n.resolvedLanguage === 'en' ? '70px 70px' : '120px 70px',
              gap: '20px',
              alignSelf: 'end',
            }}
          >
            <CustomButton
              buttonType={CustomButtonType.WHITE_BG}
              title={t(`Cancel`)}
              onClick={onClose}
            />
            <CustomButton
              type="submit"
              buttonType={CustomButtonType.NO_ICON}
              title={t(`Submit`)}
            />
          </Box>
        </Box>
      </Box>
    </PopUp>
  );
}
