/* eslint-disable max-lines */
import { ReactComponent as PublishIc } from '@/assets/icons/publish_ic.svg';
import { ReactComponent as UnPublishIc } from '@/assets/icons/unpublish_ic.svg';
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { Loader } from '@/components/common/loader';

import { ChartContainer, TopContainer } from '@/components/analysis';
import {
  CustomerIcon,
  FloorIcon,
  LocationIcon,
  SettingsIcon,
} from '@/components/icons';
import { StatusTxt } from '@/components/layouts/table/component';
import dayjs from 'dayjs';

import {
  Header,
  HeaderContainer,
  Section,
  Segment,
  SettingBtn,
  Tag,
  Text,
} from '@/components/top';
import { Container } from '@/components/venue/components';
import { VenuePopup } from '@/components/venue/components/popup';
import { PUBLISH_STATES } from '@/constants';
import { RequestStatus } from '@/constants/API';
import useCustomerHook from '@/hooks/useCustomerHook';
import useFloorHook from '@/hooks/useFloorHook';
import useGlobalHook from '@/hooks/useGlobalHook';
import useVenueHook from '@/hooks/useVenueHook';
import { VenueDetailsForm } from '@/stores/slices/venue/type';
import { checkIsRequesting } from '@/utils/helper';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  PieChart,
  Pie,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  CartesianGrid,
} from 'recharts';
import { useTranslation } from 'react-i18next';

export const AnalysisPage = () => {
  const { t } = useTranslation();

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const queries = useParams();

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    createVenueInfoStatus,
    requestUpdateVenueInfo,
    deleteVenueInfoStatus,
    updateVenueInfoStatus,
    requestDeleteVenue,
    requestResetVenue,
    venueDetails,
    getVenueDetailsStatus,
    requestVenueDetails,
  } = useVenueHook();
  const { getAllCustomerListStatus } = useCustomerHook();

  const { floors } = useFloorHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getVenueDetailsStatus,
        createVenueInfoStatus,
        deleteVenueInfoStatus,
        updateVenueInfoStatus,
        getAllCustomerListStatus,
      ]),
    [
      getVenueDetailsStatus,
      createVenueInfoStatus,
      deleteVenueInfoStatus,
      updateVenueInfoStatus,
      getAllCustomerListStatus,
    ],
  );
  const submitAddOrEdit = (formData: VenueDetailsForm) => {
    requestUpdateVenueInfo({ ...formData, id: queries.venueId });

    setOpenPopup(false);
  };

  const onEdit = () => {
    setOpenPopup(true);
  };

  const onDelete = () => {
    setOpenPopup(false);
    setOpenDelete(true);
  };

  const submitDelete = () => {
    requestDeleteVenue(queries.venueId);
  };

  useEffect(() => {
    requestVenueDetails({ id: queries.venueId });

    return () => requestResetVenue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateVenueInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Venue updated!')]);
    }
    if (updateVenueInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update venue')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateVenueInfoStatus]);

  useEffect(() => {
    if (deleteVenueInfoStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to delete venue')]);
    }
    if (deleteVenueInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Venue deleted!')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteVenueInfoStatus]);

  const data = [
    { name: 'Android', value: 400, fill: '#0088FE' },
    { name: 'IOS', value: 300, fill: '#00C49F' },
    { name: 'Window', value: 300, fill: '#FFBB28' },
    { name: 'MacOS', value: 100, fill: '#0033FF' },
  ];

  const renderColorfulLegendText = (value: string, entry: any) => {
    return (
      <>
        <span style={{ color: '#596579', fontWeight: 500, padding: '10px' }}>
          {value}
        </span>
        <span style={{ color: '#221111', fontWeight: 600 }}>
          {Math.round(entry?.payload?.percent * 100)}%
        </span>
      </>
    );
  };
  const rangeData = Array.from(Array(30).keys()).map(item => ({
    name: dayjs().subtract(item, 'd').format('YYYY-MM-DD'),
    uv: Number((Math.round(Math.random() * 100) / 100).toFixed(2)) * 1000,
  }));

  const da = [
    { name: 'Nike', pv: 240 },
    { name: 'Hugo Boss', pv: 2210 },
    { name: 'Chanel', pv: 2300 },
    { name: 'Celine', pv: 2000 },
    { name: 'Tom Ford', pv: 5000 },
    { name: 'Hifie', pv: 123 },
    { name: 'Balenciaga', pv: 2091 },
  ];

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, height, value } = props;

    const fireOffset = value?.toString().length < 5;
    const offset = fireOffset ? -40 : 5;
    return (
      <text
        x={x + width - offset}
        y={y + height - 5}
        fill={fireOffset ? '#111111' : '#fff'}
        textAnchor="end"
      >
        {value}
      </text>
    );
  };
  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active) {
      return (
        <Box
          sx={{
            bordeRadius: '5px',
            padding: '8px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          }}
        >
          <Text className="normal" mb={1}>
            {dayjs(label).format('MM/DD')}
          </Text>
          <Text sx={{ textAlign: 'center' }}>{payload[0].value}</Text>
        </Box>
      );
    }
    return null;
  };

  return (
    <Container>
      <HeaderContainer>
        <Box>
          <Header>
            <Text className="main">{venueDetails?.name}</Text>
            {venueDetails?.publish ? (
              <Tag>
                {t(PUBLISH_STATES[1].title)}
                <PublishIc />
              </Tag>
            ) : (
              <Tag>
                {t(PUBLISH_STATES[0].title)}
                <UnPublishIc />
              </Tag>
            )}
          </Header>
          <Header>
            <LocationIcon />
            <Text className="small" mt={1}>
              {venueDetails?.address}
            </Text>
          </Header>
        </Box>
        <SettingBtn onClick={onEdit}>
          <SettingsIcon />
          {t('Settings')}
        </SettingBtn>
      </HeaderContainer>
      <TopContainer>
        <Segment>
          <Text className="normal">{t('Number of floor')}</Text>
          <Text className="normal flex">
            <FloorIcon />
            <Text>{floors?.total_records}</Text>
          </Text>
        </Segment>
        <Segment>
          <Text className="normal">{t('Status')}</Text>
          {venueDetails?.publish ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center',
                '& svg': {
                  fill: '#03ba5e',
                  stroke: '#fff',
                },
                '& span': {
                  fontWeight: 600,
                },
                '& svg path': {
                  stroke: '#fff',
                },
              }}
            >
              <PublishIc />
              <StatusTxt className="blue">
                {t(PUBLISH_STATES[1].title)}
              </StatusTxt>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center',
              }}
            >
              <UnPublishIc />{' '}
              <StatusTxt>{t(PUBLISH_STATES[0].title)}</StatusTxt>
            </Box>
          )}
        </Segment>
        <Segment>
          <Text className="normal">{t('Customer')}</Text>
          <Text className="normal flex">
            <CustomerIcon />
            <Text>{venueDetails?.customer?.name}</Text>
          </Text>
        </Segment>
      </TopContainer>
      <Box mt={3} ml={`24px`} mr={`24px`} sx={{ width: 'calc(100vw - 224px)' }}>
        <Section title={t('Line chart')}>
          <ResponsiveContainer width={'100%'} height={300}>
            <AreaChart
              data={rangeData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8DBBFF" stopOpacity={0.8} />
                  <stop offset="1" stopColor="#8DBBFF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                dataKey="uv"
                type="linear"
                stroke="#2B6CCD"
                fillOpacity={1}
                fill="url(#colorUv)"
              />
              <XAxis
                dataKey="name"
                tickLine={false}
                tickFormatter={it => {
                  // if (dayjs(it).day() % 7 === 0) {
                  return dayjs(it).format('MM/DD');
                  // }
                  // return '';
                }}
              />
              <YAxis dataKey="uv" tickLine={false} />
              <Tooltip content={CustomTooltip} />
              <CartesianGrid opacity={0.3} vertical={false} />
            </AreaChart>
          </ResponsiveContainer>
        </Section>
      </Box>
      <ChartContainer mt={3} ml={`24px`} mr={`24px`}>
        <Section title={t('Top searched location')}>
          <ResponsiveContainer width={'100%'} height={300}>
            <BarChart data={da} layout="vertical" width={1500} height={400}>
              <XAxis hide />
              <YAxis
                width={120}
                yAxisId={0}
                dataKey={'name'}
                type="category"
                axisLine={false}
                tickLine={false}
              />

              <Bar dataKey={'pv'} minPointSize={4} barSize={20}>
                {da.map((d, idx) => {
                  return (
                    <>
                      <Cell key={d.name} fill="#2B6CCD" />
                      <LabelList
                        dataKey="pv"
                        content={renderCustomizedLabel}
                        position="insideRight"
                        style={{ fill: 'white' }}
                      />
                    </>
                  );
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Section>
        <Section title={t('Access device')}>
          <Box
            sx={{
              '& .recharts-legend-item': {
                display: 'flex !important',
                alignItems: 'center',
                gap: '8px',
                whiteSpace: 'nowrap',
              },
            }}
          >
            <ResponsiveContainer width={'100%'} height={200}>
              <PieChart width={400} height={200}>
                <Legend
                  height={36}
                  iconType="circle"
                  layout="vertical"
                  iconSize={10}
                  formatter={renderColorfulLegendText}
                  wrapperStyle={{
                    top: '100%',
                    right: '10px',
                  }}
                />
                <Pie
                  data={data}
                  cx={120}
                  cy={100}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={0}
                  dataKey="value"
                ></Pie>
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Section>
      </ChartContainer>

      <VenuePopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={submitAddOrEdit}
        data={venueDetails}
        onDelete={onDelete}
      />
      <ConfirmPopUp
        title={t('Alert')}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onSubmit={submitDelete}
      />
      <Loader show={loading} />
    </Container>
  );
};
