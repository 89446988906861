export const FloorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
  >
    <path
      d="M10.0031 11.5991V12.523L6.2955 10.5468L3.79933 9.20697L1.79872 8.14242L1.56011 8.01395L0 7.18189V6.25806L3.3833 8.06289L6.13032 9.53123L6.81554 9.89831L9.78281 11.4829L10.0031 11.5991Z"
      fill="#555C6A"
    />
    <path
      d="M10.0031 14.4192V15.343L1.66412 10.889L0 9.99581V9.10257L0.0244723 9.09033L2.02508 10.1549L2.52677 10.4241L4.52738 11.4947L9.77669 14.3029L10.0031 14.4192Z"
      fill="#555C6A"
    />
    <path
      d="M20 6.25806V7.18189L18.446 8.01395L13.8269 10.4795L11.1961 11.8867L10.2233 12.4067V11.4829L12.9642 10.0207L13.2518 9.86772L13.937 9.50064L15.0627 8.89495L15.7479 8.53398L20 6.25806Z"
      fill="#555C6A"
    />
    <path
      d="M20 9.07837V10.0022L18.342 10.8893L10.2233 15.227V14.3032L12.9642 12.8349L15.6011 11.4277L17.4794 10.4243L20 9.07837Z"
      fill="#555C6A"
    />
    <path
      d="M20 7.45703V8.83972H19.9939L17.253 10.3081L15.3747 11.3114L12.744 12.7186L10.0092 14.1747L4.75987 11.3726L2.75314 10.3019L2.25757 10.0327L0.256961 8.9682L0.0183563 8.83972L0.263079 8.70512L1.30315 8.14837L1.54176 8.27685L3.54237 9.34752L4.04405 9.6106L6.04466 10.6813L10.0031 12.7981L11.4469 12.0211L14.0838 10.614L15.9621 9.6106L18.703 8.14837L20 7.45703Z"
      fill="#555C6A"
    />
    <path
      d="M20 6.01935V6.02547L15.5277 8.41764L14.0593 7.6284L13.3741 7.99549L14.8425 8.7786L13.7167 9.38429L13.0315 9.74526L12.7501 9.89821L10.2233 11.2503L10.0031 11.3666H9.99694L7.02967 9.78197L9.78281 8.31363L10.0642 8.16068L10.4374 8.36257L11.5632 8.96214L12.2484 8.59506L11.1227 7.99549L10.7495 7.79359L10.0642 7.43263L9.12206 6.93094L8.43683 7.29191L9.37902 7.79359L9.1037 7.94654L6.34445 9.41488L3.59743 7.94654L2.91221 7.57946L0 6.02547L3.02845 4.39806L5.94677 5.96429L6.632 6.32525L7.3111 6.69234L7.99633 6.32525L7.31722 5.96429L6.632 5.5972L3.70756 4.03709L10.0031 0.659912L12.9275 2.23226L8.44295 4.63055L7.75772 4.99151L8.44295 5.3586L9.12206 5.72568L10.2478 6.32525L12.2484 7.39592L12.9336 7.02883L10.933 5.95817L9.80728 5.3586L9.12206 4.99151L13.6127 2.59323L20 6.01935Z"
      fill="#555C6A"
    />
  </svg>
);
