/* eslint-disable max-lines */
import { Tag } from '@/components/common/tag';
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  LocationIcon,
} from '@/components/icons';
import {
  CONNECTION_TYPES,
  EVENT_TYPES,
  LOCATION_STATES,
  LOCATION_STATUS,
  PUBLISH_STATES,
  VENUE_TYPES,
} from '@/constants';
import Path from '@/constants/path';
import _ from 'lodash';

import {
  ActionCell,
  CustomLink,
  Grid,
  IconText,
  IconWrapper,
  LinkText,
  TableCell,
  StatusTxt,
  Text,
  TopCell,
  PublishContainer,
  ClippedText,
} from './component';
import { TagProps } from './table';
import {
  ConnectionRowProps,
  CustomerRowProps,
  FloorRowProps,
  LocationRowProps,
  SnapshotRowProps,
  VenueRowProps,
  QRCodeRowProps,
} from './types';
import NoImg from '@/assets/icons/no-image.svg';
import { ReactComponent as PublishIc } from '@/assets/icons/publish_ic.svg';
import { ReactComponent as UnPublishIc } from '@/assets/icons/unpublish_ic.svg';
import { Box, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '@/utils/helper';
import { LocationDetail } from '@/stores/slices/location/type';
import { QRCodeSVG } from 'qrcode.react';

export const FloorRow = ({
  row,
  onView,
  onDelete,
  onEdit,
  onSelect,
  selected = false,
}: FloorRowProps) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState<boolean>(!!selected);

  useEffect(() => {
    setChecked(!!selected);
  }, [selected]);

  return (
    <tr key={row.id} className={checked ? 'selected' : ''}>
      <TableCell className="center">
        <Checkbox
          checked={checked}
          onChange={e => {
            setChecked(e.target.checked);
            onSelect && onSelect(row.id);
          }}
        />
      </TableCell>
      <TableCell className="center">{row.index + 1}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row?.type?.name}</TableCell>
      <TableCell>
        {row?.publish ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'flex-start',
            }}
          >
            <PublishIc />
            <StatusTxt className="blue">{PUBLISH_STATES[1].title}</StatusTxt>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'flex-start',
            }}
          >
            <UnPublishIc /> <StatusTxt>{PUBLISH_STATES[0].title}</StatusTxt>
          </Box>
        )}
      </TableCell>
      <ActionCell>
        <IconWrapper>
          <IconText onClick={() => onView && onView(row.id)}>
            <LocationIcon />
            <Text>{t('Location')}</Text>
          </IconText>
          <IconText onClick={() => onEdit && onEdit(row.id)}>
            <EditIcon />
            <Text>{t('Edit')}</Text>
          </IconText>
          <IconText onClick={() => onDelete && onDelete(row.id)}>
            <DeleteIcon />
            <Text className="danger">{t('Delete')}</Text>
          </IconText>
        </IconWrapper>
      </ActionCell>
    </tr>
  );
};

export const TopRow = ({ row }: FloorRowProps) => {
  const { t } = useTranslation();

  return (
    <>
      <TopCell className="top">{row.index + 1}</TopCell>
      <TopCell>{row?.name}</TopCell>
      <TopCell>{row?.type?.name}</TopCell>
      <TopCell className="bottom">
        {row?.publish ? (
          <PublishContainer>
            <PublishIc />
            <StatusTxt className="blue">{t(PUBLISH_STATES[1].title)}</StatusTxt>
          </PublishContainer>
        ) : (
          <PublishContainer>
            <UnPublishIc /> <StatusTxt>{t(PUBLISH_STATES[0].title)}</StatusTxt>
          </PublishContainer>
        )}
      </TopCell>
    </>
  );
};

export const VenueRow = ({
  row,
  onView,
  onDelete,
  onEdit,
  onSelect,
  selected,
}: VenueRowProps) => {
  const [checked, setChecked] = useState<boolean>(!!selected);
  const { t } = useTranslation();

  useEffect(() => {
    setChecked(!!selected);
  }, [selected]);

  return (
    <tr key={row.id} className={checked ? 'selected' : ''}>
      <TableCell className="center">
        <Checkbox
          checked={checked}
          onChange={e => {
            setChecked(e.target.checked);
            onSelect && onSelect(row.id);
          }}
        />
      </TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.customer?.name}</TableCell>
      <TableCell>{row.country}</TableCell>
      <TableCell>{row.address}</TableCell>
      <TableCell>
        {row?.type
          ? t(VENUE_TYPES.find(type => type.value === row.type)?.label ?? '') ??
            ''
          : ''}
      </TableCell>
      <TableCell>
        {row?.publish ? (
          <PublishContainer>
            <PublishIc />
            <StatusTxt className="blue">{t(PUBLISH_STATES[1].title)}</StatusTxt>
          </PublishContainer>
        ) : (
          <PublishContainer>
            <UnPublishIc /> <StatusTxt>{t(PUBLISH_STATES[0].title)}</StatusTxt>
          </PublishContainer>
        )}
      </TableCell>
      <ActionCell>
        <IconWrapper>
          <LinkText
            to={`${Path.CUSTOMERS}/${row.customer?.id}${Path.VENUE}/${row.id}${Path.TOP}`}
          >
            <EyeIcon />
            <Text>{t('View')}</Text>
          </LinkText>
          <IconText onClick={() => onEdit && onEdit(row.id)}>
            <EditIcon />
            <Text>{t('Edit')}</Text>
          </IconText>
        </IconWrapper>
      </ActionCell>
    </tr>
  );
};

export const CustomerRow = ({
  row,
  onView,
  onDelete,
  onEdit,
}: CustomerRowProps) => {
  const { t } = useTranslation();
  return (
    <tr key={row.id}>
      <TableCell className="center">{row.index + 1}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.phone}</TableCell>
      <TableCell>
        <CustomLink href={row.url} target="_blank">
          {row.url}
        </CustomLink>
      </TableCell>
      <TableCell>{row.description}</TableCell>
      <ActionCell>
        <IconWrapper>
          <IconText onClick={() => onView && onView(row.id)}>
            <EyeIcon />
            <Text>{t('View')}</Text>
          </IconText>
          <IconText onClick={() => onEdit && onEdit(row.id)}>
            <EditIcon />
            <Text>{t('Edit')}</Text>
          </IconText>
          <IconText onClick={() => onDelete && onDelete(row.id)}>
            <DeleteIcon />
            <Text className="danger">{t('Delete')}</Text>
          </IconText>
        </IconWrapper>
      </ActionCell>
    </tr>
  );
};

export const LocationRow = ({
  row,
  onView,
  onDelete,
  onEdit,
  handleDeleteTag,
}: LocationRowProps) => {
  const { t } = useTranslation();

  return (
    <tr key={row.id}>
      <TableCell className="center">{row.index + 1}</TableCell>
      <TableCell>
        <img
          src={row?.logo?.original}
          alt={row.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = NoImg;
          }}
        />
      </TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>
        {' '}
        <Grid>
          {row.categories?.map((it: TagProps) => (
            <Tag
              key={it.id}
              tagName={it.name}
              onClick={() => handleDeleteTag(row.id)}
            />
          ))}
        </Grid>
      </TableCell>
      <TableCell>{row.level?.name}</TableCell>
      <TableCell>
        {row.status
          ? t(
              LOCATION_STATUS.find(status => status.value === row.status)
                ?.label ?? '',
            )
          : ''}
      </TableCell>
      <TableCell>
        {row.state
          ? LOCATION_STATES.find(status => status.value === row.state)?.label ??
            ''
          : ''}
      </TableCell>
      <TableCell>
        <Grid>
          {row.tags?.map((it: TagProps) => (
            <Tag
              key={it.id}
              tagName={it.name}
              onClick={() => handleDeleteTag(row.id)}
            />
          ))}
        </Grid>
      </TableCell>

      <ActionCell>
        <IconWrapper>
          <IconText onClick={() => onEdit && onEdit(row.id)}>
            <EditIcon />
            <Text>{t('Edit')}</Text>
          </IconText>
          <IconText onClick={() => onDelete && onDelete(row.id)}>
            <DeleteIcon />
            <Text className="danger">{t('Delete')}</Text>
          </IconText>
        </IconWrapper>
      </ActionCell>
    </tr>
  );
};

export const EventRow = ({
  row,
  onView,
  onDelete,
  onEdit,
  handleDeleteTag,
}: LocationRowProps) => {
  const { t } = useTranslation();

  return (
    <tr key={row.id}>
      <TableCell className="center">{row.index + 1}</TableCell>
      <TableCell>
        <img
          src={row?.bannerImage ?? row?.iconImage}
          alt={row.title}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = NoImg;
          }}
        />
      </TableCell>
      <TableCell>{row.title}</TableCell>
      <TableCell>
        {row.type
          ? t(EVENT_TYPES.find(type => type.value === row.type)?.label ?? '')
          : ''}
      </TableCell>
      <TableCell>
        <ClippedText>{row.contentDetail}</ClippedText>
      </TableCell>
      <TableCell>
        {row.startTime && formatDateTime(row.startTime, 'YYYY/MM/DD HH:mm')}
      </TableCell>
      <TableCell>
        {row.endTime && formatDateTime(row.endTime, 'YYYY/MM/DD HH:mm')}
      </TableCell>
      <TableCell>
        {/* <Grid>
          {row.tags?.map((it: TagProps) => (
            <Tag
              key={it.id}
              tagName={it.name}
              onClick={() => handleDeleteTag(row.id)}
            />
          ))}
        </Grid> */}
        <ul>
          {row.locations?.map((it: LocationDetail) => <li>{it.name}</li>)}
        </ul>
      </TableCell>
      <ActionCell>
        <IconWrapper>
          <IconText onClick={() => onEdit && onEdit(row.id)}>
            <EditIcon />
            <Text>{t('Edit')}</Text>
          </IconText>
          <IconText onClick={() => onDelete && onDelete(row.id)}>
            <DeleteIcon />
            <Text className="danger">{t('Delete')}</Text>
          </IconText>
        </IconWrapper>
      </ActionCell>
    </tr>
  );
};

export const CategoryRow = ({
  row,
  onView,
  onDelete,
  onEdit,
}: CustomerRowProps) => {
  const { t } = useTranslation();

  return (
    <tr key={row.id}>
      <TableCell className="center">{row.index + 1}</TableCell>

      <TableCell>{row.name}</TableCell>
      <TableCell>
        <img
          src={row?.icon}
          alt={row.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = NoImg;
          }}
        />
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: row.color,
              borderRadius: '20%',
              marginRight: '8px',
            }}
          ></div>
          {row.color}
        </div>
      </TableCell>
      <ActionCell>
        <IconWrapper>
          <IconText onClick={() => onEdit && onEdit(row.id)}>
            <EditIcon />
            <Text>{t('Edit')}</Text>
          </IconText>
          <IconText onClick={() => onDelete && onDelete(row.id)}>
            <DeleteIcon />
            <Text className="danger">{t('Delete')}</Text>
          </IconText>
        </IconWrapper>
      </ActionCell>
    </tr>
  );
};

export const ConnectionRow = ({
  row,
  onView,
  onDelete,
  onEdit,
}: ConnectionRowProps) => {
  const { t } = useTranslation();

  return (
    <tr key={row.id}>
      <TableCell className="center">{row.index + 1}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>
        {t(
          _.get(
            _.find(CONNECTION_TYPES, it => it.value === row.type),
            'label',
          ) ?? '',
        )}
      </TableCell>
      <TableCell>{_.map(row.levels, it => it.name).join(', ')}</TableCell>
      <TableCell>
        {row.status
          ? t(
              LOCATION_STATUS.find(status => status.value === row.status)
                ?.label ?? '',
            )
          : ''}
      </TableCell>
      <TableCell>
        {row.state
          ? t(
              LOCATION_STATES.find(status => status.value === row.state)
                ?.label ?? '',
            )
          : ''}
      </TableCell>
      <TableCell>{row.description}</TableCell>
      <ActionCell>
        <IconWrapper>
          <IconText onClick={() => onEdit && onEdit(row.id)}>
            <EditIcon />
            <Text>{t('Edit')}</Text>
          </IconText>
          <IconText onClick={() => onDelete && onDelete(row.id)}>
            <DeleteIcon />
            <Text className="danger">{t('Delete')}</Text>
          </IconText>
        </IconWrapper>
      </ActionCell>
    </tr>
  );
};

export const QRCodeRow = ({
  row,
  onView,
  onDelete,
  onEdit,
}: QRCodeRowProps) => {
  const removePrefix = (link: string) => {
    const prefix = 'digimap://';
    return link.startsWith(prefix) ? link.substring(prefix.length) : link;
  };

  return (
    <tr key={row.id}>
      <TableCell className="center">{row.index + 1}</TableCell>
      <TableCell>{row.level?.name}</TableCell>
      <TableCell>{row.location?.name}</TableCell>
      <TableCell className="center">
        <QRCodeSVG size={128} value={removePrefix(row.link)} />
      </TableCell>
    </tr>
  );
};

export const SnapshotRow = ({
  row,
  onDelete,
  firstDraftRendered,
  firstPublicRendered,
}: SnapshotRowProps) => {
  const { t } = useTranslation();

  const isFirstDraft = row.state === 'draft' && firstDraftRendered;
  const isFirstPublic = row.state === 'public' && firstPublicRendered;

  const showIcon = !(isFirstDraft || isFirstPublic);

  return (
    <tr key={row.id}>
      <TableCell className="center">{row.index + 1}</TableCell>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.state}</TableCell>
      <TableCell>{row.created_at}</TableCell>
      <ActionCell>
        <IconWrapper>
          {showIcon && row.index !== 0 && (
            <IconText onClick={() => onDelete && onDelete(row.id)}>
              <DeleteIcon />
              <Text className="danger">{t('Revert')}</Text>
            </IconText>
          )}
        </IconWrapper>
      </ActionCell>
    </tr>
  );
};

export const AmenityRow = ({
  row,
  onView,
  onDelete,
  onEdit,
}: CustomerRowProps) => {
  const { t } = useTranslation();

  return (
    <tr key={row.id}>
      <TableCell className="center">{row.index + 1}</TableCell>

      <TableCell>{row.master?.common_name}</TableCell>
      <TableCell>
        <img
          src={row?.master?.common_logo}
          alt={row?.master?.common_name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = NoImg;
          }}
        />
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: row.master.common_color,
              borderRadius: '20%',
              marginRight: '8px',
            }}
          ></div>
          {row.master.common_color}
        </div>
      </TableCell>
      {/* <ActionCell>
        <IconWrapper>
          <IconText onClick={() => onEdit && onEdit(row.id)}>
            <EditIcon />
            <Text>{t('Edit')}</Text>
          </IconText>
          <IconText onClick={() => onDelete && onDelete(row.id)}>
            <DeleteIcon />
            <Text className="danger">{t('Delete')}</Text>
          </IconText>
        </IconWrapper>
      </ActionCell> */}
    </tr>
  );
};
