import { useAppSelector, useAppDispatch } from '@/hooks';

import { selectCustomer } from '@/stores/slices/customer/selector';
import {
  getCustomerDetailsRequest,
  getCustomerListRequest,
  updateCustomerInfoRequest,
  deleteCustomerInfoRequest,
  createCustomerInfoRequest,
  resetCustomerInfoRequestStatus,
  getAllCustomerListRequest,
} from '@/stores/slices/customer/customer';

const useCustomerHook = () => {
  const {
    getCustomerDetailsStatus,
    getAllCustomerListStatus,
    getCustomerListStatus,
    updateCustomerInfoStatus,
    createCustomerInfoStatus,
    deleteCustomerInfoStatus,
    customerDetails,
    customers,
    allCustomers,
  } = useAppSelector(selectCustomer);

  const dispatch = useAppDispatch();

  const requestCustomerList = (params: any) =>
    dispatch(getCustomerListRequest(params));

  const requestCustomerDetails = (params: any) =>
    dispatch(getCustomerDetailsRequest(params));

  const requestUpdateCustomerInfo = (params: any) =>
    dispatch(updateCustomerInfoRequest(params));

  const requestCreateCustomer = (params: any) =>
    dispatch(createCustomerInfoRequest(params));

  const requestDeleteCustomer = (params: any) =>
    dispatch(deleteCustomerInfoRequest(params));

  const requestAllCustomerList = (params: any) =>
    dispatch(getAllCustomerListRequest(params));

  const requestResetCustomer = () => dispatch(resetCustomerInfoRequestStatus());

  return {
    requestCustomerList,
    requestCustomerDetails,
    requestUpdateCustomerInfo,
    requestCreateCustomer,
    requestDeleteCustomer,
    requestResetCustomer,
    requestAllCustomerList,
    getAllCustomerListStatus,
    getCustomerDetailsStatus,
    getCustomerListStatus,
    updateCustomerInfoStatus,
    createCustomerInfoStatus,
    deleteCustomerInfoStatus,
    customerDetails,
    customers,
    allCustomers,
  };
};

export default useCustomerHook;
