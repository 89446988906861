import { FilterBar, FilterType } from '@/components/common/FilterBar';
import Input from '@/components/form/input';
import { FormWrapper } from '@/components/venue/components';
import queryString from 'query-string';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

type FilterProps = {
  onAdd: () => void;
  onKeywordChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const Filter = ({
  onAdd,
  // onExport,
  onKeywordChange,
}: FilterProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  let params = queryString.parse(window.location.search);
  const { control, reset } = useForm<{ keyword: string }>({
    defaultValues: {
      keyword: '',
    },
  });

  useEffect(() => {
    reset({
      keyword: Array.isArray(params?.keyword)
        ? params?.keyword[0] ?? ''
        : params?.keyword ?? undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <FilterBar
      type={FilterType.GENERIC}
      onAdd={onAdd}
      // onExport={onExport}
      hasButton
      buttonText={t('Add new amenity')}
    >
      <FormWrapper>
        <FormWrapper>
          <Controller
            control={control}
            name="keyword"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  inputType={1}
                  onChange={e => {
                    onChange(e);
                    onKeywordChange(e);
                  }}
                  placeholder={t('Search')}
                  value={value}
                />
              );
            }}
          />
        </FormWrapper>
      </FormWrapper>
    </FilterBar>
  );
};
