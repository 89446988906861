import { toast, ToastContent } from 'react-toastify';
import _ from 'lodash';

const ToastMessage = {
  success(msg: ToastContent) {
    toast.success(`${msg}`, {
      position: 'top-right',
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  inform(msg: ToastContent) {
    toast.info(`${msg}`, {
      position: 'top-right',
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  warn(msg: ToastContent) {
    toast.warn(`${msg}`, {
      position: 'top-right',
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  error(msg: ToastContent) {
    const toastId = _.isString(msg) ? msg?.toString() : 1;
    toast.error(`${msg}`, {
      position: 'top-right',
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId,
    });
  },
};

export default ToastMessage;
