import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { snapshotSlice } from './snapshot';

const selectSlice: any = (state: RootState) =>
  state.snapshot || snapshotSlice.getInitialState;

export const selectSnapshot = createSelector([selectSlice], snapshotState => ({
  ...snapshotState,
}));
