import { useAppSelector, useAppDispatch } from '@/hooks';

import { selectLocation } from '@/stores/slices/location/selector';
import {
  getLocationDetailsRequest,
  getLocationListRequest,
  updateLocationInfoRequest,
  deleteLocationInfoRequest,
  createLocationInfoRequest,
  resetLocationInfoRequestStatus,
  getLocationsToVenueRequest,
  getTagListRequest,
} from '@/stores/slices/location/location';

const useLocationHook = () => {
  const {
    getLocationDetailsStatus,
    getLocationListStatus,
    getCategoryListStatus,
    getTagListStatus,
    updateLocationInfoStatus,
    createLocationInfoStatus,
    deleteLocationInfoStatus,
    getLocationToVenueStatus,
    locationDetails,
    locations,
    tags,
    locationsToVenue,
  } = useAppSelector(selectLocation);

  const dispatch: any = useAppDispatch();

  const requestLocationList = (params: any) =>
    dispatch(getLocationListRequest(params));

  const requestTagList = () => dispatch(getTagListRequest());

  const requestLocationDetails = (params: any) =>
    dispatch(getLocationDetailsRequest(params));

  const requestUpdateLocationInfo = (params: any) =>
    dispatch(updateLocationInfoRequest(params));

  const requestCreateLocation = (params: any) =>
    dispatch(createLocationInfoRequest(params));

  const requestDeleteLocation = (params: any) =>
    dispatch(deleteLocationInfoRequest(params));

  const requestLocationsToVenueList = (params: any) =>
    dispatch(getLocationsToVenueRequest(params));

  const requestResetLocation = () => dispatch(resetLocationInfoRequestStatus());

  return {
    requestLocationList,
    requestLocationDetails,
    requestUpdateLocationInfo,
    requestCreateLocation,
    requestDeleteLocation,
    requestResetLocation,
    requestTagList,
    requestLocationsToVenueList,
    getLocationDetailsStatus,
    getLocationListStatus,
    updateLocationInfoStatus,
    createLocationInfoStatus,
    deleteLocationInfoStatus,
    getCategoryListStatus,
    getTagListStatus,
    getLocationToVenueStatus,
    tags,
    locationDetails,
    locations,
    locationsToVenue,
  };
};

export default useLocationHook;
