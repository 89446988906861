import styled from 'styled-components';
import { ReactNode } from 'react';

const Container = styled.div`
  flex: 1 1 0;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
`;

interface ContentProps {
  children: ReactNode;
}

export const Content = ({ children }: ContentProps) => {
  return <Container>{children}</Container>;
};
