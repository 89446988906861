import { DownIcon, EditIcon, PlusIcon } from '@/components/icons';
import {
  AddItem,
  FloorWrapper,
  IconWrapper,
  ItemName,
  ItemWrapper,
  SubContainer,
} from '@/components/layouts/sidebar/components';
import {
  CUSTOMER_ONLY_PATH_REGEX,
  CUSTOMER_VENUE_CONNECTION_PATH_REGEX,
  CUSTOMER_VENUE_FLOOR_PATH_REGEX,
  CUSTOMER_VENUE_LOCATION_PATH_REGEX,
  CUSTOMER_VENUE_ONLY_PATH_REGEX,
  MAPPING_PATH_REGEX,
  EVENT_PATH_REGEX,
  ACCESS_TOKEN_KEY,
} from '@/constants';
import Path from '@/constants/path';
import { SubMenuItemProps } from '@/types/layout';
import { Box, Collapse, Tooltip } from '@mui/material';
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGlobalHook from '@/hooks/useGlobalHook';

interface SidebarItemProps {
  name: string;
  icon: ReactNode;
  to: string;
  items?: any[];
  openAddFloor?: (val: boolean) => void;
  className?: string;
}

export const SidebarItem = ({
  name,
  icon,
  to,
  items,
  openAddFloor,
  className = '',
}: SidebarItemProps) => {
  const { t } = useTranslation();
  const [floorId, setFloorId] = useState<string>();

  const location = useLocation();
  const params = useParams();

  const { collaspe } = useGlobalHook();

  useEffect(() => {
    if (items && items.length > 0) {
      setFloorId(items[0].id);
    }
  }, [items]);

  const active = useMemo(() => {
    if (location.pathname === '/') {
      return to === Path.CUSTOMERS;
    }
    const urlRegex = [
      CUSTOMER_VENUE_FLOOR_PATH_REGEX,
      CUSTOMER_VENUE_ONLY_PATH_REGEX,
      CUSTOMER_VENUE_CONNECTION_PATH_REGEX,
      CUSTOMER_VENUE_LOCATION_PATH_REGEX,
      CUSTOMER_ONLY_PATH_REGEX,
      MAPPING_PATH_REGEX,
      EVENT_PATH_REGEX,
    ];

    let checkActiveItem = false;
    checkActiveItem = urlRegex.some(it => {
      return location.pathname.match(it) && to.match(it);
    });

    return (
      to === location.pathname ||
      checkActiveItem ||
      (!!location.pathname.match(CUSTOMER_VENUE_ONLY_PATH_REGEX) &&
        to === Path.VENUE) ||
      (!!location.pathname.match(EVENT_PATH_REGEX) &&
        !!to.match(EVENT_PATH_REGEX))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, to]);

  return (
    <>
      <ItemWrapper to={to} selected={active}>
        {collaspe ? (
          <Tooltip title={name} arrow placement="right">
            <IconWrapper className={className}>{icon}</IconWrapper>
          </Tooltip>
        ) : (
          <>
            <IconWrapper className={className}>{icon}</IconWrapper>
            <ItemName>{name}</ItemName>
          </>
        )}
      </ItemWrapper>
      {items}
    </>
  );
};
