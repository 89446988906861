import { FilterBar, FilterType } from '@/components/common/FilterBar';
import { FormWrapper } from '@/components/venue/components';
import { Controller, useForm } from 'react-hook-form';
import SelectItem from '@/components/form/select';
import MultipleSelectCustomize from 'src/components/form/customSelect';
import Input from '@/components/form/input';
import { FilterFormData } from '@/types';
import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useCallback, useEffect } from 'react';
import useLocationHook from '@/hooks/useLocationHook';
import { transformSelectOptions } from '@/utils/helper';
import useFloorHook from '@/hooks/useFloorHook';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import MultiSelectItem from '../form/multiselect';
import { useTranslation } from 'react-i18next';
import useCategoryHook from '@/hooks/useCategoryHook';

type FilterProps = {
  onAdd: () => void;
  // onExport: () => void;
  onCategoryChange: (e: SelectChangeEvent<any>) => void;
  onLevelChange: (e: SelectChangeEvent<any>) => void;
  onKeywordChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onTagChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const Filter = ({
  onAdd,
  // onExport,
  onLevelChange,
  onKeywordChange,
  onCategoryChange,
  onTagChange,
}: FilterProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  let params = queryString.parse(window.location.search);
  const { control, reset } = useForm<FilterFormData>({
    defaultValues: {
      keyword: '',
      level: undefined,
      tags: [],
      asset: [],
    },
  });

  const convertTags = useCallback((tags: any) => {
    if (Array.isArray(tags)) {
      return tags.map(it => it);
    }
    return tags ? [tags] : [];
  }, []);

  useEffect(() => {
    reset({
      keyword: Array.isArray(params?.keyword)
        ? params?.keyword[0] ?? ''
        : params?.keyword ?? undefined,
      level:
        typeof params?.level === 'string'
          ? params?.level ?? undefined
          : Array.isArray(params?.level)
          ? params?.level[0] ?? undefined
          : undefined,
      tags: convertTags(params?.tags),

      asset: Array.isArray(params?.category)
        ? params?.category ?? []
        : [params?.category ?? ''],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const { floorOptions }: any = useFloorHook();
  const { tags } = useLocationHook();
  const { categories } = useCategoryHook();

  return (
    <FilterBar
      type={FilterType.GENERIC}
      onAdd={onAdd}
      // onExport={onExport}
      hasButton
      buttonText={t('Add new location')}
    >
      <FormWrapper>
        <Controller
          control={control}
          name="asset"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <MultiSelectItem
                options={transformSelectOptions(categories)}
                value={value ?? []}
                onChange={e => {
                  onChange(e);
                  onCategoryChange(e);
                }}
                placeholder={t('Category')}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="level"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <SelectItem
                options={transformSelectOptions(floorOptions)}
                value={value}
                onChange={e => {
                  onChange(e);
                  onLevelChange(e);
                }}
                placeholder={t('Floor')}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="tags"
          render={({ field }) => {
            const { onChange, value } = field;
            return (
              <MultipleSelectCustomize
                options={transformSelectOptions(tags)}
                onChange={val => {
                  onChange(val);
                  onTagChange(val);
                }}
                value={value || []}
                sx={{
                  '& .multiple-container': {
                    minWidth: '200px',
                  },
                }}
                placeholder={t('Tags')}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="keyword"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <Input
                inputType={1}
                onChange={e => {
                  onChange(e);
                  onKeywordChange(e);
                }}
                placeholder={t('Search')}
                value={value}
              />
            );
          }}
        />
      </FormWrapper>
    </FilterBar>
  );
};
