export const SelectIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.6668 5.66675L8.00016 10.3334L3.3335 5.66675"
      stroke="#40495C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
