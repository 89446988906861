/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ToastContent, ToastOptions } from 'react-toastify';

type Messages = Array<string | undefined>;
type FuncDisplay = (
  content: ToastContent,
  options?: ToastOptions<{}> | undefined,
) => void;

const useDisplayMessages = (
  messages: Messages,
  displayFunc: FuncDisplay,
  setMessagesFunc: any,
) => {
  React.useEffect(() => {
    if (messages.length) {
      messages.map(message => displayFunc(message));
      // reset the messages back to empty after displaying all
      setMessagesFunc([]);
    }
  }, [messages.length]);
};

export default useDisplayMessages;
