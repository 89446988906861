import { useAppSelector, useAppDispatch } from '@/hooks';

import { selectCategory } from '@/stores/slices/category/selector';
import {
  getCategoryListRequest,
  updateCategoryInfoRequest,
  deleteCategoryInfoRequest,
  createCategoryInfoRequest,
  resetCategoryInfoRequestStatus,
} from '@/stores/slices/category/category';

const useCategoryHook = () => {
  const {
    getCategoryListStatus,
    updateCategoryInfoStatus,
    createCategoryInfoStatus,
    deleteCategoryInfoStatus,
    categories,
  } = useAppSelector(selectCategory);

  const dispatch = useAppDispatch();

  const requestCategoryList = (params: any) =>
    dispatch(getCategoryListRequest(params));

  const requestUpdateCategoryInfo = (params: any) =>
    dispatch(updateCategoryInfoRequest(params));

  const requestCreateCategory = (params: any) =>
    dispatch(createCategoryInfoRequest(params));

  const requestDeleteCategory = (params: any) =>
    dispatch(deleteCategoryInfoRequest(params));

  const requestResetCategory = () => dispatch(resetCategoryInfoRequestStatus());

  return {
    requestCategoryList,
    requestUpdateCategoryInfo,
    requestCreateCategory,
    requestDeleteCategory,
    requestResetCategory,
    getCategoryListStatus,
    updateCategoryInfoStatus,
    createCategoryInfoStatus,
    deleteCategoryInfoStatus,
    categories,
  };
};

export default useCategoryHook;
