import { createSelector } from '@reduxjs/toolkit';
import { authenSlice } from './auth';
import { RootState } from '../..';

const selectSlice = (state: RootState) =>
  state.auth || authenSlice.getInitialState;

export const selectAuth = createSelector([selectSlice], authState => ({
  ...authState,
}));
