import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  align-self: stretch;
  padding: 4px;
  background: #f0f4fe;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  flex-direction: column;
  > div {
    margin-bottom: 0;
  }
  &.no_venue {
    justify-content: center;
  }
  padding-bottom: 20px;
`;
HeaderContainer.displayName = 'HeaderContainer';

export const HeaderContent = styled.div`
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
  cursor: pointer;
  &.border_bottom {
    border-bottom: 1px solid #e0e3ea;
  }
  &.item {
    padding: 10px 15px;
  }
`;

export const LinkHeaderContent = styled(Link)`
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  &.border_bottom {
    border-bottom: 1px solid #e0e3ea;
  }
  &.item {
    padding: 10px 15px;
  }
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 9999px;
`;

export const Username = styled.div`
  color: #202634;
  font-size: 14px;
  font-family: Poppins, serif;
  font-weight: 600;
  word-wrap: break-word;
  &.normal_txt {
    color: #202634;
    font-weight: 400;
  }
  &.grey {
    color: #3d4760;
    font-size: 12px;
  }
`;

export const Chevron = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  padding-right: 12px;
`;

export const PasswordContainer = styled(Box)`
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 15px;
  align-items: flex-end;
  > div {
    margin-bottom: 0 !important;
  }
`;

export const BlueButton = styled(Box)`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px dashed #3080ec;
  background: #e4f0ff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
`;

export const Title = styled(Typography)`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
`;

export const PasswordToggleIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50px;
  height: 24px;
  transform: translateY(-50%);
`;
