/* eslint-disable max-lines */
import { PaginationProps } from '@/components/common/pagination';
import { ReactComponent as RecIcon } from '@/assets/icons/rec_icon.svg';
import {
  TableHeader,
  TopTableHeader,
} from '@/components/layouts/table/component';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';

export enum TableType {
  FLOOR = 'FLOOR',
  VENUE = 'VENUE',
  LOCATION = 'LOCATION',
  CUSTOMER = 'CUSTOMER',
  CONNECTION = 'CONNECTION',
  TOP = 'TOP',
  EVENT = 'EVENT',
  CATEGORY = 'CATEGORY',
  QRCODE = 'QRCODE',
  AMENITY = 'AMENITY',
  SNAPSHOT = 'SNAPSHOT',
}

export interface CommonTableProps {
  type: TableType;
  data: Record<string, any>[];
  pagination?: PaginationProps;
  onView?: (id: number | string) => void;
  onEdit?: (id: number | string) => void;
  onDelete?: (id: number | string) => void;
  onSelect?: (id: number | string) => void;
  onChangeSelect?: (e: any) => void;
  indeterminate?: boolean;
  selected?: (number | string)[];
}

export interface TagProps {
  name: string;
  id: number;
}

export interface ICheckHeader {
  indeterminate?: boolean;
  onChange?: (e: any) => void;
}

export const LocationHeader = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeader
          className="center"
          style={{ width: '60px', minWidth: '60px' }}
        >
          {t('No')}
        </TableHeader>
        <TableHeader style={{ width: '80px', minWidth: '80px' }}>
          {t('Logo')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('Location Name')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Categories')}
        </TableHeader>
        <TableHeader style={{ width: '160px', minWidth: '160px' }}>
          {t('Floor Plan')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('Status')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('State')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Tag')}
        </TableHeader>
        <TableHeader style={{ width: '100px', minWidth: '100px' }}>
          {t('Action')}
        </TableHeader>
      </tr>
    </thead>
  );
};

export const EventHeader = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeader
          className="center"
          style={{ width: '50px', minWidth: '50px' }}
        >
          {t('No')}
        </TableHeader>
        <TableHeader style={{ width: '80px', minWidth: '80px' }}>
          {t('Banner')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('Event Title')}
        </TableHeader>
        <TableHeader style={{ width: '100px', minWidth: '100px' }}>
          {t('Type')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('Content Details')}
        </TableHeader>
        <TableHeader style={{ width: '140px', minWidth: '140px' }}>
          {t('Starting Time')}
        </TableHeader>
        <TableHeader style={{ width: '140px', minWidth: '140px' }}>
          {t('Ending Time')}
        </TableHeader>
        <TableHeader style={{ width: '220px', minWidth: '220px' }}>
          {t('Locations')}
        </TableHeader>
        <TableHeader style={{ width: '100px', minWidth: '100px' }}>
          {t('Action')}
        </TableHeader>
      </tr>
    </thead>
  );
};

export const CategoryHeader = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeader
          className="center"
          style={{ width: '50px', minWidth: '50px' }}
        >
          {t('No')}
        </TableHeader>
        <TableHeader style={{ width: '100px', minWidth: '100px' }}>
          {t('Name')}
        </TableHeader>
        <TableHeader style={{ width: '100px', minWidth: '100px' }}>
          {t('Icon')}
        </TableHeader>
        <TableHeader style={{ width: '80px', minWidth: '80px' }}>
          {t('Color')}
        </TableHeader>
        <TableHeader style={{ width: '80px', minWidth: '80px' }}>
          {t('Action')}
        </TableHeader>
      </tr>
    </thead>
  );
};

export const VenueHeader = ({
  onChange,
  indeterminate = false,
}: ICheckHeader) => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeader
          className="center"
          style={{ width: '60px', minWidth: '60px' }}
        >
          <Checkbox
            indeterminate={indeterminate}
            indeterminateIcon={<RecIcon />}
            onChange={onChange && onChange}
          />
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Venue Name')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Customer')}
        </TableHeader>
        <TableHeader style={{ width: '120px', minWidth: '120px' }}>
          {t('Country')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Address')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('Type')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('Status')}
        </TableHeader>
        <TableHeader style={{ width: '160px', minWidth: '160px' }}>
          {t('Action')}
        </TableHeader>
      </tr>
    </thead>
  );
};

export const FloorHeader = ({
  onChange,
  indeterminate = false,
}: ICheckHeader) => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeader
          className="center"
          style={{ width: '60px', minWidth: '60px' }}
        >
          <Checkbox
            indeterminate={indeterminate}
            indeterminateIcon={<RecIcon />}
            onChange={onChange && onChange}
          />
        </TableHeader>
        <TableHeader
          className="center"
          style={{ width: '60px', minWidth: '60px' }}
        >
          {t('No')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Floor Name')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Level')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('Status')}
        </TableHeader>
        <TableHeader style={{ width: '215px', minWidth: '215px' }}>
          {t('Action')}
        </TableHeader>
      </tr>
    </thead>
  );
};

export const TopHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <TopTableHeader className="center top">{t('No')}</TopTableHeader>
      <TopTableHeader>{t('Floor Name')}</TopTableHeader>
      <TopTableHeader>{t('Level')}</TopTableHeader>
      <TopTableHeader className="bottom">{t('Status')}</TopTableHeader>
    </>
  );
};

export const CustomerHeader = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeader
          className="center"
          style={{ width: '60px', minWidth: '60px' }}
        >
          {t('No')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Customer Name')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('Phone Number')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('Link')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Description')}
        </TableHeader>
        <TableHeader style={{ width: '215px', minWidth: '215px' }}>
          {t('Action')}
        </TableHeader>
      </tr>
    </thead>
  );
};

export const ConnectionHeader = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeader
          className="center"
          style={{ width: '60px', minWidth: '60px' }}
        >
          {t('No')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Connector Group')}
        </TableHeader>
        <TableHeader style={{ width: '100px', minWidth: '100px' }}>
          {t('Type')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Upward-accessible floors')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('Status')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('State')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Description')}
        </TableHeader>
        <TableHeader style={{ width: '215px', minWidth: '215px' }}>
          {t('Action')}
        </TableHeader>
      </tr>
    </thead>
  );
};

export const QRCodeHeader = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeader
          className="center"
          style={{ width: '60px', minWidth: '60px' }}
        >
          {t('No')}
        </TableHeader>
        <TableHeader style={{ width: '160px', minWidth: '160px' }}>
          {t('Floor')}
        </TableHeader>
        <TableHeader style={{ width: '160px', minWidth: '160px' }}>
          {t('Location')}
        </TableHeader>
        <TableHeader style={{ width: '150px', minWidth: '150px' }}>
          {t('QR Code')}
        </TableHeader>
      </tr>
    </thead>
  );
};

export const SnapshotHeader = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeader
          className="center"
          style={{ width: '60px', minWidth: '60px' }}
        >
          {t('No')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Id')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('State')}
        </TableHeader>
        <TableHeader style={{ width: '200px', minWidth: '200px' }}>
          {t('Timestamp')}
        </TableHeader>
        <TableHeader style={{ width: '80px', minWidth: '80px' }}>
          {t('Action')}
        </TableHeader>
      </tr>
    </thead>
  );
};

export const AmenityHeader = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeader
          className="center"
          style={{ width: '50px', minWidth: '50px' }}
        >
          {t('No')}
        </TableHeader>
        <TableHeader style={{ width: '80px', minWidth: '80px' }}>
          {t('Name')}
        </TableHeader>
        <TableHeader style={{ width: '80px', minWidth: '80px' }}>
          {t('Icon')}
        </TableHeader>
        <TableHeader style={{ width: '80px', minWidth: '80px' }}>
          {t('Color')}
        </TableHeader>
        {/* <TableHeader style={{ width: '100px', minWidth: '100px' }}>
          {t('Action')}
        </TableHeader> */}
      </tr>
    </thead>
  );
};
