import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  getCustomerListRequest,
  getCustomerListSuccess,
  getCustomerListFailure,
  getAllCustomerListRequest,
  getAllCustomerListSuccess,
  getAllCustomerListFailure,
  getCustomerDetailsRequest,
  getCustomerDetailsSuccess,
  getCustomerDetailsFailure,
  createCustomerInfoRequest,
  createCustomerInfoSuccess,
  createCustomerInfoFailure,
  updateCustomerInfoRequest,
  updateCustomerInfoSuccess,
  updateCustomerInfoFailure,
  deleteCustomerInfoRequest,
  deleteCustomerInfoSuccess,
  deleteCustomerInfoFailure,
} from '../slices/customer/customer';

type IPayload = Record<string, any>;

function* getCustomerListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getCustomers, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getCustomerListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getCustomerListFailure.type });
    }
  } catch (error) {
    yield put({ type: getCustomerListFailure.type });
  }
}

function* getAllCustomerListFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getAllCustomers, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getAllCustomerListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getAllCustomerListFailure.type });
    }
  } catch (error) {
    yield put({ type: getAllCustomerListFailure.type });
  }
}

function* getCustomerDetailsFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getCustomerDetails, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getCustomerDetailsSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getCustomerDetailsFailure.type });
    }
  } catch (error) {
    yield put({ type: getCustomerDetailsFailure.type });
  }
}

function* createCustomerFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createCustomer, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createCustomerInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createCustomerInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createCustomerInfoFailure.type });
  }
}

function* updateCustomerFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateCustomer, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateCustomerInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateCustomerInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: updateCustomerInfoFailure.type });
  }
}

function* deleteCustomerFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteCustomer, payload);

    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteCustomerInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteCustomerInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteCustomerInfoFailure.type });
  }
}

function* customerSaga() {
  yield takeLatest(getCustomerListRequest, getCustomerListFlow);
  yield takeLatest(getAllCustomerListRequest, getAllCustomerListFlow);
  yield takeLatest(createCustomerInfoRequest, createCustomerFlow);
  yield takeLatest(updateCustomerInfoRequest, updateCustomerFlow);
  yield takeLatest(deleteCustomerInfoRequest, deleteCustomerFlow);
  yield takeLatest(getCustomerDetailsRequest, getCustomerDetailsFlow);
}

export default customerSaga;
