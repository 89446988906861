/* eslint-disable max-lines */
import { store } from '@/stores';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Path from './constants/path';
import Login from './pages/login';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';
import { Suspense } from 'react';

import ProtectedRoute from './components/common/ProtectedRoute';
import { SnapshotPage } from '@/pages/snapshot';
import { MappingPage } from '@/pages/mapping';
import { FloorPlanPage } from '@/pages/floor';
import { VenuePage } from '@/pages/venue';
import { TopPage } from '@/pages/top';
import { AnalysisPage } from '@/pages/analysis';
import { LocationPage } from '@/pages/location';
import { EventPage } from '@/pages/event';
import { AmenityPage } from '@/pages/amenity';
import { CustomerPage } from './pages/customer';
import { ConnectionPage } from './pages/connection';
import { SettingsPage } from './pages/settings';
import { GlobalContainer } from './components/layouts/global';
import { Loader } from './components/common/loader';
import './locales/i18n';
import { CategoryPage } from './pages/category';
import { QRCodePage } from './pages/qrcode';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: Path.HOME,
    element: (
      <ProtectedRoute>
        <CustomerPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.MAPPING,
    element: (
      <ProtectedRoute>
        <MappingPage />
      </ProtectedRoute>
    ),
  },

  {
    path: Path.CUSTOMER_VENUE_FLOOR,
    element: (
      <ProtectedRoute>
        <FloorPlanPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.VENUE,
    element: (
      <ProtectedRoute>
        <VenuePage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_TOP,
    element: (
      <ProtectedRoute>
        <TopPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_VIEWER,
    element: (
      <ProtectedRoute>
        <QRCodePage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_ANALYTICS,
    element: (
      <ProtectedRoute>
        <AnalysisPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_LOCATION,
    element: (
      <ProtectedRoute>
        <LocationPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_EVENT,
    element: (
      <ProtectedRoute>
        <EventPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_ADD_EVENT,
    element: (
      <ProtectedRoute>
        <EventPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_EDIT_EVENT,
    element: (
      <ProtectedRoute>
        <EventPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_AMENITY,
    element: (
      <ProtectedRoute>
        <AmenityPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_CATEGORY,
    element: (
      <ProtectedRoute>
        <CategoryPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_SNAPSHOT,
    element: (
      <ProtectedRoute>
        <SnapshotPage />
      </ProtectedRoute>
    ),
  },

  {
    path: Path.CUSTOMERS,
    element: (
      <ProtectedRoute>
        <CustomerPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMERS_VENUE,
    element: (
      <ProtectedRoute>
        <VenuePage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.CUSTOMER_VENUE_CONNECTIONS,
    element: (
      <ProtectedRoute>
        <ConnectionPage />
      </ProtectedRoute>
    ),
  },

  {
    path: Path.SETTINGS,
    element: (
      <ProtectedRoute>
        <SettingsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: Path.LOGIN,
    element: <Login />,
  },
]);

root.render(
  <Suspense fallback={<Loader show />}>
    <Provider store={store}>
      <GlobalContainer>
        <RouterProvider router={router} />
      </GlobalContainer>
    </Provider>
  </Suspense>,
);

// Hot reload able translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
