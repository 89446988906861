import { OneInputContainer } from '@/components/floor/components';
import CheckBoxItem from '@/components/form/checkbox';
import { ImageInput } from '@/components/form/imageUploading';
import { Label } from '@/components/form/input';
import CustomTimePicker from '@/components/form/timepicker';
import { TimeIcon } from '@/components/icons/Time';
import { LocationDetailsForm } from '@/stores/slices/location/type';
import { formatWorkingTime } from '@/utils/helper';
import { Box, FormHelperText } from '@mui/material';
import { useCallback } from 'react';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { InputType } from 'src/components/form/customSelect';
import { SectionWrapper, WeekdayFields } from './styled';
import { useTranslation } from 'react-i18next';

export interface StateSectionProps {
  form: UseFormReturn<LocationDetailsForm, any, undefined>;
  type?: InputType;
}
export const StateSection = ({
  form,
  type = InputType.POP_UP,
}: StateSectionProps) => {
  const {
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = form;
  const weekDayField = useFieldArray({
    name: 'workHours',
    control,
  });
  const { t } = useTranslation();

  const onUpdateTime = useCallback(
    (value: any) => {
      const isInvalid = getValues('workHours').filter(
        it =>
          formatWorkingTime(it.open) >= formatWorkingTime(it.close) &&
          !!it.selected,
      );

      if (isInvalid?.length) {
        setError('workHours', {
          type: 'custom',
          message: t(
            'Invalid work hour. closing time should greater than opening time',
          ),
        });
      } else {
        clearErrors('workHours');
      }
    },
    [clearErrors, setError, getValues, t],
  );

  return (
    <OneInputContainer>
      <Box component="div" mt={2} mb={2}>
        <Label>{t('Work Hour')}</Label>
        {weekDayField.fields.map((item, fieldIndex) => {
          return (
            <WeekdayFields key={item.id}>
              <Controller
                control={control}
                name={`workHours.${fieldIndex}.selected` as const}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <CheckBoxItem
                      onChange={val => {
                        onChange(val);
                        onUpdateTime(val);
                      }}
                      label={t(item.day)}
                      checked={!!value}
                      value={value}
                    />
                  );
                }}
              />

              <SectionWrapper
                sx={{
                  gridTemplateColumns:
                    type === InputType.POP_UP ? '120px 120px' : '100px 100px',
                }}
              >
                <Controller
                  control={control}
                  name={`workHours.${fieldIndex}.open` as const}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <CustomTimePicker
                        onChange={val => {
                          onChange(val);
                          onUpdateTime(val);
                        }}
                        value={value}
                      />
                    );
                  }}
                />
                <TimeIcon />
                <Controller
                  control={control}
                  name={`workHours.${fieldIndex}.close` as const}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <CustomTimePicker
                        onChange={val => {
                          onChange(val);
                          onUpdateTime(val);
                        }}
                        value={value}
                      />
                    );
                  }}
                />
              </SectionWrapper>
            </WeekdayFields>
          );
        })}

        {!!errors?.workHours?.message && (
          <FormHelperText
            sx={{
              marginTop: '10px',
              color: '#e34a66',
              fontSize: '10px',
            }}
          >
            {errors?.workHours?.message}
          </FormHelperText>
        )}

        <Box component="div" mb={3}>
          <Controller
            control={control}
            name="originalLogo"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <ImageInput
                  label={t('Logo')}
                  title={t('Drag an image here or click “Upload” below')}
                  width={120}
                  height={120}
                  inputType={type}
                  onUpload={onChange}
                  value={value ? [value] : []}
                />
              );
            }}
          />
        </Box>
        <Box component="div">
          <Controller
            control={control}
            name="images"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <ImageInput
                  label={t('Location picture')}
                  title={t('Drag an image here or click “Upload” below')}
                  width={type === InputType.POP_UP ? 320 : 220}
                  height={160}
                  multiple
                  inputType={type}
                  onUpload={onChange}
                  value={value ?? []}
                />
              );
            }}
          />
        </Box>
      </Box>
    </OneInputContainer>
  );
};
