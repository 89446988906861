import { createSelector } from '@reduxjs/toolkit';
import { locationSlice } from './location';
import { RootState } from '../..';

const selectSlice: any = (state: RootState) =>
  state.location || locationSlice.getInitialState;

export const selectLocation = createSelector([selectSlice], locationState => ({
  ...locationState,
}));
