export const SnapshotIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17px"
    height="17px"
    viewBox="0 0 17 17"
    version="1.1"
  >
    <g id="surface1">
      <path d="M 16.734375 8.484375 C 16.746094 13.019531 13.042969 16.730469 8.507812 16.734375 C 6.546875 16.734375 4.75 16.054688 3.332031 14.910156 C 2.964844 14.617188 2.9375 14.066406 3.273438 13.730469 L 3.648438 13.355469 C 3.933594 13.070312 4.394531 13.035156 4.707031 13.289062 C 5.746094 14.113281 7.066406 14.609375 8.5 14.609375 C 11.875 14.609375 14.609375 11.875 14.609375 8.5 C 14.609375 5.125 11.875 2.390625 8.5 2.390625 C 6.878906 2.390625 5.410156 3.023438 4.3125 4.046875 L 6 5.734375 C 6.335938 6.070312 6.097656 6.640625 5.625 6.640625 L 0.796875 6.640625 C 0.503906 6.640625 0.265625 6.402344 0.265625 6.109375 L 0.265625 1.28125 C 0.265625 0.808594 0.835938 0.570312 1.171875 0.90625 L 2.8125 2.546875 C 4.289062 1.132812 6.296875 0.265625 8.5 0.265625 C 13.042969 0.265625 16.722656 3.945312 16.734375 8.484375 Z M 10.726562 11.101562 L 11.054688 10.679688 C 11.324219 10.332031 11.261719 9.835938 10.914062 9.5625 L 9.5625 8.511719 L 9.5625 5.046875 C 9.5625 4.605469 9.207031 4.25 8.765625 4.25 L 8.234375 4.25 C 7.792969 4.25 7.4375 4.605469 7.4375 5.046875 L 7.4375 9.550781 L 9.609375 11.242188 C 9.957031 11.511719 10.457031 11.453125 10.726562 11.101562 Z M 10.726562 11.101562 " />
    </g>
  </svg>
);
