import { useAppSelector, useAppDispatch } from '@/hooks';

import { selectAmenity } from '@/stores/slices/amenity/selector';
import {
  getAmenityListRequest,
  getLocationAmenityListRequest,
  setVenueAmenitiesRequest,
  createAmenityInfoRequest,
  updateAmenityInfoRequest,
  deleteAmenityInfoRequest,
  resetAmenityInfoRequestStatus,
} from '@/stores/slices/amenity/amenity';

const useAmenityHook = () => {
  const {
    getAmenityListStatus,
    getLocationAmenityListStatus,
    setVenueAmenitiesStatus,
    createAmenityInfoStatus,
    updateAmenityInfoStatus,
    deleteAmenityInfoStatus,
    amenities,
    locationAmenities,
  } = useAppSelector(selectAmenity);

  const dispatch = useAppDispatch();

  const requestAmenityList = (params: any) =>
    dispatch(getAmenityListRequest(params));
  const requestLocationAmenityList = (params: any) =>
    dispatch(getLocationAmenityListRequest(params));
  const requestSetVenueAmenities = (params: any) =>
    dispatch(setVenueAmenitiesRequest(params));
  const requestCreateAmenityInfo = (params: any) =>
    dispatch(createAmenityInfoRequest(params));
  const requestUpdateAmenityInfo = (params: any) =>
    dispatch(updateAmenityInfoRequest(params));
  const requestDeleteAmenityInfo = (params: any) =>
    dispatch(deleteAmenityInfoRequest(params));
  const requestResetAmenity = () => dispatch(resetAmenityInfoRequestStatus());

  return {
    requestAmenityList,
    requestLocationAmenityList,
    requestSetVenueAmenities,
    requestCreateAmenityInfo,
    requestUpdateAmenityInfo,
    requestDeleteAmenityInfo,
    requestResetAmenity,
    getAmenityListStatus,
    getLocationAmenityListStatus,
    setVenueAmenitiesStatus,
    createAmenityInfoStatus,
    updateAmenityInfoStatus,
    deleteAmenityInfoStatus,
    amenities,
    locationAmenities,
  };
};

export default useAmenityHook;
