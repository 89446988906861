import { Box } from '@mui/material';
import styled from 'styled-components';

export const SectionWrapper = styled(Box)`
  position: relative;
  display: grid;
  grid-template-columns: 120px 120px;
  > svg {
    position: absolute;
    left: 120px;
    z-index: 10;
    top: 16px;
  }

  > div:first-child {
    .MuiInputBase-root {
      border-right: none;
    }
    svg {
      width: 20px;
    }
  }
  > svg + div {
    margin-left: -3px;
    border-left: none;
    .MuiInputBase-root {
      border-left: none;
      padding-left: 5px;
    }
    svg {
      width: 20px;
    }
  }
`;

export const CloseIconWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
`;

export const WeekdayFields = styled(Box)`
  display: grid;
  grid-template-columns: 100px 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
`;
