export const LocationIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="location">
      <path
        id="Vector"
        d="M9.99999 1.60693C11.847 1.60693 13.6184 2.34067 14.9245 3.64673C16.2305 4.95278 16.9643 6.72418 16.9643 8.57122C16.9643 11.5141 14.8964 14.7212 10.8143 18.2269C10.5873 18.4219 10.2979 18.529 9.99865 18.5288C9.69942 18.5285 9.4102 18.4209 9.18356 18.2255L8.91356 17.9912C5.01213 14.5769 3.03571 11.4484 3.03571 8.57122C3.03571 6.72418 3.76944 4.95278 5.0755 3.64673C6.38155 2.34067 8.15295 1.60693 9.99999 1.60693ZM9.99999 5.89265C9.28959 5.89265 8.60828 6.17485 8.10596 6.67718C7.60363 7.17951 7.32142 7.86082 7.32142 8.57122C7.32142 9.28162 7.60363 9.96293 8.10596 10.4653C8.60828 10.9676 9.28959 11.2498 9.99999 11.2498C10.7104 11.2498 11.3917 10.9676 11.894 10.4653C12.3964 9.96293 12.6786 9.28162 12.6786 8.57122C12.6786 7.86082 12.3964 7.17951 11.894 6.67718C11.3917 6.17485 10.7104 5.89265 9.99999 5.89265Z"
        fill="#555C6A"
      />
    </g>
  </svg>
);
