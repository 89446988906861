/* eslint-disable max-lines */
import {
  LogoutIcon,
  SettingIcon,
  AvatarIcon,
  EyeIcon,
} from '@/components/icons';
import {
  CUSTOMER_VENUE_ONLY_PATH_REGEX,
  FLOOR_PATH_REGEX,
  POPOVER_ID,
} from '@/constants';
import Path from '@/constants/path';
import useAuthHook from '@/hooks/useAuthHook';
import { useNavigateHook } from '@/hooks/useHistoryHook';
import { Box } from '@mui/material';
import Popover from '@mui/material/Popover';
import React, { useEffect, useMemo } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';
import {
  Avatar,
  Chevron,
  HeaderContainer,
  HeaderContent,
  Username,
} from './styled';
import useGlobalHook from '@/hooks/useGlobalHook';
import useCustomerHook from '@/hooks/useCustomerHook';
import useVenueHook from '@/hooks/useVenueHook';
import { transformSelectOptions } from '@/utils/helper';
import SelectItem from '@/components/form/select';
import ActionMenu, { ActionType } from '@/components/common/ActionMenu';
import useFloorHook from '@/hooks/useFloorHook';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();

  const { requestAllCustomerList, allCustomers } = useCustomerHook();
  const { requestFloorLevels } = useFloorHook();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigateHook().navigate;
  const { logout } = useAuthHook();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? POPOVER_ID : undefined;

  const handleLogout = () => {
    logout();
    navigate(Path.LOGIN);
  };
  const isVenueSelected = useMemo(
    () =>
      location.pathname.includes(Path.TOP) ||
      location.pathname.includes(Path.FLOOR_PLAN) ||
      location.pathname.includes(Path.CONNECTIONS) ||
      location.pathname.includes(Path.LOCATION) ||
      location.pathname.includes(Path.ANALYTICS) ||
      location.pathname.includes(Path.EVENT) ||
      location.pathname.includes(Path.VIEWER) ||
      location.pathname.includes(Path.SNAPSHOT) ||
      location.pathname.includes(Path.AMENITY) ||
      location.pathname.includes(Path.CATEGORY),
    [location.pathname],
  );

  const isCustomerSelected = useMemo(
    () =>
      (location.pathname.match(FLOOR_PATH_REGEX) &&
        !location.pathname.includes(Path.FLOOR_PLAN) &&
        !location.pathname.includes(Path.CONNECTIONS) &&
        !location.pathname.includes(Path.LOCATION)) ||
      !!location.pathname.match(CUSTOMER_VENUE_ONLY_PATH_REGEX),
    [location.pathname],
  );

  const { requestVenueOptions, requestVenueDetails } = useVenueHook();

  useEffect(() => {
    if (isVenueSelected) {
      requestVenueDetails({ id: params?.venueId });
      requestFloorLevels({});
    }
    requestAllCustomerList({});
    requestVenueOptions({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVenueSelected]);

  const { userData, setSettingsPopUp, setChangePwPopUp, collaspe } =
    useGlobalHook();

  // const onChangeVenue = (e: any) => {
  //   if (e?.target?.value) {
  //     const customer = _.get(
  //       _.get(
  //         _.find(venues?.data, it => it.id === e.target?.value),
  //         'customer',
  //       ),
  //       'id',
  //     );
  //     navigate(
  //       `${Path.CUSTOMERS}/${customer}${Path.VENUE}/${e.target?.value}${Path.FLOOR_PLAN}`,
  //       {
  //         replace: true,
  //       },
  //     );
  //   }
  // };

  const onChangeCustomer = (e: any) => {
    if (e?.target?.value) {
      navigate(`${Path.CUSTOMERS}/${e?.target?.value}${Path.VENUE}`, {
        replace: true,
      });
    }
  };
  return (
    <>
      <HeaderContainer
        className={isVenueSelected || isCustomerSelected ? '' : 'no_venue'}
      >
        {isVenueSelected && (
          // <SelectItem
          //   options={transformSelectOptions(venueOptions ?? [])}
          //   onChange={onChangeVenue}
          //   value={params?.venueId ?? 0}
          //   sx={{ minWidth: '320px' }}
          // />
          <ActionMenu type={ActionType.CUSTOM} />
        )}
        {isCustomerSelected && (
          <SelectItem
            options={transformSelectOptions(allCustomers ?? [])}
            sx={{ minWidth: '320px' }}
            onChange={onChangeCustomer}
            value={params?.customerId}
          />
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <HeaderContent aria-describedby={id} onClick={handleClick}>
            {userData?.profile_image ? (
              <Avatar
                src={
                  userData?.profile_image ?? 'https://via.placeholder.com/32x32'
                }
              />
            ) : (
              <AvatarIcon />
            )}

            {!collaspe && (
              <>
                <Username>{userData?.username}</Username>
              </>
            )}
          </HeaderContent>
        </Box>
      </HeaderContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            padding: '15px 0',
            '& .password svg': {
              width: '20px',
            },
            '& .password svg path': {
              stroke: '#222222',
              fill: '#fff',
            },
          }}
        >
          <HeaderContent className="item">
            {userData?.profile_image ? (
              <Avatar
                src={
                  userData?.profile_image ?? 'https://via.placeholder.com/32x32'
                }
              />
            ) : (
              <AvatarIcon />
            )}
            <Username>
              {userData?.full_name ?? userData?.username}
              <Username className="normal_txt grey">
                {userData?.email ?? ''}
              </Username>
            </Username>
          </HeaderContent>
          <HeaderContent
            className="item border_bottom"
            onClick={() => setSettingsPopUp(true)}
          >
            <SettingIcon />
            <Username className="normal_txt">{t('Settings')}</Username>
          </HeaderContent>
          <HeaderContent
            className="item border_bottom password"
            onClick={() => setChangePwPopUp(true)}
          >
            <EyeIcon />
            <Username className="normal_txt">{t('Change password')}</Username>
          </HeaderContent>
          <HeaderContent onClick={handleLogout} className="item">
            <LogoutIcon />
            <Username className="normal_txt">{t('Logout')}</Username>
          </HeaderContent>
        </Box>
      </Popover>
    </>
  );
};
