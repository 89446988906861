export const CustomerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
  >
    <path
      d="M9.83334 8.83333C10.7174 8.83333 11.5652 9.18452 12.1904 9.80964C12.8155 10.4348 13.1667 11.2826 13.1667 12.1667V13.4167C13.1667 13.7482 13.035 14.0661 12.8006 14.3005C12.5661 14.535 12.2482 14.6667 11.9167 14.6667H1.91667C1.58515 14.6667 1.26721 14.535 1.03279 14.3005C0.798368 14.0661 0.666672 13.7482 0.666672 13.4167V12.1667C0.666672 11.2826 1.01786 10.4348 1.64298 9.80964C2.2681 9.18452 3.11595 8.83333 4.00001 8.83333H9.83334ZM14.8333 8.83333C15.4964 8.83333 16.1323 9.09672 16.6011 9.56557C17.0699 10.0344 17.3333 10.6703 17.3333 11.3333V12.5833C17.3333 12.9149 17.2016 13.2328 16.9672 13.4672C16.7328 13.7016 16.4149 13.8333 16.0833 13.8333H14.8333V12.1667C14.8339 11.5197 14.6835 10.8816 14.3942 10.3029C14.1048 9.72428 13.6845 9.22109 13.1667 8.83333H14.8333ZM6.91667 0.5C7.91123 0.5 8.86506 0.895088 9.56832 1.59835C10.2716 2.30161 10.6667 3.25544 10.6667 4.25C10.6667 5.24456 10.2716 6.19839 9.56832 6.90165C8.86506 7.60491 7.91123 8 6.91667 8C5.92211 8 4.96828 7.60491 4.26502 6.90165C3.56176 6.19839 3.16667 5.24456 3.16667 4.25C3.16667 3.25544 3.56176 2.30161 4.26502 1.59835C4.96828 0.895088 5.92211 0.5 6.91667 0.5ZM14 3C14.663 3 15.2989 3.26339 15.7678 3.73223C16.2366 4.20107 16.5 4.83696 16.5 5.5C16.5 6.16304 16.2366 6.79893 15.7678 7.26777C15.2989 7.73661 14.663 8 14 8C13.337 8 12.7011 7.73661 12.2322 7.26777C11.7634 6.79893 11.5 6.16304 11.5 5.5C11.5 4.83696 11.7634 4.20107 12.2322 3.73223C12.7011 3.26339 13.337 3 14 3Z"
      fill="#555C6A"
    />
  </svg>
);
