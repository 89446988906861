export const ConnectionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M3.97893 8.06266C3.84701 8.06277 3.71823 8.01756 3.60995 7.93313C3.50167 7.84869 3.4191 7.72909 3.37335 7.59044C3.32761 7.45179 3.3209 7.30076 3.35412 7.1577C3.38735 7.01464 3.45891 6.88644 3.55917 6.79037L9.58027 1.00721C9.69718 0.894953 9.84606 0.833252 10 0.833252C10.154 0.833252 10.3029 0.894953 10.4198 1.00721L16.4409 6.79037C16.5411 6.88644 16.6127 7.01464 16.6459 7.1577C16.6791 7.30076 16.6724 7.45179 16.6267 7.59044C16.5809 7.72909 16.4984 7.84869 16.3901 7.93313C16.2818 8.01756 16.153 8.06277 16.0211 8.06266H3.97893Z"
      fill="white"
    />
    <path
      d="M3.97893 11.9373C3.84701 11.9372 3.71823 11.9824 3.60995 12.0669C3.50167 12.1513 3.4191 12.2709 3.37335 12.4096C3.32761 12.5482 3.3209 12.6992 3.35412 12.8423C3.38735 12.9854 3.45891 13.1136 3.55917 13.2096L9.58027 18.9928C9.69718 19.105 9.84606 19.1667 10 19.1667C10.154 19.1667 10.3029 19.105 10.4198 18.9928L16.4409 13.2096C16.5411 13.1136 16.6127 12.9854 16.6459 12.8423C16.6791 12.6992 16.6724 12.5482 16.6267 12.4096C16.5809 12.2709 16.4984 12.1513 16.3901 12.0669C16.2818 11.9824 16.153 11.9372 16.0211 11.9373H3.97893Z"
      fill="white"
    />
  </svg>
);
