export const CategoryIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <g xmlns="http://www.w3.org/2000/svg" transform="translate(0,-289.0625)">
      <path
        d="M 15 4 L 8.5 14 L 21.5 14 L 15 4 z M 8.5 16 C 5.453 16 3 18.453 3 21.5 C 3 24.547 5.453 27 8.5 27 C 11.547 27 14 24.547 14 21.5 C 14 18.453 11.547 16 8.5 16 z M 16 16 L 16 27 L 27 27 L 27 16 L 16 16 z "
        transform="translate(0,289.0625)"
        id="rect4590"
      />
    </g>
  </svg>
);
