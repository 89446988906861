import PopUp from '@/components/common/Popup';
import Input from '@/components/form/input';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import { OneInputContainer } from '@/components/floor/components';
// import { BlueButton, PasswordContainer } from './styled';
// import { ImageInput } from '@/components/form/imageUploading';
import useGlobalHook from '@/hooks/useGlobalHook';
import {
  checkIsRequesting,
  transformSelectOptionsWithTranslation,
} from '@/utils/helper';
import { useMemo, useEffect } from 'react';
import useAuthHook from '@/hooks/useAuthHook';
import { Loader } from '@/components/common/loader';
import { RequestStatus } from '@/constants/API';
import { useTranslation } from 'react-i18next';
import SelectItem from '@/components/form/select';
import { LANG_LIST } from '@/constants';

export interface SettingProps {
  open: boolean;
  onClose: () => void;
}

export interface SettingsForm {
  full_name: string;
  email: string;
  profile_image: string;
  language: string;
}

export function Settings({ open, onClose }: SettingProps) {
  const { userData, setSettingsPopUp } = useGlobalHook();
  const { requestUpdateProfile, updateProfileRequestStatus } = useAuthHook();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const { t, i18n } = useTranslation();

  const changeLang = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SettingsForm>({
    defaultValues: {
      full_name: userData.full_name,
      email: userData.email,
      profile_image: userData.profile_image ?? '',
      language: i18n?.resolvedLanguage ?? 'en',
    },
  });

  const loading = useMemo(
    () => checkIsRequesting([updateProfileRequestStatus]),
    [updateProfileRequestStatus],
  );

  const submitForm = (data: SettingsForm) => {
    requestUpdateProfile({
      ...data,
      profile_image: Array.isArray(data?.profile_image)
        ? data?.profile_image[0]?.data_url ?? null
        : data.profile_image ?? null,
      id: userData?.id,
    });
    changeLang(data.language);
  };

  useEffect(() => {
    if (updateProfileRequestStatus === RequestStatus.ERROR) {
      setErrorMsg(['Something went wrong. Unable to update your profile']);
      setSettingsPopUp(false);
    }
    if (updateProfileRequestStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Profile is updated!']);
      setSettingsPopUp(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileRequestStatus]);

  return (
    <PopUp
      title={t('Settings')}
      className="no_padding"
      onClose={onClose}
      open={open}
    >
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        {/* <Box component="div">
          <Controller
            control={control}
            name="profile_image"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <ImageInput
                  onUpload={onChange}
                  value={value ? [value] : []}
                  isSetting
                  width={200}
                  height={200}
                />
              );
            }}
          />
        </Box> */}

        <OneInputContainer>
          <Controller
            control={control}
            name="full_name"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('Full name')}
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={errors?.full_name?.message}
                />
              );
            }}
          />
        </OneInputContainer>
        <OneInputContainer>
          <Controller
            control={control}
            name="email"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={t('Email')}
                  isRequired
                  disabled
                  value={value}
                  onChange={onChange}
                  error={errors?.email?.message}
                />
              );
            }}
          />
        </OneInputContainer>
        <OneInputContainer>
          <Controller
            control={control}
            name="language"
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <SelectItem
                  label={t('Language')}
                  onChange={onChange}
                  options={transformSelectOptionsWithTranslation(LANG_LIST, t)}
                  value={value}
                  width={70}
                />
              );
            }}
          />
        </OneInputContainer>

        <Box
          component="div"
          sx={{
            margin: '40px 0 0',
            display: 'grid',
            gridTemplateColumns:
              i18n.resolvedLanguage === 'ja' ? '120px 70px' : '70px 70px',
            justifyContent: 'flex-end',
            gridGap: '20px',
          }}
        >
          <CustomButton
            buttonType={CustomButtonType.WHITE_BG}
            title={t(`Cancel`)}
            onClick={onClose}
          />
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={t(`Submit`)}
          />
        </Box>
      </Box>
      <Loader show={loading} />
    </PopUp>
  );
}
