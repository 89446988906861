import { call, put, takeLatest } from 'redux-saga/effects';

import { StatusCode } from '@/constants/API';
import SERVICES from '@/services';
import {
  getVenueOptionsRequest,
  getVenueOptionsSuccess,
  getVenueOptionsFailure,
  getVenueListRequest,
  getVenueListSuccess,
  getVenueListFailure,
  getProvincesRequest,
  getProvincesSuccess,
  getProvincesFailure,
  getVenueDetailsRequest,
  getVenueDetailsSuccess,
  getVenueDetailsFailure,
  createVenueInfoRequest,
  createVenueInfoSuccess,
  createVenueInfoFailure,
  updateVenueInfoRequest,
  updateVenueInfoSuccess,
  updateVenueInfoFailure,
  deleteVenueInfoRequest,
  deleteVenueInfoSuccess,
  deleteVenueInfoFailure,
  updateVenuePublishSuccess,
  updateVenuePublishFailure,
  updateVenuePublishRequest,
  deleteMultiVenueInfoFailure,
  deleteMultiVenueInfoRequest,
  deleteMultiVenueInfoSuccess,
  duplicateVenueSuccess,
  duplicateVenueFailure,
  duplicateVenueRequest,
} from '../slices/venue/venue';

type IPayload = Record<string, any>;

function* getVenueOptionsFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getVenueOptions, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getVenueOptionsSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getVenueOptionsFailure.type });
    }
  } catch (error) {
    yield put({ type: getVenueOptionsFailure.type });
  }
}

function* getVenueListFlow({ payload }: IPayload): any {
  try {
    const response = payload.customerId
      ? yield call(SERVICES.getVenuesToCustomer, payload)
      : yield call(SERVICES.getVenues, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getVenueListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getVenueListFailure.type });
    }
  } catch (error) {
    yield put({ type: getVenueListFailure.type });
  }
}

function* getVenueDetailsFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.getVenueDetails, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getVenueDetailsSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getVenueDetailsFailure.type });
    }
  } catch (error) {
    yield put({ type: getVenueDetailsFailure.type });
  }
}

function* createVenueFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.createVenue, payload);

    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createVenueInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createVenueInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: createVenueInfoFailure.type });
  }
}

function* updateVenueFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updateVenue, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateVenueInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateVenueInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: updateVenueInfoFailure.type });
  }
}

function* deleteVenueFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteVenue, payload);

    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteVenueInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteVenueInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteVenueInfoFailure.type });
  }
}

function* updateMultiVenueFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.updatePublishStatusVenue, payload);

    if (
      response.status === StatusCode.SUCCESS_COMMON ||
      response.status === StatusCode.SUCCESS_CREATED
    ) {
      yield put({
        type: updateVenuePublishSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateVenuePublishFailure.type });
    }
  } catch (error) {
    yield put({ type: updateVenuePublishFailure.type });
  }
}

function* deleteMultiVenueFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.deleteVenues, payload);

    if (
      response.status === StatusCode.SUCCESS_COMMON ||
      response.status === StatusCode.SUCCESS_CREATED
    ) {
      yield put({
        type: deleteMultiVenueInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: deleteMultiVenueInfoFailure.type });
    }
  } catch (error) {
    yield put({ type: deleteMultiVenueInfoFailure.type });
  }
}

function* getProvincesFlow(): any {
  try {
    const response = yield call(SERVICES.getProvinceDistrictWard);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getProvincesSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getProvincesFailure.type });
    }
  } catch (error) {
    yield put({ type: getProvincesFailure.type });
  }
}

function* duplicateVenueFlow({ payload }: IPayload): any {
  try {
    const response = yield call(SERVICES.duplicateVenues, payload);

    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: duplicateVenueSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: duplicateVenueFailure.type });
    }
  } catch (error) {
    yield put({ type: duplicateVenueFailure.type });
  }
}

function* venueSaga() {
  yield takeLatest(getVenueOptionsRequest, getVenueOptionsFlow);
  yield takeLatest(getVenueListRequest, getVenueListFlow);
  yield takeLatest(getProvincesRequest, getProvincesFlow);
  yield takeLatest(createVenueInfoRequest, createVenueFlow);
  yield takeLatest(updateVenueInfoRequest, updateVenueFlow);
  yield takeLatest(deleteVenueInfoRequest, deleteVenueFlow);
  yield takeLatest(getVenueDetailsRequest, getVenueDetailsFlow);
  yield takeLatest(deleteMultiVenueInfoRequest, deleteMultiVenueFlow);
  yield takeLatest(updateVenuePublishRequest, updateMultiVenueFlow);
  yield takeLatest(duplicateVenueRequest, duplicateVenueFlow);
}

export default venueSaga;
