import {
  getToken,
  removeToken,
  removeUserCachedDataFromLocalStorage,
} from '@/utils/localStorage';
import type { Method as AxiosMethod } from 'axios';
import axios, { AxiosHeaders } from 'axios';

import { AppConfig } from '@/config';
import _ from 'lodash';
import Path from '@/constants/path';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/constants';

export type ESystemMethod = Extract<
  AxiosMethod,
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH'
  | 'options'
  | 'OPTIONS'
>;

const mainRequestConfig = {
  // Mock baseURL is from a local Postman Mock Server
  baseURL: AppConfig.apiBase,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Authorization: `Api-Key ${AppConfig.apiKey}`,
  },
};

const mainAxiosInstance = axios.create(mainRequestConfig);

mainAxiosInstance.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const handleTokenInvalid = () => {
  alert('Access token expired. Please log in again.');
  removeToken();
  removeUserCachedDataFromLocalStorage();
  window.location.href = Path.LOGIN;
};

mainAxiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return mainAxiosInstance(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      if (!refreshToken) {
        handleTokenInvalid();
        return Promise.reject(error);
      }

      try {
        const { data } = await mainAxiosInstance.post('/users/token/refresh/', {
          refresh: refreshToken,
        });
        const newToken = data.token;
        localStorage.setItem(ACCESS_TOKEN_KEY, newToken);
        mainAxiosInstance.defaults.headers.common['Authorization'] =
          'Bearer ' + newToken;
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;

        processQueue(null, newToken);
        return mainAxiosInstance(originalRequest);
      } catch (err) {
        processQueue(err, null);
        handleTokenInvalid();
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  },
);

export const mainRequest = (
  url: string,
  payload: any,
  method: ESystemMethod,
  headers: AxiosHeaders = new AxiosHeaders({
    'X-Requested-With': 'XMLHttpRequest',
  }),
) => {
  const data = payload;
  let params;
  if (method === 'get') {
    params = payload;
  }
  return mainAxiosInstance(url, { data, params, method, headers });
};

const SERVICES = {
  /* <AUTHEN> */
  login: (payload: any) => mainRequest('/users/login/', payload, 'post'),
  getProfile: (id: number) => mainRequest(`/profile/${id}/`, {}, 'get'),
  updateProfile: (payload: any) =>
    mainRequest(`/profile/${payload.id}/`, payload, 'put'),
  changePassword: (payload: any) =>
    mainRequest('/users/upd-pwd/', payload, 'put'),

  /* <CUSTOMERS> */
  getCustomers: (payload: any) => mainRequest('/customers/', payload, 'get'),
  getAllCustomers: (payload: any) =>
    mainRequest('/customer-options/', payload, 'get'),
  getCustomerDetails: (payload: any) =>
    mainRequest(`/customers/${payload.id}/`, payload, 'get'),
  createCustomer: (payload: any) => mainRequest(`/customers/`, payload, 'post'),
  updateCustomer: (payload: any) =>
    mainRequest(`/customers/${payload.id}/`, payload, 'put'),
  deleteCustomer: (payload: any) =>
    mainRequest(`/customers/${payload}/`, payload, 'delete'),

  /* <FLOORS> */
  getFloorLevels: (payload: any) => mainRequest('/types/', payload, 'get'),
  getFloors: (payload: any) => mainRequest('/levels/', payload, 'get'),
  getFloorsOption: (payload: any) =>
    mainRequest('/level-options/', payload, 'get'),
  getFloorDetails: (payload: any) =>
    mainRequest(`/floors/${payload.id}/`, payload, 'get'),
  createFloor: (payload: any) => mainRequest(`/levels/`, payload, 'post'),
  updateFloor: (payload: any) =>
    mainRequest(`/levels/${payload.id}/`, payload, 'put'),
  updatePublishStatusFloor: (payload: any) =>
    mainRequest(`/upd-level-publish/`, payload, 'post'),
  deleteFloors: (payload: any) =>
    mainRequest(`/del-multi-levels/`, payload, 'post'),
  deleteFloor: (payload: any) =>
    mainRequest(`/levels/${payload}/`, payload, 'delete'),

  /* <VENUES> */
  getVenueOptions: (payload: any) =>
    mainRequest('/venue-options/', payload, 'get'),
  getVenues: (payload: any) => mainRequest('/venues/', payload, 'get'),
  getVenuesToCustomer: (payload: any) =>
    mainRequest(`/customers/${payload.customerId}/venues/`, payload, 'get'),
  getVenueDetails: (payload: any) =>
    mainRequest(`/venues/${payload.id}/`, payload, 'get'),
  createVenue: (payload: any) => mainRequest(`/venues/`, payload, 'post'),
  updateVenue: (payload: any) =>
    mainRequest(`/venues/${payload.id}/`, _.omit(payload, ['id']), 'put'),
  updatePublishStatusVenue: (payload: any) =>
    mainRequest(`/upd-venue-publish/`, payload, 'post'),
  deleteVenues: (payload: any) =>
    mainRequest(`/del-multi-venues/`, payload, 'post'),
  deleteVenue: (payload: any) =>
    mainRequest(`/venues/${payload}/`, payload, 'delete'),

  /* <CONNECTION> */
  getConnections: (payload: any) =>
    mainRequest(`/connections/`, payload, 'get'),

  getConnectionDetails: (payload: any) =>
    mainRequest(`/connections/${payload.id}/`, payload, 'get'),
  createConnection: (payload: any) =>
    mainRequest(`/connections/`, payload, 'post'),
  updateConnection: (payload: any) =>
    mainRequest(`/connections/${payload.id}/`, payload, 'put'),
  deleteConnection: (payload: any) =>
    mainRequest(`/connections/${payload}/`, payload, 'delete'),

  /* <LOCATIONS> */
  getLocations: (payload: any) => mainRequest(`/locations/`, payload, 'get'),
  getAllLocations: (payload: any) =>
    mainRequest('/location-options/', payload, 'get'),

  getLocationDetails: (payload: any) =>
    mainRequest(`/locations/${payload.id}/`, payload, 'get'),
  createLocation: (payload: any) => mainRequest(`/locations/`, payload, 'post'),
  updateLocation: (payload: any) =>
    mainRequest(`/locations/${payload.id}/`, payload, 'put'),
  deleteLocation: (payload: any) =>
    mainRequest(`/locations/${payload}/`, payload, 'delete'),

  /* <EVENTS> */
  getEventTags: () => mainRequest(`/event-tags/`, null, 'get'),
  getEventTypes: () => mainRequest(`/event-types/`, null, 'get'),

  getEvents: (payload: any) => mainRequest(`/events/`, payload, 'get'),

  getEventDetails: (payload: any) =>
    mainRequest(`/events/${payload.id}/`, payload, 'get'),
  createEvent: (payload: any) => mainRequest(`/events/`, payload, 'post'),
  updateEvent: (payload: any) =>
    mainRequest(`/events/${payload.id}/`, payload, 'put'),
  deleteEvent: (payload: any) =>
    mainRequest(`/events/${payload}/`, payload, 'delete'),

  /* <AMENITY> */
  getAmenities: (payload: any) => mainRequest(`/amenities/`, payload, 'get'),
  getLocationAmenities: (payload: any) =>
    mainRequest(`/amenities/`, payload, 'get'),
  createAmenity: (payload: any) => mainRequest(`/amenities/`, payload, 'post'),
  updateAmenity: (payload: any) =>
    mainRequest(`/amenities/${payload.id}/`, payload, 'put'),
  deleteAmenity: (payload: any) =>
    mainRequest(`/amenities/${payload}/`, payload, 'delete'),
  setVenueAmenities: (payload: any) => {
    return mainRequest(
      `/set-amenities/${payload.venueId}/`,
      _.omit(payload, ['venueId']),
      'put',
    );
  },

  /* <CATEGORY> */
  getCategoriesToVenue: (payload: any) =>
    mainRequest(`/categories/`, payload, 'get'),

  createCategory: (payload: any) =>
    mainRequest(`/categories/`, payload, 'post'),
  updateCategory: (payload: any) =>
    mainRequest(`/categories/${payload.id}/`, payload, 'put'),
  deleteCategory: (payload: any) =>
    mainRequest(`/categories/${payload}/`, payload, 'delete'),

  /* <QRCode> */
  getQRCodes: (payload: any) => mainRequest(`/qrcodes/`, payload, 'get'),
  getQRCodeDetail: (payload: any) =>
    mainRequest(`/qrcodes/${payload.id}/`, payload, 'get'),
  createQRCode: (payload: any) => mainRequest(`/qrcodes/`, payload, 'post'),
  updateQRCode: (payload: any) =>
    mainRequest(`/qrcodes/${payload.id}/`, payload, 'put'),
  deleteQRCode: (payload: any) =>
    mainRequest(`/qrcodes/${payload}/`, payload, 'delete'),

  /* <TAGS> */
  getTags: () => mainRequest(`/tags/`, null, 'get'),

  /* <CATEGORIES> */
  getCategories: () => mainRequest(`/categories/`, null, 'get'),

  /* <SYNC> */
  // syncToServer: ({
  //   payload,
  //   venueId,
  //   levelId,
  // }: {
  //   payload: string;
  //   venueId: string;
  //   levelId: string;
  // }) => mainRequest(`/sync/${venueId}/${levelId}/`, payload, 'post'),

  getSnapshots: (payload: any) =>
    mainRequest(`/snapshots/${payload.venueId}/`, payload, 'get'),
  deleteSnapshot: (payload: any) =>
    mainRequest(`/snapshots/revert/${payload}/`, payload, 'delete'),

  /* <PROVINCE> */
  getProvinceDistrictWard: () =>
    axios({
      url: 'https://raw.githubusercontent.com/kenzouno1/DiaGioiHanhChinhVN/master/data.json',
      method: 'GET',
    }),

  /* <GEO> */
  geoSearch: (payload: any) => mainRequest(`/geo-search/`, payload, 'get'),

  /* <CATEGORY> */
  duplicateVenues: (payload: any) =>
    mainRequest(`/venues/${payload.id}/clone/`, payload, 'post'),
};

export default SERVICES;
