export const UploadImgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M1 4.75C1 4.02065 1.28973 3.32118 1.80546 2.80546C2.32118 2.28973 3.02065 2 3.75 2H20.25C20.9793 2 21.6788 2.28973 22.1945 2.80546C22.7103 3.32118 23 4.02065 23 4.75V18.5C23 19.2293 22.7103 19.9288 22.1945 20.4445C21.6788 20.9603 20.9793 21.25 20.25 21.25H3.75C3.02065 21.25 2.32118 20.9603 1.80546 20.4445C1.28973 19.9288 1 19.2293 1 18.5V4.75ZM2.375 17.125V18.5C2.375 18.8647 2.51987 19.2144 2.77773 19.4723C3.03559 19.7301 3.38533 19.875 3.75 19.875H20.25C20.6147 19.875 20.9644 19.7301 21.2223 19.4723C21.4801 19.2144 21.625 18.8647 21.625 18.5V13.6875L16.4316 11.0104C16.3027 10.9458 16.1567 10.9234 16.0143 10.9463C15.8719 10.9693 15.7404 11.0364 15.6383 11.1383L10.537 16.2395L6.8795 13.803C6.74745 13.7151 6.58905 13.6755 6.43117 13.6911C6.27328 13.7066 6.12563 13.7763 6.01325 13.8883L2.375 17.125ZM9.25 8.1875C9.25 7.64049 9.0327 7.11589 8.64591 6.72909C8.25911 6.3423 7.73451 6.125 7.1875 6.125C6.64049 6.125 6.11589 6.3423 5.72909 6.72909C5.3423 7.11589 5.125 7.64049 5.125 8.1875C5.125 8.73451 5.3423 9.25911 5.72909 9.64591C6.11589 10.0327 6.64049 10.25 7.1875 10.25C7.73451 10.25 8.25911 10.0327 8.64591 9.64591C9.0327 9.25911 9.25 8.73451 9.25 8.1875Z"
      fill="#6C7587"
    />
  </svg>
);
